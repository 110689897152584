import React from "react";
import ClearIcon from "@material-ui/icons/Clear";

const style = {
  borderRadius: 20,
  height: 20,
  width: 20,
  backgroundColor: "red",
  cursor: "pointer",
};

export function RedClearButton({ onClick }) {
  return (
    <div style={style} onClick={onClick}>
      <ClearIcon fontSize="small" htmlColor="#FFF" />
    </div>
  );
}
