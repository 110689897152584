import React from 'react';
import { connect } from 'react-redux';
// import { rosterActions } from '../../redux/actions';
import ShiftButton from '../../../components/TabButton';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';


const StyledShiftContainer = withStyles({
    root: {
        flex: '0 0 auto',
        backgroundColor: '#fafafa'
    }
})(Box)

const ShiftSelector = ({ shift, setShift, fleetGroup, date, normalRental, persistRental }) => {

    const {
        morningTotalOnShift,
        nightTotalOnShift
    } = persistRental[fleetGroup] && persistRental[fleetGroup][date] && persistRental[fleetGroup][date].summary ? persistRental[fleetGroup][date].summary : {}


    const handleClick = (index) => {
        setShift(index)
    }

    return <StyledShiftContainer>

        <ShiftButton
            select={shift === 0 ? 'select' : undefined}
            onClick={() => handleClick(0)}>日更{normalRental.isEditable && ` (${morningTotalOnShift})`}</ShiftButton>
        <ShiftButton
            select={shift === 1 ? 'select' : undefined}
            onClick={() => handleClick(1)}>夜更{normalRental.isEditable && ` (${nightTotalOnShift})`}</ShiftButton>

    </StyledShiftContainer>
}
const mapStateToProps = (state) => ({
    persistRental: state.persistRental,
    normalRental: state.normalRental,

    // selector:state.roster.selector
})
const mapDispatchToProps = (dispatch) => ({
    // getRoster: (fleet,fleetGroup, shift, from, to) => dispatch({ type: rosterActions.GET_ROSTER,fleet, fleetGroup, shift, from, to })
})
export default connect(mapStateToProps, mapDispatchToProps)(ShiftSelector);





