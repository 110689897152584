import React, { useMemo } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";
import { rosterActions } from "../../../redux/actions";
import RosterIcon from "../../../components/Icon/Roster";
import { CenterBox } from "../../../components/Box/CenterBox";
import moment from "moment";
import { checker } from "../../../permission";
import "../roster.css";


const ReplacementDriverBtn = withStyles((props) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 8px",
    opacity: (props) => (props.disabled ? 0.6 : 1),
    cursor: (props) => (props.disabled ? "unset" : "pointer"),
    "&:hover": {
      backgroundColor: (props) =>
        props.disabled ? "unset" : "rgba(0,0,0,0.07)",
      borderRadius: "8px",
    },
    borderBottom: "none",
  },
}))(Box);

const StyledTableCell = withStyles((props) => ({
  root: {
    width: "10%",
    minWidth: 90,
    position: "relative",
    padding: "0px 16px",
    borderBottom: (props) => (props.todayIsPosted ? "none" : null),
    backgroundColor: (props) =>
      props.before ? "rgba(0,0,0,0.3)" : props.today ? "#dddcfb" : "unset",
  },
}))(TableCell);

const RosterCell = ({
  role,
  row,
  date,
  dayOfWeek,
  positionOfWeek,
  driverObj,
  taxiId,
  replacement,
  openAndSetPartTimeDialog,
  driverShift,
  todayIsPosted,
  children,

  openDialogAndSetDriverInfo
}) => {

  const todayReplacement = replacement[date];
  const replacementDriverId =
    todayReplacement &&
    todayReplacement.data &&
    Object.keys(todayReplacement.data).find(
      (driverId) => todayReplacement.data[driverId].taxi._id === taxiId
    );
  const notEditable = useMemo(() => !checker(role, "roster:edit"), [role]);
  const handleOpenFullTimeDialog=()=>{
        openDialogAndSetDriverInfo({
          fullTimeDriverId:driverObj._id,
          fullTimeDriverName:driverObj.name,
          date,
          dayOfWeek,
          taxiId
        })
      }

  const isBefore = moment(date).isBefore(moment(), "day");
  const isToday = moment(date).isSame(moment(), "day");
  const handlePartTimeDriverSelection = () =>
    isBefore || notEditable
      ? undefined
      : openAndSetPartTimeDialog({
          driverObj,
          dayOfWeek,
          positionOfWeek,
          targetDate: date,
          taxiId: taxiId,
          pendingUnits:row?.["pendingUnits"]?.[date]?._id,
          leave: row?.["leaves"]?.[date]?._id,
          replacementDriver:
            replacementDriverId && todayReplacement.data[replacementDriverId],
        });

  let text;
  text =
    row?.leaves?.[date]?.status === "PENDING" ? (
      <div className="cell-box">待確認休假</div>
    ) : 
      row?.leaves?.[date] ||
      row?.pendingUnits?.[date]||
      !row?.[driverShift] ||
      !row?.[driverShift]?.driver ||
      row?.[driverShift]?.driver?.dayoff.includes(dayOfWeek) ? (
      replacementDriverId ? (
        <CenterBox>
          <ReplacementDriverBtn
            onClick={handlePartTimeDriverSelection}
            disabled={(isToday && todayIsPosted) || isBefore || notEditable}
          >
            <Box mr={0.5} whiteSpace="nowrap">
              {todayReplacement.data[replacementDriverId].driver.name}
            </Box>
            <RosterIcon
              type={todayReplacement.data[
                replacementDriverId
              ].status.toLowerCase()}
            />
          </ReplacementDriverBtn>
        </CenterBox>
      ) : (
        <IconButton
          onClick={handlePartTimeDriverSelection}
          disabled={isBefore || notEditable}
        >
          <RosterIcon disabled={notEditable} type="add" />
        </IconButton>
      )
    ) : (
      <IconButton
        onClick={handleOpenFullTimeDialog}
        disabled={isBefore || notEditable}
      >
        <RosterIcon disabled={notEditable} type="normal" />
      </IconButton>
    );

  return (
    <StyledTableCell
      before={isBefore ? "true" : undefined}
      today={isToday ? "true" : undefined}
      todayIsPosted={isToday && todayIsPosted}
      align={"center"}
    >
      {text}
      {isToday && todayIsPosted ? (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      ) : null}
      {children}

    </StyledTableCell>
  );
};
const mapStateToProps = (state) => ({
  todayIsPosted: state.roster.todayIsPosted,
  role: state.user.role,
  replacement: state.roster.replacement,
});
const mapDispatchToProps = (dispatch) => ({
  openDialogAndSetDriverInfo:({fullTimeDriverId,fullTimeDriverName,date,dayOfWeek,taxiId})=>
    dispatch({type:rosterActions.OPEN_AND_SET_ROSTER_CELL_DIALOG,
      fullTimeDriverId,
      fullTimeDriverName,
      date,
      dayOfWeek,
      taxiId
    }),
  openAndSetPartTimeDialog:(payload)=>dispatch({type:rosterActions.OPEN_AND_SET_PART_TIME_DIALOG,...payload})
});
export default connect(mapStateToProps, mapDispatchToProps)(RosterCell);
