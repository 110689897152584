import React, { useCallback, useMemo } from 'react';
import Modal from '../../components/Modal';
import { Box, withStyles } from '@material-ui/core';
import StyledButton from '../../components/Button';

const Wrapper = withStyles({
    root: {
        margin: '10px 0px'
    }
})(Box)

const BtnBox = withStyles({
    root: {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: 'end',
        marginTop: '24px',
    }
})(Box)

export const OverlayDialog = ({ dialogStatus, setDialogStatus }) => {
    const { type, isSuccess, isErrorOccur, isLoading, uploadStatus, isAllDone,
        setRefreshCounter, onClickAction } = dialogStatus;

    const showUploadResult = useCallback(() => {
        return <>
            <Box mt={1} fontWeight="bold">以下文件未能上傳：</Box>
            {uploadStatus.map(({ fileName, isSuccess }) => isSuccess
                ?
                ""
                :
                <Box style={{ color: 'red' }} key={fileName}>檔案名稱：{fileName}-{isSuccess ? "上傳成功" : "上傳失敗"}</Box>
            )}
            <Box>請重新上傳。</Box>
        </>
    }, [uploadStatus])

    const closeDialog = () => {
        if (isSuccess) {
            setRefreshCounter(prev => prev + 1)
        }
        if (isErrorOccur) {
            return setDialogStatus({ isOpen: false })
        }
        setDialogStatus(prev => ({ ...prev, isOpen: false }))
        setTimeout(() => setDialogStatus({ isOpen: false }), 200)
    }

    const confirmAction = () => {
        onClickAction()
        closeDialog()
    }

    const [title, contents, btnText] = useMemo(() => {
        if (isLoading) {
            return ["請稍後", ""]
        }
        if (isSuccess) {
            return [isAllDone ? "成功" : "注意！", <><Box>已更新維修資料</Box>{isAllDone ? "" : showUploadResult()}</>, "關閉"]
        }
        if (type === "conflict") {
            return ["注意！", <Box>項目尚未達到需要維修里數</Box>]
        }

        return ["", ""]

    }, [isLoading, type, isSuccess, isAllDone, showUploadResult])

    return (<Modal disableBGClose={true} {...{ closeDialog, title, ...dialogStatus }}>
        <Wrapper>
            {isLoading ?
                "資料處理中，請稍後" :
                type === "conflict" ?
                    <>
                        <Box>{contents}</Box>
                        <BtnBox>
                            <Box mr={1}>
                                <StyledButton background="white" variant="outlined" onClick={closeDialog} >
                                    取消
                            </StyledButton >
                            </Box>
                            <StyledButton background="purple" onClick={confirmAction} >
                                確定繼續
                            </StyledButton >
                        </BtnBox>
                    </>
                    :
                    isSuccess &&
                    <>
                        <Box>{contents}</Box>
                        <BtnBox>
                            <StyledButton background="purple" onClick={closeDialog} >
                                {btnText}
                            </StyledButton >
                        </BtnBox>
                    </>
            }
        </Wrapper>
    </Modal>
    )
}
