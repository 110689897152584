import { submitRentalTransaction } from "../redux/API";
import _ from "lodash";
import { persistRentalActions } from "../redux/actions";
import { store } from "../App";
import moment from 'moment';

const handleRentalSubmit = async ({
  persistRental,
  date,
  fleetId,
  fleetGroup,
  setDialogStatus,
  setRefetchAPI,
}) => {
  setDialogStatus({ isOpen: true, isLoading: true, isChecking: true });

  let {
    morningTaxi,
    nightTaxi,
    morningDeductionList,
    nightDeductionList,
    morningDeductionUi,
    nightDeductionUi,
    removedDrivers,
    rentArrearsEntities,
  } = persistRental[fleetGroup][date];
  if (morningDeductionUi === "dialog") {
    morningDeductionList = [];
  }
  if (nightDeductionUi === "dialog") {
    nightDeductionList = [];
  }

  const morningTaxiErr = taxiRentalChecker(morningTaxi);
  const nightTaxiErr = taxiRentalChecker(nightTaxi);
  const morningDeductionErr = deductionChecker(
    morningDeductionList,
    rentArrearsEntities
  );
  const nightDeductionErr = deductionChecker(
    nightDeductionList,
    rentArrearsEntities
  );

  if (
    morningTaxiErr ||
    nightTaxiErr ||
    morningDeductionErr ||
    nightDeductionErr
  ) {
    const err = {
      taxi: {
        0: taxiRentalChecker(morningTaxi),
        1: taxiRentalChecker(nightTaxi),
      },
      driver: {
        0: morningDeductionErr,
        1: nightDeductionErr,
      },
    };
    return setDialogStatus({
      isOpen: true,
      isLoading: false,
      isChecking: false,
      err,
    });
  }

  try {
    setDialogStatus({ isOpen: true, isLoading: true, isChecking: false });
    const transaction = await submitRentalTransaction({
      fleet: fleetId,
      fleetGroup,
      date: date,
      taxis: _convertTransactionFormat(morningTaxi.concat(nightTaxi)),
      drivers: _convertDeductionFormat(
        morningDeductionList.concat(nightDeductionList)
      ),
      removedDrivers: removedDrivers || [],
    });

    if (transaction.msg) {
      setDialogStatus({ isOpen: true, isLoading: false, isSuccess: true });
      setRefetchAPI((prev) => prev + 1);
      store.dispatch({
        type: persistRentalActions.DELETE_TARGET_DATE_RECORD,
        fleetGroup,
        date,
      });
    }
  } catch (e) {
    setDialogStatus({
      isOpen: true,
      isLoading: false,
      isErrorOccur: true,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
    console.log(e);
  }
};

const _convertTransactionFormat = (arr) => {
  return arr.map((taxi) => {
    if (taxi.isAttended) {
      return {
        increasedRent: taxi.increasedRent,
        waivedRent: taxi.waivedRent,
        reason: taxi.reason,
        actualRent: taxi.actualRent,
        payable: taxi.payable,
        _id: taxi._id,
        driver: taxi.driver._id,
        isAttended: taxi.isAttended,
      };
    } else {
      return {
        _id: taxi._id,
        isAttended: taxi.isAttended,
      };
    }
  });
};

const _convertDeductionFormat = (arr) => {
  return arr.reduce((acc, data) => {
    const driverRentArrear = data.driverRentArrear
      ? data.driverRentArrear.reduce(
        (rentArrearsAccumulator,{ rentArrears, increasedRent, waivedRent, reason, paid,_id }) => {
            if(reason||paid){
              rentArrearsAccumulator.push({
                _id,
                amount: paid,
                driver: data.driver,
                shift: data.shift,
                type: "RENT_ARREARS",
                rentArrears,
                increasedRent,
                waivedRent,
                reason,
              })
            }
            return rentArrearsAccumulator
          }
        ,[])
      : [];

    const others = data.others
      ? data.others.map(({ type, amount,...rest }) => ({
          driver: data.driver,
          shift: data.shift,
          type,
          amount,
          ...rest
        }))
      : [];
      
    return [...acc, ...driverRentArrear, ...others];
  }, []);
};

const taxiRentalChecker = (arr) => {
  const list = arr.filter((item) => item.isAttended);
  const msg = list.reduce((acc, curr, index) => {
    let err = "";
    if ((curr.waivedRent || curr.increasedRent) && curr.reason === "OTHERS") {
      err += "請填寫調整租金「原因」。";
    }
    if (!curr.waivedRent && !curr.increasedRent && curr.reason) {
      err += "必須輸入調整「金額」。";
    }
    if (curr.waivedRent && !curr.reason) {
      err += "必須輸入「扣租原因」。";
    }
    if (curr.increasedRent && !curr.reason) {
      err += "必須輸入「加租原因」。";
    }

    if (curr.actualRent > curr.payable) {
      err += "實付租金超出應交租金。";
    }
    if (!curr.driver || !curr.driver._id) {
      err += "必須選擇司機。";
    } else {
      list.forEach((item, i) => {
        if (i !== index && item.driver && item.driver._id === curr.driver._id) {
          err += `所選司機與${item.taxi.plateNumber}重覆。`;
        }
      });
    }
    if (err) {
      return {
        ...acc,
        [curr.taxi.plateNumber]: err,
      };
    } else {
      return acc;
    }
  }, "");
  return msg;
};

const deductionChecker = (arr, rentArrearsEntities) => {
  const err = arr.reduce((acc, curr, index) => {
    let error = false;
    let msg;
    let driverRentArrearErr = [];
    let othersErr = [];
    if (!curr.driver) {
      msg = "請選擇司機。如不需要儲存，請刪除此行紀錄。";
      error = true;
    } else {
      let noDriverRentArrear=false;
      if(!curr.driverRentArrear||curr.driverRentArrear.length===0){
        noDriverRentArrear=true;
      }
      if (curr.driverRentArrear&&curr.driverRentArrear.length > 0) {
        let missingRentArrearInput=0;
        curr.driverRentArrear.forEach(
          (
            {
              rentArrears,
              waivedRent,
              increasedRent,
              reason,
              waiveOrIncreaseRent,
              paid,
            },
            i
          ) => {
            if(!(waiveOrIncreaseRent|| reason || increasedRent || waivedRent || paid)){
              // noDriverRentArrear=true;
              missingRentArrearInput++;
            }
            if (
              waiveOrIncreaseRent !== null &&
              !(reason || increasedRent || waivedRent)
            ) {
              driverRentArrearErr[i] = "未填寫調整欠款金額及原因。";
              error = true;
            }
            if (reason && !increasedRent && !waivedRent) {
              driverRentArrearErr[i] = "未填寫調整欠款金額。";
              error = true;
            }
            if (
              (waivedRent || increasedRent) &&
              (!reason || reason === "OTHERS")
            ) {
              driverRentArrearErr[i] = "未填寫調整欠款原因。";
              error = true;
            }

            if(rentArrearsEntities[rentArrears]){
            var finalDebt =
              rentArrearsEntities[rentArrears].amount + increasedRent - waivedRent;
            if (finalDebt < 0) {
              driverRentArrearErr[i] = `${
                (driverRentArrearErr[i] || "") + "調整後總欠款不能少於0。"
              }`;
              error = true;
            }
            if (finalDebt < paid) {
              driverRentArrearErr[i] = `${
                (driverRentArrearErr[i] || "") + "還款金額不能大過總欠款。"
              }`;
              error = true;
            }
            }else{
              driverRentArrearErr[i] = `${
                (driverRentArrearErr[i] || "") + " "
              }`;
              error = true;
            }
          }
        );
        if(missingRentArrearInput===curr.driverRentArrear.length){
          noDriverRentArrear=true;
        }
      }

      if (curr.others&&curr.others.length > 0) {
        curr.others.forEach(({ type, amount }, i) => {
          if (!type && !amount) {
            othersErr[i] = "未選擇原因及金額。如不需要入帳，請刪除此項。";
            error = true;
          }
          if (type && !amount) {
            othersErr[i] = "未填寫金額";
            error = true;
          }
          if ((!type && amount) || (type === "OTHERS" && amount)) {
            othersErr[i] = "未選擇原因";
            error = true;
          }
        });
      }
      if((!curr.others||curr.others.length === 0)&&noDriverRentArrear){
        error = true;
        msg = "沒有任何入帳或還款。如不需要儲存，請刪除此行紀錄。";
      }
    }
    if (error) {
      return { ...acc, [index]: { msg, driverRentArrearErr, othersErr } };
    } else {
      return acc;
    }
  }, "");

  return err;
};

const driverTransactionResponseFormat = (arr,rentArrearsList,rentArrearsEntities) => {

  let driverIdToIndex = {morning:{},night:{}};
  let index = {morning:0,night:0};
  let acc={totalRentPaidReceived:0,totalDeposit:0,morningDeductionList:[],nightDeductionList:[],popUpMsg:{morning:{},night:{}},isPopUpOpen:false}
  const rentArrearObj={...rentArrearsList};

  if (!arr || arr.length === 0) {
    return acc
  }
  const deductionList={
    "morning":acc.morningDeductionList,
    "night":acc.nightDeductionList
  }

  arr.forEach(
    ({
      _id,
      driver,
      type,
      amount,
      increasedRent,
      waivedRent,
      reason,
      shift,
      rentArrears
    }) => {
      if (driverIdToIndex[shift][driver._id]===undefined) {
        deductionList[shift][index[shift]] = {
          driver: driver._id,
          shift,
        };
        driverIdToIndex[shift][driver._id] = index[shift];
        index[shift]++;
      }
      if (type === "RENT_ARREARS") {
        if(driver._id){
          const rentArrearDetail = {
            _id,
            rentArrears: rentArrears?._id,
            date:rentArrears?.date,
            shift:rentArrears?.shift,
            plateNumber:rentArrears?.taxiTransaction?.taxi?.plateNumber,
            paid: amount,
            increasedRent,
            waivedRent,
            waiveOrIncreaseRent: waivedRent ? 0 : increasedRent ? 1 : null,
            reason,
          };
          if (deductionList[shift][driverIdToIndex[shift][driver._id]].driverRentArrear) {
            deductionList[shift][driverIdToIndex[shift][driver._id]].driverRentArrear.push(
              rentArrearDetail
            );
          } else {
            deductionList[shift][driverIdToIndex[shift][driver._id]].driverRentArrear = [
              rentArrearDetail
            ];
          }

          if(rentArrearObj&&rentArrearObj[driver._id]&&rentArrearObj[driver._id].includes(rentArrears?._id)){
            const rentArrearsIndex=rentArrearObj[driver._id].indexOf(rentArrears._id);
            rentArrearObj[driver._id].splice(rentArrearsIndex,1);
            acc.totalRentPaidReceived+=amount;
          }else{
            acc.popUpMsg[shift][rentArrears._id]=true
            acc.isPopUpOpen=true;
          }
        }
      } else {
        const otherDetail = { _id, type, amount };
        if(type==="DEPOSIT"){
          acc.totalDeposit+=amount;
        }else{
          acc.totalRentPaidReceived+=amount;
        }
        if (deductionList[shift][driverIdToIndex[shift][driver._id]].others) {
          deductionList[shift][driverIdToIndex[shift][driver._id]].others.push(otherDetail);
        } else {
          deductionList[shift][driverIdToIndex[shift][driver._id]].others = [otherDetail];
        }
      }
    }
  );

  let checkNewRentArrears = (deduction) => {
    if (rentArrearObj && rentArrearObj[deduction.driver]) {
      let shift = deduction.shift;
      let driver = deduction.driver;
      let driverDebt = deductionList[shift][driverIdToIndex[shift][driver]].driverRentArrear ||
        [];

      rentArrearObj[deduction.driver].forEach((id) => {
        const rentArrearsDetail = {
          rentArrears: id,
          date: rentArrearsEntities[id].date,
          shift: rentArrearsEntities[id].shift,
          plateNumber:
            rentArrearsEntities[id].taxiTransaction?.taxi?.plateNumber,
          paid: 0,
          increasedRent: 0,
          waivedRent: 0,
          waiveOrIncreaseRent: null,
          reason: null,
        };

        let continueLooping = true;
        let index = 0;
        while (continueLooping && index < driverDebt.length) {
          if (
            moment(driverDebt[index].date).isAfter(
              rentArrearsDetail.date,
              "day"
            )
          ) {
            continueLooping = false;
            driverDebt.splice(index, 0, rentArrearsDetail);
            return 
          }
          index++;
        }
        return driverDebt.push(rentArrearsDetail);
      });
      deductionList[shift][driverIdToIndex[shift][driver]].driverRentArrear = driverDebt;
    }
  };
  
  acc.morningDeductionList.forEach(checkNewRentArrears);
  acc.nightDeductionList.forEach(checkNewRentArrears);

  return acc;
};

const driverTransactionRentArrearUpdate=(deductionList,arrears,rentArrearsEntities)=>{

  const rentArrearsList=JSON.parse(JSON.stringify(arrears));
  let popUpMsg={};
  let isPopUpOpen=false;
  for(let i=0;i<deductionList.length;i++){
    const driverId=deductionList[i].driver;
    const driverRentArrear=deductionList[i].driverRentArrear;
    let newDriverRentArrear=[];

    if(driverRentArrear){
      // eslint-disable-next-line
      driverRentArrear.forEach((item)=>{
          if(rentArrearsList&&rentArrearsList[driverId]&&rentArrearsList[driverId].includes(item.rentArrears)){
            const rentArrearsIndex=rentArrearsList[driverId].indexOf(item.rentArrears);
            rentArrearsList[driverId].splice(rentArrearsIndex,1)
          }else{
            popUpMsg[item.rentArrears]=true;
            isPopUpOpen=true;
          }
          newDriverRentArrear.push(item)
      });
    }

    if(driverId&&rentArrearsList&&rentArrearsList[driverId]){
      rentArrearsList[driverId].forEach(id=>{
        newDriverRentArrear.push({
          rentArrears:id,
          date:rentArrearsEntities[id].date,
          shift:rentArrearsEntities[id].shift,
          plateNumber:rentArrearsEntities[id].taxiTransaction?.taxi?.plateNumber,
          waivedRent:0,
          increasedRent:0,
          reason:null,
          waiveOrIncreaseRent:null,
          paid:0})
      })
    }

    if(driverRentArrear||newDriverRentArrear.length>0){
      deductionList[i].driverRentArrear=newDriverRentArrear;
    }
  }

  return {result:deductionList,isPopUpOpen,popUpMsg}
}

const removedDriversChecker=(deductionDriverObj,currentRemovedDrivers)=>{
  let removedDrivers=[...currentRemovedDrivers];
  if(deductionDriverObj.driverRentArrear){
    deductionDriverObj.driverRentArrear.forEach(item=>{
      if(item._id){
        removedDrivers.push(item._id)
      }
    })
  }
  if(deductionDriverObj.others){
    deductionDriverObj.others.forEach(item=>{
      if(item._id){
        removedDrivers.push(item._id)
      }
    })
  }
  return removedDrivers
}

const permissionChecker = ({ role, item }) => {
  switch (role) {
    case "fleet_manager":
    case "group_leader":
      if (item.status === "PENDING" || item.status === "SUBMITTED") {
        return true;
      } else {
        return false;
      }
    case "super_admin":
    case "operator":
      if (item.status === "PENDING") {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
};

const taxiResponseFormat = (arr) => {
  const rent = _.groupBy(arr, (current) => current.shift);
  const summary = summaryCalculator(rent["morning"], rent["night"]);
  if (arr.length > 0) {
    summary.lastUpdateAt = arr[0].lastUpdateAt;
  }
  return {
    morningTaxi: rent["morning"] || [],
    nightTaxi: rent["night"] || [],
    summary,
  };
};

const displayTaxiTransaction = (arr) => {
  const rent = _.groupBy(arr, (current) => current.shift);
  const summary = summaryCalculator(rent["morning"], rent["night"]);
  if (arr.length > 0) {
    summary.lastUpdateAt = arr[0].lastUpdateAt;
  }
  const taxiTransaction = taxiTransactionSum(arr);
  return { taxiTransaction, summary };
};

const taxiTransactionSum = (arr) => {
  return arr.reduce((acc, curr) => {
    if (curr.morningShiftRent !== undefined) {
      return {
        ...acc,
        [curr.taxi.plateNumber]: {
          ...acc[curr.taxi.plateNumber],
          morningPaid: curr.actualRent || 0,
          morningDriver: curr.driver && curr.driver.name,
          isMorningAttended: curr.isAttended,
        },
      };
    } else {
      return {
        ...acc,
        [curr.taxi.plateNumber]: {
          ...acc[curr.taxi.plateNumber],
          nightPaid: curr.actualRent || 0,
          nightDriver: curr.driver && curr.driver.name,
          isNightAttended: curr.isAttended,
        },
      };
    }
  }, {});
};

const summaryCalculator = (morningTaxi, nightTaxi) => {
  let summary = {
    expectedRent: 0,
    morningRent: 0,
    morningPaid: 0,
    morningTotalOnShift: 0,
    nightRent: 0,
    nightPaid: 0,
    nightTotalOnShift: 0,
    totalRent: 0,
    totalPaid: 0,
    totalRentArrear: 0,
    totalDeposit:0
  };
  morningTaxi &&
    morningTaxi.forEach((taxi) => {
      summary.expectedRent += taxi.morningShiftRent;
      summary.morningRent += taxi.morningShiftRent;
      const rentArrear =
        taxi.morningShiftRent +
        (taxi.increasedRent || 0) -
        taxi.waivedRent -
        taxi.actualRent;
      if (taxi.isAttended) {
        summary.morningPaid += taxi.actualRent;
        summary.totalRent +=
          taxi.morningShiftRent + (taxi.increasedRent || 0) - taxi.waivedRent;
        summary.totalPaid += taxi.actualRent;
        summary.totalRentArrear += (rentArrear > 0 ? rentArrear : 0);
        if (taxi.actualRent > 0) {
          summary.morningTotalOnShift += 1;
        }
      }
    });
  nightTaxi &&
    nightTaxi.forEach((taxi) => {
      summary.expectedRent += taxi.nightShiftRent;
      summary.nightRent += taxi.nightShiftRent;
      const rentArrear =
        taxi.nightShiftRent +
        (taxi.increasedRent || 0) -
        taxi.waivedRent -
        taxi.actualRent;
      if (taxi.isAttended) {
        summary.nightPaid += taxi.actualRent;
        summary.totalRent +=
          taxi.nightShiftRent + (taxi.increasedRent || 0) - taxi.waivedRent;
        summary.totalPaid += taxi.actualRent;
        summary.totalRentArrear += (rentArrear > 0 ? rentArrear : 0);
        if (taxi.actualRent > 0) {
          summary.nightTotalOnShift += 1;
        }
      }
    });

  return summary;
};

const totalDepositCalculator = (array) => {
  return array.reduce((acc, curr) => {
    if (curr.type === "DEPOSIT") {
      acc.totalDeposit+=curr.amount;
    } else {
      acc.totalRentPaidReceived+=curr.amount;
    } 
      return acc;
  }, {totalDeposit:0,totalRentPaidReceived:0});
};

const persistStoreDeductionSummaryCalculator = (array1=[],array2=[]) => {
  const array=[...array1,...array2];
  return array.reduce((acc, curr) => {
    if (curr.others && curr.others.length > 0) {
      curr.others.forEach(({ type, amount }) => {
        if (type === "DEPOSIT") {
          acc.totalDeposit += amount;
        }else{
          acc.totalRentPaidReceived+=amount;
        }
      });
    }
    if(curr.driverRentArrear&&curr.driverRentArrear.length>0){
      curr.driverRentArrear.forEach(({paid})=>{
        acc.totalRentPaidReceived+=paid;
      })
    }
    return acc;
  }, {totalDeposit:0,totalRentPaidReceived:0});
};

const deleteRow = (array, index) => {
  let newArr = [...array];
  newArr.splice(index, 1);
  return newArr;
};

const setWaiveOrIncreaseRent = (array, index) => {
  let newArr = [...array];
  newArr[index] = {
    ...newArr[index],
    reason: "",
    increasedRent: 0,
    waivedRent: 0,
  };
  return newArr;
};

export const RentalService = {
  taxiRentalChecker,
  deductionChecker,
  handleRentalSubmit,
  driverTransactionResponseFormat,
  driverTransactionRentArrearUpdate,
  removedDriversChecker,
  permissionChecker,
  taxiResponseFormat,
  summaryCalculator,
  totalDepositCalculator,
  persistStoreDeductionSummaryCalculator,
  displayTaxiTransaction,
  deleteRow,
  setWaiveOrIncreaseRent,
};
