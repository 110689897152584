import { fleetActions } from "../actions";
const init = { status: "init", list: [], isReady: false };

export default function fleetReducer(state = init, action) {
  let index;
  switch (action.type) {
    case fleetActions.GET_ALL_FLEET:
      return {
        ...state,
        status: "loading",
        isReady: false,
      };
    case fleetActions.UPDATE_FLEET:
      return {
        ...state,
        status: "ready",
        list: action.fleet,
        isReady: true,
      };
    case fleetActions.UPDATE_DAYS_APPLY_LEAVE:
      const newList = state.list.slice();
      index = newList.findIndex((fleet) => fleet._id === action.fleet._id);
      newList[index] = { ...newList[index], daysBeforeApplyLeave: action.day };
      return {
        ...state,
        status: "ready",
        list: newList,
        isReady: true,
      };
    case fleetActions.CREATE_FLEET:
      return {
        ...state,
        status: "loading",
        isReady: true,
      };
    case fleetActions.ADD_NEW_FLEET:
      return {
        ...state,
        status: "ready",
        isCloseDialogNeeded: true,
        list: [...state.list, action.fleet],
        isReady: true,
      };
    case fleetActions.FAIL_CREATE_FLEET:
      return {
        ...state,
        status: "error",
        errMsg: action.errMsg,
      };
    case fleetActions.ERROR_FETCHING_FLEET:
      return {
        ...state,
        status: "error",
        isReady: false,
        errMsg: action.errMsg,
      };
    case fleetActions.RESET_FLEET_STATUS:
      return {
        ...state,
        status: "ready",
        errMsg: "",
        isCloseDialogNeeded: false,
      };
    default:
      return state;
  }
}
