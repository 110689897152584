import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fleetActions, fleetGroupActions, accountActions, taxiActions, driverActions, taxiOwnerActions } from '../../redux/actions';


const GetData = ({ isFleetReady, isFleetGroupReady, isTaxiReady, isDriverReady, isTaxiOwnerReady,
    systemUserStatus,
    getAllFleet, getAllFleetGroup, getAllSystemUser, getAllTaxi, getAllDriver, getAllTaxiOwner,
    fleet, fleetGroup, taxi, driver, taxiOwner }) => {

    useEffect(() => {
        if (!isFleetReady && fleet) {
            getAllFleet()
        }
    }, [isFleetReady, fleet, getAllFleet])

    useEffect(() => {
        if (!isFleetGroupReady && fleetGroup) {
            getAllFleetGroup()
        }
    }, [isFleetGroupReady, fleetGroup, getAllFleetGroup])

    useEffect(() => {
        if (systemUserStatus === "init") {
            getAllSystemUser()
        }
    }, [systemUserStatus, getAllSystemUser])

    useEffect(() => {
        if (!isTaxiReady && taxi) {
            getAllTaxi()
        }
    }, [isTaxiReady, taxi, getAllTaxi])

    useEffect(() => {
        if (!isDriverReady && driver) {
            getAllDriver()
        }
    }, [isDriverReady, driver, getAllDriver])


    useEffect(() => {
        if (!isTaxiOwnerReady && taxiOwner) {
            getAllTaxiOwner()
        }
    }, [isTaxiOwnerReady, taxiOwner, getAllTaxiOwner])

    return <></>
}

const mapStateToProps = (state) => ({
    isFleetReady: state.fleet.isReady,
    isFleetGroupReady: state.fleetGroup.isReady,
    isTaxiReady: state.taxi.isReady,
    isDriverReady: state.driver.isReady,
    isTaxiOwnerReady: state.taxiOwner.isReady,
    systemUserStatus: state.systemUser.status,

})
const mapDispatchToProps = (dispatch) => ({
    getAllFleet: () => dispatch({ type: fleetActions.GET_ALL_FLEET }),
    getAllFleetGroup: () => dispatch({ type: fleetGroupActions.GET_ALL_FLEET_GROUP }),
    getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNT }),
    getAllTaxi: () => dispatch({ type: taxiActions.GET_ALL_TAXI }),
    getAllDriver: () => dispatch({ type: driverActions.GET_ALL_DRIVER }),
    getAllTaxiOwner: () => dispatch({ type: taxiOwnerActions.GET_ALL_TAXI_OWNER }),
})
export default connect(mapStateToProps, mapDispatchToProps)(GetData);

