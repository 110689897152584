import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import ManagementContainer from "../ManagementContainer";
import { ManagementGrid } from "../ManagementGrid";
import { ManagementFetchData } from "../ManagementFetchData";
import { Divider } from "../../../components/Divider";
import { UserService } from "../../../Service/UserService";
import { DayOffCheckBox } from "../../../components/Checkbox/DayOff";
import { TrueOrFalseCheckBox } from "../../../components/Checkbox/TrueOrFalse";
import SelectTextField from "../../../components/SelectTextField";
import GetData from "../../../components/GetData";
import { UploadAndDownloadDoc } from "../../../components/UploadAndDownloadDoc";
import { UtilsService } from "../../../Service/UtilsService";
import { DocumentService } from "../../../Service/DocumentService";
import Card, { StyledCard } from "../../../components/Card";
import { Box, Button, TextField, withStyles } from "@material-ui/core";
import { driverActions, taxiActions } from "../../../redux/actions";
import { constants, YupValidationConstants } from "../../../constants";
import { checker } from "../../../permission";
import { getTransaction, updateDeposit } from "../../../redux/API";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Text from "../../../components/Text";
import StyledButton from "../../../components/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

const OverflowScrollCollapse = withStyles(props => ({
  container: {
    flex: 1
  },
  entered: {
    overflowY: 'auto',
    overflowX: 'hidden'
  }
}))(Collapse)

const ExpandIconButton = withStyles((theme) => ({
  root: {
    display: "flex",
    height: '48px',
    transform: (theme) => (theme.isOpen ? "rotate(180deg)" : "rotate(0deg)"),
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}))(IconButton);

const ClientCreateUpdate = (props) => {
  const [isDepositExpand, setIsDepositExpand] = useState(false);
  const { userRole, type, group, getAllDriver, getAllTaxi } = props;
  const { id } = useParams();
  const isCreatePage = props.type.toLowerCase() === "create";
  const isEditable = isCreatePage || checker(userRole, "driver:update");
  const [dialogStatus, setDialogStatus] = useState({
    isOpen: false,
    password: "",
  });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [edit, setEdit] = useState(false);
  const [newDepositValue, setNewDepositValue] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = ["基本信息", "出勤/按金紀錄"];
  const isTabSelectorShow = props.type.toLowerCase() !== "create";

  const {
    userData: {
      isDisabled,
      _id,
      username,
      name,
      phone,
      fleet,
      fleetId = fleet && fleet._id ? fleet._id : "",
      fleetName = fleet && fleet.name ? fleet.name : "",
      fleetGroup,
      fleetGroupId = fleetGroup && fleetGroup._id ? fleetGroup._id : "",
      fleetGroupName = fleetGroup && fleetGroup.name ? fleetGroup.name : "",
      driver: {
        nickname,
        hkid,
        birthday,
        role = group,
        validDrivingLicense,
        DrivingLicenseExpiryDate,
        drivingLicenseNum,
        serviceStartDate,
        image: driver_image,
        secondaryPhone,
        homePhone,
        address,
        region,
        contact: {
          name: contact_name,
          relationship: contact_relationship,
          phone: contact_phone,
        } = {},
        dayoff,
        shift,
        contract: {
          date: contract_date,
          image: contract_image,
          status: contract_status,
        } = {},
        other: {
          deducted,
          deductedLastUpdated,
          englishLevel,
          smoker,
          status: driver_status,
          priority: driver_priority,
        } = {},
      } = {},
    } = {},
    isLoading,
    isError,
  } = id
      ? ManagementFetchData({ group, id, refreshCounter })
      : { userData: {}, isLoading: false, isError: false };

  const [driverImage, setDriverImage] = useState("");
  const [contract, setContract] = useState("");
  const [transaction, setTransaction] = useState("");
  const [refreshTransaction, setRefreshTransaction] = useState(0);

  const [loadingRecord, setLoadingRecord] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [maxIndex, setMaxIndex] = useState(1);
  const [record, setRecord] = useState({});

  const [
    disabledOverlay,
    isLoadingDisableOverlay,
    enableHandler,
  ] = UserService.useDisabledReducer({
    isDisabled,
    id,
    type: "driver",
  });

  const handleTransactionPage = (type) => {
    if (type === "next") {
      if (currentIndex === maxIndex - 1) {
        setLoadingRecord(true);
        getTransaction(id, maxIndex + 1)
          .then((res) => {
            setCurrentIndex((prev) => prev + 1);
            if (res.msg && res.list && res.list.length > 0) {
              setMaxIndex(maxIndex + 1);
              setRecord({ ...record, [maxIndex + 1]: res.list });
              setLoadingRecord(false);
            } else {
              setLoadingRecord(false);
            }
          })
          .catch((e) => {
            console.log(e);
            setLoadingRecord(false);
            setTransaction({ isErrorOccur: true });
            console.log("Fail to get transaction");
          });
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    } else {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setLoadingRecord(true);
    getTransaction(id, 1)
      .then((res) => {
        if (res.msg) {
          setTransaction(res);
          if (res.list && res.list.length === 0) {
            return setLoadingRecord(false);
          }

          getTransaction(id, 2)
            .then((response) => {
              if (response.msg && response.list && response.list.length > 0) {
                setMaxIndex(2);
                setRecord({ 1: res.list, 2: response.list });
                setLoadingRecord(false);
              } else {
                setRecord({ 1: res.list });
                setLoadingRecord(false);
              }
            })
            .catch((e) => {
              console.log(e);
              setLoadingRecord(false);
              setTransaction({ isErrorOccur: true });
              console.log("Fail to get transaction");
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setLoadingRecord(false);
        setTransaction({ isErrorOccur: true });
        console.log("Fail to get transaction");
      });
  }, [id, refreshTransaction]);

  useEffect(() => {
    setDriverImage("");
    setContract("");
  }, [refreshCounter]);

  const onSubmitNewDeposit = () => {
    if (newDepositValue !== "") {
      updateDeposit({
        driver: id,
        amount:
          parseFloat(newDepositValue) - parseFloat(transaction.totalDeposit),
      })
        .then((res) => {
          setRefreshTransaction((prev) => prev + 1);
        })
        .catch((e) => {
          window.alert(
            e.response && e.response.data && e.response.data.result.err
              ? e.response.data.result.err
              : e
          );
        });
    }
  };
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: _id || "",
      username: username || "",
      name: name || "",
      nickname: nickname || "",
      hkid: hkid || "",
      birthday: UtilsService.showReadableDay(birthday),
      role: role || "",
      validDrivingLicense: validDrivingLicense || false,
      drivingLicenseNum: drivingLicenseNum || "",
      DrivingLicenseExpiryDate: UtilsService.showReadableDay(
        DrivingLicenseExpiryDate
      ),
      serviceStartDate: UtilsService.showReadableDay(serviceStartDate),
      driver_image: driver_image || "",

      phone: phone || "",
      secondaryPhone: secondaryPhone || "",
      homePhone: homePhone || "",
      address: address || "",
      region: region || "",

      contact_name: contact_name || "",
      contact_relationship: contact_relationship || "",
      contact_phone: contact_phone || "",

      dayoff: dayoff || [],

      shift: shift || "",

      contract_date: UtilsService.showReadableDay(contract_date),
      contract_image: contract_image || "",
      contract_status: contract_status || "",

      fleetId: fleetId || "",
      fleetGroupId: fleetGroupId || "",
      fleetName: fleetName || "",
      fleetGroupName: fleetGroupName || "",

      deducted: deducted || "",
      deductedLastUpdated: UtilsService.showReadableDay(deductedLastUpdated),
      englishLevel: englishLevel || "",
      smoker: smoker || false,

      driver_status: driver_status || "",
      driver_priority: driver_priority || 3,
    },
    validationSchema: Yup.object({
      username: Yup.string().required(constants.REQUIRED),
      name: Yup.string()
        .required(constants.REQUIRED)
        .max(15, constants.NAME_MAX_CAP),
      birthday: YupValidationConstants.DATE_FORMAT,
      DrivingLicenseExpiryDate: YupValidationConstants.DATE_FORMAT,
      serviceStartDate: YupValidationConstants.DATE_FORMAT,
      contract_date: YupValidationConstants.DATE_FORMAT,
      deductedLastUpdated: YupValidationConstants.DATE_FORMAT,
      smoker: Yup.boolean().required(constants.REQUIRED),
      driver_priority: YupValidationConstants.NUMBER_REQUIRED,
    }),
    onSubmit: async (values) => {
      const newForm = UserService.driverFormat(values);
      UtilsService.emptyToNull(newForm);
      if (
        (form.initialValues.shift === "morning" ||
          form.initialValues.shift === "night") &&
        (form.initialValues.shift !== values.shift ||
          form.initialValues.fleetId !== values.fleetId ||
          form.initialValues.fleetGroupId !== values.fleetGroupId)
      ) {
        setDialogStatus({
          isOpen: true,
          type: "conflict",
          group,
          conflicts: { shift: form.initialValues.shift },
          updateDriverHandler,
        });
      } else {
        setDialogStatus({ isOpen: true, isLoading: true });
        updateDriverHandler();
      }
    },
  });

  const updateDriverHandler = async () => {
    const editedForm = UserService.driverFormat(form.values);
    if (!editedForm._id) delete editedForm._id;
    UtilsService.emptyToNull(editedForm);

    setDialogStatus({ isOpen: true, isLoading: true });
    const {
      isSuccess,
      isAllDone,
      isErrorOccur,
      errMsg,
      uploadStatus,
      password,
    } = await DocumentService.handleCreateUpdateSubmit({
      group,
      isCreatePage,
      form: editedForm,
      uploadFile: [
        { type: "driver-image", file: driverImage },
        { type: "driver-contract", file: contract },
      ],
    });
    setDialogStatus({
      isOpen: true,
      isLoading: false,
      type,
      group,
      isSuccess,
      isAllDone,
      isErrorOccur,
      errMsg,
      uploadStatus,
      password,
      setRefreshCounter,
    });
    getAllDriver();
    getAllTaxi();
  };

  if (isError) {
    return <Box>{isError}</Box>;
  }

  return (
    <ManagementContainer
      group={group}
      {...{
        form,
        type,
        isLoading,
        dialogStatus,
        setDialogStatus,
        isTabSelectorShow,
        tabs,
        tabIndex,
        setTabIndex,
        isCreatePage,
        disabledOverlay,
        isLoadingDisableOverlay,
        enableHandler,
      }}
    >
      <GetData fleet={isEditable ? true : false} fleetGroup driver />
      {tabIndex === 0 ? (
        <StyledCard>
          <h2>司機</h2>

          <Divider />

          <UploadAndDownloadDoc
            editable={isEditable}
            type="的士司機證"
            id="driver-image"
            isCreatePage={isCreatePage}
            fileKey={driver_image}
            fileContent={driverImage}
            setFileContent={setDriverImage}
          />

          <Grid container spacing={4}>
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { label: "姓名", id: "name" },
                { label: "別名", id: "nickname" },
                { label: "登入名稱", id: "username", disabled: !isCreatePage },
                { label: "身份證", id: "hkid" },
                { type: "date", label: "生日", id: "birthday" },
              ]}
            />
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                {
                  type: "component",
                  component: (
                    <TrueOrFalseCheckBox
                      editable={isEditable}
                      form={form}
                      field={"validDrivingLicense"}
                      title="有效的士駕駛執照"
                      trueWording="有"
                      falseWording="無"
                    />
                  ),
                  id: "validDrivingLicense",
                },
                { label: "的士駕駛執照號碼", id: "drivingLicenseNum" },
                {
                  type: "date",
                  label: "駕駛執照到期日",
                  id: "DrivingLicenseExpiryDate",
                },
                { type: "date", label: "開始服務日期", id: "serviceStartDate" },
              ]}
            />
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { type: "phone", label: "手機號碼(1)", id: "phone" },
                { type: "phone", label: "手機號碼(2)", id: "secondaryPhone" },
                { type: "phone", label: "家居電話號碼", id: "homePhone" },
              ]}
            />
          </Grid>

          <h2>地址</h2>
          <Divider />
          <Grid container spacing={4}>
            <SelectTextField
              editable={isEditable}
              form={form}
              sm={4}
              fieldArr={[{ label: "地址", id: "address" }]}
            />
            <SelectTextField
              editable={isEditable}
              form={form}
              sm={2}
              fieldArr={[{ type: "select", label: "地區", id: "region" }]}
            />
          </Grid>

          <h2>緊急聯絡人</h2>
          <Divider />
          <Grid container spacing={4}>
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { label: "緊急聯絡人", id: "contact_name" },
                {
                  type: "select",
                  label: "緊急聯絡人關係",
                  id: "contact_relationship",
                },
                { type: "phone", label: "緊急人聯絡號碼", id: "contact_phone" },
              ]}
            />
          </Grid>

          <h2>車隊</h2>
          <Divider />
          <Grid container spacing={4}>
            <Grid item container spacing={4}>
              <SelectTextField
                editable={isEditable}
                form={form}
                sm={2}
                fieldArr={[{ type: "select", label: "更次", id: "shift" }]}
              />
              <Grid item sm={7}>
                <DayOffCheckBox editable={isEditable} form={form} />
              </Grid>
            </Grid>
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={
                isEditable
                  ? [
                    { type: "select", label: "車隊", id: "fleetId" },
                    { type: "select", label: "車組", id: "fleetGroupId" },
                  ]
                  : [
                    { label: "車隊", id: "fleetName" },
                    { label: "車組", id: "fleetGroupName" },
                  ]
              }
            />
          </Grid>

          <h2>合同</h2>
          <Divider />
          <Grid container spacing={4}>
            <UploadAndDownloadDoc
              editable={isEditable}
              type="合同"
              id="contract"
              isCreatePage={isCreatePage}
              fileKey={contract_image}
              fileContent={contract}
              setFileContent={setContract}
            />
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { type: "date", label: "合同日期", id: "contract_date" },
                { type: "select", label: "合同有效", id: "contract_status" },
              ]}
            />
          </Grid>

          <h2>其他</h2>
          <Divider />
          <Grid container spacing={4}>
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { label: "運輸處扣分分數", id: "deducted" },
                {
                  type: "date",
                  label: "扣分分數更新日期",
                  id: "deductedLastUpdated",
                },
                { type: "select", label: "英語水平", id: "englishLevel" },
                { type: "select", label: "是否吸煙", id: "smoker" },
              ]}
            />
            <ManagementGrid
              editable={isEditable}
              form={form}
              fieldArr={[
                { type: "select", label: "司機服務狀態", id: "driver_status" },
                { type: "select", label: "優先度", id: "driver_priority" },
              ]}
            />
          </Grid>
        </StyledCard>
      ) : !transaction ? (
        <LoadingIndicator />
      ) : transaction.isErrorOccur ? (
        <Box>出現錯誤</Box>
      ) : (
              <Box display="flex" flexDirection="column">
                <Box mb={2}>
                  <Card
                    style={{
                      height: isDepositExpand ? "65vh" : "unset",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <h2>按金</h2>
                      <ExpandIconButton
                        isOpen={isDepositExpand}
                        onClick={() => setIsDepositExpand(!isDepositExpand)}
                      >
                        <ExpandMoreIcon />
                      </ExpandIconButton>
                    </Box>

                    <OverflowScrollCollapse in={isDepositExpand} timeout="auto" unmountOnExit>
                      <Divider />
                      <Box display="flex" mb={1}>
                        <Box minWidth="90px">
                          <Text type="itemTitle">總按金</Text>
                          <Box mt={1}>
                            <Text type="itemText">
                              $
                        {transaction.totalDeposit
                                ? transaction.totalDeposit.toLocaleString()
                                : 0}
                            </Text>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="flex-end">
                          {edit ? (
                            <Box display="flex">
                              <Box mr={1}>
                                <StyledButton
                                  height="30px"
                                  minheight="30px"
                                  minwidth="50px"
                                  disabled={!newDepositValue}
                                  onClick={onSubmitNewDeposit}
                                  background={
                                    !newDepositValue ? "lightGrey" : "purple"
                                  }
                                >
                                  確定
                          </StyledButton>
                              </Box>
                              <Box mr={1}>
                                <StyledButton
                                  height="30px"
                                  minheight="30px"
                                  minwidth="50px"
                                  onClick={() => setEdit(false)}
                                  variant="outlined"
                                  background="white"
                                >
                                  取消
                          </StyledButton>
                              </Box>
                              <NumberFormat
                                allowNegative={false}
                                onValueChange={(valuesObj) =>
                                  setNewDepositValue(valuesObj.value)
                                }
                                value={newDepositValue}
                                customInput={TextField}
                                placeholder="請輸入新按金總額"
                                prefix="$"
                                thousandSeparator={true}
                              />
                            </Box>
                          ) : (
                              <StyledButton
                                background="purple"
                                height="30px"
                                minheight="30px"
                                minwidth="120px"
                                onClick={() => setEdit(true)}
                              >
                                修改
                              </StyledButton>
                            )}
                        </Box>
                      </Box>

                      {transaction.depositList &&
                        transaction.depositList.length > 0 ? (
                          <TableContainer
                            style={{ flex: 1 }}
                            component={Paper}
                          >
                            <Table style={{ minWidth: 650 }} aria-label="deposit">
                              <TableHead>
                                <TableRow>
                                  {["日期", "款項", "金額"].map((elem) => (
                                    <TableCell key={elem}>{elem}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {transaction.depositList.map((row) => (
                                  <TableRow key={row.name}>
                                    <TableCell component="th" scope="row">
                                      {row.date
                                        ? UtilsService.showReadableDay(row.date)
                                        : "-"}
                                    </TableCell>
                                    <TableCell style={{ width: "70%" }} align="left">
                                      按金
                            </TableCell>
                                    <TableCell
                                      style={{ minWidth: "10%" }}
                                    >
                                      {row.amount ? `$${row.amount.toLocaleString()}` : "-"}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <Box
                            height="50%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {" "}
                    沒有按金紀錄
                          </Box>
                        )}
                    </OverflowScrollCollapse>
                  </Card>
                </Box>

                <StyledCard
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 1,
                    height: "65vh",
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <h2>出勤 </h2>
                    <Box mr={1} color="red" fontSize="14px">總欠租金額<Box component="span" fontSize="20px" fontWeight="bold" pl={1}> ${(transaction.totalArrears !== undefined
                      && transaction.totalArrears !== null)
                      ? transaction.totalArrears.toLocaleString() : "-"}</Box> </Box>
                  </Box>
                  <Divider />
                  <TableContainer
                    style={{ flex: 1 }}
                    component={Paper}
                  >
                    <Table style={{ minWidth: 650 }} aria-label="deposit">
                      <TableHead>
                        <TableRow>
                          {[
                            "日期",
                            "車牌號碼",
                            "應付租金",
                            "已付租金",
                            "欠租金額",
                          ].map((elem) => (
                            <TableCell key={elem}>{elem}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {loadingRecord ? (
                        <LoadingIndicator />
                      ) : record[currentIndex] ? (
                        <TableBody>
                          {record[currentIndex].map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.date
                                  ? UtilsService.showReadableDay(row.date)
                                  : "-"}{row.shift && row.shift === "morning" ? "(早更)" : "(夜更)"
                                }
                              </TableCell>
                              <TableCell>
                                {row.taxi && row.taxi.plateNumber
                                  ? row.taxi.plateNumber
                                  : "-"}
                              </TableCell>
                              <TableCell>${row.payable}</TableCell>
                              <TableCell>${row.actualRent}</TableCell>
                              <TableCell
                                style={{
                                  color: !!row.rentArrears_amount ? "red" : "unset",
                                }}
                              >
                                ${row.rentArrears_amount}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                            <Box> 沒有出勤紀錄</Box>
                          )}
                    </Table>
                    <Box display="flex" justifyContent="flex-end">
                      {currentIndex !== 1 && (
                        <Button onClick={() => handleTransactionPage("prev")}>
                          上一頁
                        </Button>
                      )}
                      {currentIndex + 1 <= maxIndex && (
                        <Button onClick={() => handleTransactionPage("next")}>
                          下一頁
                        </Button>
                      )}
                    </Box>
                  </TableContainer>
                </StyledCard>
              </Box>
            )}
    </ManagementContainer>
  );
};
const mapStateToProps = (state) => ({
  userRole: state.user.role,
});
const mapDispatchToProps = (dispatch) => ({
  getAllDriver: () => dispatch({ type: driverActions.GET_ALL_DRIVER }),
  getAllTaxi: () => dispatch({ type: taxiActions.GET_ALL_TAXI }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientCreateUpdate);
