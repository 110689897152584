import * as Yup from 'yup';
import moment from 'moment';

export const constants = {
    REQUIRED: "必須填寫",
    NEW_PASSWORD_MUST_SAME: "請輸入相同的新密碼",
    MUST_SIX_DIGITS: "密碼必須為6位或以上",
    NUMBER: "請輪入數字",
    EMAIL: "電郵格式不正確",
    NAME_MAX_CAP: "姓名不可多於15個字"

}

export const YupValidationConstants = {
    STRING_REQUIRED: Yup.string()
        .required(constants.REQUIRED),
    DATE_REQUIRED: Yup.string()
        .required(constants.REQUIRED)
        .test("validDate", "日期不正確", function (value) {
            return moment(value, 'DD/MM/YYYY', true).isValid()
        }),
    DATE_TIME_REQUIRED: Yup.string()
        .required(constants.REQUIRED)
        .test("validDate", "格式不正確", function (value) {
            return moment(value, 'DD/MM/YYYY HH:mm', true).isValid()
        }),
    DATE_BEFORE_TODAY_REQUIRED: Yup.string()
        .required(constants.REQUIRED)
        .test("largerDate", "日期應為今日或之前", function (value) {
            return moment(value, 'DD/MM/YYYY').isSameOrBefore(moment(), 'day')
        })
        .test("validDate", "日期不正確", function (value) {
            return moment(value, 'DD/MM/YYYY', true).isValid()
        }),
    TIME_REQUIRED: Yup.string()
        .required(constants.REQUIRED)
        .test("validDate", "時間格式應為24小時制，小時為00-23，分鐘為00-59", function (value) {
            return moment(value, 'HH:mm', true).isValid()
        }),
    DATE_FORMAT: Yup.string()
        .test("validDate", "日期不正確", function (value) {
            return moment(value, 'DD/MM/YYYY', true).isValid() || !value
        }),
    NUMBER_REQUIRED: Yup.number()
        .typeError(constants.NUMBER)
        .required(constants.REQUIRED),
    POSITIVE_NUMBER_FORMAT: Yup.number()
        .min(0, "最小為0")
        .typeError(constants.NUMBER)
        .nullable()
        .transform((value, originalValue) => typeof originalValue === "string" && originalValue.trim() === "" ? null : value),

}

export const dayOfWeek = [{ name: 1, label: "星期一" },
{ name: 2, label: "星期二" },
{ name: 3, label: "星期三" },
{ name: 4, label: "星期四" },
{ name: 5, label: "星期五" },
{ name: 6, label: "星期六" },
{ name: 0, label: "星期日" }]