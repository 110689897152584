import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const StyledButton = withStyles({
    root: {
        backgroundColor: props => {
            let colorChart = {
                paleGrey: '#f3f3f3',
                lightGrey: '#9B9B9B',
                darkGrey: '#4A4A4A',
                lightBlue: '#FFF',
                blue: '#16BFE5',
                marble: '#F8F8F8',
                purple: '#5650ef',
                white: '#FFF',
                orange: '#ff7311'
            }
            return props.background ? colorChart[props.background] || '#4A4A4A' : '#4A4A4A'
        },
        color: props => {
            let colorChart = {
                lightBlue: '#0091ff',
                paleGrey: '#4E4E4E',
                white: '#9b9b9b',
                orange: '#fff'
            }
            return props.background ? colorChart[props.background] || '#FFF' : '#FFF'
        },
        padding: props => props.padding || 'initial',
        fontFamily: 'PingFangTC-Semibold',
        borderRadius: 4,
        fontSize: 14,
        minWidth: props => {
            if (props.width) {
                return props.width
            } else if (props.minwidth) {
                return props.minwidth
            } else if (props.background === "lightBlue") {
                return 90
            }
            return 120
        },
        minHeight: props => {
            if (props.height) {
                return props.height
            } else if (props.minheight) {
                return props.minheight
            } else if (props.background === "lightBlue") {
                return 30
            }
            return 35
        },
        lineHeight: props => props.height ? 0 : 'unset',
        height: props => props.height ? props.height : 'unset',
        width: props => props.width ? props.width : 'unset',
        '&:disabled': {
            backgroundColor: '#9b9b9b',
            color: '#FFF'
        },
        '&:hover': {
            backgroundColor: props => {
                let colorChart = {
                    paleGrey: '#f3f3f3',
                    lightGrey: '#9B9B9B',
                    lightBlue: '#d9f1f9',
                    darkGrey: '#4A4A4A',
                    blue: '#16BFE5',
                    marble: '#F8F8F8',
                    purple: '#5650ef',
                    white: '#FFF',
                    orange: '#ff7311'
                }
                return props.background ? colorChart[props.background] || '#4A4A4A' : '#4A4A4A'
            },
            opacity: 0.5
        },
        transition: 'all 0.25s'
    },
    outlined: {
        border: props => {
            let colorChart = {
                lightBlue: '#0091ff',
                paleGrey: '#4E4E4E',
                white: '#9b9b9b',
                orange: '#fff'
            }
            return `1px solid ${props.background && colorChart[props.background] ? colorChart[props.background] : '#FFF'}`
        },
    }
})(Button)

export const LinkButton = ({ to, children, ...rest }) => <StyledButton component={Link} to={to} {...rest}>{children}</StyledButton>

export default StyledButton;