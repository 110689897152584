import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { rosterActions } from '../../../redux/actions';
import ShiftButton from '../../../components/TabButton';
import  Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core';


const StyledShiftContainer=withStyles({
root:{
    flex:'0 0 auto'
}
})(Box)

const WeekSelector = ({ currentGroup,selector ,getRoster}) => {
    const { groupId } = useParams();

    useEffect(()=>{
        if(selector.shift==="night"){
            setShift(1)
        }else{
            setShift(0)
        }
    },[selector.shift])

    const [shift, setShift] = useState(0)
    const handleClick=(index)=>{
        getRoster(currentGroup.fleet._id,groupId,index===0?"morning":"night",selector.from, selector.to)
        setShift(index)
    }

    return  <StyledShiftContainer>
                {
                    ["日更", "夜更"].map((item, index) => <ShiftButton
                        key={item}
                        select={shift === index ? 'select' : undefined}
                        onClick={()=>handleClick(index)}>{item}</ShiftButton>)
                }
    </StyledShiftContainer>
}
const mapStateToProps = (state) => ({
    selector:state.roster.selector
})
const mapDispatchToProps = (dispatch) => ({
    getRoster: (fleet,fleetGroup, shift, from, to) => dispatch({ type: rosterActions.GET_ROSTER,fleet, fleetGroup, shift, from, to })
})
export default connect(mapStateToProps, mapDispatchToProps)(WeekSelector);





