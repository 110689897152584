import { put, takeLatest, call } from "redux-saga/effects";
import { getAllAccounts } from "../API";
import { accountActions } from "../actions";

function* watchGetAllAccounts() {
  yield takeLatest(accountActions.GET_ALL_ACCOUNT, fetchAllAccounts);
}

function* watchGetDeletedAccounts() {
  yield takeLatest(
    accountActions.GET_DELETED_SYSTEM_ACCOUNT,
    fetchDeletedAccounts
  );
}

export function* fetchAllAccounts() {
  try {
    yield put({ type: accountActions.FETCH_ALL_SYSTEM_ACCOUNT });
    const acctData = yield call(() => getAllAccounts({ role: "system" }));
    yield put({
      type: accountActions.GET_ALL_SYSTEM_ACCOUNT,
      payload: acctData,
    });
  } catch (e) {
    console.log(e);
    // yield put({ type: userActions.GET_USER_ERROR, error: 'Error happens in fetchUserData()' })
  }
}

export function* fetchDeletedAccounts() {
  try {
    const deletedSystemAccount = yield call(() =>
      getAllAccounts({ role: "system", isDisabled: true })
    );

    yield put({
      type: accountActions.UPDATE_DELETED_SYSTEM_ACCOUNT,
      deletedSystemAccount,
    });
  } catch (e) {
    console.log(e);
  }
}

export default [
  watchGetAllAccounts(),
  watchGetDeletedAccounts()]