export const accountActions = {
  GET_ALL_ACCOUNT: "GET_ALL_ACCOUNT",
  GET_ALL_SYSTEM_ACCOUNT: "GET_ALL_SYSTEM_ACCOUNT",
  FETCH_ALL_SYSTEM_ACCOUNT: "FETCH_ALL_SYSTEM_ACCOUNT",

  GET_DELETED_SYSTEM_ACCOUNT: "GET_DELETED_SYSTEM_ACCOUNT",
  UPDATE_DELETED_SYSTEM_ACCOUNT: "UPDATE_DELETED_SYSTEM_ACCOUNT",

  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_FLEET_MANAGER: "UPDATE_FLEET_MANAGER",

  REMOVE_ENABLE_ACCOUNT: "REMOVE_ENABLE_ACCOUNT",
};
