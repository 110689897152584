import React from "react";
import LoadingIndicator from "../LoadingIndicator";

export const DeletedItemOverlay = ({
  isLoadingDisableOverlay,
  disabledOverlay,
}) => {
  return isLoadingDisableOverlay ? (
    <LoadingIndicator type="floating" />
  ) : disabledOverlay ? (
    <div
      style={{
        position: "fixed",
        zIndex: -1,
        backgroundColor: "rgba(255,255,255,0.5)",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: "white",
          backgroundColor: "red",
          padding: "10px 20px",
          fontStyle: "italic",
          fontWeight: "700",
          fontSize: "23px",
          textAlign: "center",
        }}
      >
        此帳戶已被移除
        <br />
        如需重啟，請按右上角「重啟帳戶」
      </div>
    </div>
  ) : (
    <></>
  );
};
