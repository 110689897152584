import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { pageUIActions } from '../../redux/actions';
import { connect } from "react-redux";

const KeywordSearch = ({ keyword,ownerTaxiKeyword, updateTable,updateOwnerTaxiTable, selectedColumns, component, ...rest }) => {

    const onChange = e => {
        if(component==="owner-taxi"){
            updateOwnerTaxiTable(e.target.value)
        }else{
            updateTable(e.target.value, component)
        }
    }

    return <TextField
        {...rest}
        value={component==="owner-taxi"?ownerTaxiKeyword:keyword}
        onChange={onChange}
        variant={"outlined"}
        margin={"dense"}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon htmlColor="#AAA" />
                </InputAdornment>
            ),
        }}
        placeholder="輸入搜尋" />
}


const mapStateToProps = (state) => ({
    keyword: state.ui.keyword || "",
    ownerTaxiKeyword: state.ui.ownerTaxi?.keyword||"",
})

const mapDispatchToProps = (dispatch) => ({
    updateTable: (values, component) => dispatch({ type: pageUIActions.UPDATE_SCREEN_TABLE, values, component }),
    updateOwnerTaxiTable: (values) => dispatch({ type: pageUIActions.UPDATE_OWNER_TAXI_KEYWORD_SEARCH, values }),
});
export default connect(mapStateToProps, mapDispatchToProps)(KeywordSearch);
