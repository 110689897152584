import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingIndicator from "../LoadingIndicator";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import StyledButton from "../Button";

const Modal = ({
  title,
  type,
  isOpen,
  isLoading,
  isErrorOccur,
  errMsg,
  isSuccess,
  closeDialog,
  disableBGClose,
  children,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={disableBGClose ? undefined : closeDialog}
      aria-labelledby={`${title}-dialog`}
      PaperProps={{ style: { minWidth: "338px" } }}
    >
      {isLoading && <LoadingIndicator />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Box style={{ fontWeight: "bold" }}>
            {isSuccess
              ? type
                ? title
                : "成功"
              : isErrorOccur
              ? "出現錯誤"
              : title}
          </Box>
        </DialogTitle>
        <IconButton style={{ margin: "0 3px 9px 0" }} onClick={closeDialog}>
          <ClearIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {isErrorOccur ? (
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              <img
                src={`/assets/ic-fail.svg`}
                style={{ height: 70 }}
                alt="fail"
              />
            </Box>
            <Box>{errMsg || "程序未能完成，請通知管理員。"}</Box>
            <Box mt={3} mb={1} textAlign="right">
              <StyledButton background="purple" onClick={closeDialog}>
                關閉
              </StyledButton>
            </Box>
          </>
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
