import moment from 'moment';

const removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
        else if (obj[key] === null || obj[key] === undefined || obj[key] === "") delete obj[key];

        if (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0) delete obj[key];
    });
};

const emptyToNull = obj => {
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") emptyToNull(obj[key]);
        else if (obj[key] === undefined || obj[key] === "") obj[key] = null;
    });
};

const dateFormat = (date) => {
    if (date) {
        const dateString = date.split("/")
        return new Date(dateString[2], Number(dateString[1]) - 1, dateString[0]).toString()
    }
    return null
}

const dateTimeFormat = (date, time) => {
    if (date && time) {
        const dateString = date.split("/")
        const timeString = time.split(":")
        return new Date(dateString[2], Number(dateString[1]) - 1, dateString[0], timeString[0], timeString[1]).toString()
    }
    return null
}

const showReadableDay = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY')
    } else {
        return ""
    }
}

const showReadableTime = (date) => {
    if (date) {
        return moment(date).format('HH:mm')
    } else {
        return ""
    }
}

const showReadableDayTime = (date) => {
    if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm')
    } else {
        return ""
    }
}

const weekFormation = (monday) => {
    return ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'].map(
        (item, index) =>
            ({ type: 'date', label: item, date: moment(monday).add(index, 'd'), minWidth: 90, width: '10%' })
    )
}

const conflictChecker = (type, array, selectedGroupId, currentUserId) => {
    const target = array.find(elem => elem[type] && elem[type]._id === selectedGroupId)
    if (target && target._id !== currentUserId) {
        return [true, target.name, target[type].name]
    }

    return [false, undefined, undefined]
}

export const UtilsService = {
    removeEmpty,
    emptyToNull,
    dateFormat,
    dateTimeFormat,
    weekFormation,
    showReadableDay,
    showReadableTime,
    showReadableDayTime,
    conflictChecker
}