import React, { useMemo, useCallback } from "react";
import Modal from "../../components/Modal";
import { Box, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import StyledButton from "../../components/Button";

const Wrapper = withStyles({
  root: {
    margin: "10px 0px",
  },
})(Box);

const BtnBox = withStyles({
  root: {
    textAlign: "end",
    marginTop: "5px",
  },
})(Box);

export const OverlayDialog = ({
  dialogStatus,
  setDialogStatus,
  isCreatePage,
}) => {
  const {
    group,
    isSuccess,
    isLoading,
    isErrorOccur,
    isAllDone,
    uploadStatus,
    setRefreshCounter,
    id,
  } = dialogStatus;
  const history = useHistory();
  const closeDialog = () => {
    if (isErrorOccur) {
      return setDialogStatus({ isOpen: false });
    } else if (isCreatePage && isSuccess && !isAllDone) {
      return history.push(`/accident/${id}`);
    } else if (isCreatePage && isSuccess && isAllDone) {
      return history.goBack();
    } else {
      setRefreshCounter((prev) => prev + 1);
      setDialogStatus({ isOpen: false });
    }
  };

  const showUploadResult = useCallback(() => {
    return (
      <>
        <Box mt={1} fontWeight="bold">
          以下文件未能上傳：
        </Box>
        {uploadStatus.map(({ fileName, isSuccess }) =>
          isSuccess ? (
            ""
          ) : (
            <Box style={{ color: "red" }} key={fileName}>
              檔案名稱：{fileName}-{isSuccess ? "上傳成功" : "上傳失敗"}
            </Box>
          )
        )}
        <Box>請重新上傳。</Box>
      </>
    );
  }, [uploadStatus]);

  const [title, contents, btnText] = useMemo(() => {
    if (isLoading) {
      return ["請稍後", ""];
    }
    switch (isCreatePage + "_" + group) {
      case "true_accidence":
        return [
          isAllDone ? "成功" : "注意！",
          <>
            {
              <Box my={1} mb={2} display="flex" justifyContent="center">
                {isAllDone ? (
                  <img
                    src={`/assets/ic-success.svg`}
                    style={{ height: 70 }}
                    alt="success"
                  />
                ) : (
                  <img
                    src={`/assets/ic-fail.svg`}
                    style={{ height: 70 }}
                    alt="fail"
                  />
                )}
              </Box>
            }
            <Box py={1}>已新增意外資料</Box>
            {isAllDone ? "" : showUploadResult()}
          </>,
          "關閉",
        ];
      case "false_accidence":
        if (isAllDone) {
          return [
            "成功",
            <>
              <Box my={1} mb={2} display="flex" justifyContent="center">
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              </Box>
              <Box py={1}>已成功更新意外資料</Box>
            </>,
            "關閉",
          ];
        }
        return ["注意！", showUploadResult(), "關閉"];
      default:
        return ["", ""];
    }
  }, [isCreatePage, group, isLoading, isAllDone, showUploadResult]);

  return (
    <Modal
      disableBGClose={true}
      type="accidence"
      {...{ closeDialog, title, ...dialogStatus }}
    >
      <Wrapper>
        {isLoading
          ? "資料處理中，請稍後"
          : isSuccess && (
              <>
                <Box style={{ color: "#9ba4b0" }}>{contents}</Box>
                <BtnBox>
                  <StyledButton background="purple" onClick={closeDialog}>
                    {btnText}
                  </StyledButton>
                </BtnBox>
              </>
            )}
      </Wrapper>
    </Modal>
  );
};
