import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/zh-hk'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const DeductionTable = ({ shift, morningDeduction, nightDeduction }) => {
    const classes = useStyles();
    const rows = shift === 0 ? morningDeduction : nightDeduction;
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {['司機', '款項', '金額'].map(elem => <TableCell key={elem}>{elem}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((item, index) => <TableRow key={index}>
                        <TableCell>{item.driver && item.driver.name}</TableCell>
                        <TableCell>{
                            item.type === "DEPOSIT"
                                ?
                                "按金"
                                :
                                item.type === "RENT_ARREARS" ?
                                    `${item.rentArrears &&
                                    item.rentArrears.date &&
                                    moment(item.rentArrears.date).format('DD/MM/YYYY')} 欠款 
                                    ${item.rentArrears &&
                                        item.rentArrears.shift &&
                                        item.rentArrears.shift === "morning" ? "(早更)" : "(夜更)"} 
                                    ${item.reason?
                                    `${(item.waivedRent?` 扣租：$${item.waivedRent}`:"")+
                                    (item.increasedRent?` 加租：$${item.increasedRent}`:"")+
                                    ` 原因：${item.reason}`}`
                                    :""
                                    }`
                                    :
                                    item.type
                        }</TableCell>
                        <TableCell>${item.amount}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const mapStateToProps = (state) => ({
    morningDeduction: state.normalRental.morningDeductionList || [],
    nightDeduction: state.normalRental.nightDeductionList || [],
})


export default connect(mapStateToProps)(DeductionTable);