// import React, { useState, useEffect } from 'react';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useParams, Prompt } from "react-router";
import * as Yup from "yup";
import AppBar from "../../components/AppBar";
import { SpaceBetweenBox, RowAlignCenterBox } from "../../components/Box";
import Back from "../../components/Back";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import StyledButton from "../../components/Button";
import Card from "../../components/Card";
import PageContainer from "../../components/PageContainer";
import { Divider } from "../../components/Divider";
import { ManagementFetchData } from "../ClientListing/ManagementFetchData";
import { StyledDocBox } from "../../components/StyledProofDoc";
import LoadingIndicator from "../../components/LoadingIndicator";
import SelectTextField from "../../components/SelectTextField";
import { Grid } from "@material-ui/core";
import { maintenanceMatching } from "../../match";
import { UtilsService } from "../../Service/UtilsService";
import Text from "../../components/Text";
import { YupValidationConstants } from "../../constants";
import { AccidentAndMainService } from "../../Service/AccidentMaintenanceService";
import { OverlayDialog } from "./OverlayDialog";
import { checker } from "../../permission";
import { pageUIActions } from "../../redux/actions";
import { DocumentService } from "../../Service/DocumentService";
import { MultiUploadAndDownloadDoc } from "../../components/UploadAndDownloadDoc/multiple";
import DeleteDocModal from "../../components/DeleteDocModal";
import GetData from "../../components/GetData";

const RepairItemBox = ({
  field,
  value,
  checkRepairItem,
  repairedArr,
  isEditable,
  mileage,
}) => {
  const isRepaired = repairedArr.includes(field);
  return (
    <Grid style={{ maxWidth: "unset" }} item sm={2}>
      <Box display="flex">
        <Box minWidth="90px">
          <Text type="itemTitle">{maintenanceMatching[field] || "-"}</Text>
          <Box mt={1}>
            <Text type={value < mileage ? "itemWarningText" : "itemText"}>
              {value ? value.toLocaleString() : 0}
            </Text>
          </Box>
        </Box>
        {isEditable && (
          <Box display="flex" alignItems="flex-end">
            <StyledButton
              onClick={isRepaired ? undefined : () => checkRepairItem(field)}
              height="30px"
              minheight="30px"
              minwidth="85px"
              background={isRepaired ? "lightGrey" : "purple"}
              disabled={isRepaired}
            >
              {isRepaired ? "待更新" : "已維修"}
            </StyledButton>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

const MaintenanceDetails = ({ role, toggleRefreshFlag, taxiList }) => {
  const { taxiId } = useParams();
  const [isTouched, setIsTouched] = useState(false);
  const [fileContent, setFileContent] = useState([]);
  const [dialogStatus, setDialogStatus] = useState({ isOpen: false });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [repairedArr, setRepairedArr] = useState([]);
  const [deleteDialog, setDeleteDialog] = React.useState({ isOpen: false });
  const [deleteList, setDeleteList] = React.useState([]);
  const isEditable = checker(role, "maintenance:update");
  const {
    userData: {
      fixedMileageItems,
      mileageItems = {},
      _id = "",
      throMaintenanceDate = "",
      briefCheckingDate = "",
      mileage = "",
      inspectionDoc = [],
      maintenanceItems = [],
    },
    isLoading,
    isError,
  } = taxiId
    ? ManagementFetchData({ group: "maintenance", id: taxiId, refreshCounter })
    : { userData: {}, isLoading: false, isError: false };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      mileageItems,
      _id,
      throMaintenanceDate: UtilsService.showReadableDay(throMaintenanceDate),
      briefCheckingDate: UtilsService.showReadableDay(briefCheckingDate),
      mileage,
      inspectionDoc,
      maintenanceItems,
    },
    validationSchema: Yup.object({
      throMaintenanceDate: YupValidationConstants.DATE_REQUIRED,
      briefCheckingDate: YupValidationConstants.DATE_REQUIRED,
      mileage: YupValidationConstants.STRING_REQUIRED,
    }),
    onSubmit: async (values) => {
      setDialogStatus({ isOpen: true, isLoading: true });

      const form = AccidentAndMainService.maintenanceFormat(values);
      form.taxiId = taxiId;
      form._id = taxiId;
      const {
        isSuccess,
        isAllDone,
        isErrorOccur,
        errMsg,
        uploadStatus,
        id,
      } = await DocumentService.handleCreateUpdateSubmit({
        group: "maintenance",
        isCreatePage: false,
        form,
        uploadFile:
          fileContent.length > 0
            ? fileContent.reduce(
                (acc, file) => [...acc, { type: "maintenance", file }],
                []
              )
            : [],
      });
      if (isSuccess) {
        toggleRefreshFlag();
        setIsTouched(false);
      }
      setDialogStatus({
        isOpen: true,
        isLoading: false,
        type: "maintenance",
        isSuccess,
        isAllDone,
        isErrorOccur,
        errMsg,
        uploadStatus,
        setRefreshCounter,
        id,
      });
    },
  });

  const checkRepairItem = (field) => {
    if (form.values.mileage < mileageItems[field]) {
      setDialogStatus({
        isOpen: true,
        type: "conflict",
        onClickAction: () => updateRepairItem(field),
      });
    } else {
      updateRepairItem(field);
    }
  };

  const updateRepairItem = (field) => {
    form.handleChange({
      target: {
        name: "mileageItems",
        value: {
          ...form.values.mileageItems,
          [field]:
            parseInt(form.values.mileage) + Number(fixedMileageItems[field]),
        },
      },
    });
    setIsTouched(true);
    setRepairedArr((prev) => [...prev, field]);
  };

  useEffect(() => {
    setRepairedArr([]);
    setFileContent([]);
  }, [refreshCounter]);

  if (isError) {
    return <Box>{isError}</Box>;
  }

  return (
    <form onSubmit={form.handleSubmit} noValidate autoComplete="off">
      <GetData taxi />
      <Prompt
        when={isTouched || Object.keys(form.touched).length > 0}
        message="您曾改動資料，但未按「更新」鍵儲存資料。確認要離開此頁？"
      />
      {isLoading && <LoadingIndicator />}
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox>
            <Back />
            <Box ml={4}>
              <Typography variant="h5">
                維修資料 - 車牌：
                {taxiList.find((taxi) => taxi._id === taxiId)?.plateNumber}
              </Typography>
            </Box>
          </RowAlignCenterBox>

          {isEditable && (
            <RowAlignCenterBox>
              <StyledButton background="purple" type="submit">
                更新
              </StyledButton>
            </RowAlignCenterBox>
          )}
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        <Box mb={1}>
          <Card>
            <Typography variant="h5">車驗</Typography>
            <Divider />
            <Grid container spacing={2}>
              <SelectTextField
                form={form}
                editable={true}
                fieldArr={[
                  {
                    label: "大驗日期",
                    id: "throMaintenanceDate",
                    type: "date",
                    disabled: !isEditable,
                  },
                  {
                    label: "小驗日期",
                    id: "briefCheckingDate",
                    type: "date",
                    disabled: !isEditable,
                  },
                  {
                    label: "行駛公里",
                    id: "mileage",
                    type: "text",
                    disabled: true,
                  },
                ]}
                sm={2}
              />
            </Grid>
            <Box mb={1}>
              <Grid container spacing={2}>
                {form.values &&
                  form.values.mileageItems &&
                  Object.entries(form.values.mileageItems).map(
                    ([field, value]) => (
                      <RepairItemBox
                        key={field}
                        {...{
                          field,
                          value,
                          repairedArr,
                          checkRepairItem,
                          isEditable,
                          mileage,
                        }}
                      />
                    )
                  )}
              </Grid>
            </Box>
          </Card>
        </Box>

        <Card>
          <Typography variant="h5">證明文件</Typography>
          <Divider />
          <MultiUploadAndDownloadDoc
            editable={isEditable}
            type="證明文件"
            id={"maintenance"}
            {...{ fileContent, setFileContent }}
          />

          {inspectionDoc && inspectionDoc.length > 0 ? (
            inspectionDoc
              .slice()
              .map(
                (item, index) =>
                  item && (
                    <StyledDocBox
                      isDeleted={deleteList.includes(item._id)}
                      deleteId={item._id}
                      setDeleteDialog={setDeleteDialog}
                      key={item._id}
                      index={inspectionDoc.length - index}
                      name={item.name}
                      path={item.path}
                      uploadedAt={item.uploadedAt}
                      uploadedBy={item.uploadedBy && item.uploadedBy.name}
                    />
                  )
              )
          ) : (
            <Box color="#7C7C7C" mt={2} mb={1}>
              沒有文件
            </Box>
          )}
        </Card>
      </PageContainer>

      <DeleteDocModal
        {...deleteDialog}
        setDeleteDialog={setDeleteDialog}
        form={form}
        documentArr={form.values.inspectionDoc}
        documentArrName={"inspectionDoc"}
        setDeleteList={setDeleteList}
      />
      {isEditable && <OverlayDialog {...{ dialogStatus, setDialogStatus }} />}
    </form>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  taxiList: state.taxi.list,
});
const mapDispatchToProps = (dispatch) => ({
  toggleRefreshFlag: () =>
    dispatch({ type: pageUIActions.TOGGLE_REFRESH_FLAG, refresh: true }),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceDetails);
