import { fleetGroupActions } from '../actions';

const init = { status: 'init', isReady: false, list: [], byFleetId: {} }

export default function fleetGroupReducer(state = init, action) {
  switch (action.type) {
    case fleetGroupActions.GET_ALL_FLEET_GROUP:
      return {
        ...state,
        status: 'loading',
        isReady: false,
        isCloseDialogNeeded: true
      }
    case fleetGroupActions.UPDATE_FLEET_GROUP:

      return {
        ...state,
        status: 'ready',
        list: action.list,
        byFleetId: action.groupByFleetId,
        isReady: true
      }
    case fleetGroupActions.CREATE_FLEET_GROUP:
      return {
        ...state,
        status: 'loading',
      }
    case fleetGroupActions.FAIL_CREATE_FLEET_GROUP:
      return {
        ...state,
        status: 'error',
        errMsg: action.errMsg
      }
    case fleetGroupActions.ERROR_FETCHING_FLEET_GROUP:
      return {
        ...state,
        status: 'error',
        isReady: false,
        errMsg: action.errMsg
      }
    case fleetGroupActions.RESET_FLEET_GROUP_STATUS:
      return {
        ...state,
        status: 'ready',
        errMsg: "",
        isCloseDialogNeeded: false
      }
    default:
      return state
  }
}
