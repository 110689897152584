import React from "react";
import { connect } from "react-redux";

const Demo = (props) => {
  return <></>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
