export const rosterActions = {
  //ROSTER OVERALL INFO & HEADER
  GET_ROSTER: "GET_ROSTER",
  UPDATE_ROSTER: "UPDATE_ROSTER",
  ERROR_FETCHING_ROSTER: "ERROR_FETCHING_ROSTER",

  FETCH_FREEZE_ROSTER: "FETCH_FREEZE_ROSTER",
  FREEZE_ROSTER: "FREEZE_ROSTER",
  FAIL_FETCH_FREEZE_ROSTER: "FAIL_FETCH_FREEZE_ROSTER",
  RESET_FREEZE_ROSTER: "RESET_FREEZE_ROSTER",

  INCREASE_VACANCY: "INCREASE_VACANCY",
  DECREASE_VACANCY: "DECREASE_VACANCY",
  INCREASE_AVAILABLE_DRIVERS:"INCREASE_AVAILABLE_DRIVERS",

  FETCH_PUBLIC_HOLIDAY: "FETCH_PUBLIC_HOLIDAY",
  FETCH_PUBLIC_HOLIDAY_SUCCESS: "FETCH_PUBLIC_HOLIDAY_SUCCESS",
  FETCH_PUBLIC_HOLIDAY_FAIL: "FETCH_PUBLIC_HOLIDAY_FAIL",

  //ROSTER OPERATION
  ADD_REPLACEMENT: "ADD_REPLACEMENT",

  FETCH_SET_REPLACEMENT: "FETCH_SET_REPLACEMENT",
  SET_REPLACEMENT: "SET_REPLACEMENT",
  FAIL_SET_REPLACEMENT: "FAIL_SET_REPLACEMENT",

  FETCH_RESEND_MSG: "FETCH_RESEND_MSG",
  SUCCESS_RESEND_MSG: "SUCCESS_RESEND_MSG",
  FAIL_RESEND_MSG: "FAIL_RESEND_MSG",

  FETCH_CONFIRM_REPLACEMENT: "FETCH_CONFIRM_REPLACEMENT",
  UPDATE_CONFIRM_REPLACEMENT: "UPDATE_CONFIRM_REPLACEMENT",
  FAIL_FETCH_CONFIRM_REPLACEMENT: "FAIL_FETCH_CONFIRM_REPLACEMENT",

  FETCH_CANCEL_REPLACEMENT: "FETCH_CANCEL_REPLACEMENT",
  FAIL_CANCEL_REPLACEMENT: "FAIL_CANCEL_REPLACEMENT",
  DELETE_REPLACEMENT: "DELETE_REPLACEMENT",

  FETCH_APPLY_FOR_LEAVE:"FETCH_APPLY_FOR_LEAVE",
  SUCCESS_APPLY_FOR_LEAVE:"SUCCESS_APPLY_FOR_LEAVE",
  FAIL_TO_APPLY_LEAVE: "FAIL_TO_APPLY_LEAVE",

  FETCH_CANCEL_LEAVE: "FETCH_CANCEL_LEAVE",
  FAIL_FETCH_CANCEL_LEAVE: "FAIL_FETCH_CANCEL_LEAVE",

  FETCH_PENDING_UNIT_REQUEST:"FETCH_PENDING_UNIT_REQUEST",
  PENDING_UNIT_REQUEST:"PENDING_UNIT_REQUEST",
  FAIL_TO_FETCH_PENDING_UNIT:"FAIL_TO_FETCH_PENDING_UNIT",

  FETCH_CANCEL_CHANGE_TAXI:"FETCH_CANCEL_CHANGE_TAXI",
  FAIL_CANCEL_CHANGE_TAXI:"FAIL_CANCEL_CHANGE_TAXI",
  DELETE_PENDING_UNIT:"DELETE_PENDING_UNIT",
  ADD_BACK_FULL_TIME_DRIVER:"ADD_BACK_FULL_TIME_DRIVER",

  UPDATE_ROSTER_SHIFT:"UPDATE_ROSTER_SHIFT",

  //DIALOG SETTING
  OPEN_AND_SET_ROSTER_CELL_DIALOG:"OPEN_AND_SET_ROSTER_CELL_DIALOG",
  SET_LOADING_ROSTER_CELL_DIALOG:"SET_LOADING_ROSTER_CELL_DIALOG",
  CLOSE_ROSTER_CELL_DIALOG:"CLOSE_ROSTER_CELL_DIALOG",
  RESET_ROSTER_CELL_DIALOG:"RESET_ROSTER_CELL_DIALOG",
  RESET_DIALOG: "RESET_DIALOG",

  //PART TIME DIALOG SETTING
  OPEN_AND_SET_PART_TIME_DIALOG:"OPEN_AND_SET_PART_TIME_DIALOG",
  CLOSE_PART_TIME_DIALOG:"CLOSE_PART_TIME_DIALOG"
};
