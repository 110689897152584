import React from "react";
import ManagementAppBar from "./ManagementAppBar";
import Box from "@material-ui/core/Box";
import PageContainer from "../../components/PageContainer";
import LoadingIndicator from "../../components/LoadingIndicator";
import { OverlayDialog } from "./OverlayDialog";
import { withStyles } from "@material-ui/core";
import TabButton from "../../components/TabButton";

const TabContainer = withStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minHeight: "52px",
    marginBottom: 10,
  },
})(Box);

const ManagementContainer = ({
  form,
  group,
  type,
  isLoading,
  children,
  dialogStatus,
  setDialogStatus,
  isTabSelectorShow,
  tabIndex,
  setTabIndex,
  tabs,
  isCreatePage,
  disabledOverlay,
  isLoadingDisableOverlay,
  enableHandler,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const height = window.innerHeight;
    form.validateForm().then((res) => {
      const error = Object.keys(res)[0];
      if (error) {
        const elem = document.getElementsByName(error)[0];
        const elementTop = elem.getBoundingClientRect().top;
        if (elementTop < 70) {
          window.scrollBy(0, elementTop - 100);
        } else if (height < elementTop) {
          console.log(height);
          console.log(height < elementTop);
          elem.scrollIntoView();
        }
      }
    });
    form.handleSubmit();
  };

  return (
    <form
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "hidden",
      }}
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
    >
      <ManagementAppBar
        {...{
          group,
          type,
          setDialogStatus,
          tabIndex,
          isTabSelectorShow,
          disabledOverlay,
          isLoadingDisableOverlay,
          enableHandler,
        }}
      />
      <PageContainer scroll={"hidden"}>
        {/* Tab selector for driver and taxi owner update page */}
        {isTabSelectorShow && (
          <TabContainer>
            {tabs.map((item, index) => (
              <TabButton
                key={item}
                select={tabIndex === index ? "select" : undefined}
                onClick={() => setTabIndex(index)}
              >
                {item}
              </TabButton>
            ))}
          </TabContainer>
        )}

        {/* normal card for display information */}

        {isLoading && <LoadingIndicator />}
        <div style={{ overflow: "hidden", display: "block" }}>{children}</div>
      </PageContainer>
      <OverlayDialog {...{ dialogStatus, setDialogStatus, isCreatePage }} />
    </form>
  );
};

export default ManagementContainer;
