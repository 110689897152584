import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "../../components/Table";
import AppBar from "../../components/AppBar";
import PageContainer from "../../components/PageContainer";
import Grid from "@material-ui/core/Grid";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";
import { HeadingCard } from "../../components/Card";
import GetData from "../../components/GetData";
import SelectTextField from "../../components/SelectTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { modalConstants } from "../../components/Modal/constants";
import { updateFleetGroup, getTaxies, getIncome } from "../../redux/API";
import { fleetGroupActions, accountActions } from "../../redux/actions";
import ConflictAlert from "./ConflictAlert";
import KeywordSearch from "../../components/KeywordSearch";
import { checker } from "../../permission";
import Back from "../../components/Back";
import { pageUIActions } from "../../redux/actions";
import StyledButton from "../../components/Button";
import { IncomeCard } from "../../components/Card/IncomeCard";
import FilterBox from "../../components/FilterBox";
import DeleteModal from "../../components/DeleteModal";

const columns = [
  { type: "plateNumber", label: "車牌號碼", minWidth: 100, isShowGPS: true },
  { type: "modelNumber", label: "車輛型號", minWidth: 120 },
  { type: "yearOfMfr", label: "出廠年份", minWidth: 80 },
  { type: "owner", label: "車主名稱", minWidth: 80 },
  { type: "morningShiftRent", label: "日更租金", minWidth: 80 },
  {
    type: "nightShiftRent",
    label: "夜更租金",
    minWidth: 80,
  },
  {
    type: "morningShiftDriver",
    label: "正日更司機",
    minWidth: 80,
  },
  {
    type: "nightShiftDriver",
    label: "正夜更司機",
    minWidth: 80,
  },
  {
    type: "gpsInstalled",
    label: "GPS安裝",
    minWidth: 80,
  },
  {
    type: "taxiStatus",
    label: "狀態",
    minWidth: 80,
  },
];

const conflictChecker = (staffArr, staffId, currentGroupId) => {
  if (!staffId) {
    return [false, undefined, undefined];
  }
  const staff = staffArr.find((person) => person._id === staffId);
  const isConflict =
    staff &&
    staff.fleetGroup &&
    staff.fleetGroup._id &&
    staff.fleetGroup._id !== currentGroupId;
  return [
    isConflict,
    isConflict && staff.name,
    isConflict && staff.fleetGroup.name,
  ];
};

const onChangeChecker = (initialValues, newValues) => {
  if (initialValues.address !== newValues.address) {
    return true;
  }
  if (initialValues["groupLeader"]) {
    if (initialValues["groupLeader"]._id !== newValues.groupLeaderId) {
      return true;
    }
  } else {
    if (newValues.groupLeaderId) {
      return true;
    }
  }

  if (initialValues["operator"]) {
    if (initialValues["operator"]._id !== newValues.operatorId) {
      return true;
    }
  } else {
    if (newValues.operatorId) {
      return true;
    }
  }
  return false;
};

const FleetGroup = ({
  role,
  fleetGroupList,
  groupLeaderList,
  operatorList,
  getAllFleetGroup,
  getAllSystemUser,
  updateOriginalTable,
  origin,
  rentalSelectedDate,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [dialog, setDialog] = useState({ isOpen: false });
  const [incomeBox, setIncomeBox] = useState("");

  //Get taxi of the fleet group
  useEffect(() => {
    if (rentalSelectedDate.startDate && rentalSelectedDate.endDate) {
      const fetchIncome = async () => {
        try {
          const income = await getIncome({
            fleetGroup: id,
            from: rentalSelectedDate.startDate.format("YYYY-MM-DD"),
            to: rentalSelectedDate.endDate.format("YYYY-MM-DD"),
          });
          if (income) {
            setIncomeBox(income);
          } else {
            throw new Error(income);
          }
        } catch (e) {
          console.log("fail to get the taxi of fleet group");
          console.log(e);
        }
      };
      fetchIncome();
    }
  }, [id, rentalSelectedDate.startDate, rentalSelectedDate.endDate]);

  useEffect(() => {
    if (id && origin !== `fleetGroup-${id}`) {
      const fetchTaxi = async () => {
        try {
          const response = await getTaxies({ fleetGroup: id });
          if (Array.isArray(response)) {
            updateOriginalTable(response, `fleetGroup-${id}`);
          } else {
            throw new Error(response);
          }
        } catch (e) {
          console.log("fail to get the taxi of fleet group");
          console.log(e);
        }
      };
      fetchTaxi();
    }
  }, [id, origin, updateOriginalTable]);

  //Get the fleet group name from redux store. If no name, redirect to fleet.
  const [currentGroup, setCurrentGroup] = useState({ name: "" });
  useEffect(() => {
    if (fleetGroupList.length > 0) {
      const fleetGroup = fleetGroupList.find((group) => group._id === id);
      if (fleetGroup) {
        setCurrentGroup(fleetGroup);
      } else {
        history.push("/fleet");
      }
    }
    return () => {
      setCurrentGroup({ name: "" });
    };
  }, [fleetGroupList, id, history]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      fleetId:
        currentGroup && currentGroup.fleet && currentGroup.fleet._id
          ? currentGroup.fleet._id
          : "",
      groupLeaderId:
        currentGroup["groupLeader"] && currentGroup["groupLeader"]._id
          ? currentGroup["groupLeader"]._id
          : "",
      operatorId:
        currentGroup["operator"] && currentGroup["operator"]._id
          ? currentGroup["operator"]._id
          : "",
      address: currentGroup.address ? currentGroup.address : "",
    },
    validationSchema: Yup.object({
      groupLeaderId: Yup.string(),
      operatorId: Yup.string(),
      address: Yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        if (!onChangeChecker(currentGroup, form.values)) {
          return setDialog({
            isOpen: true,
            type: "noChange",
          });
        }
        const [
          operatorConflict,
          operatorName,
          operatorOriginalGroup,
        ] = conflictChecker(operatorList, values.operatorId, id);
        const [
          leaderConflict,
          leaderName,
          leaderOriginalGroup,
        ] = conflictChecker(groupLeaderList, values.groupLeaderId, id);
        if (operatorConflict || leaderConflict) {
          setDialog({
            isOpen: true,
            type: "conflict",
            operatorConflict,
            operatorName,
            operatorOriginalGroup,
            leaderConflict,
            leaderName,
            leaderOriginalGroup,
          });
        } else {
          updateFleetGroupHandler();
        }
      } catch (e) {
        console.log("Error in update fleet group info", e);
        setDialog({
          ...modalConstants.ERROR_DIALOG,
          errMsg:
            e &&
            e.response &&
            e.response.data &&
            e.response.data.result &&
            e.response.data.result.err,
        });
      }
    },
  });

  const updateFleetGroupHandler = async () => {
    setDialog({ isOpen: true, isLoading: true });
    const values = { ...form.values };
    delete values.fleetId;

    updateFleetGroup(id, values)
      .then((response) => {
        //waiting to confirm response checking
        setDialog({ isOpen: true, isLoading: false, isSuccess: true });
        getAllFleetGroup();
        getAllSystemUser();
      })
      .catch((e) => {
        console.log("Error in update fleet group info", e);
        setDialog({
          ...modalConstants.ERROR_DIALOG,
          errMsg:
            e.response &&
            e.response.data &&
            e.response.data.result &&
            e.response.data.result.err,
        });
      });
  };

  return (
    <>
      <GetData systemUser fleetGroup taxi />
      <AppBar>
        <SpaceBetweenBox flexWrap="wrap">
          <RowAlignCenterBox flexWrap="wrap" flexGrow="1">
            <Back />
            <Box mx={4}>
              <Typography style={{ fontWeight: "700" }}>
                {currentGroup.name}
              </Typography>
            </Box>

            <RowAlignCenterBox style={{ minWidth: "50%" }}>
              <KeywordSearch component="fleetGroup" style={{ flex: 1 }} />
              <FilterBox type="taxi" component="fleetGroup" />
            </RowAlignCenterBox>
          </RowAlignCenterBox>

          <RowAlignCenterBox>
            {checker(role, "fleet_group:delete") && (
              <DeleteModal category="fleet_group" id={id} />
            )}
            {checker(role, "fleet_group:update") && (
              <Box ml={2}>
                <StyledButton background="purple" onClick={form.handleSubmit}>
                  更新
                </StyledButton>
              </Box>
            )}
          </RowAlignCenterBox>
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        <IncomeCard {...{ incomeBox }} />

        <HeadingCard title={"車組"}>
          <form
            onSubmit={form.handleSubmit}
            style={{ width: 650 }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <SelectTextField
                sm={4}
                fieldArr={[
                  { label: "車組管理員", id: "groupLeaderId", type: "select" },
                  { label: "事務員", id: "operatorId", type: "select" },
                  { label: "交更地址", id: "address", type: "text" },
                ]}
                form={form}
                editable={checker(role, "fleet_group:update")}
              />
            </Grid>
          </form>
        </HeadingCard>

        <Table
          // rows={screenTableData}
          {...{ columns }}
          pathname={"/taxi/"}
          selectBy={"_id"}
          component="fleetGroup"
          origin={`fleetGroup-${id}`}
        />
      </PageContainer>

      <ConflictAlert {...{ ...dialog, updateFleetGroupHandler, setDialog }} />
    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  groupLeaderList: state.systemUser["group_leader"],
  operatorList: state.systemUser["operator"],
  fleetGroupList: state.fleetGroup.list,
  origin: state.ui.origin,
  rentalSelectedDate: state.ui.rentalSelectedDate,
});
const mapDispatchToProps = (dispatch) => ({
  getAllFleetGroup: () =>
    dispatch({ type: fleetGroupActions.GET_ALL_FLEET_GROUP }),
  getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNT }),
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(mapStateToProps, mapDispatchToProps)(FleetGroup);
