import React, { useState } from "react";
import {
  resetUserPassword,
  createUser,
  updateUser,
  toggleDisabledUser,
  toggleDisabledTaxi,
} from "../redux/API";
import { UtilsService } from "./UtilsService";
import {
  driverActions,
  taxiActions,
  taxiOwnerActions,
  accountActions,
} from "../redux/actions";
import { store } from "../App";

const createClient = async (
  form,
  setDialogStatus,
  type,
  group,
  isCreatePage,
  setRefreshCounter
) => {
  try {
    setDialogStatus({ isOpen: true, isLoading: true, type, group });
    const formattedForm = taxiOwnerFormat(form);
    UtilsService.emptyToNull(formattedForm);
    console.log(formattedForm);
    const response = isCreatePage
      ? await createUser(formattedForm)
      : await updateUser(formattedForm._id, formattedForm);

    if (response._id) {
      setDialogStatus({
        isOpen: true,
        isSuccess: true,
        type,
        group,
        password: response.password,
        setRefreshCounter,
      });
      return true;
    } else {
      console.log("Error happen in creating /update user info", group);
      console.log(response);
      setDialogStatus((prev) => ({
        ...prev,
        isOpen: true,
        isLoading: false,
        errMsg: response && response.result && response.result.err,
        isErrorOccur: true,
      }));
    }
  } catch (e) {
    setDialogStatus((prev) => ({
      ...prev,
      isOpen: true,
      isLoading: false,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
      isErrorOccur: true,
    }));
    console.log("Error happen in creating /update user info", group, e);
    console.log(JSON.stringify(e));
  }
};

const handleResetPassword = async (userId, setDialogStatus, group) => {
  try {
    setDialogStatus({ isOpen: true, type: "reset", isLoading: true, group });
    const response = await resetUserPassword(userId);
    if (response._id) {
      setDialogStatus({
        isOpen: true,
        password: response.password,
        type: "reset",
        isSuccess: true,
        group,
      });
    } else {
      console.log("Error in handleResetPassword");
      setDialogStatus((prev) => ({
        ...prev,
        isOpen: true,
        isLoading: false,
        isErrorOccur: true,
        errMsg: response && response.result && response.result.err,
      }));
    }
  } catch (e) {
    setDialogStatus((prev) => ({
      ...prev,
      isOpen: true,
      isLoading: false,
      isErrorOccur: true,
    }));
    console.log("Error in handleResetPassword", e);
  }
};

const copyToClipboard = async (password, setDialogStatus) => {
  setDialogStatus((prev) => ({ ...prev, isLoading: true }));

  const el = document.createElement("textarea");
  el.value = password;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);

  navigator.clipboard.writeText(password);

  setDialogStatus((prev) => ({
    ...prev,
    isLoading: false,
    password: "",
    type: "copied",
  }));
};

const driverFormat = (values) => {
  const {
    _id,
    username,
    name,
    role,
    nickname,
    hkid,
    birthday,
    validDrivingLicense,
    drivingLicenseNum,
    DrivingLicenseExpiryDate,
    serviceStartDate,
    driver_image,
    fleetId,
    fleetGroupId,
    phone,
    secondaryPhone,
    homePhone,
    address,
    region,
    contact_name,
    contact_relationship,
    contact_phone,
    dayoff,
    shift,
    contract_date,
    contract_image,
    contract_status,
    deducted,
    deductedLastUpdated,
    englishLevel,
    smoker,
    driver_status,
    driver_priority,
  } = values;

  const obj = {
    _id,
    username,
    name,
    role,
    phone,
    fleetId,
    fleetGroupId,
    driver: {
      nickname,
      hkid,
      birthday: UtilsService.dateFormat(birthday),
      validDrivingLicense,
      drivingLicenseNum,
      DrivingLicenseExpiryDate: UtilsService.dateFormat(
        DrivingLicenseExpiryDate
      ),
      serviceStartDate: UtilsService.dateFormat(serviceStartDate),

      image: driver_image,

      secondaryPhone,
      homePhone,
      address,
      region,
      contact: {
        name: contact_name,
        relationship: contact_relationship,
        phone: contact_phone,
      },
      dayoff,
      shift,
      contract: {
        image: contract_image,
        date: UtilsService.dateFormat(contract_date),
        status: contract_status,
      },
      other: {
        deducted,
        deductedLastUpdated: UtilsService.dateFormat(deductedLastUpdated),
        englishLevel,
        smoker,
        status: driver_status,
        priority: driver_priority,
      },
    },
  };
  return obj;
};

const taxiOwnerFormat = (values) => {
  const {
    _id,
    username,
    name,
    hkid,
    email,
    secondaryEmail,
    role,
    phone,
    secondaryPhone,
    fax,
    address,
    region,
    company_name,
    company_br,
    company_phone,
    company_fax,
    company_contact,
    company_contact_phone,
    company_contact_email,
    company_second_contact,
    company_second_contact_phone,
    company_second_contact_email,
    company_website,
    company_address,
    company_region,
  } = values;

  const obj = {
    _id,
    username,
    name,
    role,
    phone,
    taxi_owner: {
      hkid,
      email,
      secondaryEmail,
      secondaryPhone,
      fax,
      address,
      region,
      company: {
        name: company_name,
        br: company_br,
        phone: company_phone,
        fax: company_fax,
        primaryContact: {
          name: company_contact,
          phone: company_contact_phone,
          email: company_contact_email,
        },
        secondaryContact: {
          name: company_second_contact,
          phone: company_second_contact_phone,
          email: company_second_contact_email,
        },
        website: company_website,
        address: company_address,
        region: company_region,
      },
    },
  };
  return obj;
};

const taxiFormat = (values) => {
  const {
    taxi_status,
    taxi_type,
    maintByMfr,
    _id,
    plateNumber,
    modelNumber,
    vinNum,
    yearOfMfr,
    ownerId,
    licenseExpiryDate,
    serviceCommenceDate,

    // rental info
    fleetId,
    fleetGroupId,
    escrowContract,
    takeOverTime,
    rentalStartTime,
    rentalEndTime,
    morningShiftRent,
    nightShiftRent,
    morningShiftDriverId,
    nightShiftDriverId,
    rentalFeeToOwner,

    // insurance info
    insCompany,
    typeOfIns,
    agentId,
    agentName,
    insExpDate,
    insCushionAmount,
    insPolicyDocument,
    ncd,

    // Vehicle inspection
    inspectionScheduleDocu,
    throMaintenanceDate,
    briefCheckingDate,
    engineOil,
    gearboxlOil,
    differentialAssemblyOil,
    battery,
    tire,
    airConditioning,
    waterTank,
    waterPump,
    splash,
    starter,
    frontBrakePad,
    backBrakePad,
    brakeCylinder,
    sparkPlug,
    others,

    // gps info
    // mileage,
    // gpsSingleLastUpdateTime,
    gpsInstalled,
  } = values;

  const obj = {
    status: taxi_status, //CHECK
    type: taxi_type,
    maintByMfr,
    _id,
    plateNumber,
    modelNumber,
    vinNum,
    yearOfMfr,
    owner: ownerId,
    licenseExpiryDate: UtilsService.dateFormat(licenseExpiryDate),
    serviceCommenceDate: UtilsService.dateFormat(serviceCommenceDate),

    // rental info
    fleet: fleetId,
    fleetGroup: fleetGroupId,
    escrowContract,
    takeOverTime: UtilsService.dateFormat(takeOverTime),
    rentalStartTime: UtilsService.dateTimeFormat(
      rentalStartTime.split(" ")[0],
      rentalStartTime.split(" ")[1]
    ),
    rentalEndTime: UtilsService.dateFormat(rentalEndTime),
    morningShiftRent:
      morningShiftRent || morningShiftRent === 0
        ? Number(morningShiftRent)
        : null,
    nightShiftRent:
      nightShiftRent || nightShiftRent === 0 ? Number(nightShiftRent) : null,
    morningShiftDriver: morningShiftDriverId,
    nightShiftDriver: nightShiftDriverId,
    rentalFeeToOwner:
      rentalFeeToOwner || rentalFeeToOwner === 0
        ? Number(rentalFeeToOwner)
        : null,

    // insurance info
    insCompany,
    typeOfIns,
    agentId,
    agentName,
    insExpDate: UtilsService.dateFormat(insExpDate),
    insCushionAmount:
      insCushionAmount || insCushionAmount === 0
        ? Number(insCushionAmount)
        : null,
    insPolicyDocument,
    ncd,

    // Vehicle inspection
    inspectionScheduleDocu,
    throMaintenanceDate: UtilsService.dateFormat(throMaintenanceDate),
    briefCheckingDate: UtilsService.dateFormat(briefCheckingDate),
    mileageItems: {
      engineOil,
      gearboxlOil,
      differentialAssemblyOil,
      battery,
      tire,
      airConditioning,
      waterTank,
      waterPump,
      splash,
      starter,
      frontBrakePad,
      backBrakePad,
      brakeCylinder,
      sparkPlug,
      others,
    },
    // gps info
    // mileage,
    gpsInstalled,
  };
  return obj;
};

const useDisabledReducer = ({ isDisabled, type, id }) => {
  const [isLoadingDisableOverlay, setLoadingDisableOverlay] = useState(false);
  const [disabledOverlay, setDisabledOverlay] = useState(isDisabled);
  React.useEffect(() => {
    setDisabledOverlay(isDisabled);
  }, [isDisabled]);

  const enableHandler = async () => {
    setLoadingDisableOverlay(true);

    try {
      switch (type) {
        case "driver":
          await toggleDisabledUser(id);
          store.dispatch({ type: driverActions.REMOVE_ENABLE_DRIVER, id });
          store.dispatch({ type: driverActions.GET_ALL_DRIVER });
          break;
        case "taxi":
          await toggleDisabledTaxi(id);
          store.dispatch({ type: taxiActions.REMOVE_ENABLE_TAXI, id });
          store.dispatch({ type: taxiActions.GET_ALL_TAXI });
          break;
        case "taxi_owner":
          await toggleDisabledUser(id);
          store.dispatch({
            type: taxiOwnerActions.REMOVE_ENABLE_TAXI_OWNER,
            id,
          });
          store.dispatch({ type: taxiOwnerActions.GET_ALL_TAXI_OWNER });
          break;
        case "system_user":
          await toggleDisabledUser(id);
          store.dispatch({ type: accountActions.REMOVE_ENABLE_ACCOUNT, id });
          store.dispatch({ type: accountActions.GET_ALL_ACCOUNT });
          break;
        default:
          console.log("error");
      }
      setDisabledOverlay(false);
    } catch (e) {
      window.alert(JSON.stringify(e));
    } finally {
      setLoadingDisableOverlay(false);
    }
  };

  return [disabledOverlay, isLoadingDisableOverlay, enableHandler];
};

export const UserService = {
  createClient,
  handleResetPassword,
  copyToClipboard,
  driverFormat,
  taxiFormat,
  useDisabledReducer,
};
