import { put, takeLatest, call } from "redux-saga/effects";
import {
  getTaxiTransaction,
  getDriverTransaction,
  getRentArrears,
} from "../API";
import { normalRentalActions, persistRentalActions } from "../actions";
import _ from "lodash";
import { store } from "../../App";
import { RentalService } from "../../Service/RentalService";
import moment from "moment";

function* watchGetRental() {
  yield takeLatest(normalRentalActions.GET_RENTAL_INFO, fetchRental);
}

export function* fetchRental({ date, fleetGroup }) {
  try {
    const role = store.getState().user.role;
    const response = yield call(() => getTaxiTransaction({ date, fleetGroup }));
    const driver = yield call(() => getDriverTransaction({ date, fleetGroup }));
    if ((Array.isArray(response) && response.length === 0) || !response) {
      return yield put({ type: normalRentalActions.NO_RENTAL_FOUND });
    }
    const deduction = _.groupBy(driver, (current) => current.shift);
    const isEditable = RentalService.permissionChecker({
      role,
      item: response[0],
    });
    const currentStatus = response[0].status;
 
    if (isEditable) {
      const { entities, list } = yield call(() =>
        fetchRentArrears({ fleetGroup })
      );
      const isExisted =
        store.getState().persistRental[fleetGroup] &&
        store.getState().persistRental[fleetGroup][date];
      const isSameStatus =
        isExisted &&
        ((isExisted.morningTaxi &&
          isExisted.morningTaxi[0] &&
          isExisted.morningTaxi[0].status === response[0].status) ||
          (isExisted.nightTaxi &&
            isExisted.nightTaxi[0] &&
            isExisted.nightTaxi[0].status === response[0].status));
      yield put({ type: normalRentalActions.UPDATE_EDITABLE, currentStatus });
      const {
        morningTaxi,
        nightTaxi,
        summary,
      } = RentalService.taxiResponseFormat(response);
      isExisted && isSameStatus
        ? yield put({
            type: persistRentalActions.UPDATE_PERSIST_UPDATE_TIME,
            date,
            fleetGroup,
            entities,
            list,
          })
        : yield put({
            type: persistRentalActions.UPDATE_PERSIST_RENTAL,
            morningTaxi,
            nightTaxi,
            summary,
            date,
            fleetGroup,
            ...RentalService.driverTransactionResponseFormat(driver,list,entities),
            entities,
            list,
          });
    } else {
      const { taxiTransaction, summary } = RentalService.displayTaxiTransaction(response)
      const {totalDeposit,totalRentPaidReceived}=RentalService.totalDepositCalculator(driver);
      yield put({
        type: normalRentalActions.UPDATE_NORMAL_RENTAL,
        taxiTransaction,
        summary,
        totalDeposit,
        totalRentPaidReceived,
        totalDeduction: driver.reduce((acc, curr) => acc + curr.amount, 0),
        morningDeductionList: deduction["morning"] || [],
        nightDeductionList: deduction["night"] || [],
        currentStatus,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({ type: normalRentalActions.FAIL_GET_RENTAL_INFO });
  }
}

function* fetchRentArrears({ fleetGroup }) {
  try {
    const res = yield getRentArrears({ fleetGroup });
    const { entities, list } = res.reduce(
      (accumulator, current) => {
        accumulator.entities[current._id] = current;
        if (accumulator.list[current.driver]) {
          let continueLooping = true;
          let index = 0;
          let driverDebt = accumulator.list[current.driver];
          //sorting on each driver's rentArrears by date
          while (continueLooping && index < driverDebt.length) {
            if (
              moment(accumulator.entities[driverDebt[index]].date).isAfter(
                current.date,
                "day"
              )
            ) {
              continueLooping = false;
              driverDebt.splice(index, 0, current._id);
              return accumulator;
            }
            index++;
          }
          driverDebt.push(current._id);
        } else {
          accumulator.list[current.driver] = [current._id];
        }
        return accumulator;
      },
      {
        entities: {},
        list: {},
      }
    );

    return { entities, list };
  } catch (e) {
    console.log("Error in Fetch getRentArrears");
    console.log(e);
    return { entities: {}, list: {}, err: true };
  }
}

export default [
  watchGetRental()]