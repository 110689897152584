
import React from 'react';
import { Divider } from '../../../components/Divider';
import { Box, Card, withStyles } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/zh-hk'
import { RentalInfoBox } from '../RentalInfoBox'
import { connect } from 'react-redux';
import { SpaceBetweenBox } from '../../../components/Box';
import { rentalStatusMatching } from '../../../match';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const VerticalDivider = withStyles({
    root: {
        borderLeft: '1px solid #eaedf3', paddingRight: '6px', paddingTop: '1px', paddingBottom: '1px'
    }
})(Box)

const TopCard = (({ currentRentalStatus, date, fleetGroup, persistRental, normalRental, dispatch, ...rest }) => {
    const { expectedRent,
        morningRent,
        morningPaid,
        nightRent,
        nightPaid,
        totalRent,
        totalPaid,
        totalRentArrear,
        lastUpdateAt } =
        normalRental.isEditable
            ?
            persistRental[fleetGroup] && persistRental[fleetGroup][date] && persistRental[fleetGroup][date].summary ? persistRental[fleetGroup][date].summary : {}
            :
            normalRental.summary ? normalRental.summary : {}

    const {totalDeposit=0,totalRentPaidReceived=0} =
        normalRental.isEditable
            ?
            persistRental[fleetGroup] && persistRental[fleetGroup][date] ? persistRental[fleetGroup][date]:{}
            :
            normalRental

    const isLargeScreen = useMediaQuery('(min-width:1180px)');

    return (
        <Card {...rest} style={{ padding: '10px', overflow: 'unset' }}>
            <SpaceBetweenBox style={{ fontWeight: '700' }}>
                <Box>{date ? moment(date).format('M月D日') : ""}收入（狀態：{rentalStatusMatching[currentRentalStatus]}）</Box>
                {lastUpdateAt && <Box>最後更新：{moment(lastUpdateAt).format('YYYY-MM-DD A hh:mm')}</Box>}
            </SpaceBetweenBox>

            <Divider />
            <Box display="flex" justifyContent={isLargeScreen ? "space-evenly" : "initial"} mx={1} my={3} overflow="auto">
                <RentalInfoBox title="預期收入" amount={expectedRent} />
                <VerticalDivider />
                <RentalInfoBox title="日更租金" amount={morningRent} />
                <VerticalDivider />
                <RentalInfoBox title="日更實付" amount={morningPaid} />
                <VerticalDivider />
                <RentalInfoBox title="夜更租金" amount={nightRent} />
                <VerticalDivider />
                <RentalInfoBox title="夜更實付" amount={nightPaid} />
                <VerticalDivider />
                <RentalInfoBox title="已收按金" amount={totalDeposit} />
                {/* <Box display="flex" flex="1" justifyContent="flex-end" > */}
                <VerticalDivider />
                <RentalInfoBox title="總應交" amount={totalRent} />
                <VerticalDivider />
                < RentalInfoBox title="總實付" amount={totalPaid+totalRentPaidReceived+totalDeposit} />
                <VerticalDivider />
                < RentalInfoBox title="總欠租" color="red" amount={totalRentArrear} />
                {/* </Box> */}
            </Box>
        </Card >
    )
})

const mapStateToProps = (state) => ({
    persistRental: state.persistRental,
    normalRental: state.normalRental,
    currentRentalStatus: state.normalRental.currentStatus,
})

export const TopInfoCard = connect(mapStateToProps)(TopCard)