import React from 'react';
import { connect } from 'react-redux';
import { rosterActions } from '../../../redux/actions';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router';
import moment from 'moment';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const ShiftBtn = withStyles(props => ({
    root: {
        padding: '5px 7px',
        margin: props => props.type === "next" ? '0 20px 0 0' : '0 0 0 20px',
        borderRadius: '6px',
        backgroundColor: 'rgba(200,200,200,0.3)',
        fontWeight: '700',
        flexShrink: 0,
        '&:hover': {
            opacity: '0.4'
        }
    }
})
)(ButtonBase)

const add7Days = (value) => moment(value).add(7, 'd').format('YYYY-MM-DD');
const subtract7Days = (value) => moment(value).subtract(7, 'd').format('YYYY-MM-DD');

const ShiftSelector = ({ currentGroup, getRoster, selector }) => {
    const { groupId } = useParams();
    const handleClick = (value) => {
        let from, to
        if (value === "next") {
            from = add7Days(selector.from)
            to = add7Days(selector.to)
        } else {
            from = subtract7Days(selector.from)
            to = subtract7Days(selector.to)
        }
        getRoster(currentGroup.fleet._id, groupId, selector.shift, from, to)
    }

    return <>

        <ShiftBtn type="previous" onClick={() => handleClick("previous")}> {"< 上周"}</ShiftBtn>
        <Box mx={2} fontWeight="700"><Typography variant="h5">{currentGroup.name}</Typography></Box>
        <ShiftBtn type="next" onClick={() => handleClick("next")}>{"下周 >"}</ShiftBtn>
    </>
}

const mapStateToProps = (state) => ({
    selector: state.roster.selector
})
const mapDispatchToProps = (dispatch) => ({
    getRoster: (fleet, fleetGroup, shift, from, to) => dispatch({ type: rosterActions.GET_ROSTER, fleet, fleetGroup, shift, from, to })
})
export default connect(mapStateToProps, mapDispatchToProps)(ShiftSelector);

