import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import { login, getUserData, updatePassword } from "../API";
import { userActions, persistRentalActions, rosterActions } from "../actions";
import { createBrowserHistory } from "history";

export function* watchLoginUser() {
  yield takeEvery(userActions.LOGIN_USER, loginUser);
}

export function* watchUpdatePassword() {
  yield takeLatest(userActions.UPDATE_USER_PASSWORD, fetchUpdatePassword);
}

export function* watchAutoLogin() {
  yield takeLatest(userActions.AUTO_LOGIN, fetchUserData);
}

export function* watchLogoutUser() {
  yield takeLatest(userActions.LOGOUT_USER, logOut);
}

export function* loginUser(action) {
  try {
    const user = yield call(() => login(action.data));
    if (!!user.access_token && !!user.refresh_token) {
      for (let key in user) {
        localStorage.setItem(key, user[key]);
      }
      yield fetchUserData();
    } else {
      console.log("Error in USER Login saga", user);
      yield put({ type: userActions.LOGIN_FAIL });
    }
  } catch (e) {
    yield put({ type: userActions.GET_USER_ERROR, error: e });
  }
}

export function* fetchUserData() {
  try {
    const userData = yield call(() => getUserData());
    localStorage.setItem("role", userData.role);
    yield put({ type: userActions.LOGIN_SUCCESS, user: userData });
    yield put({ type: rosterActions.FETCH_PUBLIC_HOLIDAY });
  } catch (e) {
    console.log(e);
    yield put({
      type: userActions.GET_USER_ERROR,
      error: e,
    });
  }
}

//incomplete --trycatch
export function* fetchUpdatePassword(action) {
  yield call(() => updatePassword(action.payload));

  yield put({ type: userActions.UPDATE_REGISTRATION_STATUS });
}

function* logOut() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("token_type");
  localStorage.removeItem("role");
  redirect();
  //clear reducer before redirect may trigger unwanted re-render. Better to unmount all route first
  yield put({ type: persistRentalActions.LOGOUT_RESET_RECORD });
  yield put({ type: userActions.CLEAR_DATA });
}

export function redirect(destination = "/login") {
  console.log("ready to redirect");
  createBrowserHistory().push(destination);
}

export default [
watchLoginUser(),
watchUpdatePassword(),
watchAutoLogin(),
watchLogoutUser()]