import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LogoutMenu from "./LogoutMenu";

import { roleMatching } from "../../match";
import clsx from "clsx";
import { pageUIActions } from "../../redux/actions";
const MenuToggle = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#1F2429",
    cursor: "pointer",
  },
})(Box);

const MenuItem = withStyles({
  root: {
    color: "#9DA0A3",
    padding: "0 0 0 35px",
    margin: "10px 0",
    transition: "1s",
    borderRadius: 10,
    fontFamily: 'Roboto-Regular',
    "&:hover": {
      backgroundColor: "#cac8f4",
    },
    "&$selected": {
      backgroundColor: "#5650EF",
      transition: "1s",
      "&:hover": {
        backgroundColor: "#8380e5",
      },
    },
  },
  selected: {
    padding: "0 0 0 35px",
    color: "#FFF",
  },
})(ListItem);

const MenuItems = ({ item }) => {
  let history = useHistory();
  const menuClickHandler = (target) => {
    history.push(`${target}`);
  };
  let match = useRouteMatch({
    path: item.target,
  });

  return (
    <MenuItem
      button
      selected={match && true}
      onClick={() => menuClickHandler(item.target)}
    >
      <Box py={1}>
        <Typography
          style={{ color: match && "#FFF", fontFamily: match && 'PingFangTC-Semibold' }}
        >
          {item.text}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const drawerWidth = 190;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflowX: "hidden",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1F2429",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerClose: {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
  },
  scrollContainer: {
    height: '100%',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: 10
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255,255,255,0.25)',
      borderRadius: 80,
      margin: 2
    }
  }
}));

function Menu({ role, username, open, setOpen }) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  let location = useLocation();
  const isMenuShow = location.pathname !== "/login";
  if (!isMenuShow) {
    return null;
  }
  return (
    <div className="no-print">
      <div
        className={clsx(classes.drawerOpen, {
          [classes.drawerClose]: !open,
        })}
      >
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerContainer}>
            {/* LOGO Box */}
            <Box style={{ margin: "20px 0 20px 45px" }}>
              <img src={`/assets/logo.svg`} alt={"TaxiPOS Logo"} style={{ width: 80, height: 17 }} />
            </Box>
            {/* LogoutMenu and UserName */}
            <Box
              color="white"
              borderTop="1px solid grey"
              borderBottom="1px solid grey"
              pt={1}
              pb={4}
            >
              <Box style={{ margin: "0 0 0 45px" }}>
                <LogoutMenu />
                <Typography style={{ fontWeight: 700, fontSize: 16, color: 'white' }}>{username}</Typography>
                <Typography style={{ fontFamily: 'PingFangTC-Semibold', fontSize: 12, color: '#9ba4b0' }}>{roleMatching[role]}</Typography>
              </Box>
            </Box>
            {/* Menu Button */}<Box className={classes.scrollContainer}>
              <List style={{ margin: "0 10px" }}>
                {[
                  {
                    text: "車隊",
                    target: "/fleet",
                    permission: ["super_admin", "fleet_manager"],
                  },
                  {
                    text: "車主",
                    target: "/taxi-owner",
                    permission: ["super_admin"],
                  },
                  {
                    text: "的士",
                    target: "/taxi",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ],
                  },
                  {
                    text: "的士司機",
                    target: "/driver",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ],
                  },
                  {
                    text: "更表",
                    target: "/roster",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ],
                  },
                  {
                    text: "交更入數",
                    target: "/rental",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ],
                  },
                  {
                    text: "維修",
                    target: "/maintenance",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ]
                  },
                  {
                    text: "意外",
                    target: "/accident",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ]
                  },
                  {
                    text: "今日提示",
                    target: "/daily",
                    permission: [
                      "super_admin",
                      "fleet_manager",
                      "group_leader",
                      "operator",
                    ]
                  },
                  {
                    text: "系統帳戶",
                    target: "/account",
                    permission: ["super_admin"],
                  },
                ].map(
                  (item) =>
                    item.permission.includes(role) && (
                      <MenuItems key={item.target} {...{ item }} />
                    )
                )}
              </List>
              <MenuToggle mr={2} mb={2} p={1} onClick={() => setOpen(false)}>
                <ChevronLeftIcon htmlColor="#e0e0e0" />
                <Box color="#e0e0e0">隱藏</Box>
              </MenuToggle>
            </Box>
          </div>

        </Drawer>

        {!open && (
          <MenuToggle
            p={2}
            style={{ position: "fixed", bottom: "10%", left: 0, zIndex: 999 }}
            onClick={() => setOpen(true)}
          >
            <Box textAlign="end" width="50px" color="#e0e0e0">
              顯示
          </Box>
            <ChevronRightIcon htmlColor="#e0e0e0" />
          </MenuToggle>
        )}
      </div></div >
  );
}

const MapStateToProps = (state) => ({
  role: state.user.role,
  username: state.user ? state.user.username : undefined,
  open: state.ui.open
});
const mapDispatchToProps = (dispatch) => ({
  setOpen: (value) => dispatch({ type: pageUIActions.TOGGLE_SIDEBAR, value }),
})

export default connect(MapStateToProps, mapDispatchToProps)(Menu);
