import {
  BrowserRouter as Router,
  useLocation,
  withRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./redux/reducers";
import rootSaga from "./redux/saga";
import React from "react";
import "./App.css";
import Layout from "./containers/Layout";
import { useEffect } from "react";
import { userActions, persistRentalActions } from "./redux/actions";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import { apiStore } from "./redux/API";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["persistRental"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware)
);
const persistor = persistStore(store, null, () =>
  store.dispatch({ type: persistRentalActions.CHECK_EXPIRE_RECORD })
);
apiStore(store);
sagaMiddleware.run(rootSaga);

const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  useEffect(() => {
    const user = store.getState().user && store.getState().user.username;
    const token = localStorage.getItem("access_token");
    if (token && !user) {
      store.dispatch({ type: userActions.AUTO_LOGIN });
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <Layout />
            </ThemeProvider>
          </ScrollToTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}

function _ScrollToTop(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

export default App;
