import React, { useState } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Table from "../../components/Table";
import PageContainer from "../../components/PageContainer";
import AppBar from "../../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";

import { modalConstants } from "../../components/Modal/constants";
import FleetOverlayModal from "./FleetOverlay";
import NoOfDayPopup from "./NoOfDayPopup";
import FleetTab from "./FleetTab";

import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";
import { checker } from "../../permission";
import { pageUIActions } from "../../redux/actions";
import StyledButton from "../../components/Button";
import { IncomeCard } from "../../components/Card/IncomeCard";
import { getIncome } from "../../redux/API";
import DeleteModal from "../../components/DeleteModal";

const columns = [
  { type: "name", label: "車組", minWidth: 120 },
  {
    type: "groupLeader",
    label: "車組管理員",
    minWidth: 120,
  },
  {
    type: "operator",
    label: "事務員",
    minWidth: 120,
  },
  {
    type: "redTaxiCount",
    label: "紅的",
    minWidth: 80,
  },
  {
    type: "greenTaxiCount",
    label: "綠的",
    minWidth: 80,
  },
  {
    type: "blueTaxiCount",
    label: "藍的",
    minWidth: 80,
  },
  {
    type: "taxiTotalCount",
    label: "的士總數",
    minWidth: 80,
  },
  {
    type: "address",
    label: "交更地址",
    minWidth: 100,
  },
  {
    type: "totalPayable",
    label: "總應交租金",
    minWidth: 80,
  },
  {
    type: "totalRentArrears",
    label: "欠收租金",
    minWidth: 80,
  },
];

const Fleet = ({
  role,
  fleet,
  fleetGroup,
  updateOriginalTable,
  fleetIndex,
  rentalSelectedDate,
}) => {
  // create fleet
  const [isNoOfDayPopupOpen, setNoOfDayPopup] = useState(false);
  const [incomeBox, setIncomeBox] = useState("");
  const [dialog, setDialog] = useState({ isOpen: false });
  const [type, setType] = useState("");

  const handleCreate = (type) => {
    setType(type);
    if (type === "fleet") {
      setDialog({ title: "新增車隊", ...modalConstants.OPEN_DIALOG });
    }
    if (type === "fleetGroup") {
      setDialog({ title: "新增車組", ...modalConstants.OPEN_DIALOG });
    }
  };

  React.useEffect(() => {
    if (
      fleet[fleetIndex] &&
      fleet[fleetIndex]._id &&
      fleetGroup[fleet[fleetIndex]._id]
    ) {
      if (rentalSelectedDate.startDate && rentalSelectedDate.endDate) {
        getIncome({
          fleet: fleet[fleetIndex]._id,
          from: rentalSelectedDate.startDate.format("YYYY-MM-DD"),
          to: rentalSelectedDate.endDate.format("YYYY-MM-DD"),
        })
          .then((res) => {
            setIncomeBox(res);
            updateOriginalTable(
              res.list || [],
              `fleet-${fleet[fleetIndex]._id}`
            );
          })
          .catch((e) => console.log(e));
      }
    }
    return () => {
      setIncomeBox("");
    };
  }, [
    fleetIndex,
    fleetGroup,
    fleet,
    updateOriginalTable,
    rentalSelectedDate.startDate,
    rentalSelectedDate.endDate,
  ]);
  console.log(fleet);
  console.log(fleetIndex);

  return (
    <>
      <AppBar>
        <GetData fleet fleetGroup taxi />
        <SpaceBetweenBox>
          <KeywordSearch component="fleet" style={{ minWidth: "50%" }} />

          <RowAlignCenterBox>
            {checker(role, "fleet:delete") && (
              <DeleteModal
                category="fleet"
                id={fleet && fleet[fleetIndex] && fleet[fleetIndex]._id}
              />
            )}
            {checker(role, "fleet:create") && (
              <Box ml={2}>
                <StyledButton
                  background="purple"
                  onClick={() => handleCreate("fleet")}
                >
                  新增車隊
                </StyledButton>
              </Box>
            )}

            {checker(role, "fleet_group:create") && (
              <Box ml={2}>
                <StyledButton
                  background="purple"
                  onClick={() => handleCreate("fleetGroup")}
                >
                  新增車組
                </StyledButton>
              </Box>
            )}
          </RowAlignCenterBox>
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        <FleetTab fleet={fleet || []} />
        <IncomeCard
          customControl={
            fleet &&
            fleet[fleetIndex] && (
              <Box>
                <span style={{ fontSize: 12, color: "#4a4a4a" }}>
                  休假提前申請日數
                </span>{" "}
                <span style={{ fontSize: 16, color: "#000", margin: "0 10px" }}>
                  {fleet[fleetIndex].daysBeforeApplyLeave}{" "}
                </span>
                <StyledButton
                  background="purple"
                  width="60px"
                  height="35px"
                  onClick={() => setNoOfDayPopup(true)}
                >
                  更改
                </StyledButton>
              </Box>
            )
          }
          {...{ incomeBox }}
        />

        <Table
          // rows={screenTableData}
          {...{ columns }}
          pathname="/fleet/group/"
          selectBy="_id"
          component="fleet"
          origin={fleet[fleetIndex] ? `fleet-${fleet[fleetIndex]._id}` : ""}
        />
      </PageContainer>

      <NoOfDayPopup
        fleet={fleet && fleet[fleetIndex]}
        isOpen={isNoOfDayPopupOpen}
        setIsOpen={setNoOfDayPopup}
      />
      <FleetOverlayModal {...{ ...dialog, setDialog, type }} />
    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  fleet: state.fleet.list,
  fleetGroup: state.fleetGroup.byFleetId,
  fleetIndex: state.ui.fleetIndex || 0,
  rentalSelectedDate: state.ui.rentalSelectedDate,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Fleet);
