import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import StyledButton from "../components/Button";
import { SpaceBetweenBox, RowAlignCenterBox } from "../components/Box";
import Card from "../components/Card";
import AppBar from "../components/AppBar";
import PageContainer from "../components/PageContainer";
import Grid from "@material-ui/core/Grid";
import { accountActions } from "../redux/actions";
import {
  resetUserPassword,
  createUser,
  updateUser,
  getUserData,
} from "../redux/API";
import { PasswordCard } from "../components/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectTextField from "../components/SelectTextField";
import { fleetGroupActions } from "../redux/actions";
import { constants } from "../constants";
import GetData from "../components/GetData";
import Back from "../components/Back";
import { checker } from "../permission/checker";
import { UtilsService } from "../Service/UtilsService";
import { UserService } from "../Service/UserService";
import DeleteModal from "../components/DeleteModal";
import { DeletedItemOverlay } from "../components/DeletedItemOverlay";
import { systemRoleMatching } from "../match";

const CreateUpdate = (props) => {
  const { userId } = useParams();

  const {
    getAllFleetGroup,
    getAllSystemUser,
    userRole,
    fleetManagerList,
    groupLeaderList,
    operatorList,
  } = props;

  const isCreatePage = props.type.toLowerCase() === "create";
  const isEditable = isCreatePage || checker(userRole, "system_user:update");
  const [dialogStatus, setDialogStatus] = useState({
    isOpen: false,
    password: "",
  });

  const [userData, setUserData] = useState({});
  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: userData._id || "",
      name: userData.name || "",
      username: userData.username || "",
      phone: userData.phone || "",
      role: userData.role || "",
      fleetId: userData.fleetId || "",
      fleetGroupId: userData.fleetGroupId || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required(constants.REQUIRED),
      username: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required(constants.REQUIRED),
      phone: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required(constants.REQUIRED),
      role: Yup.string().required(constants.REQUIRED),
      // fleetId: Yup.string().when("role", {
      //   is: (role) =>
      //     ["fleet_manager", "group_leader", "operator"].includes(role),
      //   then: Yup.string().required(constants.REQUIRED),
      // }),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (!isCreatePage) {
        let changes = false;
        for (let key in values) {
          if (values[key] !== userData[key]) {
            changes = true;
          }
        }
        console.log("changes", changes);
        if (!changes) {
          return setDialogStatus({ isOpen: true, type: "unchanged" });
        }
      }

      if (values.role === "fleet_manager" && values.fleetId) {
        const [
          managerConflict,
          managerName,
          managerOriginalFleet,
        ] = UtilsService.conflictChecker(
          "fleet",
          fleetManagerList,
          values.fleetId,
          values._id
        );
        if (managerConflict) {
          return setDialogStatus({
            isOpen: true,
            managerConflict,
            managerName,
            managerOriginalFleet,
            newName: values.name,
            type: "conflict",
          });
        }
      }
      if (values.role === "group_leader" && values.fleetGroupId) {
        const [
          leaderConflict,
          leaderName,
          leaderOriginalGroup,
        ] = UtilsService.conflictChecker(
          "fleetGroup",
          groupLeaderList,
          values.fleetGroupId,
          values._id
        );
        if (leaderConflict) {
          return setDialogStatus({
            isOpen: true,
            leaderConflict,
            leaderName,
            leaderOriginalGroup,
            newName: values.name,
            type: "conflict",
          });
        }
      }
      if (values.role === "operator" && values.fleetGroupId) {
        const [
          operatorConflict,
          operatorName,
          operatorOriginalGroup,
        ] = UtilsService.conflictChecker(
          "fleetGroup",
          operatorList,
          values.fleetGroupId,
          values._id
        );
        if (operatorConflict) {
          return setDialogStatus({
            isOpen: true,
            operatorConflict,
            operatorName,
            operatorOriginalGroup,
            newName: values.name,
            type: "conflict",
          });
        }
      }
      handleFinalSubmit();
    },
  });

  const handleFinalSubmit = async () => {
    const editedForm = {
      ...form.values,
      systemUser: { phone: form.values.phone },
    };

    setDialogStatus({ isOpen: true, isLoading: true });
    try {
      const userInfo = isCreatePage
        ? await createUser(editedForm)
        : await updateUser(form.values._id, editedForm);

      if (userInfo._id) {
        getAllSystemUser();
        getAllFleetGroup();

        setDialogStatus({
          isLoading: false,
          isOpen: true,
          password: userInfo.password || "",
          type: isCreatePage ? "create" : "update",
          isSuccess: true,
        });
      } else {
        setDialogStatus({
          isErrorOccur: true,
          isOpen: true,
          isLoading: false,
        });
        setDialogStatus({
          isOpen: true,
          isErrorOccur: true,
          errMsg: userInfo && userInfo.result && userInfo.result.err,
        });
      }
    } catch (e) {
      setDialogStatus({
        isOpen: true,
        isErrorOccur: true,
        errMsg:
          e &&
          e.response &&
          e.response.data &&
          e.response.data.result &&
          e.response.data.result.err,
      });
      console.log("Error in createUser", e.response.data);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserData(userId).then((adminData) => {
        const {
          _id,
          name,
          username,
          role,
          isDisabled,
          systemUser,
          fleet,
          fleetGroup,
        } = adminData;
        setUserData({
          isDisabled,
          _id,
          name,
          username,
          phone: systemUser ? systemUser.phone : "",
          role,
          fleetId: fleet ? fleet._id : "",
          fleetGroupId: fleetGroup ? fleetGroup._id : "",
        });
      });
    }
  }, [userId]);

  // useEffect(() => {
  //   if (type.toLowerCase() === "update" && username) {
  //     if (systemUserStatus === "init") {
  //       //(1.1)Case:update && username exist in URL + first render
  //       getAllSystemUser();
  //     }
  //     if (systemUser) {
  //       //(1.2)Case:update && username exist in URL + second render or come from other URL
  //       const adminData = systemUser.find((item) => item.username === username);
  //       if (adminData) {
  //         const { _id, name, username, role, isDisabled } = adminData;
  //         setUserData({
  //           isDisabled,
  //           _id,
  //           name,
  //           username,
  //           phone: adminData.systemUser ? adminData.systemUser.phone : "",
  //           role,
  //           fleetId: adminData.fleet ? adminData.fleet._id : "",
  //           fleetGroupId: adminData.fleetGroup ? adminData.fleetGroup._id : "",
  //         });
  //       }
  //     }
  //   }
  // }, [systemUserStatus, username, type, systemUser, getAllSystemUser]);

  const handleResetPassword = async () => {
    try {
      setDialogStatus({ isOpen: true, isLoading: true });
      const newPassword = await resetUserPassword(form.values._id);
      setDialogStatus({
        isOpen: true,
        password: newPassword.password,
        type: "reset",
        isSuccess: true,
      });
    } catch (e) {
      setDialogStatus({
        isOpen: true,
        isErrorOccur: true,
        errMsg:
          e &&
          e.response &&
          e.response.data &&
          e.response.data.result &&
          e.response.data.result.err,
      });
      console.log("Error in handleResetPassword", e);
    }
  };

  const [
    disabledOverlay,
    isLoadingDisableOverlay,
    enableHandler,
  ] = UserService.useDisabledReducer({
    isDisabled: userData.isDisabled,
    id: userData._id,
    type: "system_user",
  });

  return (
    <form onSubmit={form.handleSubmit} noValidate autoComplete="off">
      <GetData fleet fleetGroup />
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox>
            <Back />
            <Box ml={4}>
              <Typography variant="h5">
                {isCreatePage ? "新建系統帳戶" : "帳戶資料"}
              </Typography>
            </Box>
          </RowAlignCenterBox>
          <RowAlignCenterBox>
            {checker(userRole, `system_user:delete`) &&
              userData.role &&
              !isCreatePage &&
              !disabledOverlay && (
                <DeleteModal
                  category={"system_user"}
                  roleName={systemRoleMatching[userData.role]}
                  id={userData._id}
                />
              )}

            {!isCreatePage && !disabledOverlay && (
              <Box ml={2}>
                <StyledButton background="orange" onClick={handleResetPassword}>
                  重設密碼
                </StyledButton>
              </Box>
            )}
            <Box ml={1}>
              <StyledButton
                background="purple"
                onClick={disabledOverlay ? enableHandler : undefined}
                type={disabledOverlay ? "button" : "submit"}
              >
                {disabledOverlay ? "重啟帳戶" : isCreatePage ? "新增" : "更新"}
              </StyledButton>
            </Box>
          </RowAlignCenterBox>
        </SpaceBetweenBox>
        <DeletedItemOverlay
          {...{ disabledOverlay, isLoadingDisableOverlay, enableHandler }}
        />
      </AppBar>

      <PageContainer>
        <Card px={2}>
          <Typography variant="h5">帳戶</Typography>
          <Box width="100%" borderBottom="1px solid grey" my={2}></Box>
          <Box my={2} maxWidth="700px">
            <Grid container spacing={3}>
              <SelectTextField
                editable={isEditable}
                sm={4}
                fieldArr={[
                  { label: "姓名", id: "name", type: "text" },
                  {
                    label: "登入名稱",
                    id: "username",
                    type: "text",
                    disabled: !isCreatePage && true,
                  },
                  { label: "手機號碼", id: "phone", type: "phone" },
                  {
                    label: "角色",
                    id: "role",
                    type: "select",
                    disabled: !isCreatePage && true,
                  },
                ]}
                form={form}
              />

              {["fleet_manager", "group_leader", "operator"].includes(
                form.values.role
              ) && (
                <SelectTextField
                  sm={4}
                  fieldArr={[{ label: "車隊", id: "fleetId", type: "select" }]}
                  form={form}
                  editable={isEditable}
                />
              )}

              {["group_leader", "operator"].includes(form.values.role) && (
                <SelectTextField
                  sm={4}
                  fieldArr={[
                    {
                      label: "車組(可選填)",
                      id: "fleetGroupId",
                      type: "select",
                    },
                  ]}
                  form={form}
                  editable={isEditable}
                />
              )}
            </Grid>
          </Box>
        </Card>
      </PageContainer>

      <PasswordCard
        {...{
          ...dialogStatus,
          setDialogStatus,
          handleFinalSubmit,
          isCreatePage,
        }}
      />
    </form>
  );
};
const mapStateToProps = (state) => ({
  userRole: state.user.role,
  systemUser: state.systemUser && state.systemUser.list,
  systemUserStatus: state.systemUser && state.systemUser.status,
  fleetManagerList: state.systemUser["fleet_manager"],
  groupLeaderList: state.systemUser["group_leader"],
  operatorList: state.systemUser["operator"],
});
const mapDispatchToProps = (dispatch) => ({
  getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNT }),
  getAllFleetGroup: () =>
    dispatch({ type: fleetGroupActions.GET_ALL_FLEET_GROUP }),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);
