import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import { RentalInfoBox } from "../../containers/Rental/RentalInfoBox";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import "./inputCardCalendar.css";
import moment from "moment";
import "moment/locale/zh-hk";
import { useState } from "react";
import { pageUIActions } from "../../redux/actions";
import { connect } from "react-redux";

const StyledCard = withStyles({
  root: {
    margin: "15px 0",
    padding: "20px 15px",
    borderRadius: 8,
  },
})(Card);

export const IncomeCardContainer = (props) => {
  const { rentalSelectedDate, updateSelectedDate, customControl } = props;
  const [focusedInput, setFocusedInput] = useState(null);
  const {
    expectIncome,
    totalMorningRent,
    totalNightRent,
    totalRent,
    totalRentArrears,
    totalActualRent,
  } = props.incomeBox;
  return (
    <StyledCard>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box fontSize="1.5em" fontWeight="bold">
          收入
        </Box>
        {customControl}
      </Box>
      <Box width="100%" border="0.2px solid #ddd"></Box>
      <Box display="flex" justifyContent="space-around" my={2}>
        <Box display="flex" flexShrink="0" flexDirection="column">
          <Box display="flex">
            <Box width="140px">計算收入開始日期</Box>
            <Box>計算收入結束日期</Box>
          </Box>
          <DateRangePicker
            startDatePlaceholderText="DD/MM/YYYY"
            endDatePlaceholderText="DD/MM/YYYY"
            startDate={rentalSelectedDate.startDate}
            startDateId="start_date_id"
            endDate={rentalSelectedDate.endDate}
            endDateId="end_date_id"
            customArrowIcon={<div> </div>}
            hideKeyboardShortcutsPanel={true}
            withPortal={true}
            noBorder={true}
            initialVisibleMonth={() => rentalSelectedDate.startDate}
            isOutsideRange={(day) =>
              moment(day).isAfter(moment().add(1, "days"))
            }
            onDatesChange={({ startDate, endDate }) =>
              updateSelectedDate({ startDate, endDate })
            }
            focusedInput={focusedInput}
            onFocusChange={(focused) => setFocusedInput(focused)}
            displayFormat="DD/MM/YYYY"
          />
        </Box>

        <RentalInfoBox title="預期收入" noMargin amount={expectIncome} />
        <RentalInfoBox title="總早更租金" noMargin amount={totalMorningRent} />
        <RentalInfoBox title="總夜更租金" noMargin amount={totalNightRent} />

        <RentalInfoBox title="總租金" noMargin amount={totalRent} />
        <RentalInfoBox
          title="總欠收租金"
          noMargin
          color="red"
          amount={totalRentArrears}
        />
        <RentalInfoBox title="總實付租金" noMargin amount={totalActualRent} />
      </Box>
    </StyledCard>
  );
};

const mapStateToProps = (state) => ({
  rentalSelectedDate: state.ui.rentalSelectedDate,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedDate: (payload) =>
    dispatch({ type: pageUIActions.UPDATE_RENTAL_SELECTED_DATE, ...payload }),
});

export const IncomeCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(IncomeCardContainer);
