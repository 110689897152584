export const pageUIActions = {
  CHANGE_ROW_PER_PAGE: "CHANGE_ROW_PER_PAGE",
  CHANGE_PAGE: "CHANGE_PAGE",
  UPDATE_ORIGINAL_TABLE: "UPDATE_ORIGINAL_TABLE",
  TOGGLE_REFRESH_FLAG: "TOGGLE_REFRESH_FLAG",
  TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
  TOGGLE_DELETE_PAGE: "TOGGLE_DELETE_PAGE",
  UPDATE_FILTER_BTN: "UPDATE_FILTER_BTN",
  UPDATE_ORIGINAL_TABLE_WITH_KEYWORD: "UPDATE_ORIGINAL_TABLE_WITH_KEYWORD",
  UPDATE_SCREEN_TABLE: "UPDATE_SCREEN_TABLE",
  UPDATE_CURRENT_FLEET: "UPDATE_CURRENT_FLEET",
  UPDATE_RENTAL_SELECTED_DATE: "UPDATE_RENTAL_SELECTED_DATE",
  UPDATE_TAB_INDEX: "UPDATE_TAB_INDEX",

  UPDATE_ORIGINAL_OWNER_TAXI_TABLE: "UPDATE_ORIGINAL_OWNER_TAXI_TABLE",
  UPDATE_OWNER_TAXI_KEYWORD_SEARCH: "UPDATE_OWNER_TAXI_KEYWORD_SEARCH",
  UPDATE_OWNER_TAXI_FILTER_BTN: "UPDATE_OWNER_TAXI_FILTER_BTN",
};
