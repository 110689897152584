import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

const FleetButton = withStyles({
    root: {
        minWidth: '100px',
        margin: '5px 12px 5px 0',
        padding: '10px 20px',
        backgroundColor: props => props.select ? '#5650ef' : 'transparent',
        borderRadius: '20px',
        color: props => props.select ? 'white' : 'grey',
        fontSize: '18px',
        transition: '200ms all',
        '&:hover': {
            opacity: 0.65,
            backgroundColor: props => props.select ? '#5650ef' : "#d5d3ff",
        }
    }
}
)(ButtonBase);

export default FleetButton;