import React from "react";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

const Back = ({ to, onClick }) => {

  const history = useHistory();
  return (
    <Box
      style={{ cursor: 'pointer', textDecorationLine: 'none' }}
      onClick={
        onClick ? onClick
          :
          to ? () => history.push(to)
            :
            () => { history.goBack() }}
    >
      <Typography variant="body2">返回</Typography>
    </Box>
  );
};


export default Back;
