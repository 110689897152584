import React from "react";

import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Modal from "../Modal";
import {
  deleteDialogActions,
  fleetActions,
  fleetGroupActions,
  taxiActions,
  driverActions,
  taxiOwnerActions,
  accountActions,
} from "../../redux/actions";
import StyledButton from "../Button";
import { withStyles } from "@material-ui/core";
import { useHistory } from "react-router";

const ContentBox = withStyles({
  root: {
    color: "red",
    fontFamily: "PingFangHK-Medium",
    fontSize: 16,
    width: 312,
  },
})(Box);

const DeleteModal = ({
  category,
  dialogStatus,
  handleOpen,
  handleClose,
  handleReset,
  handleDelete,
  fleetIndex,
  id,

  getAllFleet,
  getAllFleetGroup,
  getAllTaxi,
  getAllDriver,
  getAllTaxiOwner,
  getAllSystemUser,
  roleName,
}) => {
  const history = useHistory();
  const { isSuccess } = dialogStatus;
  const closeDialog = () => {
    handleClose();
    setTimeout(handleReset, 500);
    if (isSuccess && category === "system_user") {
      getAllFleet();
      getAllFleetGroup();
      getAllSystemUser();
      setTimeout(() => history.goBack(), 1000);
      return;
    }
    if (isSuccess) {
      getAllFleet();
      getAllFleetGroup();
      getAllTaxi();
      getAllDriver();
      getAllTaxiOwner();
      ["fleet_group", "taxi_owner", "driver", "taxi"].includes(category) &&
        setTimeout(() => history.goBack(), 1000);
    }
  };

  const deleteHandler = () => {
    handleDelete({ category, fleetIndex, id });
  };

  const [title, content] = React.useMemo(() => {
    switch (category) {
      case "fleet":
        return [
          "移除車隊",
          "確認移除車隊? 所有內含車組, 的士, 的士司機, 的士車主將會移除",
        ];
      case "fleet_group":
        return [
          "移除車組",
          "確認移除車組? 所有內含的士, 的士司機, 的士車主將會移除",
        ];
      case "taxi_owner":
        return ["移除車主", "確認移除車主? 所有相關的士將會移除"];
      case "taxi":
        return ["移除的士", "確認移除的士? 此的士正更司機將會解除連結"];
      case "driver":
        return [
          "移除司機",
          "確認移除司機? 司機相關的更份、正更的士司機設定將會移除",
        ];
      case "system_user":
        return [`移除帳戶`, `確認移除${roleName}?`];
      default:
        return ["", ""];
    }
  }, [category, roleName]);

  return (
    <>
      <StyledButton
        background="lightBlue"
        variant="outlined"
        height="35px"
        onClick={handleOpen}
      >
        {title}
      </StyledButton>

      <Modal
        title={title}
        disableBGClose={true}
        {...{ closeDialog, ...dialogStatus }}
      >
        {isSuccess ? (
          <Box>已{title}</Box>
        ) : (
          <ContentBox my={1}>{content}</ContentBox>
        )}

        <Box pt={3} pb={1} display="flex" justifyContent="flex-end">
          {!isSuccess && (
            <StyledButton
              background="white"
              variant="outlined"
              type="button"
              onClick={closeDialog}
            >
              取消
            </StyledButton>
          )}
          <Box ml={2}>
            <StyledButton
              background="purple"
              onClick={isSuccess ? closeDialog : deleteHandler}
            >
              {isSuccess ? "關閉" : "確認"}
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dialogStatus: state.deleteDialog,
    fleetIndex: state.ui.fleetIndex || 0,
  };
};
const mapDispatchToProps = (dispatch) => ({
  // createFleet: (payload) => dispatch({ type: fleetActions.CREATE_FLEET, payload }),

  getAllFleet: () => dispatch({ type: fleetActions.GET_ALL_FLEET }),
  getAllFleetGroup: () =>
    dispatch({ type: fleetGroupActions.GET_ALL_FLEET_GROUP }),
  getAllTaxi: () => dispatch({ type: taxiActions.GET_ALL_TAXI }),
  getAllDriver: () => dispatch({ type: driverActions.GET_ALL_DRIVER }),
  getAllTaxiOwner: () =>
    dispatch({ type: taxiOwnerActions.GET_ALL_TAXI_OWNER }),
  getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNT }),
  handleOpen: () => dispatch({ type: deleteDialogActions.OPEN_DELETE_DIALOG }),
  handleDelete: ({ category, fleetIndex, id }) =>
    dispatch({
      type: deleteDialogActions.FETCH_DELETE_DIALOG,
      category,
      fleetIndex,
      id,
    }),
  handleClose: () =>
    dispatch({ type: deleteDialogActions.CLOSE_DELETE_DIALOG }),
  handleReset: () =>
    dispatch({ type: deleteDialogActions.RESET_DELETE_DIALOG }),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
