import React from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const mapPin = {
    red: "ic-pin-red",
    green: "ic-pin-green",
    blue: "ic-pin-blue",
    grey: "ic-pin-grey",
}

const MapOverlay = ({ setIsMapOpen, isMapOpen, gps }) => {
    const handleClose = () => {
        setIsMapOpen(false);
    };

    return (
        <>
            <Dialog
                open={isMapOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <div style={{ height: '50vh', width: '50vw', maxWidth: '600px' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyCU-i6HIX-Nr8u_WufnxVJHr5LZBTwJqG0" }}
                        // bootstrapURLKeys={{ key: "" }}
                        center={{
                            lat: Number(gps && gps.Latitude ? gps.Latitude : 0),
                            lng: Number(gps && gps.Longitude ? gps.Longitude : 0)
                        }}
                        defaultZoom={16}
                    >
                        <div
                            lat={Number(gps && gps.Latitude ? gps.Latitude : 0)}
                            lng={Number(gps && gps.Longitude ? gps.Longitude : 0)}
                        ><img src={`/assets/${mapPin[gps && gps.taxiColor && gps.AccOn ? gps.taxiColor : `grey`]}.svg`} alt={"taxi-location"}
                            style={{
                                width: 40, height: 60,
                                transform: `rotate(${gps && gps.Direction ? gps.Direction : 0}deg)`,
                                marginTop: '-40px',
                                marginLeft: '-17px'
                            }} />
                        </div>
                    </GoogleMapReact>
                </div>
            </Dialog>



        </>
    )
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({

})


export default connect(mapStateToProps, mapDispatchToProps)(MapOverlay);