
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const RosterIcon = ({ type, disabled }) => {
    let icon;
    switch (type) {
        case "add":
            icon = <AddCircleIcon style={{ opacity: disabled ? 0.5 : 1 }} htmlColor="#5650ef" />;
            break;
        case "pending":
            icon = <WarningIcon htmlColor="#f5a623" />;
            break;
        case "accepted":
            icon = <CheckCircleIcon htmlColor="#f5a623" />;
            break;
        default:
            icon = <CheckCircleIcon htmlColor="#50e3c2" />;

    }

    return icon
}




export default RosterIcon;