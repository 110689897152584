import React from 'react';

import { connect } from 'react-redux';
import {  Box } from '@material-ui/core';

const Dashboard=(props) => {

    return (
        <>
        <Box>
        {props.user&&props.user.name}
        </Box>

        </>
    )
}
const mapStateToProps = (state) => ({
    status: state.status,
    user: state.user,
    error: state.error
})


export default connect(mapStateToProps)(Dashboard);