import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const StyledTabs = withStyles({
  root: {
    border: "1px solid #9b9b9b",
    height: 30,
    width: 140,
    borderRadius: 6,
    minHeight: "unset",
    boxSizing: "content-box",
  },
  indicator: {
    display: "flex",
    height: 30,
    borderRadius: 5,
    justifyContent: "center",
    backgroundColor: (props) => (props.shift === 0 ? "#349696" : "#1683FF"),
  },
})(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    padding: 0,
    minHeight: "unset",
    minWidth: "unset",
    width: 70,
    height: 30,
    color: "#9b9b9b",
    // fontFamily: 'PingFangTC',
    fontSize: "14px",
    "&$selected": {
      color: "#FFF",
      fontWeight: "600",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function TaxiWaiveRentSwitch({
  shift,
  waiveOrIncreaseRent,
  setWaiveOrIncreaseRent,
}) {
  const handleChange = (event, newValue) => {
    // 0:waiveRent ; 1:increaseRent
    setWaiveOrIncreaseRent(newValue);
  };

  return (
    <StyledTabs
      shift={shift}
      value={waiveOrIncreaseRent}
      onChange={handleChange}
      aria-label="styled tabs example"
    >
      <StyledTab label="扣租" />
      <StyledTab label="加租" />
    </StyledTabs>
  );
}
