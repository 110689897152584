import React from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Modal from "../../components/Modal";
import StyledButton from "../../components/Button";
import { Input } from "@material-ui/core";
import { updateFleet } from "../../redux/API";
import { fleetActions } from "../../redux/actions";

const NoOfDayPopup = ({
  isOpen,
  setIsOpen,
  fleet,
  isFleetReady,
  updateDaysBeforeApplyLeave,
}) => {
  const [day, setDay] = React.useState(1);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [dialogStatus, setDialogStatus] = React.useState({
    isSuccess: false,
    isLoading: false,
    isErrorOccur: false,
  });

  const closeDialog = () => {
    setDay(fleet ? fleet.daysBeforeApplyLeave : 1);
    setTimeout(
      () =>
        setDialogStatus({
          isSuccess: false,
          isLoading: false,
          isErrorOccur: false,
        }),
      100
    );
    setIsOpen(false);
  };

  const handleSubmit = () => {
    setDialogStatus((prev) => ({ ...prev, isLoading: true }));
    updateFleet(fleet._id, { daysBeforeApplyLeave: Number(day) })
      .then(() => {
        setDialogStatus({
          errMsg: "",
          isSuccess: true,
          isLoading: false,
          isErrorOccur: false,
        });
        updateDaysBeforeApplyLeave(fleet, day);
      })
      .catch((e) => {
        console.log(e);
        setDialogStatus({
          errMsg:
            e.response &&
            e.response.data &&
            e.response.data.result &&
            e.response.data.result.err,
          isSuccess: false,
          isLoading: false,
          isErrorOccur: true,
        });
      });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (value >= 1 || value === "") {
      if (value === "") {
        setErrorMsg(true);
      } else if (errorMsg) {
        setErrorMsg(false);
      }
      setDay(value);
    }
  };
  React.useEffect(() => {
    if (isFleetReady&&fleet) {
      setDay(fleet.daysBeforeApplyLeave);
    }
  }, [isFleetReady, fleet]);

  return (
    <Modal
      title={dialogStatus.isLoading ? "正在連接後台" : "更改提前申請請假日數"}
      disableBGClose
      {...{ isOpen, closeDialog, ...dialogStatus }}
    >
      {dialogStatus.isLoading ? (
        <Box>正在連接後台</Box>
      ) : dialogStatus.isSuccess ? (
        <>
          <Box>提前申請請假日數已更新</Box>
          <Box mt={3} textAlign="right">
            <StyledButton background="purple" onClick={closeDialog}>
              關閉
            </StyledButton>
          </Box>
        </>
      ) : (
        <>
          <Box fontSize="12px" color="#9b9b9b" mb={2}>
            休假提前申請日數
          </Box>
          <Input
            style={{ width: "50px" }}
            inputProps={{ min: 1, step: 1 }}
            type="number"
            value={day}
            onKeyDown={(e) =>
              ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
            }
            onChange={handleChange}
          />
          <Box height="10px" color="red">
            {errorMsg && "* 必須輸入日數"}
          </Box>
          <Box pt={3} pb={1} display="flex" justifyContent="flex-end">
            <StyledButton
              background="white"
              width="100px"
              height="35px"
              variant="outlined"
              type="button"
              onClick={closeDialog}
            >
              取消
            </StyledButton>
            <Box ml={2}>
              <StyledButton
                background="purple"
                width="100px"
                height="35px"
                disabled={errorMsg}
                onClick={handleSubmit}
              >
                更改
              </StyledButton>
            </Box>
          </Box>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isFleetReady: state.fleet.isReady,
});

const mapDispatchToProps = (dispatch) => ({
  updateDaysBeforeApplyLeave: (fleet, day) =>
    dispatch({ type: fleetActions.UPDATE_DAYS_APPLY_LEAVE, fleet, day }),
});
export default connect(mapStateToProps, mapDispatchToProps)(NoOfDayPopup);
