import { rosterActions } from "../actions";
import moment from "moment";
import { UtilsService } from "../../Service/UtilsService";

const today = moment();
const monday = today.startOf("isoWeek").format("YYYY-MM-DD");
const sunday = today.endOf("isoWeek").format("YYYY-MM-DD");
const partTimeDialog={
  category:null,
  isOpen: false,
  isLoading:false,
  isSuccess:false,
  isErrorOccur:false,
  driverObj: null,
  dayOfWeek: null,
  positionOfWeek: null,
  targetDate: null,
  taxiId: null,
  pendingUnits:null,
  leave: null,
  replacementDriver:null
}
const init = {
  status: "init",
  isReady: false,
  isLoading: false,
  isErrorOccur: false,
  list: [],
  replacement: {},
  selector: { shift: "morning", from: monday, to: sunday },
  week: UtilsService.weekFormation(monday),
  freeze: JSON.parse(
    localStorage.getItem(`freeze_${moment().format("YYYY-MM-DD")}`)
  ),
  publicHoliday: null,
  dialog:{
    isLoading:false,
    isOpen:false,
    fullTimeDriverName:null,
    fullTimeDriverId:null,
    selectedDate:null,
    err:null
  },
  partTimeDialog:partTimeDialog
};

export default function rosterReducer(state = init, action) {
  switch (action.type) {
    case rosterActions.GET_ROSTER:
      return {
        ...state,
        status: "loading",
        isReady: false,
        isLoading: true,
      };
    case rosterActions.UPDATE_ROSTER:
      return {
        ...state,
        status: "ready",
        list: action.list,
        replacement: action.replacement,
        transaction: action.transaction,
        week: action.week,
        todayIsPosted: action.todayIsPosted,
        isReady: true,
        isLoading: false,
        selector: {
          shift: action.shift,
          from: action.from,
          to: action.to,
        },
      };
    case rosterActions.ERROR_FETCHING_ROSTER:
      return {
        ...state,
        status: "error",
        isReady: false,
        isLoading: false,
        isErrorOccur: true,
      };

    case rosterActions.INCREASE_VACANCY:
      var index = action.dayOfWeek === 0 ? 6 : action.dayOfWeek - 1;
      var week = [...state.week];
      week.splice(index, 1, {
        ...week[index],
        vacancy: week[index].vacancy + 1,
      });
      return {
        ...state,
        week,
      };

    case rosterActions.INCREASE_AVAILABLE_DRIVERS:
      // eslint-disable-next-line
      var index = action.dayOfWeek === 0 ? 6 : action.dayOfWeek - 1;
      // eslint-disable-next-line
      var week = [...state.week];
      var isFakeDriver = action.driverName.substring(0, 1) === "*";

      week.splice(index, 1, {
        ...week[index],
        availableDriverNo: isFakeDriver
          ? week[index].availableDriverNo
          : week[index].availableDriverNo + 1,
        driverList: [
          { name: action.driverName, _id: action.driverId, group:action.driverShift?.includes("re")?"替更":"已取消更份" },
          ...week[index].driverList,
        ],
      });
      return {
        ...state,
        week,
      };

    case rosterActions.ADD_BACK_FULL_TIME_DRIVER:
      // eslint-disable-next-line
      var index = action.positionOfWeek;
      // eslint-disable-next-line
      var week = [...state.week];
      var driverList = week[index].driverList.filter(
        (driver) => driver._id !== action.driverId
      );

      week.splice(index, 1, {
        ...week[index],
        vacancy: week[index].vacancy - 1,
        availableDriverNo: week[index].availableDriverNo - 1,
        driverList,
      });
      return {
        ...state,
        week,
        status: "ready",
        isReady: true,
        isLoading: false,
        partTimeDialog:{
          ...partTimeDialog,
          category:"cancelPending",
          isOpen: true,
          isSuccess:true
      }
    }

    case rosterActions.FETCH_RESEND_MSG:
    case rosterActions.FETCH_SET_REPLACEMENT:
    case rosterActions.FETCH_CANCEL_REPLACEMENT:
    case rosterActions.FETCH_CANCEL_CHANGE_TAXI:
    case rosterActions.FETCH_CONFIRM_REPLACEMENT:
    case rosterActions.FETCH_CANCEL_LEAVE:
      return {
        ...state,
        status: "fetching",
        isReady: true,
        isLoading: false,
        partTimeDialog:{
          ...state.partTimeDialog,
          isLoading:true,
          isSuccess:false,
          isErrorOccur: false,
        }
      };

    case rosterActions.SET_REPLACEMENT:
      return {
        ...state,
        status: "ready",
        isReady: true,
        isLoading: false,
        partTimeDialog:{
          ...state.partTimeDialog,
          category:action.category,
          isLoading:false,
          isSuccess:true,
          isErrorOccur: false,
        }
      };

    case rosterActions.ADD_REPLACEMENT:
      var newList = [...state.list];
      var indexOf = newList.findIndex((elem) => elem._id === action.taxiId);
      newList[indexOf].leaves = {
        ...newList[indexOf].leaves,
        [action.targetDate]: { _id: action._id, status: "APPROVED" },
      };
      return {
        ...state,
        list: newList,
      };

    case rosterActions.DELETE_REPLACEMENT:
      const updatedReplacement = {
        ...state.replacement,
      };
      delete updatedReplacement[action.date].data[action.driverId];

      return {
        ...state,
        status: "ready",
        isReady: true,
        isLoading: false,
        partTimeDialog:{
          ...state.partTimeDialog,
          category:"cancel",
          isLoading:false,
          isSuccess:true,
          isErrorOccur: false,
        },
        replacement: updatedReplacement,
      };

    case rosterActions.UPDATE_CONFIRM_REPLACEMENT:
      const confirmedReplacement = {
        ...state.replacement,
        [action.date]: {
          data: {
            ...state.replacement[action.date].data,
            [action.driverId]: {
              ...state.replacement[action.date].data[action.driverId],
              status: "ACCEPTED",
            },
          },
        },
      };
      const i = action.dayOfWeek === 0 ? 6 : action.dayOfWeek - 1;
      const weekArr = [...state.week];
      weekArr.splice(i, 1, {
        ...weekArr[i],
        vacancy: weekArr[i].vacancy - 1,
      });
      return {
        ...state,
        status: "ready",
        isReady: true,
        isLoading: false,
        partTimeDialog:{
          ...state.partTimeDialog,
          category:"confirm",
          isLoading:false,
          isSuccess:true,
          isErrorOccur: false,
        },
        week: weekArr,
        replacement: confirmedReplacement,
      };

    case rosterActions.PENDING_UNIT_REQUEST:
      newList = [...state.list];
      indexOf = newList.findIndex((elem) => elem._id === action.taxiId);
      newList[indexOf].pendingUnits = {
        ...newList[indexOf].pendingUnits,
        [action.targetDate]: { _id: action._id, status: "VALID" },
      };
      return {
        ...state,
        list: newList,
      };

    case rosterActions.DELETE_PENDING_UNIT:
      newList = [...state.list];
      indexOf = newList.findIndex((elem) => elem._id === action.taxiId);
      delete newList[indexOf].pendingUnits[action.targetDate];

      return {
        ...state,
        list: newList,
      };

    case rosterActions.SUCCESS_RESEND_MSG:
      return {
        ...state,
        partTimeDialog:{
          ...state.partTimeDialog,
          category:"resend",
          isLoading:false,
          isSuccess:true,
          isErrorOccur: false,
        },
      };

    case rosterActions.FAIL_RESEND_MSG:
    case rosterActions.FAIL_SET_REPLACEMENT:
    case rosterActions.FAIL_CANCEL_REPLACEMENT:
    case rosterActions.FAIL_CANCEL_CHANGE_TAXI:
    case rosterActions.FAIL_FETCH_CANCEL_LEAVE:
    case rosterActions.FAIL_FETCH_CONFIRM_REPLACEMENT:
      return {
        ...state,
        status: "error",
        partTimeDialog:{
          ...state.partTimeDialog,
          isLoading:false,
          isSuccess:false,
          isErrorOccur: true,
        },
        errMsg: action.errMsg,
      };

    case rosterActions.RESET_DIALOG:
      return {
        ...state,
        partTimeDialog:partTimeDialog
      };

    case rosterActions.FETCH_FREEZE_ROSTER:
      return {
        ...state,
        status: "fetching",
        isFreezeLoading: true,
        isFreezeErrorOccur: false,
        isFreezeSuccess: false,
      };
    case rosterActions.FREEZE_ROSTER:
      return {
        ...state,
        status: "success",
        todayIsPosted: true,
        isFreezeLoading: false,
        isFreezeErrorOccur: false,
        isFreezeSuccess: true,
      };
    case rosterActions.FAIL_FETCH_FREEZE_ROSTER:
      return {
        ...state,
        status: "error",
        errMsg: action.errMsg,
        isFreezeLoading: false,
        isFreezeErrorOccur: true,
        isFreezeSuccess: false,
      };
    case rosterActions.RESET_FREEZE_ROSTER:
      return {
        ...state,
        status: "fetching",
        errMsg: "",
        isFreezeLoading: false,
        isFreezeErrorOccur: false,
        isFreezeSuccess: false,
      };
    case rosterActions.FETCH_PUBLIC_HOLIDAY_SUCCESS:
      return {
        ...state,
        publicHoliday: action.publicHoliday,
      };
    case rosterActions.UPDATE_ROSTER_SHIFT:
      return {
        ...state,
        selector: {
          ...state.selector,
          shift: action.shift,
        },
      };
    case rosterActions.OPEN_AND_SET_ROSTER_CELL_DIALOG:
      return {
        ...state,
        dialog:{
          isLoading:false,
          isOpen:true,
          fullTimeDriverName:action.fullTimeDriverName,
          fullTimeDriverId:action.fullTimeDriverId,
          selectedDate:action.date,
          dayOfWeek:action.dayOfWeek,
          taxiId:action.taxiId,
          err:null
        }
      }
    case rosterActions.CLOSE_ROSTER_CELL_DIALOG:
      return {
        ...state,
        dialog:{
          ...state.dialog,
          isLoading:false,
          isOpen:false,
        }
      }
    case rosterActions.RESET_ROSTER_CELL_DIALOG:
      return {
        ...state,
        dialog:{
          isLoading:false,
          isOpen:false,
          fullTimeDriverName:null,
          fullTimeDriverId:null,
          selectedDate:null,
          err:null
        }
      }
    case rosterActions.SET_LOADING_ROSTER_CELL_DIALOG:
      return {
        ...state,
        dialog:{
          ...state.dialog,
          isLoading:true,
          isOpen:true,
          err:null
        }
      }
    case rosterActions.FAIL_TO_FETCH_PENDING_UNIT:
    case rosterActions.FAIL_TO_APPLY_LEAVE:
      return {
        ...state,
        dialog:{
          ...state.dialog,
          isLoading:false,
          err:action.err
        }
      }

      case rosterActions.OPEN_AND_SET_PART_TIME_DIALOG:
        return {
          ...state,
          partTimeDialog:{
            category: null,
            isOpen: true,
            driverObj:action.driverObj,
            dayOfWeek:action.dayOfWeek,
            positionOfWeek:action.positionOfWeek,
            targetDate: action.targetDate,
            taxiId :action.taxiId,
            pendingUnits:action.pendingUnits,
            leave:action.leave,
            replacementDriver:action.replacementDriver,
            err:null
          }
        }
          case rosterActions.CLOSE_PART_TIME_DIALOG:
            return {
              ...state,
              partTimeDialog:{
                ...state.partTimeDialog,
                isOpen: false,
              }
            }
    default:
      return state;
  }
}
