import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./calendar.css";
import { DayPickerSingleDateController } from "react-dates";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import moment from "moment";
import "moment/locale/zh-hk";

class Rental extends React.Component {
  constructor(props) {
    super(props);
    moment.locale("zh-hk");
    this.state = {
      focused: true,
      calendar: [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0],
    };
  }

  componentDidMount = () => {
    //add some delay as the calendar need time to render
    setTimeout(
      () =>
        this.bottomRef && this.bottomRef.scrollIntoView({ behavior: "smooth" }),
      300
    );

    // let nextMonthObserver = new IntersectionObserver(entries => {
    //     console.log(entries)
    //     entries[0].isIntersecting && this.nextMonth()
    // }, {
    //     root: this.calendarRef, rootMargin: '30px',
    //     threshold: 0.5
    // });
    // nextMonthObserver.observe(this.bottomRef);
    // let prevMonthObserver = new IntersectionObserver(entries => {
    //     console.log(entries)
    //     entries[0].isIntersecting && this.previousMonth()
    // }, {
    //     root: this.calendarRef,
    //     threshold: 0.1
    // });
    // prevMonthObserver.observe(this.topRef);
  };

  previousMonth = () => {
    const newArr = this.state.calendar.slice();
    newArr.unshift(newArr[0] - 1);
    this.setState({ calendar: newArr }, () => {
      this.calendarRef.scrollTo(0, 10);
    });
  };

  nextMonth = () => {
    const newArr = this.state.calendar.slice();
    newArr.push(newArr[newArr.length - 1] + 1);
    this.setState({ calendar: newArr });
  };

  renderDayContents = (date) => {
    if (
      date.isBefore(moment()) &&
      // eslint-disable-next-line
      (date.weekday() == 0 ||
        this.props.publicHoliday[date.format("YYYYMMDD")] != null)
    ) {
      return <div style={{ color: "red" }}>{date.date()}</div>;
    }
    return <div>{date.date()}</div>;
  };

  render() {
    return (
      <div
        ref={(calendarRef) => (this.calendarRef = calendarRef)}
        style={{ overflowY: "scroll" }}
      >
        <Box mb={4} ref={(topRef) => (this.topRef = topRef)}></Box>
        {this.props.publicHoliday &&
          this.state.calendar.map((value) => (
            <Box key={value} mt={2}>
              <DayPickerSingleDateController
                date={this.props.date} // momentPropTypes.momentObj or null,
                focused={this.state.focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired,
                onDateChange={(date) => {
                  console.log(date);
                  console.log("run");
                  this.props.setDate(date);
                }} // PropTypes.func.isRequired
                // onOutsideClick={() => console.log("Testing")}
                // onPrevMonthClick={() => console.log("Testing")}
                // onNextMonthClick={() => console.log("Testing")}
                initialVisibleMonth={() => moment().add(value, "month")}
                noBorder={true}
                hideKeyboardShortcutsPanel={true}
                daySize={30}
                isOutsideRange={(day) =>
                  moment(day).isAfter(moment().add(1, "days"))
                }
                navPrev={" "}
                navNext={" "}
                renderDayContents={this.renderDayContents}
              />
            </Box>
          ))}
        <Box ref={(bottomRef) => (this.bottomRef = bottomRef)}></Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  publicHoliday: state.roster.publicHoliday,
});

export default connect(mapStateToProps)(Rental);
