import { accountActions } from "../actions";

const init_state = {
  status: "init",
  list: [],
  super_admin: [],
  fleet_manager: [],
  group_leader: [],
  operator: [],
  deletedSystemAccount: [],
  isDeletedSystemAccountReady: false,
};

export default function accountReducer(state = init_state, action) {
  switch (action.type) {
    case accountActions.FETCH_ALL_SYSTEM_ACCOUNT:
      return {
        ...state,
        status: "loading",
      };
    case accountActions.GET_ALL_SYSTEM_ACCOUNT:
      let role = {};
      for (let item of action.payload) {
        role[item.role] && Array.isArray(role[item.role])
          ? role[item.role].push(item)
          : (role[item.role] = [item]);
      }
      return {
        ...state,
        status: "success",
        list: action.payload,
        ...role,
      };
    case accountActions.GET_DELETED_SYSTEM_ACCOUNT:
      return {
        ...state,
        deletedSystemAccount: [],
        isDeletedSystemAccountReady: false,
      };
    case accountActions.UPDATE_DELETED_SYSTEM_ACCOUNT:
      return {
        ...state,
        isDeletedSystemAccountReady: true,
        deletedSystemAccount: action.deletedSystemAccount,
      };
    case accountActions.REMOVE_ENABLE_ACCOUNT:
      return {
        ...state,
        isDeletedSystemAccountReady: true,
        deletedSystemAccount: state.deletedSystemAccount.filter(
          (item) => item._id !== action.id
        ),
      };
    default:
      return state;
  }
}
