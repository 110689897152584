export const persistRentalActions = {
  UPDATE_PERSIST_RENTAL: "UPDATE_PERSIST_RENTAL",
  UPDATE_PERSIST_UPDATE_TIME: "UPDATE_PERSIST_UPDATE_TIME",

  ON_CHANGE_TAXI_CARD: "ON_CHANGE_TAXI_CARD",
  ON_CHANGE_TAXI_CARD_DRIVER: "ON_CHANGE_TAXI_CARD_DRIVER",
  ON_CHANGE_WAIVED_RENT: "ON_CHANGE_WAIVED_RENT",

  BATCH_ON_CHANGE_WAIVED_OR_INCREASED_RENT:
    "BATCH_ON_CHANGE_WAIVED_OR_INCREASED_RENT",

  ADD_DEDUCTION_ROW: "ADD_DEDUCTION_ROW",
  DELETE_DEDUCTION_ROW: "DELETE_DEDUCTION_ROW",
  SUBMIT_DEDUCTION_DIALOG: "SUBMIT_DEDUCTION_DIALOG",

  ON_CHANGE_DEDUCTION_DRIVER:"ON_CHANGE_DEDUCTION_DRIVER",
  ON_CHANGE_WAIVE_OR_INCREASE_RENT:"ON_CHANGE_WAIVE_OR_INCREASE_RENT",
  ON_CHANGE_RENT_ADJUSTMENT_AMOUNT:"ON_CHANGE_RENT_ADJUSTMENT_AMOUNT",
  ON_CHANGE_RENT_ADJUSTMENT_REASON:"ON_CHANGE_RENT_ADJUSTMENT_REASON",
  ON_CHANGE_DEBT_PAID:"ON_CHANGE_DEBT_PAID",

  ADD_DEDUCTION_IN_OTHERS:"ADD_DEDUCTION_IN_OTHERS",
  DELETE_DEDUCTION_IN_OTHERS:"DELETE_DEDUCTION_IN_OTHERS",
  UPDATE_DEDUCTION_TYPE_IN_OTHERS:"UPDATE_DEDUCTION_TYPE_IN_OTHERS",
  UPDATE_DEDUCTION_AMOUNT_IN_OTHERS:"UPDATE_DEDUCTION_AMOUNT_IN_OTHERS",

  DELETE_REMOVED_RENT_ARREARS:"DELETE_REMOVED_RENT_ARREARS",
  CLOSE_RENT_ARREARS_POP_UP:"CLOSE_RENT_ARREARS_POP_UP",

  DELETE_TARGET_DATE_RECORD: "DELETE_TARGET_DATE_RECORD",
  CHECK_EXPIRE_RECORD: "CHECK_EXPIRE_RECORD",

  LOGOUT_RESET_RECORD: "LOGOUT_RESET_RECORD",
};
