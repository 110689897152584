import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import AppBar from "../../components/AppBar";
import Back from "../../components/Back";
import { RosterKeywordSearch as RentalKeywordSearch } from "../../components/KeywordSearch/RosterKeywordSearch";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import moment from "moment";
import "moment/locale/zh-hk";
import CalendarSelector from "./CalendarSelector/CalendarSelector";
import PageContainer from "../../components/PageContainer";
import { Divider } from "../../components/Divider";

import ShiftSelector from "./ShiftSelector/ShiftSelector";
import { TopInfoCard } from "./TopInfoCard/TopInfoCard";
import TaxiCard from "./TaxiCard/TaxiCard";
import IndividualDeduction from "./IndividualDeduction/IndividualDeduction";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from "clsx";
import RentalTable from "./RentalTable";
import DeductionTable from "./DeductionTable";
import { normalRentalActions } from "../../redux/actions";
import GetData from "../../components/GetData";
import { useParams } from "react-router";
import StyledButton from "../../components/Button";
import { SpaceBetweenBox, RowAlignCenterBox } from "../../components/Box";
import { Typography } from "@material-ui/core";
import { RentalService } from "../../Service/RentalService";
import { UploadStatusCard } from "./UploadStatusCard";
import LoadingIndicator from "../../components/LoadingIndicator";
import { UiService } from "../../Service/UiService";
import BatchWaiveRentBtn from "./BatchWaiveRent";

const useStyles = makeStyles({
  show: {
    zIndex: 2,
    position: "sticky",
    top: -1,
    // transition: "top 0.3s",
    // transitionTimingFunction: "ease-in-out",
    flexShrink: 0,
    backgroundColor: "#FAFAFA",
  },
  hide: {
    top: -145,
  },
});

// let prevScrollpos = 0;

const Rental = ({
  userRole,
  getTodayRental,
  normalRental,
  persistRental,
  fleetGroupList,
  totalDeduction,
  partTimeDriver,
  fullTimeDriver
}) => {
  const classes = useStyles();
  // const [hide, setHide] = useState(false);
  const [refetchAPI, setRefetchAPI] = useState(0);
  const [dialogStatus, setDialogStatus] = useState({ isOpen: false });
  const [shift, setShift] = useState(0);
  const [calendarDate, setCalendarDate] = useState(moment());
  const date = calendarDate.format("YYYY-MM-DD");
  const { fleetGroup } = useParams();
  const [fleetName, setFleetName] = useState("");
  const [fleetId, setFleetId] = useState("");

  const [keyword, setKeyword] = useState("");
  const rentalInfo = normalRental.isEditable
    ? persistRental[fleetGroup] &&
      persistRental[fleetGroup][date] &&
      persistRental[fleetGroup][date][
        shift === 0 ? "morningTaxi" : "nightTaxi"
      ] &&
      UiService.keywordFilter(
        persistRental[fleetGroup][date][
          shift === 0 ? "morningTaxi" : "nightTaxi"
        ],
        keyword,
        "rental"
      )
    : normalRental.taxiTransaction &&
      UiService.normalRentalKeywordFilter(
        normalRental.taxiTransaction,
        keyword
      );

  useEffect(() => {
    const result = fleetGroupList.list.find(
      (group) => group._id === fleetGroup
    );
    if (result) {
      setFleetId(result.fleet._id);
      setFleetName(result.name);
    }
  }, [fleetGroupList.isReady, fleetGroup, fleetGroupList]);

  useEffect(() => {
    setDialogStatus({ isOpen: false });
  }, [date, fleetGroup]);

  useEffect(() => {
    console.log("date change in Rental.js");
    getTodayRental({ date: date, fleetGroup });
  }, [fleetGroup, date, getTodayRental, userRole, refetchAPI]);

const [driverList,setDriverList]=React.useState({morningIds:[],nightIds:[],labels:{},initialized:false})
const partTimeDriverStrings=JSON.stringify(partTimeDriver);
const fullTimeDriverStrings=JSON.stringify(fullTimeDriver);

React.useEffect(()=>{
  if(normalRental.isEditable){
 const driverListClone={ids:[],morningIds:[],nightIds:[],labels:{},initialized:true}
 function driveListFormation(array,shift,extraInfo){
  if (!array){
    return
  }
  array.forEach(driver=>{
    driverListClone.ids.push(driver._id)
    driverListClone[shift].push(driver._id)
    driverListClone.labels[driver._id]=`${driver.name + extraInfo}`
  })
 }
 driveListFormation(partTimeDriver['morning'][fleetId],'morningIds',"(替日)")
 driveListFormation(fullTimeDriver['morning'][fleetGroup],'morningIds',"(正日)")
 driveListFormation(partTimeDriver['night'][fleetId],'nightIds',"(替夜)")
 driveListFormation(fullTimeDriver['night'][fleetGroup],'nightIds',"(正夜)")

 setDriverList(driverListClone)
}
return ()=>setDriverList({morningIds:[],nightIds:[],labels:{},initialized:false})
//eslint-disable-next-line
},[partTimeDriverStrings,fullTimeDriverStrings,fleetId,fleetGroup,normalRental]) 

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <GetData driver fleet fleetGroup />
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox style={{ flex: 1 }}>
            <Back />
            <Box mx={2}>
              <Typography variant="h5">{fleetName}</Typography>
            </Box>
            <RentalKeywordSearch
              style={{ minWidth: "50%" }}
              {...{
                keyword,
                setKeyword,
              }}
            />
          </RowAlignCenterBox>
          {normalRental.isEditable ? (
            <Box display="flex" alignItems="center">
              <StyledButton
                background="purple"
                type="button"
                onClick={() => {
                  RentalService.handleRentalSubmit({
                    persistRental,
                    date,
                    fleetId,
                    fleetGroup,
                    setDialogStatus,
                    setRefetchAPI,
                  });
                }}
              >
                {["super_admin", "operator"].includes(userRole)
                  ? "提交今日埋數"
                  : "凍結交更入數"}
              </StyledButton>
            </Box>
          ) : (
            <></>
          )}
        </SpaceBetweenBox>
      </AppBar>
      <PageContainer>
        <div style={{ display: "flex", overflow: "hidden" }}>
          <CalendarSelector date={calendarDate} setDate={setCalendarDate} />

          {normalRental.status === "loading" ? (
            <LoadingIndicator />
          ) : normalRental.isReady && normalRental.status === "empty" ? (
            <Box
              display="flex"
              flex="1"
              justifyContent="center"
              alignItems="center"
            >
              未凍結是日更表
            </Box>
          ) : (
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              mx={2}
              mt={2}
              // ref={ref}
              style={{ overflowY: "scroll", position: "relative" }}
            >
              {/* <Box className={clsx(classes.show, { [classes.hide]: hide })}> */}

              <Box className={classes.show}>
                <TopInfoCard {...{ fleetGroup, date }} />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flex={0}
                  style={{ background: "#fafafa" }}
                >
                  <ShiftSelector {...{ shift, setShift, fleetGroup, date }} />
                  {normalRental.isEditable && (
                    <>
                      <Box display={shift === 0 ? "block" : "none"}>
                        <BatchWaiveRentBtn
                          shift={0}
                          {...{ date, fleetGroup }}
                        />
                      </Box>
                      <Box display={shift === 1 ? "block" : "none"}>
                        <BatchWaiveRentBtn
                          shift={1}
                          {...{ date, fleetGroup }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box flexGrow="1">
                {normalRental.isEditable ? (
                  <>
                    {rentalInfo &&
                      rentalInfo.map((item, index) => (
                        <TaxiCard
                          key={item._id}
                          {...item}
                          {...{
                            shift,
                            index,
                            fleetId,
                            fleetGroup,
                            date,
                            dialogStatus,
                            driverList
                          }}
                        />
                      ))}

                    <IndividualDeduction
                           errorMsg={
                            dialogStatus.err&&
                            dialogStatus.err.driver&&
                            dialogStatus.err.driver[shift]?
                            dialogStatus.err.driver[shift]:
                            {}
                           }
                      // errorMsg={dialogStatus.err.driver[shift]||{}}
                      {...{ shift, date, fleetId, driverList,setDialogStatus }}
                    />
                  </>
                ) : (
                  <>
                    <Box my={2}>
                      <Card>
                        <RentalTable rentalInfo={rentalInfo} shift={shift} />
                      </Card>
                    </Box>
                    <Box my={2}>
                      <Card>
                        <Box px={1}>
                          <Box
                            m={1}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <h3>個別司機租金入賬</h3>
                            {!!totalDeduction && (
                              <h3>早更及夜更 總入帳金額：${totalDeduction}</h3>
                            )}
                          </Box>
                          <Divider />
                          <Box my={2}>
                            <DeductionTable shift={shift} />
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
        </div>
      </PageContainer>
      <UploadStatusCard {...{ ...dialogStatus, setDialogStatus }} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  
  userRole: state.user.role,
  error: state.error,
  normalRental: state.normalRental,
  persistRental: state.persistRental,
  fleetGroupList: state.fleetGroup,
  totalDeduction: state.normalRental.totalDeduction,

  partTimeDriver: state.driver.partTimeByFleet||{morning:{},night:{}},
  fullTimeDriver: state.driver.fullTimeByFleetGroup||{morning:{},night:{}},
});
const mapDispatchToProps = (dispatch) => ({
  getTodayRental: (payload) =>
    dispatch({ type: normalRentalActions.GET_RENTAL_INFO, ...payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rental);
