import { put, takeLatest, call } from "redux-saga/effects";

import { deleteDialogActions } from "../actions";
import {
  deleteFleet,
  deleteFleetGroup,
  toggleDisabledTaxi,
  toggleDisabledUser,
} from "../API";

function* watchDeleteAction() {
  yield takeLatest(deleteDialogActions.FETCH_DELETE_DIALOG, fetchDeleteAction);
}

export function* fetchDeleteAction(action) {
  const { category, id } = action;
  try {
    let result;
    switch (category) {
      case "fleet":
        result = yield call(() => deleteFleet(id));
        break;
      case "fleet_group":
        result = yield call(() => deleteFleetGroup(id));
        break;
      case "system_user":
      case "driver":
      case "taxi_owner":
        result = yield call(() => toggleDisabledUser(id));
        break;
      case "taxi":
        result = yield call(() => toggleDisabledTaxi(id));
        break;
      default:
    }
    console.log(result);
    yield put({ type: deleteDialogActions.SUCCESS_DELETE_DIALOG });
  } catch (e) {
    console.log(e);
    yield put({
      type: deleteDialogActions.FAIL_TO_DELETE_DIALOG,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export default [
  watchDeleteAction()
]