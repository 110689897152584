// import React, { useState, useRef } from 'react';
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
// import Switch from "react-switch";
import { RentalInfoBoxPure } from "../RentalInfoBox";
import { TextField } from "@material-ui/core";
import { persistRentalActions } from "../../../redux/actions";
import NumberFormat from "react-number-format";
import DriverSelect from "./DriverSelect";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TaxiWaiveRentSwitch from "../../../components/Switch/TaxiWaiveRentSwitch";


const useStyles = makeStyles({
  root: {},
  switchBase: {
    color: "#f1f1f1",
    "&$checked": {
      color: "white",
    },
    "&$checked + $track": {
      backgroundColor: "white",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#1f1f42",
  },
});

const BlackOverlay = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "rgba(0,0,0,0.2)",
      zIndex: 1,
    }}
  />
);

const TaxiCard = ({
  _id,
  shift,
  fleetId,
  fleetGroup,
  date,
  driverList,

  index,
  waivedRent,
  increasedRent = 0,
  morningShiftRent,
  nightShiftRent,
  reason,
  payable,
  actualRent,
  taxi,
  plateNumber = taxi.plateNumber,
  driver,
  driverId = driver && driver._id,
  isAttended,
  handleChange,
  handleReasonChange,
  dialogStatus,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [customDeduction, setCustomDeduction] = React.useState("");
  const [waiveOrIncreaseRent, setType] = React.useState(
    increasedRent > 0 ? 1 : 0
  ); // 0:waiveRent ; 1:increaseRent

  const setWaiveOrIncreaseRent = React.useCallback((value) => {
    if (value === 0) {
      handleReasonChange(
        { target: { name: "reason", value: "" } },
        index,
        shift,
        fleetGroup,
        date,
        _id
      );
      setIsOpen(false);
    } else {
      handleReasonChange(
        { target: { name: "reason", value: "OTHERS" } },
        index,
        shift,
        fleetGroup,
        date,
        _id
      );
      setCustomDeduction("");
    }
    setType(value);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (waivedRent) {
      setType(0);
    }
    if (increasedRent) {
      setType(1);
    }
  }, [waivedRent, increasedRent]);

  const errMsg =
    dialogStatus.err &&
    dialogStatus.err.taxi &&
    dialogStatus.err.taxi[shift] &&
    dialogStatus.err.taxi[shift][plateNumber];
  const handleDeductionChange = (e) => {
    setCustomDeduction(e.target.value);
    if (e.target.value === "") {
      handleReasonChange(
        { target: { name: "reason", value: "OTHERS" } },
        index,
        shift,
        fleetGroup,
        date,
        _id
      );
    } else {
      handleReasonChange(e, index, shift, fleetGroup, date, _id);
    }
  };
  React.useEffect(() => {
    if (reason) {
      if (
        waiveOrIncreaseRent === 1 ||
        ["維修", "打風", "暴雨", "大驗", "小驗", "租金優惠", "意外維修"].every(
          (elem) => elem !== reason
        )
      ) {
        if (reason !== "OTHERS") {
          setCustomDeduction(reason);
        }
        setIsOpen(true);
      } else {
        setCustomDeduction("");
        setIsOpen(false);
      }
    }
  }, [reason, waiveOrIncreaseRent]);

  return (
    <div
      style={{
        display: "flex",
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: "10px",
        border: "solid 1px",
        borderColor: "#f3f3f3",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "25%",
          minHeight: "150px",
          minWidth: "120px",
          background: errMsg
            ? "linear-gradient(to top left, #ff9933 0%, #ff6666 100%)"
            : shift === 0
            ? "#349696"
            : "#1683FF",
          position: "relative",
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            position: "absolute",
            backgroundImage: 'url("/assets/top-right.svg")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top -10px right -20px",
            margin: -15,
            height: "100%",
            width: "100%",
          }}
        />
        <div>
          <div
            style={{
              color: "rgba(255,255,255,0.6)",
              marginBottom: 5,
              fontSize: 16,
            }}
          >
            #{index + 1}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/assets/taxi.svg"}
              alt="taxi-icon"
              style={{ width: 24, height: 24 }}
            />
            <div
              style={{
                color: "white",
                fontSize: 20,
                fontWeight: "700",
                marginLeft: "10px",
                display: "flex",
                alignSelf: "center",
              }}
            >
              {plateNumber}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              color: "white",
              fontWeight: "700",
              display: "flex",
              alignSelf: "center",
              flexShrink: 0,
            }}
          >
            {shift === 0 ? "日更出勤" : "夜更出勤"}
          </div>
          <Switch
            classes={{
              switchBase: classes.switchBase,
              root: classes.root,
              track: classes.track,
              checked: classes.checked,
            }}
            name="isAttended"
            checked={isAttended || false}
            onChange={(e) => {
              handleChange(e, index, shift, fleetGroup, date, _id);
            }}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      </div>
      <div
        style={{
          fontSize: "14px",
          position: "relative",
          width: "75%",
          // minWidth: "430px",
          color: "#686868",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap:'wrap',
            padding: "15px",
          }}
        >
          <div>
            <div style={{ color: "red" }}>{errMsg}</div>
            <TaxiWaiveRentSwitch
              shift={shift}
              waiveOrIncreaseRent={waiveOrIncreaseRent}
              setWaiveOrIncreaseRent={setWaiveOrIncreaseRent}
            />
          </div>
          {driverList.initialized&&<DriverSelect {...{ driverList,fleetId, shift, index, driverId, date, _id }} />}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
            flexWrap:'wrap'
          }}
        >
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div
              style={{
                width:  "70px" ,
                paddingRight: "17px",
              }}
            >
              <NumberFormat
                allowNegative={false}
                onChange={(e)=>{
                  const value=e.target.value?parseFloat(e.target.value.substring(1)):0
                  handleChange(
                    {
                      target: {
                        name:  waiveOrIncreaseRent === 0?"waivedRent":"increasedRent",
                        value: value || 0,
                      },
                    },
                    index,
                    shift,
                    fleetGroup,
                    date,
                    _id
                  );
                }}        
                value={
                  (waiveOrIncreaseRent === 0 ? waivedRent : increasedRent) || ""
                }
                customInput={TextField}
                placeholder={"$0"}
                prefix={"$"}
                thousandSeparator={true}
              />
            </div>

            <FormControl variant="outlined" margin="dense">
              <Select
                name="reason"
                displayEmpty
                value={reason || ""}
                onChange={(e) => {
                  if (e.currentTarget.getAttribute("data-type") !== "others") {
                    setIsOpen(false);
                  }
                  handleReasonChange(e, index, shift, fleetGroup, date, _id);
                }}
              >
                <MenuItem value="" disabled>
                  原因
                </MenuItem>
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="維修">維修</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="打風">打風</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="暴雨">暴雨</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="大驗">大驗</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="小驗">小驗</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="租金優惠">租金優惠</MenuItem>
                )}
                {waiveOrIncreaseRent === 0 && (
                  <MenuItem value="意外維修">意外維修</MenuItem>
                )}
                <MenuItem
                  data-type="others"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  value={customDeduction || "OTHERS"}
                >
                  其他
                </MenuItem>
                <MenuItem value={null}>取消</MenuItem>
              </Select>
            </FormControl>
            {isOpen && (
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  alignItems: "center",
                }}
              >
                <TextField
                  placeholder="請填寫原因"
                  name="reason"
                  value={customDeduction}
                  onChange={handleDeductionChange}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "230px" ,
            }}
          >
            <RentalInfoBoxPure
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: 45,
              }}
              title={shift === 0 ? "早更租金" : "夜更租金"}
              amount={shift === 0 ? morningShiftRent : nightShiftRent}
            />
            <RentalInfoBoxPure
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: 45,
              }}
              title="應交租金"
              amount={payable}
            />
            <div style={{ width: "70px" }}>
              <div>實付租金</div>
              <NumberFormat
                allowNegative={false}
                onValueChange={(valuesObj) => {
                  handleChange(
                    {
                      target: {
                        name: "actualRent",
                        value: valuesObj.value,
                      },
                    },
                    index,
                    shift,
                    fleetGroup,
                    date,
                    _id
                  );
                }}
                value={actualRent || ""}
                customInput={TextField}
                placeholder={"$0"}
                prefix={"$"}
                thousandSeparator={true}
              />
            </div>
          </div>
        </div>
        {!isAttended ? <BlackOverlay /> : ""}
      </div>
    </div>
  );
};

// let styles = {
//     container: {
//       backgroundColor: 'red'
//     }
//   }

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  // getRoster: (fleet,fleetGroup, shift, from, to) => dispatch({ type: rosterActions.GET_ROSTER,fleet, fleetGroup, shift, from, to })
  handleChange: (e, index, shift, fleetGroup, date, _id) => {
    dispatch({
      type: persistRentalActions.ON_CHANGE_TAXI_CARD,
      field: e.target.name,
      value: e.target.name === "isAttended" ? e.target.checked : e.target.value,
      shift,
      index,
      fleetGroup,
      date,
      _id,
    });
  },
  handleReasonChange: (e, index, shift, fleetGroup, date, _id) => {
    dispatch({
      type: persistRentalActions.ON_CHANGE_WAIVED_RENT,
      field: e.target.name,
      value: e.target.value,
      shift,
      index,
      fleetGroup,
      date,
      _id,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TaxiCard);
