import React from "react";

import Box from "@material-ui/core/Box";
import PageContainer from "../../components/PageContainer";
import { withStyles } from "@material-ui/core";
import TabButton from "../../components/TabButton";
import AppBar from "../../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";
import KeywordSearch from "../../components/KeywordSearch";
import { dailyActions } from "../../redux/actions";
import { connect } from "react-redux";
import RosterReminder from "./Roster/RosterReminder";
import Maintenance from "../Maintenance";
import Accident from "../Accident";
import FilterBox from "../../components/FilterBox";

const TabContainer = withStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minHeight: "52px",
        marginBottom: 10,
    },
})(Box);


const pageContainer = (tabIndex) => {
    switch (tabIndex) {
        case 0:
            return <RosterReminder />
        case 1:
            return <Maintenance dailyMode />
        case 2:
            return <Accident dailyMode />
        default:
            return
    }
}


const component = (tabIndex) => {
    switch (tabIndex) {
        case 0:
            return "daily_roster"
        case 1:
            return "daily_maintenance"
        case 2:
            return "daily_accident"
        default:
            return ""
    }
}

const ManagementContainer = ({ tabIndex, setTabIndex }) => {

    return (
        <>
            <AppBar>
                <SpaceBetweenBox>
                    <RowAlignCenterBox style={{ minWidth: '50%' }}>
                        <KeywordSearch component={component(tabIndex)} style={{ flex: "1" }} />
                        {(tabIndex === 0 || tabIndex === 2) && <FilterBox type={component(tabIndex)} component={component(tabIndex)} />}
                    </RowAlignCenterBox>
                </SpaceBetweenBox>
            </AppBar>
            <PageContainer scroll="unset">
                <TabContainer>
                    {['更表', '維修', '意外'].map((item, index) => (
                        <TabButton
                            key={item}
                            select={tabIndex === index ? "select" : undefined}
                            onClick={() => setTabIndex(index)}
                        >
                            {item}
                        </TabButton>
                    ))}
                </TabContainer>

                {pageContainer(tabIndex)}

            </PageContainer>

        </>
    );
};
const mapStateToProps = (state) => ({
    role: state.user.role,
    tabIndex: state.daily.tabIndex || 0
})

const mapDispatchToProps = (dispatch) => ({
    setTabIndex: (index) => dispatch({ type: dailyActions.CHANGE_INDEX, index }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagementContainer)
