import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PrivateRoute({ children, permission, role, path }) {
  console.log("permission", permission, role, path);
  return (
    <Route
      path={path}
      render={() =>
        localStorage.getItem("access_token") ? (
          permission ? (
            permission.includes(role) ? (
              children
            ) : (
              <Redirect to={"/daily"} />
            )
          ) : (
            children
          )
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
}

const mapStateToProps = (state) => {
  if (!state.user.role) {
    return {
      role: localStorage.getItem("role"),
    };
  }
  return {
    role: state.user.role,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
