
export const fleetGroupActions = {
    GET_ALL_FLEET_GROUP: "GET_ALL_FLEET_GROUP",
    UPDATE_FLEET_GROUP: "UPDATE_FLEET_GROUP",
    ERROR_FETCHING_FLEET_GROUP: "ERROR_FETCHING_FLEET_GROUP",

    CREATE_FLEET_GROUP: "CREATE_FLEET_GROUP",
    ADD_NEW_FLEET_GROUP: "ADD_NEW_FLEET_GROUP",
    FAIL_CREATE_FLEET_GROUP: "FAIL_CREATE_FLEET_GROUP",

    RESET_FLEET_GROUP_STATUS: "RESET_FLEET_GROUP_STATUS",
}

