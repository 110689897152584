import { put, takeLatest, call } from "redux-saga/effects";
// import { getAllTaxi, createTaxi } from '../API';
import { getTaxies, getGeolocation } from "../API";
import { taxiActions } from "../actions";
import _ from "lodash";

function* watchGetAllTaxi() {
  yield takeLatest(taxiActions.GET_ALL_TAXI, fetchAllTaxi);
}
function* watchGetTaxiLocation() {
  yield takeLatest(taxiActions.GET_TAXI_LOCATION, fetchTaxiLocation);
}
function* watchGetDeletedTaxi() {
  yield takeLatest(taxiActions.GET_DELETED_TAXI, fetchDeletedTaxi);
}

export function* fetchAllTaxi() {
  try {
    const taxies = yield call(getTaxies);

    if (Array.isArray(taxies)) {
      const byTaxiOwnerId = _.groupBy(
        taxies,
        (taxi) => taxi.owner && taxi.owner._id
      );
      const byFleetGroup = _.groupBy(
        taxies,
        (taxi) => taxi.fleetGroup && taxi.fleetGroup._id
      );
      yield put({
        type: taxiActions.UPDATE_TAXI,
        list: taxies,
        byTaxiOwnerId,
        byFleetGroup,
      });
      yield put({ type: taxiActions.GET_TAXI_LOCATION });
    } else {
      throw new Error(JSON.stringify(taxies));
    }
  } catch (e) {
    console.log(e);
    yield put({ type: taxiActions.ERROR_FETCHING_TAXI });
  }
}

export function* fetchTaxiLocation() {
  try {
    const { locations } = yield call(getGeolocation);

    if (Array.isArray(locations) && locations.length > 0) {
      const locationObj = _.keyBy(locations, "LicenseNo1");

      yield put({ type: taxiActions.UPDATE_LOCATION, location: locationObj });
    } else {
      throw new Error(JSON.stringify(locations));
    }
  } catch (e) {
    console.log(e);
    yield put({ type: taxiActions.ERROR_FETCHING_LOCATION });
  }
}

export function* fetchDeletedTaxi() {
  try {
    const deletedTaxiList = yield call(() => getTaxies({ isDisabled: true }));
    // const deletedTaxiList = yield new Promise((resolve, reject) => {
    //   setTimeout(() => resolve([]), 2000);
    // });
    yield put({
      type: taxiActions.UPDATE_DELETED_TAXI,
      deletedTaxiList,
    });
  } catch (e) {
    console.log(e);
  }
}

export default [
  watchGetAllTaxi(),
  watchGetTaxiLocation(),
  watchGetDeletedTaxi(),
]