import { taxiActions } from "../actions";

const init = {
  status: "init",
  isReady: false,
  list: [],
  byTaxiOwnerId: {},
  byFleetGroup: {},
  location: {},
  isLocationReady: false,

  deletedTaxiList: [],
  isDeletedTaxiReady: false,
};

export default function taxiReducer(state = init, action) {
  switch (action.type) {
    case taxiActions.GET_ALL_TAXI:
      return {
        ...state,
        status: "loading",
        isReady: false,
      };
    case taxiActions.GET_TAXI_LOCATION:
      return {
        ...state,
        isLocationReady: false,
      };
    case taxiActions.UPDATE_TAXI:
      return {
        ...state,
        status: "ready",
        list: action.list,
        byTaxiOwnerId: action.byTaxiOwnerId,
        byFleetGroup: action.byFleetGroup,
        isReady: true,
      };

    case taxiActions.GET_DELETED_TAXI:
      return {
        ...state,
        deletedTaxiList: [],
        isDeletedTaxiReady: false,
      };
    case taxiActions.UPDATE_DELETED_TAXI:
      return {
        ...state,
        deletedTaxiList: action.deletedTaxiList,
        isDeletedTaxiReady: true,
      };
    case taxiActions.REMOVE_ENABLE_TAXI:
      return {
        ...state,
        deletedTaxiList: state.deletedTaxiList.filter(
          (item) => item._id !== action.id
        ),
        isDeletedTaxiReady: true,
      };
    case taxiActions.UPDATE_LOCATION:
      return {
        ...state,
        location: action.location,
        isLocationReady: true,
      };
    case taxiActions.ERROR_FETCHING_LOCATION:
      return {
        ...state,
        status: "error",
        isLocationReady: false,
      };
    case taxiActions.ERROR_FETCHING_TAXI:
      return {
        ...state,
        status: "error",
        isReady: false,
      };
    default:
      return state;
  }
}
