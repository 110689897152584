import React from 'react';
import { connect } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Box, makeStyles } from '@material-ui/core';
import StyledButton from '../Button';
import clsx from "clsx";
import { taxiActions } from '../../redux/actions';

const useStyles = makeStyles({
    static: {
        color: '#FFF',
        fontSize: 15,
        paddingBottom: 1,
    },
    rotate: {
        animation: `$myEffect 1500ms infinite`,
    },
    "@keyframes myEffect": {
        "0%": {
            transform: "rotate(0deg)",
            color: '#FFF',

        },
        "100%": {
            transform: "rotate(360deg)",
            color: '#9B9B9B',
        }
    },
})

const UpdateGPSBtn = ({ isLocationReady, updateTaxiLocation }) => {
    const classes = useStyles();

    return (
        <>
            <StyledButton
                onClick={updateTaxiLocation}
                minheight="15px" minwidth="40px"
                height="18px"
                padding="0 5px"
                background="purple"
                disabled={!isLocationReady}
                display="flex" alignItems="center">
                <Box fontSize="10px">{!isLocationReady ? "更新中" : "更新"}</Box>
                <RefreshIcon className={clsx(classes.static, {
                    [classes.rotate]: !isLocationReady,
                })} />
            </StyledButton>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLocationReady: state.taxi.isLocationReady
})

const mapDispatchToProps = (dispatch) => ({
    updateTaxiLocation: () => dispatch({ type: taxiActions.GET_TAXI_LOCATION })
})


export default connect(mapStateToProps, mapDispatchToProps)(UpdateGPSBtn);
