function isInclude(string, values) {
    return string.toString().toUpperCase().trim().includes(values.toUpperCase())
}

const keywordFilter = (originalTableData, values, tableType, filter) => {
    const isFilter = filter && Object.keys(filter).length > 0
    if (!values && !isFilter) {
        return originalTableData
    }

    if (originalTableData.length > 0 && tableType) {
        let tableData = originalTableData.slice();
        if (isFilter) {
            tableData = tableData.filter(data => {
                for (var key in filter) {
                    switch (key) {
                        case "fleet":
                            if (!(data.fleet && data.fleet._id && data.fleet._id === filter[key])) {
                                return false
                            }
                            break;
                        case "fleetGroup":
                            if (!(data.fleetGroup && data.fleetGroup._id && data.fleetGroup._id === filter[key])) {
                                return false
                            }
                            break;
                        case "dayoff":
                            if (!(data.driver && data.driver.dayoff && data.driver.dayoff.includes(Number(filter[key])))) {
                                return false
                            }
                            break;
                        case "shift":
                            if (!(data.driver && data.driver.shift && data.driver.shift === filter[key])) {
                                return false
                            }
                            break;
                        case "driver_status":
                            if (!(data.driver && data.driver.other && data.driver.other.status && data.driver.other.status === filter[key])) {
                                return false
                            }
                            break;
                        case "daily_roster_status":
                            if (!(data.type && data.type === filter[key])) {
                                return false
                            }
                            break;
                        case "maintenance_status":
                            if (!(data.status && data.status === filter[key])) {
                                return false
                            }
                            break;
                        case "gpsInstalled":
                            if (!(String(data.gpsInstalled) === filter[key])) {
                                return false
                            }
                            break;
                        default:
                            if (data[key] && data[key] !== filter[key]) {
                                return false
                            }
                    }
                }
                return true
            })
        }

        switch (tableType.split('-')[0]) {
            case "driver":
            case "replacement":
                return tableData.filter(data => {
                    if (data.name && isInclude(data.name, values)) {
                        return true
                    }
                    if (data.driver && data.driver.nickname && isInclude(data.driver.nickname, values)) {
                        return true
                    }
                    if (data.phone && isInclude(data.phone, values)) {
                        return true
                    }
                    if (data.fleet && data.fleet.name && isInclude(data.fleet.name, values)) {
                        return true
                    }
                    if (data.fleetGroup && data.fleetGroup.name && isInclude(data.fleetGroup.name, values)) {
                        return true
                    }
                    return false
                })
            case "owner_taxi":
            case "fleetGroup":
            case "taxi":
                return tableData.filter(data => {
                    if (data.plateNumber && isInclude(data.plateNumber, values)) {
                        return true
                    }
                    if (data.modelNumber && isInclude(data.modelNumber, values)) {
                        return true
                    }
                    if (data.yearOfMfr && isInclude(data.yearOfMfr, values)) {
                        return true
                    }
                    if (data.owner && data.owner.name && isInclude(data.owner.name, values)) {
                        return true
                    }
                    if (data.morningShiftDriver && data.morningShiftDriver.name && isInclude(data.morningShiftDriver.name, values)) {
                        return true
                    }
                    if (data.nightShiftDriver && data.nightShiftDriver.name && isInclude(data.nightShiftDriver.name, values)) {
                        return true
                    }
                    return false
                })
            case "taxi_owner":
                return tableData.filter(data => {
                    if (data.name && isInclude(data.name, values)) {
                        return true
                    }
                    if (data.phone && isInclude(data.phone, values)) {
                        return true
                    }
                    if (data.taxi_owner && data.taxi_owner.company && data.taxi_owner.company.name && isInclude(data.taxi_owner.company.name, values)) {
                        return true
                    }
                    return false
                })
            case "fleet":
            case "roster_list":
            case "rental_list":
                return tableData.filter(data => {
                    if (data.name && isInclude(data.name, values)) {
                        return true
                    }
                    if (data.address && isInclude(data.address, values)) {
                        return true
                    }
                    if (data.groupLeader && data.groupLeader.name && isInclude(data.groupLeader.name, values)) {
                        return true
                    }
                    if (data.operator && data.operator.name && isInclude(data.operator.name, values)) {
                        return true
                    }
                    return false
                })
            case "account":
                return tableData.filter(data => {
                    if (data.name && isInclude(data.name, values)) {
                        return true
                    }
                    if (data.username && isInclude(data.username, values)) {
                        return true
                    }
                    if (data.phone && isInclude(data.phone, values)) {
                        return true
                    }
                    if (data.fleet && data.fleet.name && isInclude(data.fleet.name, values)) {
                        return true
                    }
                    if (data.fleetGroup && data.fleetGroup.name && isInclude(data.fleetGroup.name, values)) {
                        return true
                    }
                    return false
                })
            case "roster":
                return tableData.filter(data => {
                    if (data.plateNumber && isInclude(data.plateNumber, values)) {
                        return true
                    }
                    if (data.nightShiftDriver && data.nightShiftDriver.name && isInclude(data.nightShiftDriver.name, values)) {
                        return true
                    }
                    if (data.morningShiftDriver && data.morningShiftDriver.name && isInclude(data.morningShiftDriver.name, values)) {
                        return true
                    }
                    return false
                })
            case "rental":
                return tableData.filter(data => {
                    if (data.taxi && data.taxi.plateNumber && isInclude(data.taxi.plateNumber, values)) {
                        return true
                    }
                    if (data.reason && isInclude(data.reason, values)) {
                        return true
                    }
                    return false
                })
            case "daily_accident":
            case "accident":
                return tableData.filter(data => {
                    if (data.title && isInclude(data.title, values)) {
                        return true
                    }
                    if (data.taxi && data.taxi.plateNumber && isInclude(data.taxi.plateNumber, values)) {
                        return true
                    }
                    if (data.driver && data.driver.name && data.driver.name && isInclude(data.driver.name, values)) {
                        return true
                    }
                    if (data.taxi && data.taxi.owner && data.taxi.owner.name && isInclude(data.taxi.owner.name, values)) {
                        return true
                    }
                    if (data.taxi && data.taxi.insCompany && isInclude(data.taxi.insCompany, values)) {
                        return true
                    }
                    return false
                })
            case "daily_maintenance":
            case "maintenance":
                return tableData.filter(data => {
                    if (data.plateNumber && isInclude(data.plateNumber, values)) {
                        return true
                    }
                    if (data.modelNumber && data.modelNumber && isInclude(data.modelNumber, values)) {
                        return true
                    }
                    if (data.morningShiftDriver && data.morningShiftDriver.name && isInclude(data.morningShiftDriver.name, values)) {
                        return true
                    }
                    if (data.nightShiftDriver && data.nightShiftDriver.name && isInclude(data.nightShiftDriver.name, values)) {
                        return true
                    }
                    if (data.maintenanceItems && isInclude(data.maintenanceItems.join(''), values)) {
                        return true
                    }
                    return false
                })
            case "daily_roster":
                return tableData.filter(data => {
                    if (data.fleet && data.fleet.name && isInclude(data.fleet.name, values)) {
                        return true
                    }
                    if (data.fleetGroup && data.fleetGroup.name && isInclude(data.fleetGroup.name, values)) {
                        return true
                    }
                    if (data.driver && data.driver.name && isInclude(data.driver.name, values)) {
                        return true
                    }
                    if (data.driver && data.driver.nickname && isInclude(data.driver.nickname, values)) {
                        return true
                    }
                    if (data.driver && data.driver.phone && isInclude(data.driver.phone, values)) {
                        return true
                    }
                    return false
                })
            default:
                return originalTableData

        }
    } else {
        return []
    }
}

const normalRentalKeywordFilter = (objectData, keyword) => {
    const plateNumberArr = Object.keys(objectData)
    if (plateNumberArr.length === 0 || keyword === "") {
        return objectData
    }
    const lowerCaseKeyword = keyword.toLowerCase();
    const result = plateNumberArr.reduce((acc, plateNumber) => {
        if (plateNumber.includes(keyword) ||
            (objectData[plateNumber].morningDriver && objectData[plateNumber].morningDriver.toLowerCase().includes(lowerCaseKeyword)) ||
            (objectData[plateNumber].nightDriver && objectData[plateNumber].nightDriver.toLowerCase().includes(lowerCaseKeyword))
        ) {
            return ({
                ...acc,
                [plateNumber]: objectData[plateNumber]
            })
        }
        return acc
    }, {})
    console.log(result)
    return result
}

export const UiService = {
    keywordFilter,
    normalRentalKeywordFilter
}