import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, Box } from '@material-ui/core';

const StyledBox = withStyles({
    root: {
        position: props => props.type === "floating" ? 'fixed' : 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex:1,
        '&:before':{
            position:'absolute',
            content:"''",
            backgroundColor: 'rgba(255,255,255,0.85)',
            width:'100%',
            height:'100%',
        }
    }
})(Box)

export default ({ type }) => <StyledBox {...{ type }}>
        <CircularProgress />
    </StyledBox>
