const { UtilsService } = require("./UtilsService");

const accidentFormat = (values) => {
    const {
        _id,
        fleetId,
        fleetGroupId,
        date,
        time,
        title,
        accident_driver,
        accident_taxi,
        injured,
        accident_status,
        remark,
        proofDoc
    } = values;

    const obj = {
        _id,
        fleet: fleetId,
        fleetGroup: fleetGroupId,
        taxi: accident_taxi,
        title,
        driver: accident_driver,
        occurrenceDateTime: UtilsService.dateTimeFormat(date, time),
        injured: injured,
        status: accident_status,
        remark: remark,
        proofDoc
    }

    return obj;
};

const maintenanceFormat = (values) => {
    const {
        throMaintenanceDate,
        briefCheckingDate,
        mileageItems,
        mileage,
        inspectionDoc
    } = values;

    const obj = {
        throMaintenanceDate: UtilsService.dateFormat(throMaintenanceDate),
        briefCheckingDate: UtilsService.dateFormat(briefCheckingDate),
        mileageItems,
        inspectionDoc,
        lastUpdateMileage: mileage
    }
    return obj;
};

export const AccidentAndMainService = {
    accidentFormat,
    maintenanceFormat
}