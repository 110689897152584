import React from "react";
import { connect } from "react-redux";
import AppBar from "../../components/AppBar";
import PageContainer from "../../components/PageContainer";
import { Redirect } from "react-router-dom";
import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";

import FleetTab from "../Fleet/FleetTab";
import Table from "../../components/Table";
import { pageUIActions } from "../../redux/actions";
import { getFleetRoster } from "../../redux/API";
const columns = [
  { type: "name", label: "車組名", minWidth: 100 },
  {
    type: "groupLeader",
    label: "車組管理員",
    minWidth: 60,
  },
  {
    type: "operator",
    label: "事務員",
    minWidth: 60,
  },
  {
    type: "address",
    label: "交更地址",
    minWidth: 60,
  },
  // {
  //   type: "todayTotalMissing",
  //   label: "當天缺更",
  //   minWidth: 60,
  // },
  // {
  //   type: "tmrMissing",
  //   label: "明天缺更",
  //   minWidth: 60,
  // },
  // {
  //   type: "datMissing",
  //   label: "後天缺更",
  //   minWidth: 60,
  // },

  {
    type: "todayMissing",
    label: "今早缺更",
    minWidth: 90,
  },
  {
    type: "tonightMissing",
    label: "今晚缺更",
    minWidth: 90,
  },
  {
    type: "tmrMorningMissing",
    label: "明早缺更",
    minWidth: 90,
  },
  {
    type: "tmrNightMissing",
    label: "明晚缺更",
    minWidth: 90,
  },
  {
    type: "datMorningMissing",
    label: "後早缺更",
    minWidth: 90,
  },
  {
    type: "datNightMissing",
    label: "後晚缺更",
    minWidth: 90,
  },
  {
    type: "taxiCount",
    label: "的士總數（除去停運）",
    minWidth: 100,
  },
];

const AllRoster = ({
  role,
  userGroup,
  fleet,
  updateOriginalTable,
  fleetIndex,
  origin,
}) => {
  React.useEffect(() => {
    if (fleet[fleetIndex] && fleet[fleetIndex]._id) {
      if (origin !== `roster_list-${fleet[fleetIndex]._id}`) {
        getFleetRoster(fleet[fleetIndex]._id)
          .then((res) =>
            updateOriginalTable(
              res || [],
              `roster_list-${fleet[fleetIndex]._id}`
            )
          )
          .catch((e) => {
            console.log("Error in getting fleet roster");
            console.log(e);
          });
      }
    }
  }, [fleetIndex, fleet, origin, updateOriginalTable]);

  if (role === "group_leader" || role === "operator") {
    return <Redirect to={`/roster/${userGroup}`} />;
  }
  console.log("fleet", fleet);
  return (
    <>
      <GetData fleet fleetGroup />
      <AppBar>
        <KeywordSearch component="roster" style={{ maxWidth: "600px" }} />
      </AppBar>

      <PageContainer>
        <FleetTab fleet={fleet || []} />
        <Table
          {...{ columns }}
          pathname="/roster/"
          selectBy="_id"
          component="roster"
          origin={
            fleet[fleetIndex] ? `roster_list-${fleet[fleetIndex]._id}` : ""
          }
        />
      </PageContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  userGroup: state.user.fleetGroup,

  fleet: state.fleet.list,
  origin: state.ui.origin,
  fleetIndex: state.ui.fleetIndex || 0,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllRoster);
