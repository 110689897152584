import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default () => {
  let history = useHistory();
  useEffect(() => {
    localStorage.getItem("access_token")
      ? history.push("/daily")
      : history.push("/login");
  });

  return <div></div>;
};
