import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Fleet from "./Fleet";
import Roster from "./Roster";
import AllRoster from "./Roster/AllRoster";
import FleetGroup from "./FleetGroup";
import Account from "./Account";
import CreateUpdate from "./CreateUpdate";
import Demo from "./Demo";
import InvalidPage from "./InvalidPage";
import Menu from "../components/Menu";
import { makeStyles } from "@material-ui/core";
import Rental from "./Rental";
import ClientListing from "./ClientListing";
import OwnerManagement from "./ClientListing/TaxiOwner/OwnerManagement";
import DriverManagement from "./ClientListing/Driver/DriverManagement";
import TaxiManagement from "./ClientListing/Taxi/TaxiManagement";
import AllRental from "./Rental/AllRental";
import Maintenance from "./Maintenance";
import Accident from "./Accident";
import MaintenanceDetails from "./Maintenance/MaintenanceDetails";
import AccidentDetails from "./Accident/AccidentDetails";
import Daily from "./Daily";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FAFAFA",
    display: "flex",
    overflowX: "hidden",
  },

  content: {
    width: "100%",
    flexGrow: 1,
    marginLeft: 0,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Menu />
      <div className={classes.content}>
        <Switch>
          {[
            {
              path: "/fleet",
              component: <Fleet />,
              permission: ["super_admin", "fleet_manager"],
            },
            { path: "/fleet/group/:id", component: <FleetGroup /> },
            { path: "/roster", component: <AllRoster /> },
            { path: "/roster/:groupId", component: <Roster /> },

            {
              path: "/driver/create",
              component: <DriverManagement group="driver" type="create" />,
            },
            {
              path: "/driver/:id",
              component: <DriverManagement group="driver" type="update" />,
            },
            {
              path: "/driver",
              component: <ClientListing group="driver" />,
            },

            {
              path: "/taxi-owner/create",
              component: <OwnerManagement group="taxi_owner" type="create" />,
            },
            {
              path: "/taxi-owner/:id",
              component: <OwnerManagement group="taxi_owner" type="update" />,
            },
            {
              path: "/taxi-owner",
              component: <ClientListing group="taxi_owner" />,
            },

            {
              path: "/taxi/create",
              component: <TaxiManagement group="taxi" type="create" />,
            },
            {
              path: "/taxi/:id",
              component: <TaxiManagement group="taxi" type="update" />,
            },
            { path: "/taxi", component: <ClientListing group="taxi" /> },

            { path: "/rental", component: <AllRental /> },
            { path: "/rental/:fleetGroup", component: <Rental /> },
            { path: "/maintenance", component: <Maintenance /> },
            { path: "/maintenance/:taxiId", component: <MaintenanceDetails /> },
            { path: "/accident", component: <Accident /> },
            {
              path: "/accident/create",
              component: <AccidentDetails type="create" />,
              permission: ["super_admin"],
            },
            {
              path: "/accident/:accidentId",
              component: <AccidentDetails type="update" />,
            },
            { path: "/daily", component: <Daily /> },
            {
              path: "/account",
              component: <Account />,
              permission: ["super_admin"],
            },
            {
              path: "/account/create",
              component: <CreateUpdate type="create" />,
              permission: ["super_admin"],
            },
            {
              path: "/account/:userId",
              component: <CreateUpdate type="update" />,
              permission: ["super_admin"],
            },
          ].map((item) => (
            <PrivateRoute key={item.path} exact {...item}>
              {item.component}
            </PrivateRoute>
          ))}
          <Route path="/demo" exact component={Demo} />
          <Route path="/login" exact component={Login} />
          <PrivateRoute
            path="/test"
            exact
            permission={["super_admin"]}
            component={() => (
              <Menu>
                <div>super_admin_only</div>
              </Menu>
            )}
          />
          <PrivateRoute path="/dashboard" exact component={<Dashboard />} />
          <Route path="*" component={InvalidPage} />
        </Switch>
      </div>
    </div>
  );
};

export default Layout;
