import React, { useMemo } from "react";
import { connect } from "react-redux";
import { LinkButton } from "../../components/Button";
import Table from "../../components/Table";
import PageContainer from "../../components/PageContainer";
import AppBar from "../../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";
import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";
import { checker } from "../../permission/checker";
import { pageUIActions } from "../../redux/actions";
import FilterBox from "../../components/FilterBox";
import LoadingIndicator from "../../components/LoadingIndicator";
import { groupName } from "../../match";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

export const columns = {
  taxi: [
    {
      type: "plateNumber",
      label: "車牌號碼",
      minWidth: 100,
      isShowGPS: true,
      isShowColor: true,
    },
    {
      type: "modelNumber",
      label: "車輛型號",
      minWidth: 120,
    },
    {
      type: "yearOfMfr",
      label: "出廠年份",
      minWidth: 80,
    },
    {
      type: "owner",
      label: "車主名稱",
      minWidth: 80,
    },
    {
      type: "morningShiftRent",
      label: "日更租金",
      minWidth: 80,
    },
    {
      type: "nightShiftRent",
      label: "夜更租金",
      minWidth: 80,
    },
    {
      type: "morningShiftDriver",
      label: "正日更司機",
      minWidth: 80,
    },
    {
      type: "nightShiftDriver",
      label: "正夜更司機",
      minWidth: 80,
    },
    {
      type: "gpsInstalled",
      label: "GPS安裝",
      minWidth: 80,
    },
    {
      type: "taxiStatus",
      label: "狀態",
      minWidth: 80,
    },
  ],
  driver: [
    { type: "name", label: "姓名", minWidth: 100 },
    {
      type: "driver.nickname",
      nested: "nickname",
      label: "別名",
      minWidth: 80,
    },
    {
      type: "fleet",
      label: "車隊",
      minWidth: 80,
    },
    {
      type: "fleetGroup",
      label: "車組",
      minWidth: 80,
    },
    {
      type: "phone",
      nested: "phone",
      label: "手機號碼（1)",
      minWidth: 100,
    },
    {
      type: "driver.shift",
      label: "更次",
      minWidth: 80,
    },
    {
      type: "driver.address",
      label: "交更地點",
      minWidth: 80,
    },
    {
      type: "driver.dayoff",
      label: "出勤日",
      minWidth: 80,
    },
    {
      type: "driver.status",
      label: "服務狀態",
      minWidth: 80,
    },
  ],
  taxi_owner: [
    { type: "name", label: "車主姓名", minWidth: 100 },
    {
      type: "phone",
      label: "聯絡電話",
      minWidth: 80,
    },
    {
      type: "taxi_owner.company_name",
      label: "公司",
      minWidth: 80,
    },
    {
      type: "redTaxiCount",
      label: "紅的",
      minWidth: 80,
    },
    {
      type: "greenTaxiCount",
      label: "綠的",
      minWidth: 80,
    },
    {
      type: "blueTaxiCount",
      label: "藍的",
      minWidth: 80,
    },
    {
      type: "taxiTotalCount",
      label: "的士總數",
      minWidth: 80,
    },
    {
      type: "gpsInstallCount",
      label: "GPS安裝",
      minWidth: 80,
    },
    {
      type: "taxi_owner.app", //waiting to implement
      label: "App",
      minWidth: 80,
    },
  ],
};

const redirectPath = {
  taxi: "/taxi/",
  driver: "/driver/",
  taxi_owner: "/taxi-owner/",
};

const Client = ({
  role,
  group,
  deletedPage,
  setDeletedPage,

  taxiList,
  isTaxiReady,
  deletedTaxiList,
  isDeletedTaxiReady,

  driverList,
  isDriverReady,
  deletedDriverList,
  isDeletedDriverReady,

  taxiOwnerList,
  isTaxiOwnerReady,
  deletedTaxiOwnerList,
  isDeletedTaxiOwnerReady,

  updateOriginalTable,
  updateTable,
  origin,
}) => {
  React.useEffect(
    () => {
      if (deletedPage) {
        if (group !== origin) {
          setDeletedPage(false);
        } else {
          if (group === "driver" && isDeletedDriverReady) {
            console.log("deletedDriverList");
            console.log(deletedDriverList);
            updateTable(deletedDriverList);
          }
          if (group === "taxi" && isDeletedTaxiReady) {
            updateTable(deletedTaxiList);
          }
          if (group === "taxi_owner" && isDeletedTaxiOwnerReady) {
            updateTable(deletedTaxiOwnerList);
          }
        }
      } else if (origin !== group) {
        if (group === "driver" && isDriverReady) {
          updateOriginalTable(driverList, group);
        }
        if (group === "taxi" && isTaxiReady) {
          updateOriginalTable(taxiList, group);
        }
        if (group === "taxi_owner" && isTaxiOwnerReady) {
          updateOriginalTable(taxiOwnerList, group);
        }
      } else {
        if (group === "driver" && isDriverReady) {
          updateTable(driverList);
        }
        if (group === "taxi" && isTaxiReady) {
          updateTable(taxiList);
        }
        if (group === "taxi_owner" && isTaxiOwnerReady) {
          updateTable(taxiOwnerList);
        }
      }
    },
    // eslint-disable-next-line
    [
      origin,
      group,
      // driverList,
      isDriverReady,
      // taxiList,
      isTaxiReady,
      // taxiOwnerList,
      isTaxiOwnerReady,
      // updateOriginalTable,
      // updateTable,
      deletedPage,
      isDeletedTaxiReady,
      isDeletedDriverReady,
      isDeletedTaxiOwnerReady,
    ]
  );

  const [createPath, createWording] = useMemo(() => {
    switch (group) {
      case "driver":
        return ["/driver/create", "新增司機"];
      case "taxi":
        return ["/taxi/create", "新增的士"];
      case "taxi_owner":
        return ["/taxi-owner/create", "新增車主"];
      default:
        return ["", ""];
    }
  }, [group]);

  return (
    <>
      <GetData
        fleet
        fleetGroup
        driver={group === "driver"}
        taxi={group === "taxi" || group === "taxi_owner"}
        taxiOwner={group === "taxi_owner"}
      />
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox style={{ minWidth: "50%" }}>
            {deletedPage && (
              <Box mr={4}>
                <Typography style={{ fontWeight: "700" }}>
                  {deletedPage && `已刪除${groupName[group]}帳戶`}
                </Typography>
              </Box>
            )}
            <KeywordSearch component={group} style={{ flex: 1 }} />
            <FilterBox type={group} component={group} />
          </RowAlignCenterBox>

          {!deletedPage && (
            <RowAlignCenterBox>
              {(group === "taxi" && checker(role, "taxi:create")) ||
              (group === "taxi_owner" && checker(role, "taxi_owner:create")) ||
              (group === "driver" && checker(role, "driver:create")) ? (
                <LinkButton background="purple" to={createPath}>
                  {createWording}
                </LinkButton>
              ) : (
                <></>
              )}
            </RowAlignCenterBox>
          )}
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        {deletedPage &&
        ((group === "driver" && !isDeletedDriverReady) ||
          (group === "taxi" && !isDeletedTaxiReady) ||
          (group === "taxi_owner" && !isDeletedTaxiOwnerReady)) ? (
          <LoadingIndicator />
        ) : (
          <Table
            // rows={screenTableData}
            columns={columns[group] || []}
            pathname={redirectPath[group]}
            selectBy="_id"
            component={group}
            origin={group}
          />
        )}
      </PageContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,

  driverList: state.driver.list,
  isDriverReady: state.driver.isReady,
  deletedDriverList: state.driver.deletedDriverList,
  isDeletedDriverReady: state.driver.isDeletedDriverReady,

  taxiList: state.taxi.list,
  isTaxiReady: state.taxi.isReady,
  deletedTaxiList: state.taxi.deletedTaxiList,
  isDeletedTaxiReady: state.taxi.isDeletedTaxiReady,

  taxiOwnerList: state.taxiOwner.list,
  isTaxiOwnerReady: state.taxiOwner.isReady,
  deletedTaxiOwnerList: state.taxiOwner.deletedTaxiOwnerList,
  isDeletedTaxiOwnerReady: state.taxiOwner.isDeletedTaxiOwnerReady,

  deletedPage: state.ui.deletedPage,
  rows: state.ui.rows || [],
  origin: state.ui.origin,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
  updateTable: (rows) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE_WITH_KEYWORD, rows }),
  setDeletedPage: (status) =>
    dispatch({
      type: pageUIActions.TOGGLE_DELETE_PAGE,
      status,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Client);
