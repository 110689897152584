import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Table from "../../../components/Table";
import { pageUIActions, rosterActions } from "../../../redux/actions";
import { OverlayDialog } from "./OverlayDialog";
import {
  confirmReplacement,
  resendReplacement,
  acceptDriverLeave,
  rejectDriverLeave,
  getReplacement,
  getLeave,
  getDriverWorkApplication,
  rejectDriverWorkApplication,
} from "../../../redux/API";
import LoadingIndicator from "../../../components/LoadingIndicator";
const columns = [
  {
    type: "daily_roster.name",
    nested: "name",
    label: "姓名",
    minWidth: 80,
  },
  {
    type: "daily_roster.nickname",
    nested: "nickname",
    label: "別名",
    minWidth: 80,
  },
  {
    type: "daily_roster.fleet",
    label: "車隊",
    minWidth: 80,
  },
  {
    type: "daily_roster.fleetGroup",
    label: "車組",
    minWidth: 80,
  },
  {
    type: "daily_roster.phone",
    nested: "phone",
    label: "手機號碼",
    minWidth: 80,
  },
  {
    type: "date",
    label: "日期",
    minWidth: 80,
  },
  {
    type: "daily_roster.shift",
    label: "司機更份",
    minWidth: 80,
  },
  {
    type: "daily_roster.status",
    label: "狀態",
    minWidth: 80,
  },
];

const Roster = ({ updateOriginalTable,updateRosterShift }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dialogStatus, setDialogStatus] = useState({ isOpen: false });
  const [refreshCounter, setRefreshCounter] = useState(0);

  const fetchAPI=async (type,id)=>{
    setDialogStatus({ isLoading: true, isOpen: true });

    let response;
    try{
      switch (type) {
        case "resend":
          response = await resendReplacement(id);
          break;
        case "confirm":
          response = await confirmReplacement(id);
          break;
        case "acceptLeave":
          response = await acceptDriverLeave(id);
          break;
        case "rejectLeave":
          response = await rejectDriverLeave(id);
          break;
        case "rejectWorkApplication":
          response = await rejectDriverWorkApplication(id);
          break;
        default:
      }

      if (response.id||response._id) {
        setDialogStatus({ isOpen: true, type, isSuccess: true });
      } else {
        console.log(response);
        throw new Error(response);
      }

    }catch(e){
        console.log(`Error in ${type}`);
        console.log(e);
        setDialogStatus({
          type,
          isOpen: true,
          isErrorOccur: true,
          errMsg: e?.response?.data?.result?.err,
        });
    }
  }
  
  const reConfirm = useCallback((id) => {
    fetchAPI("resend",id)
  }, []);

  const directConfirm = useCallback((id) => {
    fetchAPI("confirm",id)
  }, []);

  const acceptLeave = useCallback((id) => {
    fetchAPI("acceptLeave",id)
  }, []);

  const rejectLeave = useCallback((id) => {
    fetchAPI("rejectLeave",id)
  }, []);


  const rejectWorkApplication = useCallback((id) => {
    fetchAPI("rejectWorkApplication",id)
  }, []);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getLeave({ status: "PENDING" }),
      getReplacement({ status: "REJECTED" }),
      getReplacement({ status: "PENDING" }),
      getDriverWorkApplication({ status: "PENDING" }),
    ])
      .then(([leaveArr, rejectedArr, replacementArr,applyToWorkArr 
      ]) => {
        if (Array.isArray(leaveArr) && Array.isArray(replacementArr) && Array.isArray(rejectedArr)
          &&Array.isArray(applyToWorkArr) 
        ) {
          const replacement = replacementArr.map((item) => ({
            type: "replacement",
            ...item,
          }));
          const rejected = rejectedArr.map((item) => ({ type: "rejected", ...item }))
          const leave = leaveArr.map((item) => ({ type: "leave", ...item }));
          const applyToWorkList = applyToWorkArr.map(item => ({ type: "work", ...item })) 
          const combinedArr = [...replacement, ...rejected, ...leave, ...applyToWorkList]
          combinedArr.sort(function compare(a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA - dateB;
          });
          updateOriginalTable(combinedArr, `daily_roster`);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        updateOriginalTable([], `daily_roster`);
        console.log("Fail to get Leave or replacement");
        console.log(e);
        setIsLoading(false);
      });
  }, [updateOriginalTable, refreshCounter]);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: 100,
          margin: "auto",
          position: "relative",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }
  return (
    <>
      <Table
        {...{ columns }}
        component="daily_roster"
        origin={"daily_roster"}
        {...{ reConfirm, directConfirm, acceptLeave, rejectLeave, rejectWorkApplication, updateRosterShift }}
      />
      <OverlayDialog {...{ dialogStatus, setDialogStatus,setRefreshCounter }} />
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  updateRosterShift:(shift)=>dispatch({type:rosterActions.UPDATE_ROSTER_SHIFT,shift}),
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Roster);
