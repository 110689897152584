import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import FleetButton from '../../components/TabButton';
import { pageUIActions } from "../../redux/actions";
import { connect } from "react-redux";

const FleetTabContainer = withStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minHeight: '52px',
    }
})(Box)

const FleetTab = ({ fleet, fleetIndex, setFleetIndex }) => {
    return <FleetTabContainer>
        {
            fleet.map((item, index) => <FleetButton
                key={item.name}
                select={fleetIndex === index ? 'select' : undefined}
                onClick={() => setFleetIndex(index)}>{item.name}</FleetButton>)
        }
    </FleetTabContainer>
}

const mapStateToProps = (state) => ({
    fleetIndex: state.ui.fleetIndex || 0
});
const mapDispatchToProps = (dispatch) => ({
    setFleetIndex: (fleetIndex) => dispatch({ type: pageUIActions.UPDATE_CURRENT_FLEET, fleetIndex }),

});
export default connect(mapStateToProps, mapDispatchToProps)(FleetTab);


// export default FleetTab;

