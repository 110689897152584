import React from "react";
import { connect } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import { rosterActions } from "../../../redux/actions";
import LoadingIndicator from "../../../components/LoadingIndicator";
import StyledButton from "../../../components/Button";

const FullTimeDriverOverlay = ({
  isLoading,
  isOpen,
  fullTimeDriverName,
  date,
  err,

  handleCloseDialog,
  handleApplyLeave,
  handlePendingUnitRequest,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={undefined}
      PaperProps={{ style: { minWidth: "338px" } }}
    >
      {isLoading && <LoadingIndicator />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>{!err?"正更更份":"錯誤"}</DialogTitle>
        <IconButton style={{ margin: '0px 3px 9px 0px' }} onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
      </Box>
      {!err?<DialogContent>
        <Box>
          正更司機 {fullTimeDriverName /*driverObj && driverObj.name*/} 現需於
          {date} 上班。
        </Box>
        <Box mt={3} mb={1} display="flex" justifyContent="flex-end">
          <StyledButton background="purple" onClick={handlePendingUnitRequest}>
            取消更份
          </StyledButton>
          <Box ml={1}>
            <StyledButton background="purple" onClick={handleApplyLeave}>
              請假
            </StyledButton>
          </Box>
          <Box ml={1}>
            <StyledButton
              variant="outlined"
              background="white"
              onClick={handleCloseDialog}
            >
              關閉
            </StyledButton>
          </Box>
        </Box>
      </DialogContent>
      :
      <DialogContent>
           <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              <img
                src={`/assets/ic-fail.svg`}
                style={{ height: 70 }}
                alt="fail"
              />
            </Box>
            <Box>{err}</Box>
            <Box mt={3} mb={1} textAlign="right">
              <StyledButton background="purple" onClick={handleCloseDialog}>
                關閉
              </StyledButton>
            </Box>
          </>
    </DialogContent>
    }
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
    isLoading:state.roster.dialog.isLoading,
    isOpen:state.roster.dialog.isOpen,
    fullTimeDriverName:state.roster.dialog.fullTimeDriverName,
    date:state.roster.dialog.selectedDate,
    err:state.roster.dialog.err
});
const mapDispatchToProps = (dispatch) => ({
  handleCloseDialog:()=>dispatch({type:rosterActions.CLOSE_ROSTER_CELL_DIALOG}),
  handleApplyLeave:()=>dispatch({type:rosterActions.FETCH_APPLY_FOR_LEAVE}),
  handlePendingUnitRequest:()=>dispatch({type:rosterActions.FETCH_PENDING_UNIT_REQUEST}),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullTimeDriverOverlay);
