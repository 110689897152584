import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FolderIcon from '@material-ui/icons/Folder';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '../Divider';
import { DocumentService } from '../../Service/DocumentService';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const StyledFileIcon = withStyles({
    root: {
        height: '60px',
        width: '60px',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'grey',
    }
})(({ classes }) => <Box className={classes.root}>
    <FolderIcon htmlColor="#FFF" fontSize='large' />
</Box>)

export const UploadAndDownloadDoc = ({
    editable,
    type,
    id,
    isCreatePage,
    fileKey,
    fileContent, setFileContent }) => {

    const handleSelectFile = e => {
        setFileContent(e.target.files[0])
    }

    const handleRemoveFile = () => {
        document.getElementById(id).value = ""
        setFileContent("")
    }

    return <Grid item xs={12}>
        <Box display="flex" alignItems="center">
            <Box mx={2}><StyledFileIcon /></Box>

            {!isCreatePage &&
                <Box mx={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => DocumentService.handleDownload(fileKey)}
                        disabled={!fileKey}
                    >{"檢視" + type}
                    </Button>
                </Box>}

            {editable && <Box mx={2}>
                <input hidden id={id} type="file" onChange={handleSelectFile} />
                <label htmlFor={id}>
                    <Button variant="outlined" color="primary" component="span" >{!isCreatePage && fileKey && "再次"}{"上傳" + type} </Button>
                </label>
            </Box>}

            {fileContent &&
                <Box display="flex" alignItems="center">
                    <IconButton onClick={handleRemoveFile}>
                        <CancelIcon size="small" />
                    </IconButton>
                    {fileContent.name}
                </Box>}
        </Box>
        <Divider />

    </Grid>
}