export const userActions = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  GET_USER_ERROR: "GET_USER_ERROR",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_REGISTRATION_STATUS: "UPDATE_REGISTRATION_STATUS",
  AUTO_LOGIN: "AUTO_LOGIN",
  LOGOUT_USER: "LOGOUT_USER",
  CLEAR_DATA: "CLEAR_DATA",
};
