import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FolderIcon from '@material-ui/icons/Folder';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '../Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const StyledFileIcon = withStyles({
    root: {
        height: '60px',
        width: '60px',
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'grey',
    }
})(({ classes }) => <Box className={classes.root}>
    <FolderIcon htmlColor="#FFF" fontSize='large' />
</Box>)

export const MultiUploadAndDownloadDoc = ({
    editable,
    type,
    id,
    fileContent,
    setFileContent
}) => {

    const handleSelectFile = e => {
        if (e.target.files.length) {
            const originalFileContent = [...fileContent]
            const newFiles = []
            console.log(e.target.files)
            for (let key = 0; key < e.target.files.length; key++) {
                console.log(e.target.files[key])
                newFiles.push(e.target.files[key])
            }
            console.log(originalFileContent.concat(newFiles))
            setFileContent(originalFileContent.concat(newFiles))
        }
    }

    const clearInputValue = () => {
        document.getElementById(id).value = ""
    }

    const handleRemoveFile = (index) => {
        // document.getElementById(id).value = ""
        const originalFileContent = [...fileContent]
        originalFileContent.splice(index, 1)
        setFileContent(originalFileContent)
    }


    return <Grid item xs={12}>
        <Box display="flex" alignItems="flex-start">
            <Box display="flex" alignItems="center">
                <Box mx={2}><StyledFileIcon /></Box>

                {editable && <Box mx={2}>
                    <input hidden id={id} type="file" onClick={clearInputValue} onChange={handleSelectFile} multiple />
                    <label htmlFor={id}>
                        <Button variant="outlined" color="primary" component="span" >{"上傳" + type} </Button>
                    </label>
                </Box>}
            </Box>
            <Box>
                {fileContent && fileContent.length > 0 && fileContent.map((elem, index) =>
                    <Box key={index} display="flex" alignItems="center">
                        <IconButton onClick={() => handleRemoveFile(index)}>
                            <CancelIcon size="small" />
                        </IconButton>
                        <Box > {elem.name}</Box>
                    </Box>)}
            </Box>
        </Box>
        <Divider />

    </Grid>
}