import { withStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import { connect } from "react-redux";

const Menu = withStyles(props => ({
    root: {
        width: props => props.open ? 'calc(100% - 190px)' : '100%',
        borderBottom: '1px solid #d8d8d8',
        position: 'fixed',
        boxShadow: 'unset',
        minHeight: 64,
        padding: '0 24px',
        display: 'flex',
        justifyContent: 'center',
        transition: props.transitions.create("width", {
            easing: props.transitions.easing.sharp,
            duration: props.transitions.duration.leavingScreen,
        }),
    },
    colorPrimary: {
        backgroundColor: '#FFF',
        color: '#808080'
    }
}))(AppBar)


const MapStateToProps = (state) => ({
    open: state.ui.open
});

export default connect(MapStateToProps, () => ({}))(Menu);