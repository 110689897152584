import React, { useMemo } from "react";
import Modal from "../../../components/Modal";
import { Box, withStyles } from "@material-ui/core";
import StyledButton from "../../../components/Button";

const Wrapper = withStyles({
  root: {
    margin: "10px 0px",
  },
})(Box);

const BtnBox = withStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
    marginTop: "24px",
  },
})(Box);

export const OverlayDialog = ({ dialogStatus, setDialogStatus, setRefreshCounter }) => {
  const { type, isSuccess, isLoading } = dialogStatus;

  const closeDialog = () => {
    if (["confirm", "acceptLeave", "rejectLeave","rejectWorkApplication"].includes(type) && isSuccess) {
      setRefreshCounter((prev) => prev + 1);
    }
    setDialogStatus((prev) => ({ ...prev, isOpen: false }));
    setTimeout(() => setDialogStatus({ isOpen: false }), 200);
  };

  const [title, contents, btnText] = useMemo(() => {
    if (isLoading) {
      return ["請稍後", ""];
    }
    switch (type) {
      case "resend":
        return ["完成", "訊息已發送", "關閉"];
      case "confirm":
        return ["完成", "已直接確定替更", "關閉"];
      case "acceptLeave":
        return ["完成", "已批准司機請假", "關閉"];
      case "rejectLeave":
        return ["完成", "已拒絕司機請假", "關閉"];
      case "rejectWorkApplication":
        return ["完成", "已拒絕司機上班申請", "關閉"];
      default:
        return ["", "", "關閉"];
    }
  }, [isLoading, type]);

  return (
    <Modal disableBGClose={true} {...{ closeDialog, title, ...dialogStatus }}>
      <Wrapper>
        {isLoading
          ? "資料處理中，請稍後"
          : isSuccess && (
              <>
                <Box>{contents}</Box>
                <BtnBox>
                  <StyledButton background="purple" onClick={closeDialog}>
                    {btnText}
                  </StyledButton>
                </BtnBox>
              </>
            )}
      </Wrapper>
    </Modal>
  );
};
