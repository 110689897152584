import React from "react";
import { connect } from "react-redux";
import { persistRentalActions } from "../../../../redux/actions";
import { StyledTextBox } from "../../../../components/StyledTextBox";

import { Grid, Select, TextField } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import { RedClearButton } from "../../../../components/Button/RedClearButton";

const Type = ({ value, handleChangeInfo, customRemark,setIsOpen,isDepositDisable }) => {

  const handleChangeType = (e) => {
    if (e.target.value !== "OTHERS") {
      setIsOpen(false);
    }
    handleChangeInfo({
      value: e.target.value,
    });
  };

  return (
    <StyledTextBox label="款項">
      <Select
        displayEmpty
        fullWidth
        margin="dense"
        variant="outlined"
        value={value || ""}
        onChange={handleChangeType}
      >
        <MenuItem value={""}>{"請選擇"}</MenuItem>
        <MenuItem disabled={isDepositDisable} value={"DEPOSIT"}>按金{isDepositDisable&&`(已選取)`}</MenuItem>
        <MenuItem
          onClick={() => setIsOpen(true)}
          value={customRemark || "OTHERS"}
        >
          其他
        </MenuItem>
      </Select>
   
    </StyledTextBox>
  );
};

const Amount = ({ value, handleChangeAmount }) => {
  return (
    <StyledTextBox label="金額">
      <NumberFormat
        allowNegative={false}
        onValueChange={(valuesObj) => {
          handleChangeAmount({ value: valuesObj.floatValue || 0 });
        }}
        value={value || ""}
        customInput={TextField}
        placeholder={"$0"}
        prefix={"$"}
        thousandSeparator={true}
      />
    </StyledTextBox>
  );
};

const OthersDeduction = ({
  type,
  amount,

  index,
  shift,
  fleetGroup,
  date,
  driver,
  handleChangeDeductionType,
  handleChangeDeductionAmount,
  deleteOthersDeductionRow,
  isDepositSelected,
  errorMsg
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [customRemark, setCustomRemark] = React.useState("");

  const handleChangeInfo=React.useCallback(({ value }) =>
  handleChangeDeductionType({
    deductionType: value,
    indexInOthersList: index,
    shift,
    fleetGroup,
    date,
    driver
  }),[ 
    handleChangeDeductionType,
    index,
    shift,
    fleetGroup,
    date,
    driver])

  const handleRemarkChange = (e) => {
    setCustomRemark(e.target.value);
    if (e.target.value === "") {
      handleChangeInfo({ value: "OTHERS" });
    } else {
      handleChangeInfo({ value: e.target.value });
    }
  };

  React.useEffect(() => {
    if (type && type !== "DEPOSIT") {
      if (type !== "OTHERS") {
        setCustomRemark(type);
      }
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [type]);

  return (
    <Grid
      container
      spacing={2}
      style={{ marginTop: "8px", marginBottom: "8px" }}
    >
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <Type
          isDepositDisable={type==="DEPOSIT"?false:isDepositSelected}
          value={type}
          customRemark={customRemark}
          setIsOpen={setIsOpen}
          handleChangeInfo={handleChangeInfo}
        />
      </Grid>
      <Grid item xs={3}>
      {isOpen && (
        
          <TextField
            style={{paddingTop:20}}
            placeholder="請填寫原因"
            value={customRemark}
            onChange={handleRemarkChange}
          />
      
      )}</Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}>
        <Amount
          value={amount}
          handleChangeAmount={({ value }) =>
            handleChangeDeductionAmount({
              amount: value,
              indexInOthersList: index,
              shift,
              fleetGroup,
              date,
              driver,
            })
          }
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "14px",
        }}
      >
        <RedClearButton
          onClick={() =>
            deleteOthersDeductionRow({
              indexInOthersList: index,
              shift,
              fleetGroup,
              date,
              driver,
            })
          }
        />
      </Grid>
      {errorMsg&&<>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}><div style={{color:'red'}}>{errorMsg}</div></Grid>
      </>
}
      <Grid item xs={1}></Grid>
      <Grid item xs={11}>
        <div
          style={{
            backgroundColor: "#9b9b9b",
            height: 1,
            width: "calc( 100% - 8px)",
          }}
        />
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  deleteOthersDeductionRow: (payload) => {
    dispatch({
      type: persistRentalActions.DELETE_DEDUCTION_IN_OTHERS,
      ...payload,
    });
  },
  handleChangeDeductionAmount: (payload) => {
    dispatch({
      type: persistRentalActions.UPDATE_DEDUCTION_AMOUNT_IN_OTHERS,
      ...payload,
    });
  },
  handleChangeDeductionType: (payload) => {
    dispatch({
      type: persistRentalActions.UPDATE_DEDUCTION_TYPE_IN_OTHERS,
      ...payload,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(OthersDeduction);
