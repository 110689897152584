import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import { Box, makeStyles, withStyles, Grid } from "@material-ui/core";
import clsx from "clsx";
import StyledButton from "../../../components/Button";
import { persistRentalActions } from "../../../redux/actions";
import DriverSelect from "./TextField/Driver";

import { useParams } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../../../components/Modal";
import Debt from "./TextField/Debt";
import OthersDeduction from "./TextField/OthersDeduction";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {RedConfirmClearButton} from '../../../components/Button/RedConfirmClearButton';

const StyledNumberBall = withStyles((props) => ({
  root: {
    background: (props) =>
      props.error
        ? "linear-gradient(to top left, #ff9933 0%, #ff6666 100%)"
        : "#5650ef",
    color: "#FFF",
    marginRight: "10px",
    padding: 10,
    borderRadius: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 30,
    width: 30,
  },
}))(Box);

const ArrowButton = withStyles({
  root: {
    transform: (props) => (props.open ? "rotate(-180deg)" : "rotate(0deg)"),
    transition: "all 200ms",
  },
})(KeyboardArrowUpIcon);

const DriverDeductionRow = ({
  index,
  driver,
  driverRentArrear,
  others,

  shift,
  fleetId,
  fleetGroup,
  date,
  rentArrearsEntities,
  addDeductionOthersRow,
  handleDeleteRow,
  errorMsg,
  popUpMsg,

  morningDeductionDriver,
  nightDeductionDriver,
  isContainerLarge,
  driverList,
  uiType
}) => {
  const [isDriverDeductionOpen,setIsDriverDeductionOpen]=React.useState(true)
  const [isDriverDeductionFade,setIsDriverDeductionFade]=React.useState(true)
  const toggleDriverDeductionOpen=({open=false})=>{
    if(!isDriverDeductionOpen||open){
      setIsDriverDeductionOpen(true)
      setTimeout(()=>setIsDriverDeductionFade(true),100)
    }else{
      setIsDriverDeductionFade(false)
      setTimeout(()=>setIsDriverDeductionOpen(false),550)
    }
  }
  const othersString=JSON.stringify(others);
  const isDepositSelected=React.useMemo(()=>{
    if(othersString){
      return othersString.includes("DEPOSIT")
    }else{
      return false
    }
  },[othersString])

  React.useEffect(()=>{
      toggleDriverDeductionOpen({open:true})
      // eslint-disable-next-line
  },[driver])

  return (
    <Box my={3}>
      <Grid container spacing={2}>
        <Grid item xs={1} style={{ margin: "auto" }}>
          {" "}
          <StyledNumberBall error={errorMsg?true:false}>{index + 1}</StyledNumberBall>
        </Grid>
        <Grid item xs={5} sm={isContainerLarge?4:5}>
          {" "}
          {driverList.initialized&& <DriverSelect
            value={driver}
            {...{ index, shift, fleetId, fleetGroup, date, driverList,    
              morningDeductionDriver,
              nightDeductionDriver }}
          />}
          <Box mb={0.5}/>
        </Grid>
        <Grid item xs={4} sm={isContainerLarge?5:4}></Grid>
        <Grid item xs={2} style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
       {(uiType==="dialog"&&index===0&&!driver)?<Box />: <RedConfirmClearButton onClick={() => handleDeleteRow(index)}/>}
        
          <IconButton disabled={!driver} style={{padding:'8px 6px 8px 8px'}} onClick={toggleDriverDeductionOpen}>
          <ArrowButton open={isDriverDeductionFade}/>
          </IconButton>
        </Grid>
      </Grid>
      {errorMsg&&<div style={{color:'red'}}>{
      `${errorMsg.msg||""}`+
      `${errorMsg.driverRentArrearErr.length>0?"欠款出現問題。":""}`+
      `${errorMsg.othersErr.length>0?"按金/其他入帳出現問題。":""}`
      }</div>}
      {isDriverDeductionOpen&&
      <Box>
      {rentArrearsEntities &&
        driverRentArrear &&
        driverRentArrear.length > 0 &&
        driverRentArrear.map((item,index) => (
          <Debt
            isRemoved={popUpMsg[shift===0?"morning":"night"][item.rentArrears]===true}
            errorMsg={errorMsg?.driverRentArrearErr[index]}
            key={item.rentArrears}
            rentArrearsEntities={rentArrearsEntities}
            data={rentArrearsEntities[item.rentArrears] || {}}
            {...{
              item,
              index,
              shift,
              fleetGroup,
              date,
              driver,
              isContainerLarge
            }}
          />
        ))}
      {others &&
        others.length > 0 &&
        others.map((item, index) => (
          <OthersDeduction
            isDepositSelected={isDepositSelected}
            errorMsg={errorMsg?.othersErr[index]}
            key={index}
            {...{
              ...item,
              index,
              shift,
              fleetGroup,
              date,
              driver,
            }}
          />
        ))}
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          {driver && (
            <Box mt={1}>
            <StyledButton
              width={160}
              background="lightBlue"
              variant="outlined"
              onClick={() =>
                addDeductionOthersRow({ shift, fleetGroup, date, driver })
              }
            >
              新增按金/其他入帳
            </StyledButton>
            </Box>
          )}
        </Grid>
      </Grid>
      </Box>}
    </Box>
  );
};

const useStyles = makeStyles({
  button: {
    margin: "5px 0 2px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 3s",
  },
  horizontalCard: {
    justifyContent: "unset",
    alignItems: "unset",
    borderRadius: "10px",
  },
  titleCard: {
    width: "25%",
    padding: "15px",
    backgroundColor: (props) => (props.shift === 0 ? "#349696" : "#1683FF"),
    backgroundImage: 'url("/assets/top-right.svg")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top -10px right -20px",
  },
});

const IndividualDeduction = ({
  fleetId,
  shift,
  date,
  persistRental,
  driverList,

  addRowToList,
  deleteRowInList,
  setDialogSubmit,
  addDeductionOthersRow,
  errorMsg,
  closeRentArrearsPopUp,

  setDialogStatus
}) => {
  const { fleetGroup } = useParams();
  const {
    morningDeductionList,
    nightDeductionList,
    morningDeductionUi,
    nightDeductionUi,
    rentArrearsEntities,
    popUpMsg,
    isPopUpOpen
  } =
    persistRental[fleetGroup] && persistRental[fleetGroup][date]
      ? persistRental[fleetGroup][date]
      : {
          morningDeductionList: [],
          nightDeductionList: [],
          morningDeductionUi: "dialog",
          nightDeductionUi: "dialog",
        };
  const morningDeductionDriver = morningDeductionList.map(item=>item.driver);
  const nightDeductionDriver= nightDeductionList.map(item=>item.driver);

  const classes = useStyles({ shift });
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const deductionList = {
    0: morningDeductionList,
    1: nightDeductionList,
  };
  const uiType = {
    0: morningDeductionUi,
    1: nightDeductionUi,
  };

  const handleAddRow = () => {
    addRowToList({ shift, fleetGroup, date });
  };

  const handleDeleteRow = (index, transactionId) => {
    setDialogStatus((prev) => ({
      ...prev,
      err: {
        ...prev?.err,
        driver: {
          0: shift === 0 ? null : prev?.err?.driver[0],
          1: shift === 0 ? prev?.err?.driver[1] : null,
        },
      },
    }));
    deleteRowInList({ index, shift, fleetGroup, date, transactionId });
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleDialogSubmit = () => {
    setDialogSubmit({ shift, fleetGroup, date });
    setOpen(false);
  };

  const handleCloseRentArrearsPopUp=()=>{
    closeRentArrearsPopUp({fleetGroup, date})
  }
  const [isContainerLarge,setIsContainerLarge]=React.useState(true);
  const deductionBoxWidthRef=React.useRef(null);

  React.useEffect(()=>{
    const boxWidthObserver=new ResizeObserver((entries)=>{
      if(isContainerLarge&&entries[0].contentRect.width<550){
        setIsContainerLarge(false)
      }
      if(!isContainerLarge&&entries[0].contentRect.width>550){
        setIsContainerLarge(true)
      }}
      )
    boxWidthObserver.observe(deductionBoxWidthRef.current);
    return ()=>boxWidthObserver.disconnect();
  },[isContainerLarge])

  return (
    <Card
      className={clsx(classes.button, {
        [classes.horizontalCard]: uiType[shift] === "table",
      })}
    >
      {
        uiType[shift] === "table" && (
          <Box shift={shift} className={classes.titleCard}>
            <Box style={{ color: "white", fontSize: 20 }}>個別司機租金入帳</Box>
          </Box>
        )
      }

      <Box
        ref={deductionBoxWidthRef}
        display="flex"
        flex="1"
        flexDirection="column"
        minHeight="100px"
        justifyContent="center"
        mx={1}
      >
        {uiType[shift] === "table" &&
          deductionList[shift].map(
            ({ driver, driverRentArrear, others }, index) => (
              <DriverDeductionRow
                key={driver}
                errorMsg={errorMsg[index]}
                {...{
                  driver,
                  driverRentArrear,
                  others,
                  index,
                  shift,
                  fleetId,
                  fleetGroup,
                  date,
                  rentArrearsEntities,
                  addDeductionOthersRow,
                  handleDeleteRow,
                  morningDeductionDriver,
                  nightDeductionDriver,
                  popUpMsg,
                  isContainerLarge,
                  driverList
                }}
              />
            )
          )}

        {
          uiType[shift] === "table" ? (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ margin: "auto" }}>
                  {" "}
                  <StyledNumberBall>
                    {deductionList[shift].length + 1}
                  </StyledNumberBall>{" "}
                </Grid>
                <Grid item xs={11}>
                  {" "}
                  <StyledButton
                    style={{ padding: "0 30px" }}
                    background="purple"
                    onClick={handleAddRow}
                  >
                    新增個別司機租金入賬
                  </StyledButton>{" "}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <StyledButton
                style={{ padding: "0 30px" }}
                background="purple"
                onClick={handleOpenDialog}
              >
                新增個別司機租金入賬
              </StyledButton>
            </Box>
          )
        }
      </Box>
      {isPopUpOpen&& popUpMsg && (
        <Modal
          title="欠租已清還"
          closeDialog={handleCloseRentArrearsPopUp}
          isOpen={isPopUpOpen}
        >
        <Box>{(Object.keys(popUpMsg.morning).length>0&&
          Object.keys(popUpMsg.night).length>0)?
          "早更及夜更"
          :Object.keys(popUpMsg.morning).length>0?
          "早更"
          :"夜更"}部份欠款可能已在其他日子還清，請先刪除紀錄</Box>
          <Box mt={2.5} mb={1} display="flex" justifyContent="flex-end" ml={1}>
            <StyledButton
              background="purple"
              onClick={handleCloseRentArrearsPopUp}
            >
              關閉
            </StyledButton>
          </Box>
        </Modal>
      )}

      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ style: { minWidth: "50vw", maxWidth: "800px" } }}
        style={{ maxHeight: "90vh" }}
      >
        <Box px={1.5} pt={0.5} pb={2}>
          <h2>個別司機租金入帳</h2>
          {deductionList &&
            deductionList[
              shift
            ].map(({ driver, driverRentArrear, others }, index) => (
              <DriverDeductionRow
                key={index}
                errorMsg={errorMsg[index]}
                isContainerLarge={true}
                uiType="dialog"
                {...{
                  driver,
                  driverRentArrear,
                  others,
                  index,
                  shift,
                  fleetId,
                  fleetGroup,
                  date,
                  rentArrearsEntities,
                  addDeductionOthersRow,
                  handleDeleteRow,
                  morningDeductionDriver,
                  nightDeductionDriver,
                  popUpMsg,
                  driverList
                }}
              />
            ))}
   
            <Grid container spacing={2}>
              <Grid item xs={1}>
                {" "}
                <StyledNumberBall>
                  {deductionList[shift].length + 1}
                </StyledNumberBall>{" "}
              </Grid>
              <Grid item xs={11}>
                {" "}
                <StyledButton
                  style={{ padding: "0 30px" }}
                  background="purple"
                  onClick={handleAddRow}
                >
                  新增個別租金入帳
                </StyledButton>{" "}
              </Grid>
            </Grid>
      
          <Box justifyContent="flex-end" display="flex">
            <StyledButton
              variant="outlined"
              background="white"
              onClick={() => setOpen(false)}
            >
              取消
            </StyledButton>
            <Box ml={2}>
              <StyledButton background="purple" onClick={handleDialogSubmit}>
                確認
              </StyledButton>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  persistRental: state.persistRental,
});
const mapDispatchToProps = (dispatch) => ({
  addRowToList: (payload) =>
    dispatch({ type: persistRentalActions.ADD_DEDUCTION_ROW, ...payload }),
  deleteRowInList: (payload) =>
    dispatch({ type: persistRentalActions.DELETE_DEDUCTION_ROW, ...payload }),
  setDialogSubmit: (payload) =>
    dispatch({
      type: persistRentalActions.SUBMIT_DEDUCTION_DIALOG,
      ...payload,
    }),
  addDeductionOthersRow: (payload) =>
    dispatch({
      type: persistRentalActions.ADD_DEDUCTION_IN_OTHERS,
      ...payload,
    }),
  closeRentArrearsPopUp:(payload) =>
  dispatch({
    type: persistRentalActions.CLOSE_RENT_ARREARS_POP_UP,
    ...payload,
  }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualDeduction);
