import {withStyles}from '@material-ui/styles';
import Card from '@material-ui/core/Card';

export const StyledCard = withStyles({
    root: {
        borderRadius:8,
        padding:'10px 20px',
        flex: 1,
        overflow: 'unset'
    }
})(Card)