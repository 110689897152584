import { useState, useEffect } from "react";
import { getTaxi, getUserData, getAccident, getMaintenance } from "../../redux/API";

export function ManagementFetchData({ group, id, refreshCounter }) {

    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                let response;
                switch (group) {
                    case "taxi_owner":
                        response = await getUserData(id)
                        break;
                    case "driver":
                        response = await getUserData(id)
                        break;
                    case "taxi":
                        response = await getTaxi(id);
                        break;
                    case "accident":
                        response = await getAccident(id)
                        break;
                    case "maintenance":
                        response = await getMaintenance(id)
                        break;
                    default:
                }

                if (response._id) {
                    setIsLoading(false);
                    setUserData(response);
                } else {
                    console.log(response)
                    setIsError(response && response.result && response.result.err);
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false);
                setIsError(error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.result ?
                    error.response.data.result.err :
                    "Error");
            }
            setIsLoading(false);
        }
        fetchData();
    }, [group, id, refreshCounter]);

    return ({
        userData,
        isLoading,
        isError
    });
}

