import { unstable_createMuiStrictModeTheme as createMuiTheme, } from '@material-ui/core';
export const theme = createMuiTheme({
    palette: {
        primary: { main: '#5650ef' }, //purple
        secondary: { main: '#9b9b9b' },//grey
        warning: { main: '#ff7311' }, //orange
        info: { main: '#2388ff' } //blue
    },
    typography: {
        h4: {
            color: '#393939',
            fontSize: 18,
            fontWeight: 'bold'
        },
        h5: {
            color: '#000000', //page heading 車組
            fontSize: 17,
            fontFamily: 'PingFangTC-Medium'
        },
        body2: {
            color: '#9b9b9b',
            fontSize: 14,
            fontFamily: 'PingFangTC-Regular'
        },
        subtitle1: {

        },
        subtitle2: {

        }
    }
});