import React from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal/index';
import { rosterActions } from '../../../redux/actions';
import { useParams } from 'react-router';
import moment from 'moment';

const FreezeOverlay = ({
    resetFreezeDialog,
    isOpen, setIsOpen, //from parent element - for control the modal overlay
    freezeRoster, //dispatch for redux store
    isLoading, isErrorOccur, isSuccess, errMsg //from redux store and pass to Modal - for error handling
}) => {

    const today = moment().format('YYYY-MM-DD')
    const { groupId } = useParams();
    const closeDialog = () => {
        setIsOpen(false)
        setTimeout(() => resetFreezeDialog(), 500)

    }

    const handleSubmit = () => {
        freezeRoster({ fleetGroup: groupId })
    }

    return <Modal title="凍結更表" {...{ closeDialog, isOpen, isSuccess, isLoading, isErrorOccur, errMsg }} >

        {isSuccess
            ?
            <Box>更表經已凍結</Box>
            :
            <Box>是否凍結 {today} <span style={{ color: 'red' }}>日更及夜更</span> 更表</Box>}


        <Box mt={2.5} mb={1} display="flex" justifyContent="flex-end">
            {isSuccess
                ?
                <Button onClick={closeDialog}>關閉</Button>
                :
                <>
                    <Button onClick={closeDialog}>取消</Button>
                    <Box ml={2}><Button onClick={handleSubmit}>確定</Button></Box>
                </>
            }
        </Box>

    </Modal >
}

const mapStateToProps = (state) => ({
    isLoading: state.roster.isFreezeLoading,
    isSuccess: state.roster.isFreezeSuccess,
    isErrorOccur: state.roster.isFreezeErrorOccur,
    errMsg: state.roster.errMsg
})
const mapDispatchToProps = (dispatch) => ({
    resetFreezeDialog: () => dispatch({ type: rosterActions.RESET_FREEZE_ROSTER }),
    freezeRoster: (payload) => dispatch({ type: rosterActions.FETCH_FREEZE_ROSTER, payload }),
})
export default connect(mapStateToProps, mapDispatchToProps)(FreezeOverlay);

