import { userActions } from '../actions';

export default function userReducer(state={status:'init'}, action) {
  switch (action.type) {
    case userActions.LOGIN_USER:
      return {
        ...state,
        status: 'loading'
      }
    case userActions.LOGIN_SUCCESS:
      return {
        ...state,
        status: 'loggedIn',
        ...action.user,
      }
    case userActions.LOGIN_FAIL:
      return {
        status: 'fail'
      }
    case userActions.GET_USER_ERROR:
      return {
        status: 'error',
        error: action.error
      }
    case userActions.UPDATE_REGISTRATION_STATUS:
      return {
        ...state,
        status: 'loggedIn',
        isResetPasswordNeeded:false
      }
    default:
      return state
  }
}
