export const taxiOwnerActions = {
  UPDATE_DELETED_TAXI_OWNER: "UPDATE_DELETED_TAXI_OWNER",
  GET_DELETED_TAXI_OWNER: "GET_DELETED_TAXI_OWNER",

  UPDATE_ALL_TAXI_OWNER: "UPDATE_ALL_TAXI_OWNER",
  GET_ALL_TAXI_OWNER: "GET_ALL_TAXI_OWNER",
  FAIL_GET_ALL_TAXI_OWNER: "FAIL_GET_ALL_TAXI_OWNER",

  REMOVE_ENABLE_TAXI_OWNER: "REMOVE_ENABLE_TAXI_OWNER",
};
