import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, TextField, withStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import StyledButton from "../../../components/Button";
import { persistRentalActions } from "../../../redux/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import TaxiWaiveRentSwitch from "../../../components/Switch/TaxiWaiveRentSwitch";
import Text from "../../../components/Text";

const StyledSelect = withStyles({
  root: {
    color: "#9b9b9b",
  },
})(Select);

const BatchWaiveRentBtn = ({ shift, date, fleetGroup, submit }) => {
  const [edit, setEdit] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [customDeduction, setCustomDeduction] = React.useState("");
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [waiveOrIncreaseRent, setType] = React.useState(0); // 0:waiveRent ; 1:increaseRent

  const isSubmitDisabled = !amount || !reason || (isOpen && !customDeduction);
  const handleDeductionChange = (e) => {
    setCustomDeduction(e.target.value);
    if (e.target.value === "") {
      setReason("OTHERS");
    } else {
      setReason(e.target.value);
    }
  };
  const resetAll = (closePopUp = false) => {
    closePopUp && setEdit(false);
    closePopUp && setWaiveOrIncreaseRent(0);
    setIsOpen(false);
    setCustomDeduction("");
    setReason("");
    setAmount(0);
  };

  const setWaiveOrIncreaseRent = React.useCallback((value) => {
    if (value === 0) {
      setReason("");
      setIsOpen(false);
    } else {
      setReason("OTHERS");
      setCustomDeduction("");
      setIsOpen(true);
    }
    setType(value);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    submit({ shift, date, fleetGroup, reason, amount, waiveOrIncreaseRent });
    resetAll(true);
  };
  useEffect(
    () => resetAll(true),
    // eslint-disable-next-line
    [shift]
  );

  return (
    <>
      <StyledButton
        style={{ marginRight: 5 }}
        background="purple"
        height="30px"
        minheight="30px"
        minwidth="120px"
        onClick={() => setEdit(true)}
      >
        一鍵扣/加租
      </StyledButton>

      <Dialog
        open={edit}
        onClose={resetAll}
        PaperProps={{ style: { minWidth: "338px" } }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle>
            <Box style={{ fontWeight: "bold" }}>
              一鍵扣/加租 - {shift === 0 ? "早更" : "夜更"}
            </Box>
          </DialogTitle>
          <IconButton style={{ margin: "0 3px 3px 0" }} onClick={resetAll}>
            <ClearIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            <Box mb={2}>
              <Text type="itemTitle">輸入價錢</Text>
            </Box>
            <div style={{ display: "flex" }}>
              <TaxiWaiveRentSwitch
                shift={shift}
                waiveOrIncreaseRent={waiveOrIncreaseRent}
                setWaiveOrIncreaseRent={setWaiveOrIncreaseRent}
              />
              <NumberFormat
                style={{ width: "80px", margin: "0px 0px 0px 20px" }}
                allowNegative={false}
                onValueChange={(valuesObj) => {
                  setAmount(valuesObj.floatValue);
                }}
                value={amount || ""}
                customInput={TextField}
                placeholder={"$0"}
                prefix={"$"}
                thousandSeparator={true}
              />
            </div>
            <Box mt={2} mb={1}>
              <Text type="itemTitle">選擇原因</Text>
            </Box>
            <div style={{ display: "flex" }}>
              <FormControl
                variant="outlined"
                margin="dense"
                style={{ width: "142px" }}
              >
                <StyledSelect
                  name="reason"
                  displayEmpty
                  value={reason || ""}
                  onChange={(e) => {
                    if (
                      e.currentTarget.getAttribute("data-type") !== "others"
                    ) {
                      setIsOpen(false);
                    }
                    setReason(e.target.value);
                  }}
                >
                  <MenuItem value="" disabled>
                    原因
                  </MenuItem>
                  {waiveOrIncreaseRent === 0 && (
                    <MenuItem value="維修">維修</MenuItem>
                  )}
                  {waiveOrIncreaseRent === 0 && (
                    <MenuItem value="打風">打風</MenuItem>
                  )}
                  {waiveOrIncreaseRent === 0 && (
                    <MenuItem value="暴雨">暴雨</MenuItem>
                  )}
                  <MenuItem
                    data-type="others"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    value={customDeduction || "OTHERS"}
                  >
                    其他
                  </MenuItem>
                  <MenuItem value={null}>取消</MenuItem>
                </StyledSelect>
              </FormControl>

              {isOpen && (
                <div
                  style={{
                    width: "80px",
                    display: "flex",
                    marginLeft: "20px",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    placeholder="原因"
                    name="reason"
                    value={customDeduction}
                    onChange={handleDeductionChange}
                  />
                </div>
              )}
            </div>

            <Box
              mt={2}
              mb={1}
              width="100%"
              display="flex"
              justifyContent="flex-end"
            >
              <Box ml={1}>
                <StyledButton
                  width="70px"
                  height="35px"
                  onClick={resetAll}
                  variant="outlined"
                  background="white"
                >
                  取消
                </StyledButton>
              </Box>
              <Box ml={2}>
                <StyledButton
                  width="70px"
                  height="35px"
                  disabled={isSubmitDisabled}
                  onClick={handleSubmit}
                  background={isSubmitDisabled ? "lightGrey" : "purple"}
                >
                  確定
                </StyledButton>
              </Box>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  submit: ({ shift, date, fleetGroup, reason, amount, waiveOrIncreaseRent }) =>
    dispatch({
      type: persistRentalActions.BATCH_ON_CHANGE_WAIVED_OR_INCREASED_RENT,
      shift,
      date,
      fleetGroup,
      reason,
      amount,
      waiveOrIncreaseRent,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(BatchWaiveRentBtn);
