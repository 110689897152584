import React from "react";
import { connect } from "react-redux";

import { persistRentalActions } from "../../../../redux/actions";
import { StyledTextBox } from "../../../../components/StyledTextBox";
import {
  Grid,
  TextField,
  Box,
  withStyles,
  IconButton,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import moment from "moment";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import StyledButton from "../../../../components/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {RedClearButton} from "../../../../components/Button/RedClearButton";

const ADJUSTMENT = {
  WAIVE_RENT: 0,
  INCREASE_RENT: 1,
};

const ArrowButton = withStyles({
  root: {
    transform: (props) => (props.open ? "rotate(-180deg)" : "rotate(0deg)"),
    transition: "all 200ms",
  },
})(KeyboardArrowUpIcon);

const WaiveOrIncreaseRentBtn = ({ isSelected, onClick, label,...rest }) => {
  return (
    <StyledButton
      height="28px"
      width="60px"
      variant={isSelected ? "contained" : "outlined"}
      background={isSelected ? "purple" : "white"}
      onClick={onClick}
      {...rest}
    >
      {label}
    </StyledButton>
  );
};

const Debt = ({
  item,
  data,
  shift,
  fleetGroup,
  date,
  driver,
  updateWaiveOrIncreaseRent,
  updateRentAdjustAmount,
  updateDebtPaid,
  updateRentAdjustmentReason,
  deleteRemovedRentArrears,
  isRemoved,
  errorMsg,
  isContainerLarge
}) => {
  const [isDebtDetailOpen, setIsDebtDetailOpen] = React.useState(false);
  const [isDebtDetailFullHeight, setIsDebtDetailFullHeight] = React.useState(
    false
  );
  const [isAdjustInfoShow, setIsAdjustInfoShow] = React.useState(false);
  const [customDeduction, setCustomDeduction] = React.useState("");

  React.useEffect(() => {
    setIsAdjustInfoShow(false);
    setCustomDeduction("");
  }, [item.waiveOrIncreaseRent]);

  React.useEffect(() => {
    if (item.reason === "OTHERS") {
      setIsAdjustInfoShow(true);
    }else if(
      item.reason &&(
        item.waiveOrIncreaseRent===1
        ||
      ![
        "維修",
        "打風",
        "暴雨",
        "大驗",
        "小驗",
        "租金優惠",
        "意外維修",
      ].includes(item.reason))
    ) {
      setIsAdjustInfoShow(true);
      setCustomDeduction(item.reason);
    }
    // eslint-disable-next-line
  }, [item.reason]);

  React.useEffect(()=>{
    if(
      item.increasedRent|| 
      item.waivedRent||
      item.waiveOrIncreaseRent||
      item.reason
    ){
      setIsDebtDetailOpen(true)
      setIsDebtDetailFullHeight(true)
    }
  },
      // eslint-disable-next-line
  [])

  const toggleDebtDetail = () => {
    if (isDebtDetailOpen) {
      setIsDebtDetailFullHeight(false);
      setTimeout(() => setIsDebtDetailOpen(false), 500);
    } else {
      setIsDebtDetailOpen(true);
      setTimeout(() => setIsDebtDetailFullHeight(true), 10);
    }
  };

  const onChangeRentAdjustment = ({ waiveOrIncreaseRent }) => {
    updateWaiveOrIncreaseRent({
      rentArrearId: item.rentArrears,
      shift,
      fleetGroup,
      date,
      waiveOrIncreaseRent,
      driver,
    });
  };

  const handleChangeCustomReason = (e) => {
    setCustomDeduction(e.target.value);
    updateRentAdjustmentReason({
      reason: e.target.value || "OTHERS",
      rentArrearId: item.rentArrears,
      shift,
      fleetGroup,
      date,
      driver,
    });
  };

  const handleRemovedRentArrears=()=>{
    deleteRemovedRentArrears({
      rentArrearId: item.rentArrears,
      shift,
      fleetGroup,
      date,
      driver,
    })
  }

  return (
    <>
    <Grid container style={{margin:'8px -8px'}} spacing={2} >
      <Grid item xs={1}></Grid>
      <Grid item xs={isContainerLarge?8:6}>
        <StyledTextBox label="款項">
          <Box fontWeight="bold" paddingTop="8px" display="flex">
            <Box minWidth="40px">${data.amount||0}</Box>
            <Box pr={0.5} style={{whiteSpace:'nowrap'}}>欠款</Box>
            <Box>  
              {item.plateNumber&&` ${item.plateNumber}`}
              {item.shift === "morning" && "(早更)"}{ item.shift === "night" &&"(夜更)"}
              {item.date&&` ${moment(item.date).format("DD/MM/YYYY")}`}
            </Box>
          </Box>
        </StyledTextBox>
      </Grid>
      <Grid item xs={isContainerLarge?2:4}>
        <StyledTextBox label="提交金額">
          <NumberFormat
            disabled={isRemoved}
            allowNegative={false}
            onValueChange={(valuesObj) => {
              updateDebtPaid({
                value: valuesObj.floatValue || 0,
                rentArrearId: item.rentArrears,
                shift,
                fleetGroup,
                date,
                driver,
              });
            }}
            value={item.paid || ""}
            customInput={TextField}
            placeholder={"$0"}
            prefix={"$"}
            thousandSeparator={true}
          />
        </StyledTextBox>
      </Grid>
      <Grid item xs={1} style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
        {isRemoved&&<RedClearButton onClick={handleRemovedRentArrears}/>}
        <IconButton style={{padding:'6px'}} onClick={toggleDebtDetail}>
          <ArrowButton open={isDebtDetailFullHeight} />
        </IconButton>
      </Grid>

    {(isRemoved||errorMsg)&&<>
      <Grid item xs={1}></Grid>
      <Grid item xs={11}><div style={{color:'red'}}>{errorMsg}{isRemoved&&`此欠款可能已清還，請刪除紀錄。`}</div></Grid>
      </>
    }
      </Grid>
      {isDebtDetailOpen && (
        <Grid
          container
          spacing={2} 
          style={{
            marginBottom:'8px',
            overflow: "hidden",
            maxHeight: isDebtDetailFullHeight ? 400 : 0,
            transition: "500ms all",
          }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={isContainerLarge?2:3}>
            <Box style={{ borderLeft:'1px solid #9B9B9b',display:'flex',alignItems:'center',justifyContent:'space-evenly',flexDirection:'column',height:'100%'}}>
            <Box>總欠款</Box>
            <Box fontWeight="700">${(data.amount + item.increasedRent - item.waivedRent)||"-"}</Box>
            </Box>
          </Grid>
          <Grid item xs={isContainerLarge?2:3}>
            <Box display="flex" flexDirection="column">
              <Box mb={0.5}>
              <WaiveOrIncreaseRentBtn
                disabled={isRemoved}
                isSelected={item.waiveOrIncreaseRent === ADJUSTMENT.WAIVE_RENT}
                onClick={() =>
                  onChangeRentAdjustment({
                    waiveOrIncreaseRent: ADJUSTMENT.WAIVE_RENT,
                  })
                }
                label="扣租"
              />
              </Box>
              <WaiveOrIncreaseRentBtn
                disabled={isRemoved}
                isSelected={
                  item.waiveOrIncreaseRent === ADJUSTMENT.INCREASE_RENT
                }
                onClick={() =>
                  onChangeRentAdjustment({
                    waiveOrIncreaseRent: ADJUSTMENT.INCREASE_RENT,
                  })
                }
                label="加租"
              />
            </Box>
          </Grid>
          <Grid item xs={isContainerLarge?2:4}>
            <StyledTextBox label="扣/加租金額">
              <NumberFormat
                disabled={isRemoved||item.waiveOrIncreaseRent === null}
                allowNegative={false}
                onValueChange={(valuesObj) => {
                  updateRentAdjustAmount({
                    value: valuesObj.floatValue || 0,
                    rentArrearId: item.rentArrears,
                    shift,
                    fleetGroup,
                    date,
                    driver,
                  });
                }}
                value={
                  (item.waiveOrIncreaseRent === ADJUSTMENT.WAIVE_RENT
                    ? item.waivedRent
                    : item.waiveOrIncreaseRent === ADJUSTMENT.INCREASE_RENT
                    ? item.increasedRent
                    : "") || ""
                }
                customInput={TextField}
                placeholder={"$0"}
                prefix={"$"}
                thousandSeparator={true}
              />
            </StyledTextBox>
          </Grid>
          {!isContainerLarge&&<Grid item xs={1} />}

          {!isContainerLarge&&<Grid item xs={1} />}
          <Grid item xs={isContainerLarge?2:3} style={{display:'flex',justifyContent:'center'}}>
            <FormControl variant="outlined" margin="dense">
              <Select
                disabled={isRemoved||item.waiveOrIncreaseRent === null}
                displayEmpty
                value={item.reason || ""}
                onChange={(e) => {
                  updateRentAdjustmentReason({
                    reason: e.target.value,
                    rentArrearId: item.rentArrears,
                    shift,
                    fleetGroup,
                    date,
                    driver,
                  });
                  if (e.target.value !== "OTHERS") {
                    setIsAdjustInfoShow(false);
                  }
                }}
              >
                <MenuItem value="" disabled>
                  原因
                </MenuItem>

                {item.waiveOrIncreaseRent === ADJUSTMENT.WAIVE_RENT &&
                  [
                    "維修",
                    "打風",
                    "暴雨",
                    "大驗",
                    "小驗",
                    "租金優惠",
                    "意外維修",
                  ].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}

                <MenuItem
                  onClick={() => {
                    setIsAdjustInfoShow(true);
                  }}
                  value={customDeduction || "OTHERS"}
                >
                  其他
                </MenuItem>
                <MenuItem value={null}>取消</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isContainerLarge?2:3}>
            {isAdjustInfoShow && (
              <TextField
                disabled={isRemoved}
                style={{paddingTop:isContainerLarge?'20px':'16px'}}
                placeholder="請填寫原因"
                name="reason"
                value={customDeduction}
                onChange={handleChangeCustomReason}
              />
            )}
          </Grid>
          <Grid item xs={isContainerLarge?1:5}></Grid>
        </Grid>
      )}
<Grid container spacing={2} >
<Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <Box style={{backgroundColor:'#9b9b9b',height:1,width:'calc( 100% - 8px)'}} />
          </Grid>
          </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  updateRentAdjustmentReason: (payload) =>
    dispatch({
      type: persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_REASON,
      ...payload,
    }),
  updateDebtPaid: (payload) => {
    dispatch({ type: persistRentalActions.ON_CHANGE_DEBT_PAID, ...payload });
  },
  updateRentAdjustAmount: (payload) => {
    dispatch({
      type: persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_AMOUNT,
      ...payload,
    });
  },
  updateWaiveOrIncreaseRent: (payload) => {
    dispatch({
      type: persistRentalActions.ON_CHANGE_WAIVE_OR_INCREASE_RENT,
      ...payload,
    });
  },
  deleteRemovedRentArrears: (payload) => {
    dispatch({
      type: persistRentalActions.DELETE_REMOVED_RENT_ARREARS,
      ...payload,
    });
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Debt));
