import React, { useCallback, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";
import Modal from "../Modal";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { userActions } from "../../redux/actions";
import { updatePassword } from "../../redux/API";
import { modalConstants } from "../Modal/constants";
import StyledButton from "../Button";

const PopupMenu = withStyles({
  paper: {
    width: "150px",
    backgroundColor: "#2B3038",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
));

const PopupMenuItem = withStyles({
  root: {
    color: "#bababa",
    paddingLeft: "10px",
    borderRadius: "4px",
    transition: "500ms all",
    margin: "3px 10px",
    "&:hover": {
      backgroundColor: "#bababa",
      color: "#FFF",
    },
  },
})(MenuItem);

const StyledIconButton = withStyles({
  root: {
    marginTop: "-5px",
    padding: 5,
    "&:hover": {
      backgroundColor: "rgba(155,155,155,0.4)",
    },
  },
  child: {
    backgroundColor: "white",
  },
  rippleVisible: {
    opacity: 0.5,
  },
  "@keyframes enter": {
    "0%": {
      transform: "scale(0)",
      opacity: 0.1,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 0.5,
    },
  },
})((props) => {
  const { classes, children, ...rest } = props;
  return (
    <IconButton
      classes={{ root: classes.root }}
      TouchRippleProps={{ classes: classes }}
      {...rest}
    >
      {children}
    </IconButton>
  );
});

const LogoutMenu = ({ handleLogout }) => {
  const [logoutPrompt, setLogoutPrompt] = useState(false);
  const [dialog, setDialog] = useState({
    title: "",
    isOpen: false,
    isLoading: false,
    isErrorOccur: false,
  });

  const closeLogoutPrompt = useCallback(() => {
    setLogoutPrompt(false);
    handleMenuClose();
  }, []);
  const closeDialog = () => setDialog((prev) => ({ ...prev, isOpen: false }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleUpdatePasswordClick = () => {
    handleMenuClose();
    setDialog({ title: "更改密碼", isOpen: true });
  };

  const form = useFormik({
    initialValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(6, "密碼必須為6位或以上")
        .required("必須填寫"),
      newPassword: Yup.string()
        .min(6, "密碼必須為6位或以上")
        .notOneOf([Yup.ref("oldPassword")], "新密碼不能與舊密碼相同")
        .required("必須填寫"),
      confirmPassword: Yup.string()
        .min(6, "密碼必須為6位或以上")
        .oneOf([Yup.ref("newPassword"), ""], "請輸入相同的新密碼")
        .required("必須填寫"),
    }),
    onSubmit: async (values, formikBag) => {
      try {
        setDialog((prev) => ({ ...prev, ...modalConstants.LOADING_DIALOG }));
        const response = await updatePassword({
          oldPassword: values.oldPassword,
          password: values.newPassword,
        });
        console.log(response);
        //waiting to confirm response checking
        if (response._id) {
          //waiting to confirm response checking
          setDialog((prev) => ({ ...prev, ...modalConstants.CLOSE_DIALOG }));
          formikBag.resetForm();
        } else {
          throw new Error();
        }
      } catch (e) {
        console.log("Error in updatePassword", e);
        formikBag.resetForm();
        setDialog({
          ...modalConstants.ERROR_DIALOG,
          errMsg:
            e &&
            e.response &&
            e.response.data &&
            e.response.data.result &&
            e.response.data.result.err,
        });
      }
    },
  });

  return (
    <>
      <Box textAlign="end">
        <StyledIconButton
          aria-label="logout-or-update"
          aria-controls="choose-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <MoreHorizIcon htmlColor="#FFF" />
        </StyledIconButton>
      </Box>

      <PopupMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        <PopupMenuItem onClick={handleUpdatePasswordClick}>
          更改密碼
        </PopupMenuItem>
        <PopupMenuItem onClick={() => setLogoutPrompt(true)}>
          登出
        </PopupMenuItem>
      </PopupMenu>

      <Modal {...{ ...dialog, closeDialog }}>
        <form onSubmit={form.handleSubmit} noValidate autoComplete="off">
          {[
            { label: "舊密碼", field: "oldPassword" },
            { label: "新密碼", field: "newPassword" },
            { label: "確認新密碼", field: "confirmPassword" },
          ].map(({ label, field }, index) => (
            <Box key={index} my={index === 0 ? "unset" : 2}>
              <TextField
                fullWidth
                label={label}
                type="password"
                {...form.getFieldProps(field)}
                helperText={form.touched[field] && form.errors[field]}
                error={form.touched[field] && form.errors[field] ? true : false}
              />
            </Box>
          ))}
          <Box pt={3} pb={1} textAlign="end">
            <StyledButton
              background="purple"
              disabled={!form.dirty}
              type="submit"
            >
              更改
            </StyledButton>
          </Box>
        </form>
      </Modal>

      <Modal
        isOpen={logoutPrompt}
        title="確認離開？"
        closeDialog={closeLogoutPrompt}
      >
        <Box style={{ color: "#9ba4b0" }}>
          請注意，如閣下於「交更入數」有未提交的暫存資料，登出時會全部刪除。
        </Box>

        <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
          <StyledButton
            background="white"
            variant="outlined"
            onClick={closeLogoutPrompt}
          >
            取消
          </StyledButton>
          <Box ml={1}>
            <StyledButton background="purple" onClick={handleLogout}>
              離開
            </StyledButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch({ type: userActions.LOGOUT_USER }),
});

export default connect(null, mapDispatchToProps)(LogoutMenu);
