import { deleteDialogActions } from '../actions'
const init_state = {
    isSuccess: false,
    isOpen: false,
    isLoading: false,
    isErrorOccur: false,
}

export default function deleteDialogReducer(state = init_state, action) {
    switch (action.type) {
        case deleteDialogActions.FETCH_DELETE_DIALOG:
            return {
                ...state,
                isOpen: true,
                isLoading: true,
                isErrorOccur: false,
            }
        case deleteDialogActions.SUCCESS_DELETE_DIALOG:
            return {
                ...state,
                isSuccess: true,
                isOpen: true,
                isLoading: false
            }
        case deleteDialogActions.FAIL_TO_DELETE_DIALOG:
            return {
                ...state,
                isLoading: false,
                isErrorOccur: true,
                errMsg: action.errMsg
            }
        case deleteDialogActions.OPEN_DELETE_DIALOG:
            return {
                ...state,
                isOpen: true
            }
        case deleteDialogActions.CLOSE_DELETE_DIALOG:
            return {
                ...state,
                isOpen: false
            }
        case deleteDialogActions.RESET_DELETE_DIALOG:
            return init_state
        default:
            return state
    }
}
