import {
  downloadPresignedURL,
  uploadPresignedURL,
  updateUser,
  updateTaxi,
  createUser,
  createTaxi,
  createAccident,
  updateAccident,
  updateMaintenance
} from "../redux/API";

const handleDownload = async (fileKey) => {
  try {
    let a = document.createElement("a");
    a.setAttribute("target", "_blank");
    const encodedFileKey = encodeURIComponent(fileKey);
    const presignedURL = await downloadPresignedURL(encodedFileKey);
    console.log(presignedURL);
    if (presignedURL.url) {
      a.href = presignedURL.url;
      a.click();
    } else {
      throw new Error(presignedURL);
    }
  } catch (e) {
    console.log("Error happen in handleDownload", e);
  }
};

const handleUpload = async (type, resourceId, filename, file) => {
  try {
    const presignedURL = await uploadPresignedURL(type, resourceId, filename);
    console.log(presignedURL);
    if (presignedURL.url) {
      const result = await fetch(presignedURL.url, {
        method: "PUT",
        headers: {
          "content-type": "",
        },
        body: file,
      });
      if (result.ok) {
        return presignedURL.fileKey;
      }
      console.log("Fail to upload file to s3", result);
    }
    console.log("Cannot get presignedURL", presignedURL);
    return false;
  } catch (e) {
    console.log("Error happen in handleUpload", e);
    return false;
  }
};

const handleCreateUpdateSubmit = async ({
  group,
  isCreatePage,
  form,
  uploadFile,
}) => {
  let uploadStatus = [];
  let response, password, errMsg;
  let isAllDone = true;

  try {
    if (isCreatePage) {
      switch (group) {
        case "driver":
          const createUserResult = await createUser(form);

          if (createUserResult._id) {
            form._id = createUserResult._id;

            password = createUserResult.password;
            if (uploadFile.every((item) => !item.file))
              return { isSuccess: true, isAllDone, password };
          } else {
            console.log("Error in create driver");
            console.log(createUserResult);
            return {
              isErrorOccur: true,
              errMsg:
                createUserResult &&
                createUserResult.result &&
                createUserResult.result.err,
              uploadStatus,
            };
          }
          break;
        case "taxi":
          const createTaxiResult = await createTaxi(form);

          if (createTaxiResult._id) {
            form._id = createTaxiResult._id;

            if (uploadFile.every((item) => !item.file))
              return { isSuccess: true, isAllDone };
          } else {
            console.log("Error in create taxi");
            console.log(createTaxiResult);
            console.log(
              createTaxiResult &&
              createTaxiResult.result &&
              createTaxiResult.result.err
            );
            return {
              isErrorOccur: true,
              errMsg:
                createTaxiResult &&
                createTaxiResult.result &&
                createTaxiResult.result.err,
              uploadStatus,
            };
          }
          break;
        case "accident":
          const createAccidentResult = await createAccident(form);

          if (createAccidentResult._id) {
            form._id = createAccidentResult._id;

            if (uploadFile.every((item) => !item.file))
              return { isSuccess: true, isAllDone };
          } else {
            console.log("Error in create accident");
            console.log(createAccidentResult);
            console.log(
              createAccidentResult &&
              createAccidentResult.result &&
              createAccidentResult.result.err
            );
            return {
              isErrorOccur: true,
              errMsg:
                createAccidentResult &&
                createAccidentResult.result &&
                createAccidentResult.result.err,
              uploadStatus,
            };
          }
          break;
        default:
      }
    }

    console.log("start upload file");
    await Promise.all(
      uploadFile.map(async ({ type, file }) => {
        if (file) {
          const fileKey = await handleUpload(type, form._id, file.name, file);
          console.log("fileKey", fileKey);
          if (fileKey) {
            addToForm(form, type, fileKey, file.name);
            uploadStatus.push({ type: type, isSuccess: true, fileName: file.name });
          } else {
            uploadStatus.push({ type: type, isSuccess: false, fileName: file.name });
            isAllDone = false;
          }
        }
      })
    );

    switch (group) {
      case "driver":
        response = await updateUser(form._id, form);
        console.log(response);
        if (!response._id) {
          console.log("Error in handleSubmit");
          return {
            isErrorOccur: true,
            errMsg: response && response.result && response.result.err,
            uploadStatus,
            password,
          };
        }
        break;
      case "taxi":
        response = await updateTaxi(form._id, form);
        console.log(response);
        if (!response._id) {
          console.log("Error in handleSubmit");
          return {
            isErrorOccur: true,
            errMsg: response && response.result && response.result.err,
            uploadStatus,
            password,
          };
        }
        break;
      case "accident":
        response = await updateAccident(form._id, form);
        console.log(response);
        if (!response._id) {
          console.log("Error in handleSubmit");
          return {
            isErrorOccur: true,
            errMsg: response && response.result && response.result.err,
            uploadStatus
          };
        }
        break;
      case "maintenance":
        response = await updateMaintenance(form.taxiId, form)
        console.log(response);
        if (!response._id) {
          console.log("Error in handleSubmit");
          return {
            isErrorOccur: true,
            errMsg: response && response.result && response.result.err,
            uploadStatus
          };
        }
        break;
      default:
    }

    return { isSuccess: true, isAllDone, errMsg, uploadStatus, password, id: form._id };

  } catch (e) {
    console.log("Error in handleSubmit in Document Service", e);
    return {
      isErrorOccur: true,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
      uploadStatus,
      password,
    };
  }
};

const addToForm = (form, fileType, fileKey, fileName) => {
  switch (fileType) {
    case "driver-image":
      return (form.driver.image = fileKey);
    case "driver-contract":
      if (!form.driver.contract) {
        return (form.driver.contract = { image: fileKey });
      }
      return (form.driver.contract.image = fileKey);

    case "taxi-contract":
      return (form.escrowContract = fileKey);
    case "taxi-insurance":
      return (form.insPolicyDocument = fileKey);
    case "taxi-maintenance":
      return (form.inspectionScheduleDocu = fileKey);
    case "accidence":
      form.proofDoc &&
        Array.isArray(form.proofDoc)
        ?
        form.proofDoc.push({ name: fileName, path: fileKey })
        :
        form.proofDoc = [{ name: fileName, path: fileKey }]
      break;
    case "maintenance":
      form.inspectionDoc &&
        Array.isArray(form.inspectionDoc)
        ?
        form.inspectionDoc.push({ name: fileName, path: fileKey })
        :
        form.inspectionDoc = [{ name: fileName, path: fileKey }]
      break;

    default:
  }
};


export const DocumentService = {
  handleDownload,
  handleUpload,
  handleCreateUpdateSubmit,
};
