import { pageUIActions } from "../actions";
import { UiService } from "../../Service/UiService";
import moment from "moment";

const init_state = {
  rentalSelectedDate: {
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
  },
  filter: {},
  open: true,
  refresh: false,
  tabIndex: 0,
  deletedPage: false,
};

export default function UiReducer(state = init_state, action) {
  switch (action.type) {
    case pageUIActions.CHANGE_ROW_PER_PAGE:
      return {
        ...state,
        [action.component]: {
          ...state[action.component],
          rowsPerPage: action.rowsPerPage,
        },
      };
    case pageUIActions.CHANGE_PAGE:
      return {
        ...state,
        [action.component]: {
          ...state[action.component],
          page: action.page,
        },
      };
    case pageUIActions.UPDATE_RENTAL_SELECTED_DATE:
      return {
        ...state,
        rentalSelectedDate: {
          startDate: action.startDate,
          endDate: action.endDate,
        },
      };
    case pageUIActions.UPDATE_ORIGINAL_TABLE:
      return {
        ...state,
        keyword: "",
        filter: {},
        origin: action.origin,
        originalRows: action.rows,
        rows: action.rows,
      };
    case pageUIActions.UPDATE_ORIGINAL_OWNER_TAXI_TABLE:
      return {
        ...state,
        origin: action.origin,
        ownerTaxi:{
          keyword: "",
          filter: {},
          originalRows: action.rows||[],
          rows: action.rows||[],
          taxiInfo:action.taxiInfo||{}
        }
      };
    case pageUIActions.UPDATE_ORIGINAL_TABLE_WITH_KEYWORD:
      const newRows = UiService.keywordFilter(
        action.rows,
        state.keyword,
        state.origin,
        state.filter
      );
      return {
        ...state,
        originalRows: action.rows,
        rows: newRows,
      };
    case pageUIActions.UPDATE_OWNER_TAXI_KEYWORD_SEARCH:
      var rows =UiService.keywordFilter(
        state.ownerTaxi.originalRows,
        action.values,
        state.origin,
        state.ownerTaxi.filter
      );
      return {
        ...state,
        ownerTaxi:{
          ...state.ownerTaxi,
          rows,
          keyword:action.values,
        },
        [action.component]: {
          ...state[action.component],
          page: 0,
        },
      };
      case pageUIActions.UPDATE_OWNER_TAXI_FILTER_BTN:
        rows = UiService.keywordFilter(
          state.ownerTaxi.originalRows,
          state.ownerTaxi.keyword,
          state.origin,
          action.filter
        );
        return {
          ...state,
          ownerTaxi:{
            ...state.ownerTaxi,
            rows,
            filter:action.filter
          },
          [action.component]: {
            ...state[action.component],
            page: 0,
          },
        };
    case pageUIActions.UPDATE_SCREEN_TABLE:
      rows = UiService.keywordFilter(
        state.originalRows,
        action.values,
        state.origin,
        state.filter
      );
      return {
        ...state,
        keyword: action.values,
        rows,
        [action.component]: {
          ...state[action.component],
          page: 0,
        },
      };
    case pageUIActions.UPDATE_FILTER_BTN:
      const newRow = UiService.keywordFilter(
        state.originalRows,
        state.keyword,
        state.origin,
        action.filter
      );
      return {
        ...state,
        filter: action.filter,
        rows: newRow,
        [action.component]: {
          ...state[action.component],
          page: 0,
        },
      };
    case pageUIActions.UPDATE_CURRENT_FLEET:
      return {
        ...state,
        fleetIndex: action.fleetIndex,
      };
    case pageUIActions.TOGGLE_REFRESH_FLAG:
      return {
        ...state,
        refresh: action.refresh,
      };
    case pageUIActions.TOGGLE_SIDEBAR:
      return {
        ...state,
        open: action.value,
      };
    case pageUIActions.UPDATE_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
      };
    case pageUIActions.TOGGLE_DELETE_PAGE:
      return {
        ...state,
        deletedPage: action.status,
      };
    default:
      return state;
  }
}
