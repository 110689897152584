import { persistRentalActions } from "../actions";
import moment from "moment";
import { RentalService } from "../../Service/RentalService";

const init_state = {};

export default function persistRentalReducer(state = init_state, action) {
  const {
    index,
    field,
    shift,
    value,
    fleetGroup,
    date,
  } = action;
  let current =
    state[fleetGroup] && state[fleetGroup][date]
      ? state[fleetGroup][date]
      : undefined;
  let newObj, newArr, newArray;

  switch (action.type) {
    case persistRentalActions.UPDATE_PERSIST_RENTAL:
      let isMorningDeductionExist =
        action.morningDeductionList && action.morningDeductionList.length > 0;
      let isNightDeductionExist =
        action.nightDeductionList && action.nightDeductionList.length > 0;

      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: {
            lastEditedDate: moment().format("YYYY-MM-DD"),
            morningTaxi: action.morningTaxi,
            nightTaxi: action.nightTaxi,
            summary: action.summary,
            morningDeductionList: isMorningDeductionExist
              ? action.morningDeductionList
              : [{ driver: "",  shift: "morning" }],
            nightDeductionList: isNightDeductionExist
              ? action.nightDeductionList
              : [{ driver: "", shift: "night" }],
            morningDeductionUi: isMorningDeductionExist ? "table" : "dialog",
            nightDeductionUi: isNightDeductionExist ? "table" : "dialog",
            totalDeposit: action.totalDeposit,
            totalRentPaidReceived: action.totalRentPaidReceived,
            rentArrearsEntities: action.entities,
            rentArrearsList: action.list,
            removedDrivers:[],
            popUpMsg:action.popUpMsg,
            isPopUpOpen:action.isPopUpOpen
          },
        },
      };

    case persistRentalActions.UPDATE_PERSIST_UPDATE_TIME:
      var {result:morningList,
        isPopUpOpen:isMorningPopUp,
        popUpMsg:morningPopUpMsg,
      } =RentalService.driverTransactionRentArrearUpdate(
        current.morningDeductionList,action.list,action.entities);
      var {result:nightList,
        isPopUpOpen:isNightPopUp,
        popUpMsg:nightPopUpMsg,
      } = RentalService.driverTransactionRentArrearUpdate(
        current.nightDeductionList,action.list,action.entities);
      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: {
            ...current,
            lastEditedDate: moment().format("YYYY-MM-DD"),
            morningDeductionList: morningList,
            nightDeductionList: nightList,
            popUpMsg:{morning:{...current.popUpMsg.morning,...morningPopUpMsg},night:{...current.popUpMsg.night,...nightPopUpMsg}},
            isPopUpOpen:isMorningPopUp||isNightPopUp,
            rentArrearsEntities: action.entities,
            rentArrearsList: action.list,
          },
        },
      };

    case persistRentalActions.ON_CHANGE_TAXI_CARD:
      newArr = shift === 0 ? [...current.morningTaxi] : [...current.nightTaxi];
      const newValue = value === "" ? 0 : Number(value);
      const indexOfTaxiCard = newArr.findIndex(
        (elem) => elem._id === action._id
      );
      if (field === "isAttended") {
        newArr[indexOfTaxiCard] = {
          ...newArr[indexOfTaxiCard],
          [field]: value,
        };
      } else if (field === "waivedRent") {
        const newPayable =
          newArr[indexOfTaxiCard][
            shift === 0 ? "morningShiftRent" : "nightShiftRent"
          ] - newValue;
        newArr[indexOfTaxiCard] = {
          ...newArr[indexOfTaxiCard],
          waivedRent: newValue,
          increasedRent: 0,
          payable: newPayable,
        };
      } else if (field === "increasedRent") {
        const newPayable =
          newArr[indexOfTaxiCard][
            shift === 0 ? "morningShiftRent" : "nightShiftRent"
          ] + newValue;
        newArr[indexOfTaxiCard] = {
          ...newArr[indexOfTaxiCard],
          waivedRent: 0,
          increasedRent: newValue,
          payable: newPayable,
        };
      } else {
        newArr[indexOfTaxiCard] = {
          ...newArr[indexOfTaxiCard],
          [field]: newValue,
        };
      }

      if (shift === 0) {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningTaxi: newArr,
              summary: RentalService.summaryCalculator(
                newArr,
                current.nightTaxi
              ),
            },
          },
        };
      } else {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              nightTaxi: newArr,
              summary: RentalService.summaryCalculator(
                current.morningTaxi,
                newArr
              ),
            },
          },
        };
      }

    case persistRentalActions.ON_CHANGE_TAXI_CARD_DRIVER:
      const array =
        shift === 0 ? [...current.morningTaxi] : [...current.nightTaxi];
      const indexOfDriver = array.findIndex((elem) => elem._id === action._id);
      array[indexOfDriver] = {
        ...array[indexOfDriver],
        driver: { _id: action.value === "" ? "" : action.value },
      };
      if (shift === 0) {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningTaxi: array,
            },
          },
        };
      } else {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              nightTaxi: array,
            },
          },
        };
      }

    case persistRentalActions.ON_CHANGE_WAIVED_RENT:
      newArray =
        shift === 0 ? [...current.morningTaxi] : [...current.nightTaxi];
      var indexOfWaivedRent = newArray.findIndex(
        (elem) => elem._id === action._id
      );
      newArray[indexOfWaivedRent] = {
        ...newArray[indexOfWaivedRent],
        [field]: action.value,
      };
      if (shift === 0) {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningTaxi: newArray,
            },
          },
        };
      } else {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              nightTaxi: newArray,
            },
          },
        };
      }

    case persistRentalActions.BATCH_ON_CHANGE_WAIVED_OR_INCREASED_RENT:
      newArray =
        shift === 0 ? [...current.morningTaxi] : [...current.nightTaxi];
      const isWaiveRent = action.waiveOrIncreaseRent === 0;

      newArr = newArray.map((taxi) => {
        if (taxi.isAttended) {
          taxi.payable =
            taxi[shift === 0 ? "morningShiftRent" : "nightShiftRent"] -
            (isWaiveRent ? action.amount : 0) +
            (isWaiveRent ? 0 : action.amount);
          taxi.reason = action.reason;
          taxi.waivedRent = isWaiveRent ? action.amount : 0;
          taxi.increasedRent = isWaiveRent ? 0 : action.amount;
        }
        return taxi;
      });
      if (shift === 0) {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningTaxi: newArr,
              summary: RentalService.summaryCalculator(
                newArr,
                current.nightTaxi
              ),
            },
          },
        };
      } else {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              nightTaxi: newArr,
              summary: RentalService.summaryCalculator(
                current.morningTaxi,
                newArr
              ),
            },
          },
        };
      }
    case persistRentalActions.ADD_DEDUCTION_ROW:
      switch (shift) {
        case 0:
          newObj = {
            ...current,
            morningDeductionList: [
              ...current.morningDeductionList,
              { driver: "", shift: "morning" },
            ],
          };
          break;
        case 1:
          newObj = {
            ...current,
            nightDeductionList: [
              ...current.nightDeductionList,
              { driver: "", shift: "night" },
            ],
          };
          break;
        default:
          newObj = current;
      }
      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: newObj,
        },
      };
    case persistRentalActions.DELETE_DEDUCTION_ROW:
      var removedDrivers;
        switch (shift) {
          case 0:
            removedDrivers=RentalService.removedDriversChecker(current.morningDeductionList[index],current.removedDrivers);
            newObj = {
              ...current,
              morningDeductionList: RentalService.deleteRow(
                current.morningDeductionList,
                index
              ),
              removedDrivers
            };
            break;
          case 1:
            removedDrivers=RentalService.removedDriversChecker(current.nightDeductionList[index],current.removedDrivers);
            newObj = {
              ...current,
              nightDeductionList: RentalService.deleteRow(
                current.nightDeductionList,
                index
              ),
              removedDrivers:removedDrivers
            };
            break;
          default:
            newObj = current;
        }
  
        var result =RentalService.persistStoreDeductionSummaryCalculator(newObj.morningDeductionList,newObj.nightDeductionList);
        newObj.totalDeposit = result.totalDeposit;
        newObj.totalRentPaidReceived=result.totalRentPaidReceived;
  
        if (newObj.morningDeductionList.length === 0) {
          newObj.morningDeductionUi = "dialog";
          newObj.morningDeductionList = [
            [{ driver: "", shift: "morning" }],
          ];
        }
        if (newObj.nightDeductionList.length === 0) {
          newObj.nightDeductionUi = "dialog";
          newObj.nightDeductionList = [
            [{ driver: "", shift: "night" }],
          ];
        }
  
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: newObj,
          },
        };
  
    case persistRentalActions.ON_CHANGE_DEDUCTION_DRIVER:
      var driverRentArrearArr =
        value && current.rentArrearsList[value]
          ? current.rentArrearsList[value]
          : [];
      var driverRentArrear=driverRentArrearArr.map(id =>({
          rentArrears: id,
          date:current.rentArrearsEntities[id].date,
          shift:current.rentArrearsEntities[id].shift,
          plateNumber:current.rentArrearsEntities[id].taxiTransaction?.taxi?.plateNumber,
          increasedRent: 0,
          waivedRent: 0,
          waiveOrIncreaseRent: null,
          reason: null,
          paid: 0,
        })
      )

      var newDeductionList =
        shift === 0
          ? [...current.morningDeductionList]
          : [...current.nightDeductionList];
      removedDrivers=RentalService.removedDriversChecker(newDeductionList[index],current.removedDrivers);
      newDeductionList.splice(index, 1, {
        driver: value,
        driverRentArrear,
        others:[],
        shift:shift === 0?"morning":"night"
      });

      result =
      RentalService.persistStoreDeductionSummaryCalculator(
        newDeductionList,
        shift===0?current.nightDeductionList:current.morningDeductionList);

      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: {
            ...current,
            morningDeductionList:
              shift === 0 ? newDeductionList : current.morningDeductionList,
            nightDeductionList:
              shift === 1 ? newDeductionList : current.nightDeductionList,
            totalDeposit:result.totalDeposit,
            totalRentPaidReceived:result.totalRentPaidReceived,
            removedDrivers:removedDrivers
          },
        },
      };

    case persistRentalActions.ON_CHANGE_WAIVE_OR_INCREASE_RENT:
    case persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_AMOUNT:
    case persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_REASON:
    case persistRentalActions.ON_CHANGE_DEBT_PAID:
      newDeductionList =
        shift === 0
          ? [...current.morningDeductionList]
          : [...current.nightDeductionList];
      var indexInList = newDeductionList.findIndex(
        (item) => item.driver === action.driver
      );
      var indexOfRentArrear = newDeductionList[
        indexInList
      ].driverRentArrear.findIndex((item) => item.rentArrears === action.rentArrearId);
      let targetRentArrear =
        newDeductionList[indexInList].driverRentArrear[indexOfRentArrear];

      switch (action.type) {
        case persistRentalActions.ON_CHANGE_WAIVE_OR_INCREASE_RENT:
          targetRentArrear = {
            ...targetRentArrear,
            waiveOrIncreaseRent:
              targetRentArrear.waiveOrIncreaseRent ===
              action.waiveOrIncreaseRent
                ? null
                : action.waiveOrIncreaseRent,
            waivedRent: 0,
            increasedRent: 0,
            reason: (action.waiveOrIncreaseRent===1 &&targetRentArrear.waiveOrIncreaseRent!==1)?"OTHERS":null,
          };
          break;
        case persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_AMOUNT:
          targetRentArrear = {
            ...targetRentArrear,
            waivedRent:
              targetRentArrear.waiveOrIncreaseRent === 0
                ? Number(action.value)
                : 0,
            increasedRent:
              targetRentArrear.waiveOrIncreaseRent === 1
                ? Number(action.value)
                : 0,
          };
          break;
        case persistRentalActions.ON_CHANGE_RENT_ADJUSTMENT_REASON:
          targetRentArrear = {
            ...targetRentArrear,
            reason: action.reason,
          };
          break;
        case persistRentalActions.ON_CHANGE_DEBT_PAID:
          targetRentArrear = {
            ...targetRentArrear,
            paid: action.value,
          };

          break;
        default:
      }
      if(targetRentArrear._id&&
        !targetRentArrear.paid&&!targetRentArrear.reason&&
        !targetRentArrear.increasedRent&&
        !targetRentArrear.waivedRent&&
        !targetRentArrear.waiveOrIncreaseRent){
          removedDrivers=targetRentArrear._id;
          delete targetRentArrear._id
        }
      newDeductionList[indexInList].driverRentArrear[
        indexOfRentArrear
      ] = targetRentArrear;

      if (action.type === persistRentalActions.ON_CHANGE_DEBT_PAID) {
        result = RentalService.persistStoreDeductionSummaryCalculator(
          newDeductionList,
          shift === 0
            ? current.nightDeductionList
            : current.morningDeductionList
        );
      }

      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: {
            ...current,
            totalRentPaidReceived:(result&&result.totalRentPaidReceived!==undefined)
            ?result.totalRentPaidReceived
            :current.totalRentPaidReceived,
            morningDeductionList:
              shift === 0 ? newDeductionList : current.morningDeductionList,
            nightDeductionList:
              shift === 1 ? newDeductionList : current.nightDeductionList,
            removedDrivers:removedDrivers?[...current.removedDrivers,removedDrivers]:current.removedDrivers
          },
        },
      };

      case persistRentalActions.DELETE_REMOVED_RENT_ARREARS:
        newDeductionList =
          shift === 0
            ? [...current.morningDeductionList]
            : [...current.nightDeductionList];
        indexInList = newDeductionList.findIndex(
          (item) => item.driver === action.driver
        );
        indexOfRentArrear = newDeductionList[indexInList].driverRentArrear.findIndex((item) => item.rentArrears === action.rentArrearId);
        removedDrivers=newDeductionList[indexInList].driverRentArrear[indexOfRentArrear]._id;
        newDeductionList[indexInList].driverRentArrear.splice(indexOfRentArrear,1);
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningDeductionList:
                shift === 0 ? newDeductionList : current.morningDeductionList,
              nightDeductionList:
                shift === 1 ? newDeductionList : current.nightDeductionList,
              removedDrivers:removedDrivers?[...current.removedDrivers,removedDrivers]:current.removedDrivers
            },
          },
        };
    
    case persistRentalActions.ADD_DEDUCTION_IN_OTHERS:
    case persistRentalActions.DELETE_DEDUCTION_IN_OTHERS:
    case persistRentalActions.UPDATE_DEDUCTION_TYPE_IN_OTHERS:
    case persistRentalActions.UPDATE_DEDUCTION_AMOUNT_IN_OTHERS:
          newDeductionList =
            shift === 0
              ? [...current.morningDeductionList]
              : [...current.nightDeductionList];
          indexInList = newDeductionList.findIndex(
            (item) => item.driver === action.driver
          );
          var targetDriverOthersDeduction=newDeductionList[indexInList].others||[];
          switch (action.type) {
            case persistRentalActions.ADD_DEDUCTION_IN_OTHERS:
              targetDriverOthersDeduction=[...targetDriverOthersDeduction,{driver:action.driver,type:"",amount:0}]
              break;
            case persistRentalActions.DELETE_DEDUCTION_IN_OTHERS:
              removedDrivers=targetDriverOthersDeduction[action.indexInOthersList]._id;
              targetDriverOthersDeduction.splice(action.indexInOthersList,1);
              break;
            case persistRentalActions.UPDATE_DEDUCTION_TYPE_IN_OTHERS:
              var deduction=targetDriverOthersDeduction[action.indexInOthersList];
              targetDriverOthersDeduction.splice(action.indexInOthersList,1,{...deduction,type:action.deductionType});
              break;
            case persistRentalActions.UPDATE_DEDUCTION_AMOUNT_IN_OTHERS:
              deduction=targetDriverOthersDeduction[action.indexInOthersList];
                targetDriverOthersDeduction.splice(action.indexInOthersList,1,{...deduction,amount:action.amount});
                break;
            default:
          }
          newDeductionList[indexInList].others=targetDriverOthersDeduction;

          result =
          RentalService.persistStoreDeductionSummaryCalculator(
            newDeductionList,
            shift===0?current.nightDeductionList:current.morningDeductionList);

          return {
            ...state,
            [fleetGroup]: {
              ...state[fleetGroup],
              [date]: {
                ...current,
                morningDeductionList:
                  shift === 0 ? newDeductionList : current.morningDeductionList,
                nightDeductionList:
                  shift === 1 ? newDeductionList : current.nightDeductionList,
                totalDeposit:result.totalDeposit,
                totalRentPaidReceived:result.totalRentPaidReceived,
                removedDrivers:removedDrivers?[...current.removedDrivers,removedDrivers]:current.removedDrivers
              },
            },
          };

    case persistRentalActions.SUBMIT_DEDUCTION_DIALOG:
      if (shift === 0) {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              morningDeductionUi: "table",
            },
          },
        };
      } else {
        return {
          ...state,
          [fleetGroup]: {
            ...state[fleetGroup],
            [date]: {
              ...current,
              nightDeductionUi: "table",
            },
          },
        };
      }

    case persistRentalActions.CLOSE_RENT_ARREARS_POP_UP:
      return {
        ...state,
        [fleetGroup]: {
          ...state[fleetGroup],
          [date]: {
            ...current,
            isPopUpOpen:false
          },
        },
      };

    case persistRentalActions.DELETE_TARGET_DATE_RECORD:
      if (Object.keys(state[fleetGroup]).length === 1) {
        delete state[fleetGroup];
      } else {
        delete state[fleetGroup][date];
      }
      return state;

    case persistRentalActions.CHECK_EXPIRE_RECORD:
      const fleetGroupList = Object.keys(state);
      const todayDate = moment();
      const DELETE_TIME_SPAN = 7;

      if (fleetGroupList.length > 0) {
        fleetGroupList.forEach((fleetGroup) => {
          const dateList = Object.keys(state[fleetGroup]);
          dateList.forEach((date) => {
            const noOfDaysNotEdit = todayDate.diff(
              state[fleetGroup][date].lastEditedDate,
              "days"
            );
            if (noOfDaysNotEdit >= DELETE_TIME_SPAN) {
              if (Object.keys(state[fleetGroup]).length === 1) {
                delete state[fleetGroup];
              } else {
                delete state[fleetGroup][date];
              }
            }
          });
        });
      } else {
        return state;
      }
      return state;

    case persistRentalActions.LOGOUT_RESET_RECORD:
      return {};

    default:
      return state;
  }
}
