import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Box, Typography } from "@material-ui/core";
import { userActions } from "../redux/actions";
import { TextField } from "@material-ui/core";
import Card from "../components/Card";
import { CenterBox } from "../components/Box/CenterBox";
import LoadingIndicator from "../components/LoadingIndicator";
import Alert from "../components/Alert";
import CancelIcon from "@material-ui/icons/Cancel";
import Button from "../components/Button";
import { useFormik } from "formik";
import * as Yup from "yup";

const LoginCard = ({ title, button, submitHandler, children, disabled }) => {
  return (
    <Card style={{ minWidth: 300 }}>
      <Box textAlign="center" my={2}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      <form onSubmit={submitHandler} noValidate autoComplete="off">
        {children}
        <Box textAlign="end" pt={2} mb={1}>
          <Button variant="contained" disabled={disabled} background="purple" type="submit">
            {button}
          </Button>
        </Box>
      </form>
    </Card>
  );
};

const Login = ({ user, error, status, login, updatePassword }) => {
  const userInfo = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: Yup.object({
      username: Yup.string().required("必須填寫"),
      password: Yup.string().min(6, "密碼必須為6位或以上").required("必須填寫"),
    }),
    onSubmit: (values) => {
      login(values);
    },
  });

  const newPassword = useFormik({
    initialValues: { pw: "", confirm: "" },
    validationSchema: Yup.object({
      pw: Yup.string().min(6, "密碼必須為6位或以上").required("必須填寫"),
      confirm: Yup.string()
        .oneOf([Yup.ref("pw"), ""], "請輸入相同的新密碼")
        .min(6, "密碼必須為6位或以上")
        .required("必須填寫"),
    }),
    onSubmit: (values) => {
      updatePassword({
        oldPassword: userInfo.values.password,
        password: values.pw,
      });
    },
  });

  if (status === "loading") {
    return (
      <CenterBox style={{ height: "100vh" }}>
        <Card style={{ position: "relative", width: 300, height: 300 }}>
          <LoadingIndicator />
        </Card>
      </CenterBox>
    );
  }

  return (
    <CenterBox style={{ height: "100vh" }}>
      {status !== "loggedIn" && (
        <LoginCard
          title="登入"
          button="登入"
          disabled={!userInfo.dirty}
          submitHandler={userInfo.handleSubmit}
        >
          {(status === "fail" || error) && (
            <Alert
              text={
                error &&
                  error.response &&
                  error.response.status &&
                  error.response.status.toString() === "401"
                  ? "登入逾時"
                  : "帳號或密碼不對！"
              }
              icon={<CancelIcon color="secondary" />}
            />
          )}
          {[
            { name: "username", label: "登入名稱" },
            { name: "password", label: "密碼" },
          ].map((textField, index) => (
            <Box key={index} py={2}>
              <TextField
                label={textField.label}
                type={textField.name === "password" ? "password" : ""}
                {...userInfo.getFieldProps(textField.name)}
                helperText={
                  userInfo.touched[textField.name] &&
                  userInfo.errors[textField.name]
                }
                error={
                  userInfo.touched[textField.name] &&
                    userInfo.errors[textField.name]
                    ? true
                    : false
                }
                fullWidth
              />
            </Box>
          ))}
        </LoginCard>
      )}

      {user && user.isResetPasswordNeeded && (
        <LoginCard
          title="更改密碼"
          button="更改"
          disabled={!newPassword.dirty || Object.keys(newPassword.errors).length > 0}
          submitHandler={newPassword.handleSubmit}
        >
          {[
            { name: "pw", label: "新密碼" },
            { name: "confirm", label: "確認新密碼" },
          ].map((textField, index) => (
            <Box key={index} py={2}>
              <TextField
                label={textField.label}
                type="password"
                {...newPassword.getFieldProps(textField.name)}
                helperText={
                  newPassword.touched[textField.name] &&
                  newPassword.errors[textField.name]
                }
                error={
                  newPassword.touched[textField.name] &&
                    newPassword.errors[textField.name]
                    ? true
                    : false
                }
                fullWidth
              />
            </Box>
          ))}
        </LoginCard>
      )}

      {user && !user.isResetPasswordNeeded && user.role && (
        <Redirect to="/daily" />
      )}
    </CenterBox>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  status: state.user && state.user.status,
  error: state.user && state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch({ type: userActions.LOGIN_USER, data }),
  updatePassword: (payload) =>
    dispatch({ type: userActions.UPDATE_USER_PASSWORD, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
