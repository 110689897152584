import React from "react";
import { connect } from "react-redux";
import Table from "../../components/Table";
import PageContainer from "../../components/PageContainer";
import AppBar from "../../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";
import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";
import { pageUIActions } from "../../redux/actions";
import { getAllMaintenance } from "../../redux/API";

export const columns = [
  {
    type: "plateNumber",
    label: "車牌號碼",
    minWidth: 110,
  },
  {
    type: "modelNumber",
    label: "車輀型號",
    minWidth: 110,
  },
  {
    type: "lastUpdateMileage",
    label: "上次維修里數",
    minWidth: 80,
  },
  {
    type: "mileage",
    label: "最新里數",
    minWidth: 80,
  },
  {
    type: "morningShiftDriver",
    label: "正日更司機",
    minWidth: 80,
  },
  {
    type: "nightShiftDriver",
    label: "正夜更司機",
    minWidth: 80,
  },
  {
    type: "status",
    label: "狀態",
    minWidth: 80,
  },
  {
    type: "maintenanceItems",
    label: "維修項目",
    minWidth: 180,
    maxWidth: "30%",
  },
  {
    type: "createdAt",
    label: "創建日期",
    minWidth: 80,
  },
  {
    type: "mileageLastUpdateDate",
    label: "最後更新日期",
    minWidth: 80,
  },
];

const Maintenance = ({
  updateOriginalTable,
  dailyMode,
  origin,
  isRefreshNeeded,
  toggleRefreshFlag,
}) => {
  React.useEffect(() => {
    if (
      isRefreshNeeded ||
      (dailyMode ? origin !== "daily_maintenance" : origin !== "maintenance")
    ) {
      getAllMaintenance()
        .then((res) => {
          updateOriginalTable(
            res,
            dailyMode ? "daily_maintenance" : "maintenance"
          );
        })
        .catch((e) => {
          console.log(e);
          console.log("Error in getting all maintenance info");
        });
      if (isRefreshNeeded) {
        toggleRefreshFlag();
      }
    }
  }, [
    updateOriginalTable,
    dailyMode,
    origin,
    isRefreshNeeded,
    toggleRefreshFlag,
  ]);

  return dailyMode ? (
    <>
      <GetData taxi />
      <Table
        columns={columns}
        pathname={"/maintenance/"}
        selectBy="_id"
        component={"maintenance"}
        origin={dailyMode ? "daily_maintenance" : "maintenance"}
      />
    </>
  ) : (
    <>
      <GetData taxi />
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox flex="1">
            <KeywordSearch
              component={"maintenance"}
              style={{ minWidth: "50%" }}
            />
          </RowAlignCenterBox>
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        <Table
          columns={columns}
          pathname={"/maintenance/"}
          selectBy="_id"
          component={"maintenance"}
        />
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  origin: state.ui.origin,
  isRefreshNeeded: state.ui.refresh,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
  toggleRefreshFlag: () =>
    dispatch({ type: pageUIActions.TOGGLE_REFRESH_FLAG, refresh: false }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
