import React, { useMemo, useCallback } from "react";
import Modal from "../../../components/Modal";
import { roleMatching, documentMatching, shiftMatching } from "../../../match";
import { Box, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import StyledButton from "../../../components/Button";
import Text from "../../../components/Text";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Wrapper = withStyles({
  root: {
    margin: "10px 0px",
  },
})(Box);

const BtnBox = withStyles({
  root: {
    textAlign: "end",
    marginTop: "5px",
  },
})(Box);

export const OverlayDialog = ({
  dialogStatus,
  setDialogStatus,
  isCreatePage,
}) => {
  const {
    type,
    group,
    isSuccess,
    isLoading,
    isErrorOccur,
    password,
    isAllDone,
    uploadStatus,
    conflicts,
    updateTaxiHandler,
    updateDriverHandler,
    setRefreshCounter,
  } = dialogStatus;
  const history = useHistory();
  const closeDialog = () => {
    if (isErrorOccur || type === "conflict") {
      setDialogStatus((prev) => ({ ...prev, isOpen: false }));
      return setTimeout(() => setDialogStatus({ isOpen: false }), 200);
    }
    if (isCreatePage && type === "copied") {
      return history.goBack();
    }

    switch (type + "_" + group) {
      case "create_taxi_owner":
      case "create_driver":
      case "create_taxi":
        return history.goBack();
      case "update_taxi_owner":
      case "update_taxi":
      case "update_driver":
        setRefreshCounter((prev) => prev + 1);
        setDialogStatus((prev) => ({ ...prev, isOpen: false }));
        setTimeout(() => setDialogStatus({ isOpen: false }), 200);
        break;
      default:
        setDialogStatus((prev) => ({ ...prev, isOpen: false }));
        setTimeout(() => setDialogStatus({ isOpen: false }), 200);
    }
  };

  const showUploadResult = useCallback(() => {
    return (
      <>
        <Box mt={1} fontWeight="bold">
          但以下文件未能上傳：
        </Box>
        {uploadStatus.map(({ type, isSuccess }) => (
          <Box style={{ color: "red" }} key={type}>
            {documentMatching[type]}-{isSuccess ? "上傳成功" : "上傳失敗"}
          </Box>
        ))}
      </>
    );
  }, [uploadStatus]);

  const [title, contents, btnText] = useMemo(() => {
    if (isLoading) {
      return ["請稍後", ""];
    }
    switch (type + "_" + group) {
      case "create_taxi_owner":
        return [
          "成功創建",
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              <img
                src={`/assets/ic-success.svg`}
                style={{ height: 70 }}
                alt="success"
              />
            </Box>
            <Box my={3}>
              已成功創建{roleMatching[group]}, 請{roleMatching[group]}
              用臨時密碼登入
            </Box>
            <Box my={2} style={{ color: "#000" }}>
              <Text type="password">臨時密碼: {password}</Text>
            </Box>
          </>,
          "複製臨時密碼",
        ];
      case "create_driver":
        return isAllDone
          ? [
              "成功創建",
              <>
                <Box my={1} mb={2} display="flex" justifyContent="center">
                  <img
                    src={`/assets/ic-success.svg`}
                    style={{ height: 70 }}
                    alt="success"
                  />
                </Box>
                <Box my={3}>
                  已成功創建{roleMatching[group]}, 請{roleMatching[group]}
                  用臨時密碼登入
                </Box>
                <Box my={2} style={{ color: "#000" }}>
                  <Text type="password">臨時密碼: {password}</Text>
                </Box>
              </>,
              "複製臨時密碼",
            ]
          : [
              "注意！",
              <>
                <Box my={1}>
                  <img
                    src={`/assets/ic-fail.svg`}
                    style={{ height: 70 }}
                    alt="fail"
                  />
                </Box>
                <Box mt={3} mb={1}>
                  已創建{roleMatching[group]}帳戶。
                </Box>
                <Box my={2} style={{ color: "#000" }}>
                  <Text type="password">
                    {roleMatching[group]}臨時密碼: {password}
                  </Text>
                </Box>
                {showUploadResult()}
              </>,
              "複製臨時密碼",
            ];

      case "create_taxi":
        return [
          isAllDone ? "成功" : "注意！",
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              {isAllDone ? (
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              ) : (
                <img
                  src={`/assets/ic-fail.svg`}
                  style={{ height: 70 }}
                  alt="fail"
                />
              )}
            </Box>
            <Box mt={1} mb={2}>
              已新增{roleMatching[group]}
            </Box>
            {isAllDone ? "" : showUploadResult()}
          </>,
          "關閉",
        ];

      case "update_taxi_owner":
        return [
          "成功更新",
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              <img
                src={`/assets/ic-success.svg`}
                style={{ height: 70 }}
                alt="success"
              />
            </Box>
            <Box my={3}>已成功更新{roleMatching[group]}資料</Box>
          </>,
          "關閉",
        ];
      case "update_taxi":
      case "update_driver":
        return [
          isAllDone ? "成功" : "注意！",
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              {isAllDone ? (
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              ) : (
                <img
                  src={`/assets/ic-fail.svg`}
                  style={{ height: 70 }}
                  alt="fail"
                />
              )}
            </Box>
            <Box mt={3} mb={2}>
              已成功更新{roleMatching[group]}資料。
            </Box>
            {isAllDone ? "" : showUploadResult()}
          </>,
          "關閉",
        ];

      case "reset_taxi_owner":
      case "reset_driver":
        return [
          "成功重設密碼",
          <>
            <Box my={1} mb={2} display="flex" justifyContent="center">
              <img
                src={`/assets/ic-success.svg`}
                style={{ height: 70 }}
                alt="success"
              />
            </Box>
            <Box my={3}>
              已重設{roleMatching[group]}密碼，請{roleMatching[group]}
              用臨時密碼登入
            </Box>
            <Box my={2} style={{ color: "#000" }}>
              <Text type="password">臨時密碼: {password}</Text>
            </Box>
          </>,
          "複製臨時密碼",
        ];

      case "copied_taxi_owner":
      case "copied_driver":
        return ["成功複製", <Box>已複製密碼</Box>, "關閉"];

      case "conflict_driver":
      case "conflict_taxi":
        return ["注意！", "", ""];

      default:
        return ["", ""];
    }
  }, [type, group, isLoading, password, isAllDone, showUploadResult]);

  return (
    <Modal {...{ closeDialog, title, type, ...dialogStatus }}>
      <Wrapper>
        {isLoading ? (
          "正在連接後台"
        ) : type && type.toLowerCase() === "conflict" ? (
          group === "taxi" ? (
            <>
              {conflicts.map(
                (item) =>
                  item.conflict && (
                    <Box key={item.plateNumber}>
                      {item.name}本身屬於的士(車牌：{item.plateNumber}
                      )的正更司機，系統會自動移除
                    </Box>
                  )
              )}
              <Box> 確定繼續？</Box>

              <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
                <StyledButton
                  background="white"
                  variant="outlined"
                  type="button"
                  onClick={closeDialog}
                >
                  取消選擇
                </StyledButton>
                <Box ml={1}>
                  <StyledButton background="purple" onClick={updateTaxiHandler}>
                    確定繼續
                  </StyledButton>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box style={{ color: "#9ba4b0" }}>
                如司機已獲分派的士，更改其更份後，該的士
                {shiftMatching[conflicts.shift]}司機 將會懸空。
              </Box>
              <Box style={{ color: "#9ba4b0" }}> 確定繼續？</Box>

              <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
                <StyledButton
                  background="white"
                  variant="outlined"
                  type="button"
                  onClick={closeDialog}
                >
                  取消
                </StyledButton>
                <Box ml={1}>
                  <StyledButton
                    background="purple"
                    onClick={updateDriverHandler}
                  >
                    確定繼續
                  </StyledButton>
                </Box>
              </Box>
            </>
          )
        ) : (
          isSuccess && (
            <>
              <Box style={{ color: "#9ba4b0" }}>{contents}</Box>
              <BtnBox>
                {type === "reset" ||
                (type === "create" && group === "taxi_owner") ||
                (type === "create" && group === "driver") ? (
                  <CopyToClipboard
                    text={password}
                    onCopy={() =>
                      setDialogStatus((prev) => ({
                        ...prev,
                        isLoading: false,
                        password: "",
                        type: "copied",
                      }))
                    }
                  >
                    <StyledButton background="purple">{btnText}</StyledButton>
                  </CopyToClipboard>
                ) : (
                  <StyledButton background="purple" onClick={closeDialog}>
                    {btnText}
                  </StyledButton>
                )}
              </BtnBox>
            </>
          )
        )}
      </Wrapper>
    </Modal>
  );
};
