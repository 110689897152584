import {withStyles}from '@material-ui/styles';
import Card from '@material-ui/core/Card';

export default withStyles({
    root:{
        borderRadius:8,
        padding:'10px 20px',
        overflow:'unset'
    }
})(Card)

export * from './PasswordCard';
export * from './HeadingCard';
export * from './StyledCard';