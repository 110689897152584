import React, { useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { LinkButton } from "../components/Button";
import Table from "../components/Table";
import { accountActions } from "../redux/actions";
import PageContainer from "../components/PageContainer";
import AppBar from "../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../components/Box";
import { pageUIActions } from "../redux/actions";
import KeywordSearch from "../components/KeywordSearch";
import FilterBox from "../components/FilterBox";
import { Box } from "@material-ui/core";
import LoadingIndicator from "../components/LoadingIndicator";

const columns = [
  { type: "name", label: "姓名", minWidth: 170 },
  { type: "username", label: "登入名稱", minWidth: 100 },
  {
    type: "phone",
    label: "手機號碼",
    minWidth: 170,
  },
  {
    type: "fleet",
    label: "車隊",
    minWidth: 170,
  },
  {
    type: "fleetGroup",
    label: "車組",
    minWidth: 170,
  },
  {
    type: "role",
    label: "角色",
    minWidth: 170,
  },
  {
    type: "createdAt",
    label: "創建日期",
    minWidth: 100,
  },
];

const Account = ({
  systemUserStatus,
  systemUser,
  getAllSystemUser,
  updateOriginalTable,
  updateTable,
  origin,

  deletedPage,
  setDeletedPage,
  deletedSystemAccount,
  isDeletedSystemAccountReady,
}) => {
  useEffect(() => {
    if (systemUserStatus === "init") {
      getAllSystemUser();
    }
  }, [systemUserStatus, getAllSystemUser]);

  React.useEffect(
    () => {
      if (origin !== `account`) {
        updateOriginalTable(systemUser, `account`);
        setDeletedPage(false);
      } else {
        if (deletedPage && isDeletedSystemAccountReady) {
          updateTable(deletedSystemAccount);
        } else {
          updateTable(systemUser);
        }
      }
    },
    // eslint-disable-next-line
    [
      systemUserStatus,
      systemUser,
      origin,
      updateOriginalTable,
      updateTable,
      deletedPage,
      isDeletedSystemAccountReady,
    ]
  );

  return (
    <>
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox style={{ minWidth: "50%" }}>
            {deletedPage && (
              <Box mr={4}>
                <Typography style={{ fontWeight: "700" }}>
                  {deletedPage && `已刪除系統帳戶`}
                </Typography>
              </Box>
            )}

            <KeywordSearch component={"account"} style={{ flex: "1" }} />
            <FilterBox type={"account"} component={"account"} />
          </RowAlignCenterBox>

          {!deletedPage && (
            <RowAlignCenterBox>
              <LinkButton background="purple" to={`/account/create`}>
                新建系統帳戶
              </LinkButton>
            </RowAlignCenterBox>
          )}
        </SpaceBetweenBox>
        <Typography variant="h4" noWrap></Typography>
      </AppBar>
      <PageContainer>
        {deletedPage && !isDeletedSystemAccountReady ? (
          <LoadingIndicator />
        ) : (
          <Table
            // rows={rows}
            {...{ columns }}
            pathname="/account/"
            selectBy="_id"
            component="account"
            origin={"account"}
          />
        )}
      </PageContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  deletedPage: state.ui.deletedPage,
  status: state.status,
  user: state.user,
  error: state.error,
  systemUser: state.systemUser.list,
  systemUserStatus: state.systemUser.status,
  origin: state.ui.origin,

  deletedSystemAccount: state.systemUser.deletedSystemAccount,
  isDeletedSystemAccountReady: state.systemUser.isDeletedSystemAccountReady,
});
const mapDispatchToProps = (dispatch) => ({
  getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNT }),
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
  updateTable: (rows) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE_WITH_KEYWORD, rows }),
  setDeletedPage: (status) =>
    dispatch({
      type: pageUIActions.TOGGLE_DELETE_PAGE,
      status,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Account);
