import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import ManagementContainer from "../ManagementContainer";
import { ManagementGrid } from "../ManagementGrid";
import { ManagementFetchData } from "../ManagementFetchData";
import { Divider } from "../../../components/Divider";
import { constants, YupValidationConstants } from "../../../constants";
import { UserService } from "../../../Service/UserService";
import { UploadAndDownloadDoc } from "../../../components/UploadAndDownloadDoc";
import GetData from "../../../components/GetData";

import { TrueOrFalseCheckBox } from "../../../components/Checkbox/TrueOrFalse";
import { GPSBtn } from "./GPSbtn";
import { UtilsService } from "../../../Service/UtilsService";
import { DocumentService } from "../../../Service/DocumentService";
import { StyledCard } from "../../../components/Card";
import {
  taxiActions,
  driverActions,
  taxiOwnerActions,
} from "../../../redux/actions";
import { checker } from "../../../permission";
import SelectTextField from "../../../components/SelectTextField";
import { TextField } from "@material-ui/core";

const driverConflictChecker = (driverArr, driverId, currentPlateNumber) => {
  if (!driverId || !driverArr) {
    return [false, undefined, undefined];
  }
  const driver = driverArr.find((person) => person._id === driverId);
  const isConflict =
    driver &&
    driver.driver &&
    driver.driver.taxi &&
    driver.driver.taxi.plateNumber &&
    driver.driver.taxi.plateNumber !== currentPlateNumber;
  return [
    isConflict,
    isConflict && driver.name,
    isConflict && driver.driver.taxi.plateNumber,
  ];
};

const ClientCreateUpdate = (props) => {
  const {
    userRole,
    type,
    group,
    morningDriverList,
    nightDriverList,
    getAllTaxi,
    getAllDriver,
    getAllTaxiOwner,
    location,
  } = props;

  const { id } = useParams();
  const isCreatePage = props.type.toLowerCase() === "create";
  const isEditable = isCreatePage || checker(userRole, "taxi:update");
  const [dialogStatus, setDialogStatus] = useState({ isOpen: false });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [escrowContractImage, setEscrowContractImage] = useState("");
  const [insPolicyDocumentImage, setInsPolicyDocumentImage] = useState("");
  const [
    inspectionScheduleDocuImage,
    setInspectionScheduleDocuImage,
  ] = useState("");

  useEffect(() => {
    setEscrowContractImage("");
    setInsPolicyDocumentImage("");
    setInspectionScheduleDocuImage("");
  }, [refreshCounter]);

  const { userData, isLoading, isError } = id
    ? ManagementFetchData({ group, id, refreshCounter })
    : { userData: {}, isLoading: false, isError: false };
  const {
    isDisabled,
    status = "OPERATING",
    accidentOngoingCount,
    totalAccidentCount,
    maintByMfr = false,
    _id = "",
    plateNumber = "",
    modelNumber = "",
    vinNum = "",
    yearOfMfr = "",
    owner,
    ownerId = owner && owner._id ? owner._id : "",
    ownerName = owner && owner.name ? owner.name : "",
    licenseExpiryDate = null,
    serviceCommenceDate = null,

    // rental info
    fleet = "",
    fleetId = fleet && fleet._id ? fleet._id : "",
    fleetName = fleet && fleet.name ? fleet.name : "",
    fleetGroup,
    fleetGroupId = fleetGroup && fleetGroup._id ? fleetGroup._id : "",
    fleetGroupName = fleetGroup && fleetGroup.name ? fleetGroup.name : "",
    escrowContract = "",
    takeOverTime = null,
    rentalStartTime = null,
    rentalEndTime = null,
    morningShiftRent = "",
    nightShiftRent = "",
    morningShiftDriver,
    morningShiftDriverId = morningShiftDriver && morningShiftDriver._id
      ? morningShiftDriver._id
      : "",
    morningShiftDriverName = morningShiftDriver && morningShiftDriver.name
      ? morningShiftDriver.name
      : "",
    nightShiftDriver,
    nightShiftDriverId = nightShiftDriver && nightShiftDriver._id
      ? nightShiftDriver._id
      : "",
    nightShiftDriverName = nightShiftDriver && nightShiftDriver.name
      ? nightShiftDriver.name
      : "",
    rentalFeeToOwner = "",

    // insurance info
    inspectionScheduleDocu = "",
    insCompany = "",
    typeOfIns = "",
    agentId = "",
    agentName = "",
    insExpDate = null,
    insCushionAmount = "",
    insPolicyDocument = "",
    ncd = "",

    // Vehicle inspection
    throMaintenanceDate = null,
    briefCheckingDate = null,

    mileageItems,
    engineOil = mileageItems && mileageItems.engineOil,
    gearboxlOil = mileageItems && mileageItems.gearboxlOil,
    differentialAssemblyOil = mileageItems &&
      mileageItems.differentialAssemblyOil,
    battery = mileageItems && mileageItems.battery,
    tire = mileageItems && mileageItems.tire,
    airConditioning = mileageItems && mileageItems.airConditioning,
    waterTank = mileageItems && mileageItems.waterTank,
    waterPump = mileageItems && mileageItems.waterPump,
    splash = mileageItems && mileageItems.splash,
    starter = mileageItems && mileageItems.starter,
    frontBrakePad = mileageItems && mileageItems.frontBrakePad,
    backBrakePad = mileageItems && mileageItems.backBrakePad,
    brakeCylinder = mileageItems && mileageItems.brakeCylinder,
    sparkPlug = mileageItems && mileageItems.sparkPlug,
    others = mileageItems && mileageItems.others,

    // gps info
    mileage = "",
    mileageLastUpdateDate = null,
    gpsSingleLastUpdateTime = null,
    gpsInstalled = true,
  } = userData || {};
  const taxi_type = userData && userData.type ? userData.type : "";

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      isDisabled,
      taxi_status: status,
      taxi_type,
      maintByMfr,
      _id,
      plateNumber,
      modelNumber,
      vinNum,
      yearOfMfr,
      ownerId,
      ownerName,
      licenseExpiryDate: UtilsService.showReadableDay(licenseExpiryDate),
      serviceCommenceDate: UtilsService.showReadableDay(serviceCommenceDate),

      // rental info
      fleetId,
      fleetGroupId,
      fleetName,
      fleetGroupName,
      escrowContract,
      takeOverTime: UtilsService.showReadableDay(takeOverTime),
      rentalStartTime: UtilsService.showReadableDayTime(rentalStartTime),
      rentalEndTime: UtilsService.showReadableDay(rentalEndTime),
      morningShiftRent,
      nightShiftRent,
      morningShiftDriverId,
      morningShiftDriverName,
      nightShiftDriverId,
      nightShiftDriverName,
      rentalFeeToOwner,

      // insurance info
      insCompany,
      typeOfIns,
      agentId,
      agentName,
      insExpDate: UtilsService.showReadableDay(insExpDate),
      insCushionAmount,
      insPolicyDocument,
      ncd,

      // Vehicle inspection
      inspectionScheduleDocu,
      throMaintenanceDate: UtilsService.showReadableDay(throMaintenanceDate),
      briefCheckingDate: UtilsService.showReadableDay(briefCheckingDate),

      engineOil,
      gearboxlOil,
      differentialAssemblyOil,
      battery,
      tire,
      airConditioning,
      waterTank,
      waterPump,
      splash,
      starter,
      frontBrakePad,
      backBrakePad,
      brakeCylinder,
      sparkPlug,
      others,

      // gps info
      mileage,
      mileageLastUpdateDate: UtilsService.showReadableDayTime(
        mileageLastUpdateDate
      ),
      gpsSingleLastUpdateTime,
      gpsInstalled,
    },
    validationSchema: Yup.object({
      taxi_status: YupValidationConstants.STRING_REQUIRED,
      taxi_type: YupValidationConstants.STRING_REQUIRED,
      plateNumber: Yup.string()
        .max(15, "最多只可輸入15個字元")
        .required(constants.REQUIRED),
      modelNumber: YupValidationConstants.STRING_REQUIRED,
      yearOfMfr: Yup.number()
        .typeError(constants.NUMBER)
        .min(1000, "年份不正確")
        .max(
          new Date().getFullYear(),
          `年份不應超過${new Date().getFullYear()}年`
        )
        .required(constants.REQUIRED),
      maintByMfr: Yup.boolean()
        .typeError(constants.REQUIRED)
        .required(constants.REQUIRED),
      ownerId: YupValidationConstants.STRING_REQUIRED,
      licenseExpiryDate: YupValidationConstants.DATE_REQUIRED,
      serviceCommenceDate: YupValidationConstants.DATE_REQUIRED,
      fleetId: YupValidationConstants.STRING_REQUIRED,
      fleetGroupId: YupValidationConstants.STRING_REQUIRED,
      takeOverTime: YupValidationConstants.DATE_REQUIRED,
      rentalStartTime: YupValidationConstants.DATE_TIME_REQUIRED,
      rentalEndTime: YupValidationConstants.DATE_FORMAT,
      morningShiftRent: YupValidationConstants.NUMBER_REQUIRED,
      nightShiftRent: YupValidationConstants.NUMBER_REQUIRED,
      rentalFeeToOwner: YupValidationConstants.NUMBER_REQUIRED,

      insExpDate: YupValidationConstants.DATE_FORMAT,
      insCushionAmount: YupValidationConstants.POSITIVE_NUMBER_FORMAT,
      ncd: Yup.number()
        .min(0, "最小為0%")
        .max(100, "最多為100%")
        .typeError(constants.NUMBER)
        .nullable()
        .transform((value, originalValue) =>
          typeof originalValue === "string" && originalValue.trim() === ""
            ? null
            : value
        ),

      throMaintenanceDate: YupValidationConstants.DATE_FORMAT,
      briefCheckingDate: YupValidationConstants.DATE_FORMAT,
      engineOil: YupValidationConstants.NUMBER_REQUIRED,
      gearboxlOil: YupValidationConstants.NUMBER_REQUIRED,
      differentialAssemblyOil: YupValidationConstants.NUMBER_REQUIRED,
      battery: YupValidationConstants.NUMBER_REQUIRED,
      tire: YupValidationConstants.NUMBER_REQUIRED,
      airConditioning: YupValidationConstants.NUMBER_REQUIRED,
      waterTank: YupValidationConstants.NUMBER_REQUIRED,
      waterPump: YupValidationConstants.NUMBER_REQUIRED,
      splash: YupValidationConstants.NUMBER_REQUIRED,
      starter: YupValidationConstants.NUMBER_REQUIRED,
      frontBrakePad: YupValidationConstants.NUMBER_REQUIRED,
      backBrakePad: YupValidationConstants.NUMBER_REQUIRED,
      brakeCylinder: YupValidationConstants.NUMBER_REQUIRED,
      sparkPlug: YupValidationConstants.NUMBER_REQUIRED,
      others: YupValidationConstants.NUMBER_REQUIRED,
      gpsInstalled: Yup.boolean().required(constants.REQUIRED),
    }),
    onSubmit: (values) => {
      const [
        morningDriverConflict,
        morningDriverName,
        morningDriverPlateNumber,
      ] = driverConflictChecker(
        morningDriverList[values.fleetGroupId],
        values.morningShiftDriverId,
        values.plateNumber
      );
      const [
        nightDriverConflict,
        nightDriverName,
        nightDriverPlateNumber,
      ] = driverConflictChecker(
        nightDriverList[values.fleetGroupId],
        values.nightShiftDriverId,
        values.plateNumber
      );

      if (morningDriverConflict || nightDriverConflict) {
        setDialogStatus({
          isOpen: true,
          type: "conflict",
          group,
          conflicts: [
            {
              conflict: morningDriverConflict,
              name: morningDriverName,
              plateNumber: morningDriverPlateNumber,
            },
            {
              conflict: nightDriverConflict,
              name: nightDriverName,
              plateNumber: nightDriverPlateNumber,
            },
          ],
          updateTaxiHandler,
        });
      } else {
        setDialogStatus({ isOpen: true, isLoading: true });
        updateTaxiHandler(values);
      }
    },
  });

  React.useEffect(() => {
    if (
      !isLoading &&
      (form.values.fleetId !== form.initialValues.fleetId ||
        form.values.fleetGroupId !== form.initialValues.fleetGroupId)
    ) {
      form.handleChange({
        target: { name: "morningShiftDriverId", value: "" },
      });
      form.handleChange({ target: { name: "nightShiftDriverId", value: "" } });
    }
    // eslint-disable-next-line
  }, [
    form.values.fleetId,
    form.values.fleetGroupId,
    form.initialValues.fleetId,
    form.initialValues.fleetGroupId,
    isLoading,
  ]);

  const [
    disabledOverlay,
    isLoadingDisableOverlay,
    enableHandler,
  ] = UserService.useDisabledReducer({
    isDisabled,
    id,
    type: "taxi",
  });

  const updateTaxiHandler = async () => {
    const editedForm = UserService.taxiFormat(form.values);
    if (!editedForm._id) delete editedForm._id;
    UtilsService.emptyToNull(editedForm);

    setDialogStatus({ isOpen: true, isLoading: true });
    const {
      isSuccess,
      isAllDone,
      isErrorOccur,
      errMsg,
      uploadStatus,
    } = await DocumentService.handleCreateUpdateSubmit({
      group,
      isCreatePage,
      form: editedForm,
      uploadFile: [
        { type: "taxi-contract", file: escrowContractImage },
        { type: "taxi-insurance", file: insPolicyDocumentImage },
        { type: "taxi-maintenance", file: inspectionScheduleDocuImage },
      ],
    });
    setDialogStatus({
      isOpen: true,
      isLoading: false,
      type,
      group,
      isSuccess,
      isAllDone,
      isErrorOccur,
      errMsg,
      uploadStatus,
      setRefreshCounter,
    });
    getAllTaxi();
    getAllDriver();
    getAllTaxiOwner();
  };

  if (isError) {
    return <Box>{isError}</Box>;
  }

  return (
    <ManagementContainer
      group={group}
      {...{
        form,
        type,
        isLoading,
        dialogStatus,
        setDialogStatus,
        isCreatePage,
        disabledOverlay,
        isLoadingDisableOverlay,
        enableHandler,
      }}
    >
      <GetData driver fleet fleetGroup taxi taxiOwner />
      <StyledCard>
        <h2>的士</h2>
        <Divider />
        <UploadAndDownloadDoc
          editable={isEditable}
          type="車主託管合約"
          id="escrowContract"
          isCreatePage={isCreatePage}
          fileKey={escrowContract}
          fileContent={escrowContractImage}
          setFileContent={setEscrowContractImage}
        />
        <Grid container spacing={4}>
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { label: "車牌號碼", id: "plateNumber" },
              { label: "車輛型號", id: "modelNumber" },
              { type: "select", label: "的士類型", id: "taxi_type" },
              { label: "出廠年份", id: "yearOfMfr" },
              { type: "select", label: "狀態", id: "taxi_status" },
            ]}
          />
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              {
                type: "component",
                component: (
                  <TrueOrFalseCheckBox
                    editable={isEditable}
                    form={form}
                    field={"maintByMfr"}
                    title="是否原廠保養"
                    trueWording="是"
                    falseWording="否"
                  />
                ),
                id: "maintByMfr",
              },
              {
                type: isEditable ? "select" : "text",
                label: "車主",
                id: isEditable ? "ownerId" : "ownerName",
              },
              { label: "VIN 號碼", id: "vinNum" },
              { type: "date", label: "牌照到期日", id: "licenseExpiryDate" },
            ]}
          />
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              {
                type: "date",
                label: "開始服務日期",
                id: "serviceCommenceDate",
              },
              { type: "date", label: "車輛接手時間", id: "takeOverTime" },
              {
                type: "datetime",
                label: "車輛起租時間(24小時格式)",
                id: "rentalStartTime",
              },
              { type: "date", label: "停運日期及時間", id: "rentalEndTime" },
            ]}
          />
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "dollar", label: "日更租金", id: "morningShiftRent" },
              { type: "dollar", label: "夜更租金", id: "nightShiftRent" },
              { type: "dollar", label: "支付車主租金", id: "rentalFeeToOwner" },
            ]}
          />
        </Grid>

        <h2>車隊</h2>
        <Divider />
        <Grid container spacing={4}>
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={
              isEditable
                ? [
                    { type: "select", label: "車隊", id: "fleetId" },
                    { type: "select", label: "車組", id: "fleetGroupId" },
                    {
                      type: "select",
                      label: "正日更司機",
                      id: "morningShiftDriverId",
                    },
                    {
                      type: "select",
                      label: "正夜更司機",
                      id: "nightShiftDriverId",
                    },
                  ]
                : [
                    { label: "車隊", id: "fleetName" },
                    { label: "車組", id: "fleetGroupName" },
                    {
                      type: "select",
                      label: "正日更司機",
                      id: "morningShiftDriverId",
                    },
                    {
                      type: "select",
                      label: "正夜更司機",
                      id: "nightShiftDriverId",
                    },
                  ]
            }
          />
        </Grid>
        <h2>保險</h2>
        <Divider />
        <UploadAndDownloadDoc
          editable={isEditable}
          type="保單文件"
          id="insPolicyDocument"
          isCreatePage={isCreatePage}
          fileKey={insPolicyDocument}
          fileContent={insPolicyDocumentImage}
          setFileContent={setInsPolicyDocumentImage}
        />
        <Grid container spacing={4}>
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { label: "保險公司", id: "insCompany" },
              { label: "保險種類", id: "typeOfIns" },
              { label: "管理人編號", id: "agentId" },
              { label: "管理人姓名 ", id: "agentName" },
            ]}
          />
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "date", label: "保單到期日", id: "insExpDate" },
              { type: "dollar", label: "保險墊款金額", id: "insCushionAmount" },
              { type: "percentage", label: "No Claim Deduction", id: "ncd" },
            ]}
          />
        </Grid>
        <h2>車驗</h2>
        <Divider />
        <UploadAndDownloadDoc
          editable={isEditable}
          type="小驗時間表"
          id="taxi-maintenance"
          isCreatePage={isCreatePage}
          fileKey={inspectionScheduleDocu}
          fileContent={inspectionScheduleDocuImage}
          setFileContent={setInspectionScheduleDocuImage}
        />
        <Grid container spacing={4}>
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "date", label: "大驗日期", id: "throMaintenanceDate" },
              { type: "date", label: "小驗日期", id: "briefCheckingDate" },
              { label: "行駛公里", id: "mileage", disabled: true },
              {
                type: "datetime",
                label: "最後行駛公里更新日期",
                id: "mileageLastUpdateDate",
                disabled: true,
              },
            ]}
          />
          <Grid item spacing={4}>
            各項目下一次維修里數
          </Grid>
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "number", label: "機油", id: "engineOil" },
              { type: "number", label: "波箱潤滑油", id: "gearboxlOil" },
              {
                type: "number",
                label: "尾牙油",
                id: "differentialAssemblyOil",
              },
              { type: "number", label: "電池", id: "battery" },
              { type: "number", label: "輪呔", id: "tire" },
            ]}
          />
          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "number", label: "空調", id: "airConditioning" },
              { type: "number", label: "水箱", id: "waterTank" },
              { type: "number", label: "水泵", id: "waterPump" },
              { type: "number", label: "電水撥", id: "splash" },
              { type: "number", label: "士撻", id: "starter" },
            ]}
          />

          <ManagementGrid
            editable={isEditable}
            form={form}
            fieldArr={[
              { type: "number", label: "頭迫力皮", id: "frontBrakePad" },
              { type: "number", label: "尾迫力皮", id: "backBrakePad" },
              { type: "number", label: "迫力泵", id: "brakeCylinder" },
              { type: "number", label: "火咀", id: "sparkPlug" },
              { type: "number", label: "呔泵及其他", id: "others" },
            ]}
          />
        </Grid>

        <h2>GPS</h2>
        <Divider />
        {isCreatePage ? (
          <SelectTextField
            sm={2}
            editable={true}
            form={form}
            fieldArr={[
              { type: "select", label: "GPS已安裝", id: "gpsInstalled" },
            ]}
          />
        ) : (
          <>
            <GPSBtn gps={{ ...location[plateNumber], taxiColor: taxi_type }} />
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item sm={2}>
                  <TextField
                    label="GPS運作"
                    value={
                      location &&
                      location[plateNumber] &&
                      location[plateNumber].GPSDateTime
                        ? "正常"
                        : "沒有資料"
                    }
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <TextField
                    label="GPS訊號最後更新日期"
                    value={
                      location &&
                      location[plateNumber] &&
                      location[plateNumber].GPSDateTime
                        ? UtilsService.showReadableDayTime(
                            location[plateNumber].GPSDateTime
                          )
                        : "沒有資料"
                    }
                    disabled
                  />
                </Grid>
                <SelectTextField
                  sm={2}
                  editable={isEditable}
                  form={form}
                  fieldArr={[
                    { type: "select", label: "GPS已安裝", id: "gpsInstalled" },
                  ]}
                />
              </Grid>
              <Grid item container spacing={4}>
                <Grid item sm={2}>
                  <TextField
                    label="車CAM運作"
                    value={
                      location &&
                      location[plateNumber] &&
                      !!location[plateNumber].IsDVROnline
                        ? "正常"
                        : "沒有資料"
                    }
                    disabled
                  />
                </Grid>
                <Grid item sm={2}>
                  <TextField
                    label="車CAM最新檢查日期"
                    value={
                      location &&
                      location[plateNumber] &&
                      location[plateNumber].requestDate
                        ? UtilsService.showReadableDayTime(
                            location[plateNumber].requestDate
                          )
                        : "沒有資料"
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <h2>交通事故</h2>
        <Divider />
        <Grid container spacing={4}>
          <Grid item sm={2}>
            <TextField
              label="交通事故總數量"
              value={
                isNaN(totalAccidentCount) ? "未有資料" : totalAccidentCount
              }
              disabled
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              label="交通事故未終結數量"
              value={
                isNaN(accidentOngoingCount) ? "未有資料" : accidentOngoingCount
              }
              disabled
            />
          </Grid>
        </Grid>
      </StyledCard>
    </ManagementContainer>
  );
};
const mapStateToProps = (state) => ({
  userRole: state.user.role,
  morningDriverList: state.driver.fullTimeByFleetGroup.morning,
  nightDriverList: state.driver.fullTimeByFleetGroup.night,
  location: state.taxi.location,
});
const mapDispatchToProps = (dispatch) => ({
  getAllTaxi: () => dispatch({ type: taxiActions.GET_ALL_TAXI }),
  getAllDriver: () => dispatch({ type: driverActions.GET_ALL_DRIVER }),
  getAllTaxiOwner: () =>
    dispatch({ type: taxiOwnerActions.GET_ALL_TAXI_OWNER }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientCreateUpdate);
