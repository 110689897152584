import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import SelectBox from "../SelectBox";
import NumberFormat from "react-number-format";
import { Grid } from "@material-ui/core";

const SelectTextField = (props) => {
  const { editable, fieldArr, form, sm } = props;
  let content;

  return fieldArr.map((field) => {
    const type = field.type && field.type.toLowerCase();
    let generalProps = {
      label: field.label,
      error: form.touched[field.id] && form.errors[field.id] ? true : false,
      helperText: form.touched[field.id] && form.errors[field.id],
      disabled:
        field.disabled === undefined
          ? editable
            ? false
            : true
          : field.disabled,
      fullWidth: true,
      style: { height: "70px" },
    };
    switch (type) {
      case "select":
        content = (
          <FormControl
            variant="outlined"
            margin="dense"
            fullWidth
            error={
              form.touched[field.id] && form.errors[field.id] ? true : false
            }
            disabled={
              field.disabled === undefined
                ? editable
                  ? false
                  : true
                : field.disabled
            }
          >
            <InputLabel id={`${field.id}-select-label`}>
              {field.label}
            </InputLabel>
            <SelectBox id={field.id} form={form} label={field.label} />
            <FormHelperText>
              {form.touched[field.id] &&
                form.errors[field.id] &&
                form.errors[field.id]}
            </FormHelperText>
          </FormControl>
        );
        break;
      case "component":
        content = React.cloneElement(field.component);
        break;
      case "date":
        content = (
          <NumberFormat
            {...form.getFieldProps(field.id)}
            {...generalProps}
            customInput={TextField}
            format="##/##/####"
            placeholder="DD/MM/YYYY"
          />
        );
        break;
      case "datetime":
        content = (
          <NumberFormat
            {...form.getFieldProps(field.id)}
            {...generalProps}
            customInput={TextField}
            format="##/##/#### ##:##"
            placeholder="DD/MM/YYYY HH:MM"
          />
        );
        break;
      case "time":
        content = (
          <NumberFormat
            {...form.getFieldProps(field.id)}
            {...generalProps}
            customInput={TextField}
            format="##:##"
            placeholder="HH:MM"
          />
        );
        break;
      case "phone":
        content = (
          <NumberFormat
            {...form.getFieldProps(field.id)}
            {...generalProps}
            customInput={TextField}
            format="#### ####"
          />
        );
        break;
      case "number":
      case "dollar":
      case "percentage":
        content = (
          <NumberFormat
            allowNegative={false}
            // {...form.getFieldProps(field.id)}
            id={field.id}
            name={field.id}
            onValueChange={(valuesObj) => {
              form.setValues({ ...form.values, [field.id]: valuesObj.value });
            }}
            onBlur={form.handleBlur}
            value={form.values[field.id]}
            customInput={TextField}
            placeholder={
              type === "dollar" ? "$" : type === "percentage" ? "%" : ""
            }
            prefix={type === "dollar" ? "$" : ""}
            suffix={type === "percentage" ? "%" : ""}
            thousandSeparator={
              type === "dollar" || type === "number" ? true : false
            }
            {...generalProps}
          />
        );
        break;
      case "outlined-text":
        content = (
          <TextField
            {...form.getFieldProps(field.id)}
            {...generalProps}
            type={field.type}
            margin="dense"
            variant="outlined"
          />
        );
        break;
      case "text":
      default:
        content = (
          <TextField
            {...form.getFieldProps(field.id)}
            {...generalProps}
            type={field.type}
          />
        );
    }

    return (
      <Grid key={field.id} item sm={sm || 12}>
        {content}
      </Grid>
    );
  });
};

export default SelectTextField;
