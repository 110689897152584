
export const fleetActions = {
    GET_ALL_FLEET: "GET_ALL_FLEET",
    UPDATE_FLEET: "UPDATE_FLEET",
    ERROR_FETCHING_FLEET: "ERROR_FETCHING_FLEET",

    UPDATE_DAYS_APPLY_LEAVE: "UPDATE_DAYS_APPLY_LEAVE",

    CREATE_FLEET: "CREATE_FLEET",
    ADD_NEW_FLEET: "ADD_NEW_FLEET",
    FAIL_CREATE_FLEET: "FAIL_CREATE_FLEET",

    RESET_FLEET_STATUS: "RESET_FLEET_STATUS",
}

