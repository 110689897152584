import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';

import Modal from '../../components/Modal';
import SelectTextField from '../../components/SelectTextField';
import { fleetActions, fleetGroupActions } from '../../redux/actions';
import StyledButton from '../../components/Button';

const FleetOverlay = ({ type, createFleet, createFleetGroup, fleetStatus, fleetGroupStatus, resetFleetStatus, resetFleetGroupStatus, setDialog, isCloseDialogNeeded, ...rest }) => {
    const isLoading = fleetStatus === "loading" || fleetGroupStatus === "loading";
    const isErrorOccur = fleetStatus === "error" || fleetGroupStatus === "error";

    const fleetForm = useFormik({
        initialValues:
            { name: "", fleetManagerId: "" }
        ,
        validationSchema: Yup.object({
            name: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('必須填寫'),
        }),
        onSubmit: (values) => {
            createFleet(values)
        }
    })
    const fleetGroupForm = useFormik({
        initialValues:
            { name: '', address: '', fleetId: '', operatorId: '', groupLeaderId: '' }
        ,
        validationSchema: Yup.object({
            name: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('必須填寫'),
            address: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('必須填寫'),
            fleetId: Yup.string()
                .required('必須選取'),
            groupLeaderId: Yup.string(),
            operatorId: Yup.string()
        }),
        onSubmit: (values) => {

            for (let key in values) {
                if (!values[key]) {
                    delete values[key]
                }
            }
            createFleetGroup(values)

        }
    })

    const closeDialog = () => {
        setDialog((prev) => ({ ...prev, isOpen: false }))
        fleetForm.handleReset()
        fleetGroupForm.handleReset()
        setTimeout(() => {
            resetFleetStatus()
            resetFleetGroupStatus()
            setDialog({ isOpen: false })
        }, 100)
    }

    React.useEffect(() => {
        if (isCloseDialogNeeded) {
            closeDialog()
        }
        // eslint-disable-next-line
    }, [isCloseDialogNeeded]
    )
    const form = {
        fleet: {
            value: fleetForm,
            handleSubmit: fleetForm.handleSubmit,
            handleCancel: () => {
                closeDialog()
                fleetForm.handleReset()
            }
        },
        fleetGroup: {
            value: fleetGroupForm,
            handleSubmit: fleetGroupForm.handleSubmit,
            handleCancel: () => {
                closeDialog()
                fleetGroupForm.handleReset()
            }
        }
    }

    const disabled = type === "fleet"
        ?
        fleetForm.values.name === ""
        :
        type === "fleetGroup" &&
        (fleetGroupForm.values.name === "" || fleetGroupForm.values.address === "" || fleetGroupForm.values.fleetId === "")


    return <Modal {...{ closeDialog, ...rest, isLoading, isErrorOccur }} >
        <form noValidate autoComplete="off">
            {type === "fleet" &&
                <SelectTextField
                    editable={true}
                    fieldArr={[{ label: "車隊名", id: "name", type: "text" },
                    { label: "管理人", id: "fleetManagerId", type: "select" }]}
                    form={fleetForm}
                />
            }

            {type === "fleetGroup" &&
                <SelectTextField
                    editable={true}
                    fieldArr={[{ label: "車組名", id: "name", type: "text" },
                    { label: "交更地址", id: "address", type: "text" },
                    { label: "車隊", id: "fleetId", type: "select" },
                    { label: "車組管理員", id: "groupLeaderId", type: "select" },
                    { label: "事務員", id: "operatorId", type: "select" }
                    ]}
                    form={fleetGroupForm} />
            }

            <Box pt={3} pb={1} display="flex" justifyContent="flex-end">
                <StyledButton background="white" variant="outlined" type="button" onClick={closeDialog}>取消</StyledButton>
                <Box ml={2}><StyledButton background="purple" disabled={disabled} onClick={type && form[type].handleSubmit} >新增</StyledButton></Box>
            </Box>
        </form>
    </Modal >
}

const mapStateToProps = (state) => {
    return ({
        fleetStatus: state.fleet.status,
        errMsg: state.fleet.errMsg || state.fleetGroup.errMsg,
        fleetGroupStatus: state.fleetGroup.status,
        isCloseDialogNeeded: state.fleet.isCloseDialogNeeded || state.fleetGroup.isCloseDialogNeeded,
    })
}
const mapDispatchToProps = (dispatch) => ({
    createFleet: (payload) => dispatch({ type: fleetActions.CREATE_FLEET, payload }),
    createFleetGroup: (payload) => dispatch({ type: fleetGroupActions.CREATE_FLEET_GROUP, payload }),
    resetFleetStatus: () => dispatch({ type: fleetActions.RESET_FLEET_STATUS }),
    resetFleetGroupStatus: () => dispatch({ type: fleetGroupActions.RESET_FLEET_GROUP_STATUS }),
})
export default connect(mapStateToProps, mapDispatchToProps)(FleetOverlay);

