import React from "react";
import Box from "@material-ui/core/Box";
import Modal from "../Modal";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import StyledButton from "../Button";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const PasswordCard = ({
  type,
  isLoading,
  isSuccess,
  isErrorOccur,
  setDialogStatus,
  newName,
  password,
  handleFinalSubmit,
  managerConflict,
  managerName,
  managerOriginalFleet,
  leaderConflict,
  leaderName,
  leaderOriginalGroup,
  operatorConflict,
  operatorName,
  operatorOriginalGroup,
  isCreatePage,
  ...rest
}) => {
  const history = useHistory();

  let title;
  switch (type) {
    case "create":
      title = "成功創建";
      break;
    case "reset":
      title = "成功重設密碼";
      break;
    case "update":
      title = "成功更新";
      break;
    case "conflict":
      title = "注意";
      break;
    case "unchanged":
      title = "未有更改";
      break;
    default:
      title = "請稍後";
  }

  console.log(rest.errMsg);
  const closeDialog = () => {
    if (isErrorOccur) {
      return setDialogStatus((prev) => ({ ...prev, isOpen: false }));
    }

    switch (type + "_" + isCreatePage) {
      case "create_true":
      case "copied_true":
        history.goBack();
        break;
      default:
        setDialogStatus((prev) => ({ ...prev, isOpen: false }));
    }

    setTimeout(() => {
      setDialogStatus({ isOpen: false });
    }, 500);
  };

  return (
    <Modal
      title={title}
      {...{
        closeDialog,
        isSuccess,
        isLoading,
        isErrorOccur,
        handleFinalSubmit,
        ...rest,
      }}
    >
      {isLoading && <Box my={2}>正在連接後台</Box>}

      {isSuccess && (
        <>
          {type === "create" && (
            <Box>
              <Box display="flex" justifyContent="center" mt={1} mb={2}>
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              </Box>
              <Box mt={1} style={{ color: "#9ba4b0" }}>
                已成功創建帳戶，請用臨時密碼登入
              </Box>
              <Text type="password">臨時密碼：{password}</Text>
            </Box>
          )}
          {type === "reset" && (
            <Box>
              <Box display="flex" justifyContent="center" mt={1} mb={2}>
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              </Box>
              <Box mt={1} style={{ color: "#9ba4b0" }}>
                已成功重設密碼，請用新密碼登入
              </Box>
              <Text type="password">新密碼：{password}</Text>
            </Box>
          )}
          {type === "update" && (
            <>
              <Box display="flex" justifyContent="center" mt={1} mb={2}>
                <img
                  src={`/assets/ic-success.svg`}
                  style={{ height: 70 }}
                  alt="success"
                />
              </Box>

              <Box mt={1} style={{ color: "#9ba4b0" }}>
                已更新資料
              </Box>
            </>
          )}
          {type === "copied" && (
            <Box style={{ color: "#9ba4b0" }}>已複製密碼</Box>
          )}
        </>
      )}

      {type === "conflict" && (
        <>
          {managerConflict && (
            <Box>
              {managerOriginalFleet}車隊經理原為{managerName}，{newName}
              將取代其職位
            </Box>
          )}
          {leaderConflict && (
            <Box>
              {leaderOriginalGroup}車組管理員原為{leaderName}，{newName}
              將取代其職位
            </Box>
          )}
          {operatorConflict && (
            <Box>
              {operatorOriginalGroup}事務員原為{operatorName}，{newName}
              將取代其職位
            </Box>
          )}

          <Box> 確定繼續？</Box>
        </>
      )}

      {type === "unchanged" && (
        <Box style={{ color: "#9ba4b0" }}>沒有資料需要更新</Box>
      )}

      <Box mt={2.5} mb={1} display="flex" justifyContent="flex-end">
        {!isLoading && (
          <Box>
            <StyledButton
              background={
                type === "conflict" || type === "reset" ? "white" : "purple"
              }
              variant={
                type === "conflict" || type === "reset"
                  ? "outlined"
                  : "contained"
              }
              onClick={closeDialog}
            >
              {type === "conflict" ? "取消" : "關閉"}
            </StyledButton>
          </Box>
        )}

        {type === "conflict" && (
          <Box ml={1}>
            <StyledButton background="purple" onClick={handleFinalSubmit}>
              確定繼續
            </StyledButton>
          </Box>
        )}

        {(type === "create" || type === "reset") && isSuccess && (
          <Box ml={1}>
            <CopyToClipboard
              text={password}
              onCopy={() =>
                setDialogStatus((prev) => ({
                  ...prev,
                  isLoading: false,
                  password: "",
                  type: "copied",
                }))
              }
            >
              <StyledButton background="purple">複製臨時密碼</StyledButton>
            </CopyToClipboard>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
