import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AppBar from "../../components/AppBar";
import Back from "../../components/Back";
import Card from "../../components/Card";
import GetData from "../../components/GetData";
import LoadingIndicator from "../../components/LoadingIndicator";
import PageContainer from "../../components/PageContainer";
import SelectTextField from "../../components/SelectTextField";
import StyledButton from "../../components/Button";
import { constants, YupValidationConstants } from "../../constants";
import { SpaceBetweenBox, RowAlignCenterBox } from "../../components/Box";
import { Divider } from "../../components/Divider";
import { Grid, TextField } from "@material-ui/core";
import { MultiUploadAndDownloadDoc } from "../../components/UploadAndDownloadDoc/multiple";
import { AccidentAndMainService } from "../../Service/AccidentMaintenanceService";
import { DocumentService } from "../../Service/DocumentService";
import { OverlayDialog } from "./OverlayDialog";
import { ManagementFetchData } from "../ClientListing/ManagementFetchData";
import { UtilsService } from "../../Service/UtilsService";
import { StyledDocBox } from "../../components/StyledProofDoc";
import { pageUIActions } from "../../redux/actions";
import DeleteDocModal from "../../components/DeleteDocModal";

const AccidentDetails = (props) => {
  const { accidentId } = useParams();
  const [fileContent, setFileContent] = useState([]);
  const [dialogStatus, setDialogStatus] = useState({ isOpen: false });
  const [refreshCounter, setRefreshCounter] = useState(0);
  const isCreatePage = props.type.toLowerCase() === "create";
  const [deleteDialog, setDeleteDialog] = React.useState({ isOpen: false });
  const [deleteList, setDeleteList] = React.useState([]);
  console.log(props.plateNumberList);
  const {
    userData: {
      _id = "",
      lastUpdateAt,
      fleet,
      fleetId = fleet || "",
      fleetGroup,
      fleetGroupId = fleetGroup || "",
      occurrenceDateTime,
      driver,
      accident_driver = driver || "",
      driver_name = props.driverList.find(
        (person) => person._id === accident_driver
      )?.name || "",
      taxi,
      title = "",
      accident_taxi = taxi ? taxi._id : "",
      taxi_plate_number = props.plateNumberList.find(
        (car) => car._id === accident_taxi
      )?.plateNumber || "",
      injured = false,
      status,
      accident_status = status || 1,
      remark,
      proofDoc = [],
    },
    isLoading,
    isError,
  } = accidentId
    ? ManagementFetchData({ group: "accident", id: accidentId, refreshCounter })
    : { userData: {}, isLoading: false, isError: false };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id,
      fleetId,
      fleetGroupId,
      date: UtilsService.showReadableDay(occurrenceDateTime),
      time: UtilsService.showReadableTime(occurrenceDateTime),
      title,
      accident_driver,
      driver_name,
      accident_taxi,
      taxi_plate_number,
      injured,
      accident_status,
      remark,
      proofDoc,
    },
    validationSchema: Yup.object({
      fleetId: YupValidationConstants.STRING_REQUIRED,
      fleetGroupId: YupValidationConstants.STRING_REQUIRED,
      date: YupValidationConstants.DATE_BEFORE_TODAY_REQUIRED,
      time: YupValidationConstants.TIME_REQUIRED,
      title: YupValidationConstants.STRING_REQUIRED,
      accident_driver: YupValidationConstants.STRING_REQUIRED,
      accident_taxi: YupValidationConstants.STRING_REQUIRED,
      injured: Yup.boolean()
        .typeError(constants.REQUIRED)
        .required(constants.REQUIRED),
      accident_status: YupValidationConstants.NUMBER_REQUIRED,
    }),
    onSubmit: async (values) => {
      const form = AccidentAndMainService.accidentFormat(values);
      setDialogStatus({ isOpen: true, isLoading: true });
      const {
        isSuccess,
        isAllDone,
        isErrorOccur,
        errMsg,
        uploadStatus,
        id,
      } = await DocumentService.handleCreateUpdateSubmit({
        group: "accident",
        isCreatePage,
        form,
        uploadFile:
          fileContent.length > 0
            ? fileContent.reduce(
                (acc, file) => [...acc, { type: "accidence", file }],
                []
              )
            : [],
      });
      if (isSuccess) {
        props.toggleRefreshFlag();
      }
      setDialogStatus({
        isOpen: true,
        isLoading: false,
        group: "accidence",
        isSuccess,
        isAllDone,
        isErrorOccur,
        errMsg,
        uploadStatus,
        setRefreshCounter,
        id,
      });
    },
  });

  useEffect(() => {
    setFileContent([]);
  }, [refreshCounter]);

  if (isError) {
    return <Box>{isError}</Box>;
  }

  return (
    <form onSubmit={form.handleSubmit} noValidate autoComplete="off">
      {isLoading && <LoadingIndicator />}
      <GetData fleet fleetGroup taxi driver />
      <AppBar>
        <SpaceBetweenBox>
          <RowAlignCenterBox>
            <Back />
            <Box ml={4}>
              <Typography variant="h5">
                {isCreatePage ? "新增意外" : "意外資料"}
              </Typography>
            </Box>
          </RowAlignCenterBox>

          <RowAlignCenterBox>
            <StyledButton background="purple" type="submit">
              {isCreatePage ? "新增" : "更新"}
            </StyledButton>
          </RowAlignCenterBox>
        </SpaceBetweenBox>
      </AppBar>
      <PageContainer>
        <Box mb={1}>
          <Card>
            <Typography variant="h5">意外</Typography>
            <Divider />
            <Grid container spacing={1}>
              <SelectTextField
                editable={true}
                sm={3}
                fieldArr={[
                  {
                    label: "車隊",
                    id: "fleetId",
                    type: "select",
                    disabled: !isCreatePage,
                  },
                  {
                    label: "車組",
                    id: "fleetGroupId",
                    type: "select",
                    disabled: !isCreatePage,
                  },
                  {
                    label: "車牌號碼",
                    id: isCreatePage ? "accident_taxi" : "taxi_plate_number",
                    type: isCreatePage ? "select" : "outlined-text",
                    disabled: !isCreatePage,
                  },
                  {
                    label: "司機",
                    id: isCreatePage ? "accident_driver" : "driver_name",
                    type: isCreatePage ? "select" : "outlined-text",
                    disabled: !isCreatePage,
                  },
                  {
                    label: "日期",
                    id: "date",
                    type: "date",
                    // disabled: !isCreatePage
                  },
                  {
                    label: "時間(24小時格式)",
                    id: "time",
                    type: "time",
                    // disabled: !isCreatePage,
                  },
                  {
                    label: "傷人",
                    id: "injured",
                    type: "select",
                  },
                  {
                    label: "狀態",
                    id: "accident_status",
                    type: "select",
                  },
                ]}
                form={form}
              />
              {lastUpdateAt && (
                <Grid item sm={3}>
                  <TextField
                    label="流程更新日期"
                    disabled
                    value={UtilsService.showReadableDayTime(lastUpdateAt)}
                  />
                </Grid>
              )}
            </Grid>
            <Box my={1}>
              <TextField
                fullWidth
                margin="dense"
                name="title"
                placeholder="標題"
                variant="outlined"
                error={form.touched.title && form.errors.title ? true : false}
                helperText={form.touched.title && form.errors.title}
                value={form.values.title}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
            </Box>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              multiline
              name="remark"
              rows={4}
              placeholder="備註"
              variant="outlined"
              value={form.values.remark}
              onChange={form.handleChange}
            />
          </Card>
        </Box>
        <Card>
          <Typography variant="h5">證明文件</Typography>
          <Divider />
          <MultiUploadAndDownloadDoc
            editable={true}
            type="證明文件"
            id={"accidence"}
            {...{ fileContent, setFileContent, refreshCounter }}
          />

          {proofDoc && proofDoc.length > 0 ? (
            proofDoc
              .slice()
              .map(
                (item, index) =>
                  item && (
                    <StyledDocBox
                      isDeleted={deleteList.includes(item._id)}
                      deleteId={item._id}
                      setDeleteDialog={setDeleteDialog}
                      key={item._id}
                      index={proofDoc.length - index}
                      name={item.name}
                      path={item.path}
                      uploadedAt={item.uploadedAt}
                      uploadedBy={item.uploadedBy && item.uploadedBy.name}
                    />
                  )
              )
          ) : (
            <Box color="#7C7C7C" mt={2} mb={1}>
              沒有文件
            </Box>
          )}
        </Card>
      </PageContainer>
      <DeleteDocModal
        {...deleteDialog}
        setDeleteDialog={setDeleteDialog}
        form={form}
        documentArr={form.values.proofDoc}
        documentArrName={"proofDoc"}
        setDeleteList={setDeleteList}
      />
      <OverlayDialog {...{ dialogStatus, setDialogStatus, isCreatePage }} />
    </form>
  );
};
const mapStateToProps = (state) => ({
  plateNumberList: state.taxi.list || [],
  driverList: state.driver.list || [],
});
const mapDispatchToProps = (dispatch) => ({
  toggleRefreshFlag: () =>
    dispatch({ type: pageUIActions.TOGGLE_REFRESH_FLAG, refresh: true }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccidentDetails);
