import React from "react";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  accidentStatusMatching,
  shiftMatching,
  taxiStatusMatching,
  taxiTypeMatching,
} from "../../match";

const objectToArray = (object) => {
  return Object.entries(object).map(([key, value]) => ({
    _id: key,
    name: value,
  }));
};
//need to change array to real fleetGroup or fleet
const SelectBox = ({
  id,
  fleet,
  fleetGroupByFleetId,
  form,
  label,
  superAdmin,
  fleetManager,
  groupLeader,
  operator,
  fullTimeDriverByFleetGroup,
  partTimeDriver,
  taxiOwner,
  taxiByFleetGroup,
}) => {
  const staff = {
    groupLeaderId: groupLeader,
    operatorId: operator,
  };

  let arr;
  switch (id) {
    case "role":
      arr = [
        { _id: "super_admin", name: "系統管理員" },
        { _id: "fleet_manager", name: "車隊經理" },
        { _id: "group_leader", name: "車組管理員" },
        { _id: "operator", name: "事務員" },
        { _id: "taxi_keeper", name: "大掌柜" },
      ];
      break;
    case "fleetManagerId":
      fleetManager.length > 0
        ? (arr = fleetManager)
        : (arr = [{ _id: "", name: "請先新增車隊經理" }]);
      break;
    case "groupLeaderId":
    case "operatorId":
      if (!form.values.fleetId) {
        arr = [{ _id: "", name: "請先選擇車隊" }];
      } else {
        const result = staff[id].filter(
          (person) => person.fleet && person.fleet._id === form.values.fleetId
        );
        result.length > 0
          ? (arr = result)
          : (arr = [
              {
                _id: "",
                name: `此車隊未有 ${
                  id === "groupLeaderId" ? "車組管理員" : "事務員"
                }`,
              },
            ]);
      }
      break;

    case "morningShiftDriverId":
    case "nightShiftDriverId":
      if (!form.values.fleetGroupId) {
        arr = [{ _id: "", name: "請先選擇車組" }];
      } else {
        let res;

        id === "morningShiftDriverId"
          ? (res =
              (fullTimeDriverByFleetGroup.morning &&
                fullTimeDriverByFleetGroup.morning[form.values.fleetGroupId]) ||
              [])
          : (res =
              (fullTimeDriverByFleetGroup.night &&
                fullTimeDriverByFleetGroup.night[form.values.fleetGroupId]) ||
              []);

        res.length > 0
          ? (arr = res)
          : (arr = [
              {
                _id: "",
                name: `此車隊未有 ${
                  id === "morningShiftDriverId" ? "正早更司機" : "正夜更司機"
                }`,
              },
            ]);
      }
      break;

    case "fleetId":
      arr = fleet;
      break;
    case "fleetGroupId":
      if (form.values.fleetId && fleetGroupByFleetId[form.values.fleetId]) {
        arr = fleetGroupByFleetId[form.values.fleetId];
      } else if (!form.values.fleetId) {
        arr = [{ _id: "", name: "請先選擇車隊" }];
      } else {
        arr = [{ _id: "", name: "沒有車組" }];
      }
      break;
    case "company_region":
    case "region":
      arr = [
        { _id: "hk", name: "港島" },
        { _id: "kowloon", name: "九龍" },
        { _id: "nt", name: "新界" },
      ];
      break;
    case "contact_relationship":
      arr = [
        { _id: "wife", name: "妻子" },
        { _id: "husband", name: "丈夫" },
        { _id: "son", name: "兒子" },
        { _id: "daughter", name: "女兒" },
        { _id: "others", name: "其它" },
      ];
      break;
    case "shift":
      arr = objectToArray(shiftMatching);
      break;
    case "injured":
      arr = [
        { _id: true, name: "有" },
        { _id: false, name: "無" },
      ];
      break;
    case "accident_status":
      arr = objectToArray(accidentStatusMatching);
      break;
    case "contract_status":
      arr = [
        { _id: "true", name: "有效" },
        { _id: "false", name: "無效" },
      ];
      break;
    case "englishLevel":
      arr = [
        { _id: "fluent", name: "流利" },
        { _id: "excellent", name: "優良" },
        { _id: "good", name: "良好" },
        { _id: "fair", name: "一般" },
      ];
      break;
    case "gpsInstalled":
    case "smoker":
      arr = [
        { _id: true, name: "是" },
        { _id: false, name: "否" },
      ];
      break;

    case "driver_status":
      arr = [
        { _id: "服務中", name: "服務中" },
        { _id: "停工", name: "停工" },
        { _id: "離職", name: "離職" },
      ];
      break;

    case "driver_priority":
      arr = [
        { _id: 1, name: "高" },
        { _id: 2, name: "中" },
        { _id: 3, name: "低" },
      ];
      break;
    case "ownerId":
      Array.isArray(taxiOwner) && taxiOwner.length > 0
        ? (arr = taxiOwner)
        : (arr = [{ _id: "", name: `系統未能找到任何車主` }]);
      break;
    case "taxi_status":
      arr = objectToArray(taxiStatusMatching);
      break;
    case "taxi_type":
      arr = objectToArray(taxiTypeMatching);
      break;
    case "accident_driver":
      arr = [];
      if (form.values.fleetId && form.values.fleetGroupId) {
        if (
          fullTimeDriverByFleetGroup.morning &&
          fullTimeDriverByFleetGroup.morning[form.values.fleetGroupId]
        ) {
          arr = arr.concat(
            fullTimeDriverByFleetGroup.morning[form.values.fleetGroupId]
          );
        }
        if (
          fullTimeDriverByFleetGroup.night &&
          fullTimeDriverByFleetGroup.night[form.values.fleetGroupId]
        ) {
          arr = arr.concat(
            fullTimeDriverByFleetGroup.night[form.values.fleetGroupId]
          );
        }
        if (partTimeDriver && partTimeDriver["morning"][form.values.fleetId]) {
          arr = arr.concat(partTimeDriver["morning"][form.values.fleetId]);
        }
        if (partTimeDriver && partTimeDriver["night"][form.values.fleetId]) {
          arr = arr.concat(partTimeDriver["night"][form.values.fleetId]);
        }
        if (arr.length === 0) {
          arr = [{ _id: "", name: `此車隊未有任何司機` }];
        }
      } else if (form.values.fleetId) {
        if (partTimeDriver && partTimeDriver["morning"][form.values.fleetId]) {
          arr = arr.concat(partTimeDriver["morning"][form.values.fleetId]);
        }
        if (partTimeDriver && partTimeDriver["night"][form.values.fleetId]) {
          arr = arr.concat(partTimeDriver["night"][form.values.fleetId]);
        }
        if (arr.length === 0) {
          arr = [{ _id: "", name: `此車隊未有任何司機` }];
        }
      } else {
        arr = [{ _id: "", name: `請先選取車隊` }];
      }

      break;
    case "accident_taxi":
      if (form.values.fleetGroupId && taxiByFleetGroup) {
        arr = taxiByFleetGroup[form.values.fleetGroupId]
          ? taxiByFleetGroup[form.values.fleetGroupId]
          : [{ _id: "", name: `此車組未有的士` }];
      } else if (!form.values.fleetGroupId) {
        arr = [{ _id: "", name: `請先選取車組` }];
      } else {
        arr = [{ _id: "", name: `未有的士` }];
      }

      break;
    default:
      arr = [];
      break;
  }

  const handleChange = (e) => {
    switch (id) {
      case "role":
        form.setValues({
          ...form.values,
          role: e.target.value,
          fleetId: "",
          fleetGroupId: "",
        });
        // form.setValues({ ...form.values, role: e.target.value, fleetId: "", fleetGroupId: "", groupLeaderId: "", operatorId: "" });
        break;
      case "fleetId":
        form.setValues({
          ...form.values,
          fleetId: e.target.value,
          fleetGroupId: "",
        });
        // form.setValues({ ...form.values, fleetId: e.target.value, fleetGroupId: "", groupLeaderId: "", operatorId: "" });
        break;
      default:
        form.setValues({ ...form.values, [e.target.name]: e.target.value });
    }
  };

  return (
    <Select
      labelId={`${id}-select-label`}
      id={`${id}-select`}
      name={id}
      onChange={handleChange}
      onBlur={form.handleBlur}
      value={form.values[id]}
      label={label}
    >
      {arr[0] && arr[0]._id !== "" && <MenuItem value={""}>未選取</MenuItem>}
      {arr.map((item) => (
        <MenuItem key={item._id} value={item._id}>
          {item.name || item.plateNumber}
          {(id === "morningShiftDriverId" || id === "nightShiftDriverId") &&
            item.driver &&
            item.driver.taxi &&
            item.driver.taxi.plateNumber &&
            ` (${item.driver.taxi.plateNumber})`}
          {id === "fleetManagerId" &&
            item.fleet &&
            item.fleet.name &&
            ` (${item.fleet.name})`}
          {["groupLeaderId", "operatorId"].includes(id) &&
            item.fleetGroup &&
            item.fleetGroup.name &&
            ` (${item.fleetGroup.name})`}
        </MenuItem>
      ))}
    </Select>
  );
};

const mapStateToProps = (state) => ({
  superAdmin: state.systemUser["super_admin"],
  fleetManager: state.systemUser["fleet_manager"],
  groupLeader: state.systemUser["group_leader"],
  operator: state.systemUser["operator"],

  fleet: state.fleet.list,
  fleetGroupByFleetId: state.fleetGroup.byFleetId,

  fullTimeDriverByFleetGroup: state.driver.fullTimeByFleetGroup,
  partTimeDriver: state.driver.partTimeByFleet,

  taxiOwner: state.taxiOwner.list,

  taxiByFleetGroup: state.taxi.byFleetGroup,
});
const mapDispatchToProps = (dispatch) => ({
  // getAllSystemUser: () => dispatch({ type: accountActions.GET_ALL_ACCOUNTS, role: 'system' }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectBox);
