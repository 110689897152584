export const rules = {
  super_admin: {
    static: [
      "disable:view",
      "system_user:update",
      "system_user:delete",
      "fleet:create",
      "fleet:delete",
      "fleet_group:create",
      "fleet_group:update",
      "fleet_group:delete",
      "taxi:create",
      "taxi:update",
      "taxi:delete",
      "taxi_owner:create",
      "taxi_owner:update",
      "taxi_owner:reset",
      "taxi_owner:delete",
      "driver:create",
      "driver:update",
      "driver:reset",
      "driver:delete",
      "accident:create",
      "maintenance:update",
    ],
  },
  fleet_manager: {
    static: [
      "fleet_group:update",
      "driver:update",
      "driver:reset",
      "roster:edit",
      "roster:freeze",
      "roster:check_part_time",
    ],
  },
  group_leader: {
    static: ["roster:edit", "roster:freeze", "roster:check_part_time"],
  },
  operator: {
    static: ["roster:edit", "roster:check_part_time"],
  },
};
