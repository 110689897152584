import React from "react";
import { Box } from "@material-ui/core";

export const RentalInfoBox = ({
  title,
  amount,
  leftMargin,
  noMargin,
  ...rest
}) => (
    <Box
      mr={noMargin ? 0 : leftMargin ? 0 : 2}
      ml={noMargin ? 0 : leftMargin ? 4 : 0}
      {...rest}
    >
      <Box style={{
        color: '#4a4a4a', fontSize: 12,
        whiteSpace: "nowrap",
      }} mb={1.2}>{title}</Box>
      <Box style={{ fontWeight: "700" }}>${amount ? amount.toLocaleString() : 0}</Box>
    </Box>
  );

export const RentalInfoBoxPure = ({ title, amount, style }) => (
  <div style={style}>
    <div style={{ marginBottom: 1.2 }}>{title}</div>
    <div style={{ fontWeight: "700" }}>${amount ? amount.toLocaleString() : 0}</div>
  </div>
);
