import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";

import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import ManagementContainer from "../ManagementContainer";
import { ManagementGrid } from "../ManagementGrid";
import { ManagementFetchData } from "../ManagementFetchData";
import { Divider } from "../../../components/Divider";
import { UserService } from "../../../Service/UserService";
import SelectTextField from "../../../components/SelectTextField";
import Card, { StyledCard } from "../../../components/Card";
import { Box } from "@material-ui/core";
import { taxiOwnerActions, pageUIActions } from "../../../redux/actions";
import { constants } from "../../../constants";
import Table from "../../../components/Table";
import { columns } from "../index";
import GetData from "../../../components/GetData";

const ClientCreateUpdate = (props) => {
  const {
    type,
    group,
    origin,
    tabIndex,
    setTabIndex,
    getAllTaxiOwner,
    taxiByOwnerId,
    updateOriginalOwnerTaxiTable,
    taxiInfo
  } = props;
  const { id } = useParams();
  const isCreatePage = props.type.toLowerCase() === "create";
  const [dialogStatus, setDialogStatus] = useState({
    isOpen: false,
    password: "",
  });
  const [refreshCounter, setRefreshCounter] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);

  const tabs = ["基本信息", "的士"];
  const isTabSelectorShow = props.type.toLowerCase() !== "create";

  React.useEffect(() => {
    if (
      isTabSelectorShow &&
      origin !== `owner_taxi-${id}` &&
      taxiByOwnerId[id]
    ) {
      const taxiColorMatch = {
        red: "redTaxiCount",
        green: "greenTaxiCount",
        blue: "blueTaxiCount",
      };
      const taxiCount = taxiByOwnerId[id].reduce(
        (acc, curr) => {
          if (curr.type) {
            acc[taxiColorMatch[curr.type]] += 1;
          }
          if (curr.gpsInstalled) {
            acc.gpsInstalled += 1;
          }
          acc.taxiTotalCount += 1;
          return acc;
        },
        {
          redTaxiCount: 0,
          greenTaxiCount: 0,
          blueTaxiCount: 0,
          gpsInstalled: 0,
          taxiTotalCount: 0,
        }
      );
      updateOriginalOwnerTaxiTable(taxiCount, taxiByOwnerId[id], `owner_taxi-${id}`);
    } else if (!taxiByOwnerId[id]) {
      const taxiCount={
        redTaxiCount: 0,
        greenTaxiCount: 0,
        blueTaxiCount: 0,
        gpsInstalled: 0,
        taxiTotalCount: 0,
      }
      updateOriginalOwnerTaxiTable(taxiCount, null, `owner_taxi-${id}`);
    }
  }, [origin, isTabSelectorShow, id, taxiByOwnerId, updateOriginalOwnerTaxiTable]);

  const {
    userData: {
      isDisabled,
      _id,
      username,
      name,
      phone,
      role = group,
      taxi_owner: {
        hkid ,
        email,
        secondaryEmail,
        secondaryPhone,
        fax,
        address ,
        region,
        company: {
          name: company_name,
          br: company_br,
          phone: company_phone,
          fax: company_fax,
          primaryContact: {
            name: company_contact,
            phone: company_contact_phone,
            email: company_contact_email,
          } = {},
          secondaryContact: {
            name: company_second_contact,
            phone: company_second_contact_phone,
            email: company_second_contact_email,
          } = {},
          website: company_website,
          address: company_address,
          region: company_region,
        } = {},
      } = {},
    } = {},
    isLoading,
    isError,
  } = id
    ? ManagementFetchData({ group, id, refreshCounter })
    : { userData: {}, isLoading: false, isError: false };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id:_id||"",
      username:username||"",
      name:name||"",
      hkid:hkid||"",
      email:email||"",
      secondaryEmail:secondaryEmail||"",
      phone:phone||"",
      role,
      secondaryPhone:secondaryPhone||"",
      fax:fax||"",
      address:address||"",
      region:region||"",
      company_name:company_name||"",
      company_br:company_br||"",
      company_phone:company_phone||"",
      company_fax:company_fax||"",
      company_contact:company_contact||"",
      company_contact_phone:company_contact_phone||"",
      company_contact_email:company_contact_email||"",
      company_second_contact:company_second_contact||"",
      company_second_contact_phone:company_second_contact_phone||"",
      company_second_contact_email:company_second_contact_email||"",
      company_website:company_website||"",
      company_address:company_address||"",
      company_region:company_region||""
    },
    validationSchema: Yup.object({
      username: Yup.string().required(constants.REQUIRED),
      name: Yup.string()
        .required(constants.REQUIRED)
        .max(15, constants.NAME_MAX_CAP),
      email: Yup.string().email(constants.EMAIL).nullable(),
      secondaryEmail: Yup.string().email(constants.EMAIL).nullable(),
      company_contact_email: Yup.string().email(constants.EMAIL).nullable(),
      company_second_contact_email: Yup.string()
        .email(constants.EMAIL)
        .nullable(),
    }),
    onSubmit: async (form) => {
      const response = await UserService.createClient(
        form,
        setDialogStatus,
        type,
        group,
        isCreatePage,
        setRefreshCounter
      );
      response && getAllTaxiOwner();
    },
  });

  const [
    disabledOverlay,
    isLoadingDisableOverlay,
    enableHandler,
  ] = UserService.useDisabledReducer({
    isDisabled,
    id,
    type: "taxi_owner",
  });

  if (isError) {
    return <Box>{isError}</Box>;
  }
  
  return (
    <ManagementContainer
      group={group}
      {...{
        form,
        type,
        isLoading,
        dialogStatus,
        setDialogStatus,
        isTabSelectorShow,
        tabs,
        tabIndex,
        setTabIndex,
        isCreatePage,
        disabledOverlay,
        isLoadingDisableOverlay,
        enableHandler,
      }}
    >
      <GetData taxi taxiOwner />
      {tabIndex === 0 ? (
        <StyledCard>
          <h2>車主</h2>
          <Divider />
          <Grid container spacing={4}>
            <ManagementGrid
              editable={true}
              form={form}
              fieldArr={[
                { label: "姓名", id: "name" },
                { label: "身份證", id: "hkid" },
                { label: "登入名稱", id: "username", disabled: !isCreatePage },
                { label: "Email", id: "email" },
                { label: "第二Email", id: "secondaryEmail" },
              ]}
            />
            <ManagementGrid
              editable={true}
              form={form}
              fieldArr={[
                { type: "phone", label: "聯絡號碼", id: "phone" },
                { type: "phone", label: "第二聯絡號碼", id: "secondaryPhone" },
                { type: "phone", label: "Fax號碼", id: "fax" },
              ]}
            />

            <Grid item container spacing={4}>
              <SelectTextField
                editable={true}
                form={form}
                sm={4}
                fieldArr={[{ label: "地址", id: "address" }]}
              />
              <SelectTextField
                editable={true}
                form={form}
                sm={2}
                fieldArr={[{ type: "select", label: "地區", id: "region" }]}
              />
            </Grid>
          </Grid>

          <h2>公司 *非必填</h2>
          <Divider />
          <Grid container spacing={4}>
            <ManagementGrid
              editable={true}
              form={form}
              fieldArr={[
                { label: "公司名", id: "company_name" },
                { label: "商業登記號碼", id: "company_br" },
                { type: "phone", label: "公司電話號碼", id: "company_phone" },
                { type: "phone", label: "公司FAX號碼", id: "company_fax" },
              ]}
            />
            <ManagementGrid
              editable={true}
              form={form}
              fieldArr={[
                { label: "主要聯絡人", id: "company_contact" },
                {
                  type: "phone",
                  label: "主要聯絡人號碼",
                  id: "company_contact_phone",
                },
                { label: "主要聯絡人Email", id: "company_contact_email" },
              ]}
            />

            <ManagementGrid
              editable={true}
              form={form}
              fieldArr={[
                { label: "第二聯絡人", id: "company_second_contact" },
                {
                  type: "phone",
                  label: "第二聯絡人號碼",
                  id: "company_second_contact_phone",
                },
                {
                  label: "第二聯絡人Email",
                  id: "company_second_contact_email",
                },
              ]}
            />

            <Grid item container spacing={4}>
              <SelectTextField
                editable={true}
                sm={2}
                form={form}
                fieldArr={[{ label: "公司網站", id: "company_website" }]}
              />
              <SelectTextField
                editable={true}
                form={form}
                sm={4}
                fieldArr={[{ label: "地址", id: "company_address" }]}
              />
              <SelectTextField
                editable={true}
                form={form}
                sm={2}
                fieldArr={[
                  { type: "select", label: "地區", id: "company_region" },
                ]}
              />
            </Grid>
          </Grid>
        </StyledCard>
      ) : (
        <>
          <Box mt={1} mb={1}>
            <Card>
              <h2>分類</h2>
              <Divider />
              <Box display="flex" mb={2}>
                <Box marginX={3}>
                  <Box fontWeight="700">紅的</Box>
                  <Box>{taxiInfo.redTaxiCount}</Box>
                </Box>{" "}
                <Box marginX={3}>
                  <Box fontWeight="700">綠的</Box>
                  <Box>{taxiInfo.greenTaxiCount}</Box>
                </Box>{" "}
                <Box marginX={3}>
                  <Box fontWeight="700">藍的</Box>
                  <Box>{taxiInfo.blueTaxiCount}</Box>
                </Box>
                <Box marginX={3}>
                  <Box fontWeight="700">的士總數</Box>
                  <Box>{taxiInfo.taxiTotalCount}</Box>
                </Box>
                <Box marginX={3}>
                  <Box fontWeight="700">GPS安裝</Box>
                  <Box>{taxiInfo.gpsInstalled}</Box>
                </Box>
              </Box>
            </Card>
          </Box>
          <Table
            columns={columns.taxi || []}
            pathname={"/taxi/"}
            selectBy="_id"
            component={"owner-taxi"}
            origin={`owner_taxi`}
          />
        </>
      )}
    </ManagementContainer>
  );
};
const mapStateToProps = (state) => ({
  origin: state.ui.origin,
  taxiByOwnerId: state.taxi.byTaxiOwnerId,
  tabIndex: state.ui.tabIndex,
  taxiInfo:state.ui.ownerTaxi?.taxiInfo||{}
});
const mapDispatchToProps = (dispatch) => ({
  getAllTaxiOwner: () =>
    dispatch({ type: taxiOwnerActions.GET_ALL_TAXI_OWNER }),
  updateOriginalOwnerTaxiTable: (taxiInfo,rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_OWNER_TAXI_TABLE, taxiInfo,rows, origin }),
  setTabIndex: (tabIndex) =>
    dispatch({ type: pageUIActions.UPDATE_TAB_INDEX, tabIndex }),
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientCreateUpdate);
