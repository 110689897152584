import React from "react";
import Box from "@material-ui/core/Box";
import StyledButton from "../Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

const DeleteModal = ({
  isOpen,
  name,
  deleteId,
  setDeleteDialog,

  form,
  documentArr,
  documentArrName,
  setDeleteList,
}) => {
  const closeDialog = React.useCallback(() => {
    setDeleteDialog((prev) => ({ ...prev, isOpen: false }));
    setTimeout(() => setDeleteDialog({ isOpen: false }), 500);
  }, [setDeleteDialog]);

  const handleDelete = () => {
    const newDocList = documentArr.slice();
    const index = newDocList.findIndex((item) => item._id === deleteId);
    newDocList.splice(index, 1);
    form.handleChange({
      target: { name: documentArrName, value: newDocList },
    });
    setDeleteList((prev) => `${prev && prev + ` `}${deleteId}`);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{ style: { minWidth: "338px" } }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Box style={{ fontWeight: "bold" }}>刪除檔案</Box>
        </DialogTitle>
        <IconButton style={{ margin: "0 3px 3px 0" }} onClick={closeDialog}>
          <ClearIcon />
        </IconButton>
      </Box>
      <DialogContent>
        {
          <>
            <Box>
              是否移除 {name} 檔案？ <br />
              確認後請按右上角「更新」儲存。
            </Box>
            <Box pt={3} pb={1} display="flex" justifyContent="flex-end">
              <StyledButton
                background="white"
                variant="outlined"
                type="button"
                onClick={closeDialog}
              >
                取消
              </StyledButton>
              <Box ml={2}>
                <StyledButton
                  background="purple"
                  onClick={() => {
                    handleDelete();
                    closeDialog();
                  }}
                >
                  確認
                </StyledButton>
              </Box>
            </Box>
          </>
        }
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
