export const roleMatching = {
  super_admin: "系統管理員",
  fleet_manager: "車隊經理",
  group_leader: "車組管理員",
  operator: "事務員",
  taxi_owner: "車主",
  driver: "司機",
  taxi: "的士",
  taxi_keeper: "大掌柜",
};

export const systemRoleMatching = {
  super_admin: "系統管理員",
  fleet_manager: "車隊經理",
  group_leader: "車組管理員",
  operator: "事務員",
};

export const shiftMatching = {
  night: "正夜更",
  morning: "正早更",
  re_morning: "替早更",
  re_night: "替夜更",
  special: "特更",
};

export const documentMatching = {
  "driver-image": "的士司機證",
  "driver-contract": "司機合約",
  "taxi-contract": "車主託管合約",
  "taxi-insurance": "保單文件",
  "taxi-maintenance": "小驗時間表",
};

export const driverStatusMatching = {
  WORKING: "服務中",
  STOPPED: "停工",
  RESIGNED: "離職",
};

export const taxiTypeMatching = {
  red: "紅的",
  green: "綠的",
  blue: "藍的",
};

export const taxiStatusMatching = {
  OPERATING: "營運",
  STOPPED: "停擺",
  ONHOLD: "暫停",
  MAINTENANCE: "維修",
  OUTSOURCING: "外判",
  SPECIAL: "特更",
};

export const accidentStatusMatching = {
  1: "建立檔案",
  2: "索取和解協議書",
  3: "遞交CLAIM FORM",
  4: "索取口供紙",
  5: "擬檢控通知書",
  6: "索取法庭傳票",
  7: "索取罰款收據",
  8: "索償中",
  9: "結束CASE",
  // ONGOING: "進行中",
  // RESOLVED: "結束",
};

export const groupName = {
  taxi_owner: "車主",
  driver: "司機",
  taxi: "的士",
};

export const rentalStatusMatching = {
  PENDING: "未提交",
  SUBMITTED: "已提交，未凍結",
  POSTED: "已凍結",
};

export const maintenanceMatching = {
  engineOil: "機油",
  gearboxlOil: "波箱潤滑油",
  differentialAssemblyOil: "尾牙油",
  battery: "電池",
  tire: "輪呔",
  airConditioning: "空調",
  waterTank: "水箱",
  waterPump: "水泵",
  splash: "電水撥",
  starter: "士撻",
  frontBrakePad: "頭迫力皮",
  backBrakePad: "尾迫力皮",
  brakeCylinder: "迫力泵",
  sparkPlug: "火咀",
  others: "呔泵及其他",
};
