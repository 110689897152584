import React from 'react';
import { connect } from 'react-redux';

import {  makeStyles } from '@material-ui/core';
import { persistRentalActions } from '../../../redux/actions';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
    container: {
        display: "flex",
        justifyContent: "flex-end"
    },
})

const DriverSelect = ({ 
    shift,
    index,
    driverId,
    handleDriverChange,
    fleetId,
    date,
    _id,
    driverList
}) => {
    const classes = useStyles();
    const { fleetGroup } = useParams();

    if (!fleetId) {
        return "請選擇"
    }

console.log(driverList)
    return (
      <Box className={classes.container}>
        <Autocomplete
          options={driverList.ids}
          getOptionLabel={(option) => driverList.labels[option]||""}
          style={{ minWidth: 170 }}
          noOptionsText="沒有相關司機"
          value={driverId}
          onChange={(e, newValue) => {
            handleDriverChange(newValue, index, shift, fleetGroup, date, _id);
          }}
          renderInput={(params) => <TextField {...params} label="司機" margin="dense" variant="outlined" />}
        />
        {/* {shift === 0 ?
                <Select
                    className={classes.menuItem}
                    displayEmpty
                    variant="outlined"
                    margin="dense"
                    value={driverId || ""}
                    onChange={(e) => handleDriverChange(e, index, shift, fleetGroup, date, _id)}>
                    <MenuItem value={""}>請選擇</MenuItem>
                    {partTimeDriver && partTimeDriver['morning'][fleetId] && partTimeDriver['morning'][fleetId].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(替日)"}</MenuItem>)}
                    {fullTimeDriver && fullTimeDriver['morning'][fleetGroup] && fullTimeDriver['morning'][fleetGroup].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(正日)"}</MenuItem>)}
                    {partTimeDriver && partTimeDriver['night'][fleetId] && partTimeDriver['night'][fleetId].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(替夜)"}</MenuItem>)}
                    {fullTimeDriver && fullTimeDriver['night'][fleetGroup] && fullTimeDriver['night'][fleetGroup].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(正夜)"}</MenuItem>)}
                </Select>

                :

                <Select
                    className={classes.menuItem}
                    displayEmpty
                    variant="outlined"
                    margin="dense"
                    value={driverId || ""}
                    onChange={(e) => handleDriverChange(e, index, shift, fleetGroup, date, _id)}>
                    <MenuItem value={""}>請選擇</MenuItem>
                    {partTimeDriver && partTimeDriver['night'][fleetId] && partTimeDriver['night'][fleetId].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(替夜)"}</MenuItem>)}
                    {fullTimeDriver && fullTimeDriver['night'][fleetGroup] && fullTimeDriver['night'][fleetGroup].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(正夜)"}</MenuItem>)}
                    {partTimeDriver && partTimeDriver['morning'][fleetId] && partTimeDriver['morning'][fleetId].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(替日)"}</MenuItem>)}
                    {fullTimeDriver && fullTimeDriver['morning'][fleetGroup] && fullTimeDriver['morning'][fleetGroup].map(driver => <MenuItem key={driver._id} value={driver._id}>{driver.name + "(正日)"}</MenuItem>)}
                </Select>} */}
      </Box>
    );
}
const mapStateToProps = (state) => ({
    partTimeDriver: state.driver.partTimeByFleet,
    fullTimeDriver: state.driver.fullTimeByFleetGroup,

})
const mapDispatchToProps = (dispatch) => ({
    handleDriverChange: (value, index, shift, fleetGroup, date, _id) => { dispatch({ type: persistRentalActions.ON_CHANGE_TAXI_CARD_DRIVER, value, shift, index, fleetGroup, date, _id }) }
})
export default connect(mapStateToProps, mapDispatchToProps)(DriverSelect);
