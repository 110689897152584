import React from "react";
import { connect } from "react-redux";
import { persistRentalActions } from "../../../../redux/actions";
import { StyledTextBox } from "../../../../components/StyledTextBox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const DriverSelect = ({
  value,
  index,
  shift,
  fleetId,
  fleetGroup,
  date,
  handleChangeDriver,

  morningDeductionDriver,
  nightDeductionDriver,
  driverList,
}) => {
  if (!fleetId) {
    return "請選擇";
  }

  return (
    <StyledTextBox label="司機">
      <Autocomplete
        key={value}
        options={driverList[shift === 0 ? "morningIds" : "nightIds"]}
        getOptionDisabled={(option) =>
          option === value
            ? false
            : shift === 0
            ? morningDeductionDriver.includes(option)
            : nightDeductionDriver.includes(option)
        }
        getOptionLabel={(option) => driverList.labels[option] || ""}
        noOptionsText="沒有相關司機"
        value={value}
        onChange={(e, newValue) => {
          handleChangeDriver({
            value: newValue,
            shift,
            index,
            fleetGroup,
            date,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            placeholder="請選擇"
            variant="outlined"
          />
        )}
      />
    </StyledTextBox>
  );
};
const mapStateToProps = (state) => {
  return {
    partTimeDriver: state.driver.partTimeByFleet,
    fullTimeDriver: state.driver.fullTimeByFleetGroup,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChangeDriver: (payload) => {
    dispatch({
      type: persistRentalActions.ON_CHANGE_DEDUCTION_DRIVER,
      ...payload,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(DriverSelect);
