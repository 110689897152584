import React from 'react';
import "../roster.css";
import moment from "moment";
import { connect } from "react-redux";
import StyledButton from "../../../components/Button";

const PrintBtnAndLayout = ({
  currentGroupName,
  driverShift,
  selector,
  week,
  roster,
  replacement
}) => {
  const handlePrint = () => {
    const newTitle = `${currentGroupName}${moment(selector.from).format(
      "_YYYYMMDD_"
    )}${selector.shift === "morning" ? "日更" : "夜更"}`.replace(/\s/g, "");
    const newElem = document.getElementById("roster-print");
    const newWin = window.open("");
    newWin.document.write(newElem.outerHTML);
    newWin.document.write(
      '<link rel="stylesheet" type="text/css" href="/roster.css">'
    );
    newWin.document.title = newTitle;
    setTimeout(() => newWin.print(), 10);
  };

  return (
    <>
      <div>
        <StyledButton background="paleGrey" onClick={handlePrint}>
          打印
        </StyledButton>
      </div>
      <div
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        id="roster-print"
      >
        <div>
          {currentGroupName} {selector.shift === "morning" ? "日更" : "夜更"}
        </div>
        <table className="table-style border">
          <thead>
            <tr>
              <th className="border"> </th>
              <th className="border">車牌號碼</th>
              {week.map((column) => (
                <th key={column.label} className="border">
                  {column.date.format("D/M")}
                  <br />
                  {column.label}
                  <br />
                  缺更：{column.vacancy}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {roster.map((row, index) => (
              <tr key={index}>
                <td className="border">{index + 1}</td>
                <td className="border">{row.plateNumber}</td>
                {week.map((column, index) => {
                  const date = moment(selector.from)
                    .add(index, "d")
                    .format("YYYY-MM-DD");
                  const replacementDriverId =
                    replacement[date] &&
                    replacement[date].data &&
                    Object.keys(replacement[date].data).find(
                      (driverId) =>
                        replacement[date].data[driverId].taxi._id === row._id &&
                        replacement[date].data[driverId].status === "ACCEPTED"
                    );
                  return (
                    <td
                      key={index}
                      className="border"
                      style={{ textAlign: "center" }}
                    >
                        {
                            replacementDriverId
                            ? replacement[date].data[replacementDriverId].driver.name + "(替)"
                            :
                            row?.["leaves"]?.[date]?.status === "PENDING"
                            ? "[待批假]"
                            :
                            (row?.leaves?.[date] ||
                            row?.pendingUnits?.[date]||
                            row?.[driverShift]?.driver?.dayoff?.includes(index === 6 ? 0 : index + 1) ||
                            !row?.[driverShift] ||
                            !row?.[driverShift]?.driver )?
                            "-":
                            row[driverShift].name
                        }
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
    roster: state.roster.list,
    selector: state.roster.selector,
    week: state.roster.week,
    replacement: state.roster.replacement || {},
  });

export default connect(mapStateToProps, null)(PrintBtnAndLayout)