import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

export default withStyles((props) => ({
  root: {
    flex: 1,
    overflowY: (props) => (props.scroll ? props.scroll : "auto"),
    marginTop: 65,
    padding: props.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}))(Box);
