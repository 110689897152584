import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    grey: {
        color: "#c6c9cb"
    }
});

const RentalTable = ({ rentalInfo }) => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {['車牌號碼', '日更司機', '日更實付租金', '夜更司機', '夜更實付租金', '總實付租金'].map
                            (elem => <TableCell key={elem}>{elem}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {Object.keys(rentalInfo).length > 0 &&
                        Object.keys(rentalInfo).map((plateNumber, index) => {
                            const { isMorningAttended, morningDriver, morningPaid, isNightAttended, nightDriver, nightPaid } = rentalInfo[plateNumber]
                            return (<TableRow key={index}>
                                <TableCell>{plateNumber}</TableCell>
                                <TableCell className={!isMorningAttended ? classes.grey : undefined}>{isMorningAttended ? morningDriver || "-" : "缺勤"}</TableCell>
                                <TableCell className={!isMorningAttended ? classes.grey : undefined}>{isMorningAttended ? morningPaid : 0}</TableCell>
                                <TableCell className={!isNightAttended ? classes.grey : undefined}>{isNightAttended ? nightDriver || "-" : "缺勤"}</TableCell>
                                <TableCell className={!isNightAttended ? classes.grey : undefined}>{isNightAttended ? nightPaid : 0}</TableCell>
                                <TableCell className={(!isMorningAttended && !isNightAttended) ? classes.grey : undefined}>{(isMorningAttended ? morningPaid : 0) + (isNightAttended ? nightPaid : 0)}</TableCell>
                            </TableRow>)


                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const mapStateToProps = (state) => ({
})


export default connect(mapStateToProps)(RentalTable);