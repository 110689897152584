import React, { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import PrintBtnAndLayout from "./Elements/PrintBtnAndLayout";
import ShiftSelector from "./Elements/ShiftSelector";
import WeekSelector from "./Elements/WeekSelector";
import RosterCell from "./Elements/RosterCell";
import moment from "moment";

import { checker } from "../../permission";
import { UiService } from "../../Service/UiService";
import { rosterActions } from "../../redux/actions";
import ReplacementDriverList from "./ReplacementDriverList";
import LoadingIndicator from "../../components/LoadingIndicator";
import Back from "../../components/Back";
import StyledButton from "../../components/Button";
import { RosterKeywordSearch } from "../../components/KeywordSearch/RosterKeywordSearch";
import { SpaceBetweenBox, RowAlignCenterBox } from "../../components/Box";
import KeywordSearch from "../../components/KeywordSearch";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import AppBar from "../../components/AppBar";
import GetData from "../../components/GetData";

import FreezeOverlay from "./Overlay/FreezeOverlay";
import FullTimeDriverOverlay from "./Overlay/FullTimeDriverOverlay";
import PartTimeDriverOverlay from "./Overlay/PartTimeDriverOverlay";
import "./roster.css";

const isRosterShowPastRecord = false;

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  container: {
    overflowX: "auto",
    backgroundColor: "#F8F8F8",
    height: "calc(100% - 52px)",
  },
});

const Roster = ({
  role,
  fleetGroupList,
  getRoster,
  roster,
  isReady,
  isLoading,
  selector,
  todayIsPosted,
  week,
  publicHoliday,
}) => {
  const classes = useStyles();
  const { groupId } = useParams();
  const history = useHistory();
  const [isRosterPage, setIsRosterPage] = useState(true);
  const [isFreezeModalOpen, setIsFreezeModalOpen] = useState(false);
  const [dialog, setDialog] = useState({ isOpen: false });
  const [keyword, setKeyword] = useState("");
  const [driverShift, setDriveShift] = useState(
    selector.shift === "night" ? "nightShiftDriver" : "morningShiftDriver"
  );
  const columns = useMemo(
    () => [
      {
        type: "plateNumber",
        label: "車牌號碼",
        minWidth: 50,
        width: "8%",
        align: "left",
      },
      {
        type: "driver",
        whiteSpace:"nowrap",
        label: selector.shift === "night" ? "正夜更司機" : "正日更司機",
        width: "5%",
        minWidth: 90,
        align: "left",
      },
    ],
    [selector.shift]
  );

  //Get the taxi info and change the Table
  const [screenTableData, setScreenTableData] = useState([]);
  const [originalTableData, setOriginalTableData] = useState([]);
  // const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef(null);
  // useEffect(() => {
  //   setTableHeight(tableRef.current ? tableRef.current.clientHeight - 57 : 0);
  // }, [screenTableData.length]);

  useEffect(() => {
    if (isReady) {
      const screenTable = keyword
        ? UiService.keywordFilter(roster, keyword, "roster")
        : roster;
      setScreenTableData(screenTable);
      setOriginalTableData(roster);
    }
  }, [isReady, roster, keyword]);

  useEffect(() => {
    setDriveShift(
      selector.shift === "night" ? "nightShiftDriver" : "morningShiftDriver"
    );
  }, [selector.shift]);

  //Get the fleet group name from redux store. If no name, redirect to fleet.
  const [currentGroup, setCurrentGroup] = useState({
    name: "",
    fleet: { _id: "" },
  });
  useEffect(() => {
    if (fleetGroupList.length > 0) {
      const fleetGroup = fleetGroupList.find((group) => group._id === groupId);
      if (fleetGroup) {
        setCurrentGroup(fleetGroup);
      } else {
        // history.push('/fleet')
      }
    }
    return () => {
      setCurrentGroup({ name: "", fleet: { _id: "" } });
    };
  }, [fleetGroupList, groupId, history]);

  useEffect(() => {
    if (currentGroup.fleet._id && groupId) {
      getRoster(
        currentGroup.fleet._id,
        groupId,
        selector.shift,
        selector.from,
        selector.to
      );
    }
  }, [
    currentGroup.fleet._id,
    groupId,
    selector.shift,
    selector.from,
    selector.to,
    getRoster,
  ]);

  const handleFreezeRoster = () => {
    setIsFreezeModalOpen(true);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  var isNeedPostedOverlay = true;

  return (
    <>
      <GetData fleetGroup driver />
      <AppBar>
        <SpaceBetweenBox flexWrap="wrap" flexGrow="1">
          <RowAlignCenterBox flexWrap="wrap" flexGrow="1">
            <Box mr={0.5}>
              {isRosterPage ? (
                <Back />
              ) : (
                  <Back onClick={() => setIsRosterPage(true)} />
                )}
            </Box>
            {isRosterPage ? (
              <WeekSelector {...{ groupId }} currentGroup={currentGroup} />
            ) : (
                <Box mx={3} pr={1} fontWeight="700">
                  <Typography variant="h5">出勤替更司機</Typography>
                </Box>
              )}
            {isRosterPage ? (
              <RosterKeywordSearch
                style={{ flexGrow: 0.5 }}
                {...{
                  keyword,
                  setKeyword,
                  setPage,
                  setScreenTableData,
                  originalTableData,
                }}
              />
            ) : (
                <KeywordSearch style={{ flexGrow: 0.5 }} />
              )}
          </RowAlignCenterBox>
          {checker(role, "roster:freeze") && (
            <RowAlignCenterBox>
              <Button
                background="purple"
                disabled={todayIsPosted}
                onClick={handleFreezeRoster}
              >
                凍結今日更表
              </Button>
            </RowAlignCenterBox>
          )}
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        {isRosterPage ? (
          <>
            <SpaceBetweenBox>
              <ShiftSelector currentGroup={currentGroup} />
              <Box display="flex">
                <Box mr={2}>
                  <StyledButton
                    background="paleGrey"
                    onClick={() => setIsRosterPage(false)}
                  >
                    可選擇替更司機
                  </StyledButton>
                </Box>
                <PrintBtnAndLayout 
                  currentGroupName={currentGroup?.name||""} 
                  driverShift={driverShift}
                />
              </Box>
            </SpaceBetweenBox>
            <Paper className={classes.root}>
              <TableContainer ref={tableRef} className={classes.container}>
                {isLoading && <LoadingIndicator />}
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ backgroundColor: "#FFF" }}
                >
                  {/*Section 1: Table Heading */}
                  <TableHead>
                    <TableRow>
                      {/* Sub-section 1-1: Column Names */}
                      {columns.concat(week).map((column, index) => (
                        <TableCell
                          key={column.label}
                          align={column.align || "center"}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            whiteSpace: column.whiteSpace||"unset",
                            padding: "0px 16px",
                            height: "57px",
                            color:
                              publicHoliday &&
                              column.type === "date" &&
                              // eslint-disable-next-line
                              (column.date.weekday() == 0 ||
                                publicHoliday[column.date.format("YYYYMMDD")] !=
                                null) &&
                              "red",
                          }}
                        >
                          {column.label}{" "}
                          {column.type === "date" &&
                            `(${column.date.format("D/M")})`}
                          {column.vacancy !== undefined && (
                            <Box>
                              {isRosterShowPastRecord &&
                                moment(column.date).isBefore(moment(), "day")
                                ? "缺勤"
                                : "缺更"}
                              ：{column.vacancy}
                            </Box>
                          )}
                         {column.availableDriverNo!==undefined&& <Box>可選司機：{column.availableDriverNo}</Box>}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* Section 2: Table rows */}
                  <TableBody>
                    {/* Sub-section 2-1: Individual Records */}
                    {screenTableData
                      ? screenTableData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              // key={index}
                              key={JSON.stringify(row)}
                              hover={checker(role, "roster:edit")}
                              role="checkbox"
                              tabIndex={-1}
                            >
                              {columns.map((field) => {
                                let text;
                                switch (field.type) {
                                  case "driver":
                                    text =row?.[driverShift]?.name||"-";
                                    break;
                                  default:
                                    row[field.type] && row[field.type].name
                                      ? (text = row[field.type].name)
                                      : (text = row[field.type] || "-");
                                }
                                return (
                                  <TableCell key={field.type}>
                                    {text}
                                  </TableCell>
                                );
                              })}
                              {week.map((field, index) => {
                                const date = moment(selector.from)
                                  .add(index, "d")
                                  .format("YYYY-MM-DD");
                                const isToday = moment(date).isSame(
                                  moment(),
                                  "day"
                                );
                                var overlay = null;
                                if (
                                  isToday &&
                                  todayIsPosted &&
                                  isNeedPostedOverlay
                                ) {
                                  overlay = (
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        minHeight: "49px",
                                        maxHeight: "490px", //height of 10 rows,
                                        height: `${screenTableData.length * 49
                                          }px`,
                                        zIndex: 999,
                                      }}
                                    >
                                      <Box
                                        style={{
                                          // width: "50%",
                                          paddingLeft: "15px",
                                          paddingRight: "15px",
                                          borderRadius: "10px",
                                          height:
                                            screenTableData.length > 1
                                              ? "70px"
                                              : "40px",
                                          backgroundColor: "rgba(0,0,0,0.8)",
                                          color: "white",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        已凍結
                                        </Box>
                                    </Box>
                                  );
                                  isNeedPostedOverlay = false;
                                }
                                return (
                                  <RosterCell
                                    key={index}
                                    date={date}
                                    positionOfWeek={index}
                                    dayOfWeek={index === 6 ? 0 : index + 1}
                                    row={row}
                                    setDialog={setDialog}
                                    driverObj={
                                      row[driverShift] && row[driverShift]
                                    }
                                    taxiId={row["_id"]}
                                    driverShift={driverShift}
                                  >
                                    {overlay}
                                  </RosterCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                style={{ flex: "0 0 auto" }}
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={screenTableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} / ${count}`
                }
                labelRowsPerPage={"每頁行數："}
              />
         
            </Paper>
          </>
        ) : (
            <ReplacementDriverList />
          )}
      </PageContainer>
      <FullTimeDriverOverlay />
      <PartTimeDriverOverlay {...{ ...dialog, setDialog, currentGroup }} />
      <FreezeOverlay
        isOpen={isFreezeModalOpen}
        setIsOpen={setIsFreezeModalOpen}
      />

    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  fleetGroupList: state.fleetGroup.list,

  todayIsPosted: state.roster.todayIsPosted,
  isReady: state.roster.isReady,
  isLoading: state.roster.isLoading,
  roster: state.roster.list,
  selector: state.roster.selector,

  driverList: state.driver.list,
  week: state.roster.week,
  publicHoliday: state.roster.publicHoliday,
});
const mapDispatchToProps = (dispatch) => ({
  getRoster: (fleet, fleetGroup, shift, from, to) =>
    dispatch({
      type: rosterActions.GET_ROSTER,
      fleet,
      fleetGroup,
      shift,
      from,
      to,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Roster);
