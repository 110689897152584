import { combineReducers } from "redux";
import userReducer from "./user";
import fleetReducer from "./fleet";
import fleetGroupReducer from "./fleetGroup";
import systemUserReducer from "./systemUser";
import rosterReducer from "./roster";
import { userActions } from "../actions";
import taxiReducer from "./taxi";
import driverReducer from "./driver";
import taxiOwnerReducer from "./taxiOwner";
import UiReducer from "./ui";
import normalRentalReducer from "./normalRental";
import persistRentalReducer from "./persistRental";
import dailyReducer from "./daily";
import deleteDialogReducer from "./deleteDialog";

const allReducers = combineReducers({
  user: userReducer,
  systemUser: systemUserReducer,
  taxi: taxiReducer,
  taxiOwner: taxiOwnerReducer,
  driver: driverReducer,
  roster: rosterReducer,
  fleet: fleetReducer,
  fleetGroup: fleetGroupReducer,
  ui: UiReducer,
  normalRental: normalRentalReducer,
  persistRental: persistRentalReducer,
  daily: dailyReducer,
  deleteDialog: deleteDialogReducer
});

const rootReducer = (state, action) => {
  if (action.type === userActions.CLEAR_DATA) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
