import React from 'react';
import Box from '@material-ui/core/Box';
import Modal from '../../components/Modal';

// import Text from '../../components/Text';
import StyledButton from '../../components/Button';
import { Typography } from '@material-ui/core';

function alert({ obj, type }) {
    return Object.keys(obj).map(elem => <Typography key={elem} variant="body2">{type === "taxi" ? `車牌${elem} : ` : `第${Number(elem) + 1}項出現問題 `}{type === "taxi" &&obj[elem]}</Typography>)
}

export const UploadStatusCard = ({ isLoading, isSuccess, isChecking, isErrorOccur, setDialogStatus, err,
    ...rest }) => {


    const closeDialog = () => {
        setDialogStatus(prev => ({ ...prev, isOpen: false }))
    }

    return (
        <Modal title={isLoading ? "請稍候" : err ? "出現錯誤" : ""} disableBGClose {...{ closeDialog, isSuccess, isLoading, isErrorOccur, ...rest }}>

            {isLoading &&
                <Box my={2}>{isChecking ? "正在檢查格式" : "正在連接公司後台"}</Box>}

            {isSuccess
                &&
                <>
                    成功上傳到公司後台
                </>}

    
            {err && <>
                <Typography variant="h5">的士</Typography>
                {err.taxi&&err.taxi[0] && <>
                    <Typography variant="body2">早更:</Typography>
                    {alert({ obj: err.taxi[0], type: "taxi" })}
                </>
                }
                {err.taxi&&err.taxi[1] && <>
                    <Typography variant="body2">夜更:</Typography>
                    {alert({ obj: err.taxi[1], type: "taxi" })}</>
                }
                {err.driver&&err.driver[0] && <>
                    <Typography variant="h5">入帳</Typography>
                    <Typography variant="body2">早更:</Typography>
                    {alert({ obj: err.driver[0] })}
                </>
                }
                {err.driver&&err.driver[1] && <>
                    <Typography variant="body2">夜更:</Typography>
                    {alert({ obj: err.driver[1] })}
                </>
                }
            </>}


            <Box mt={2.5} mb={1} display="flex" justifyContent="flex-end">
                {!isLoading
                    && <Box mr={1}><StyledButton
                        background="purple"
                        // background={err ? "white" : "purple"}
                        //     variant={err ? "outlined" : "contained"}
                        onClick={closeDialog}>關閉</StyledButton></Box>}
            </Box>
        </Modal>
    )
}