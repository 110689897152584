import { all } from "redux-saga/effects";
import userSaga from "./user";
import systemAccountSaga from "./account";
import driverSaga from "./driver";
import taxiOwnerSaga from "./taxiOwner";
import taxiSaga from "./taxi";
import fleetSaga from "./fleet";
import fleetGroupSaga from "./fleetGroup";
import rosterSaga from './roster';
import normalRentalSaga from "./normalRental";
import deleteDialogSaga from "./deleteDialog";

export default function* rootSaga() {
  yield all([
    ...userSaga,
    ...systemAccountSaga,
    ...driverSaga,
    ...taxiOwnerSaga,
    ...taxiSaga,
    ...fleetSaga,
    ...fleetGroupSaga,
    ...rosterSaga,
    ...normalRentalSaga,
    ...deleteDialogSaga
  ]);
}
