import React from "react";
import { Box } from "@material-ui/core";


const style = {
    password: {
        fontSize: 20,
        fontFamily: 'PingFangTC-Medium'
    },
    link: {
        color: '#7B57FF',
        cursor: 'pointer',
        textDecorationLine: 'none'
    },
    itemTitle: {
        fontSize: 12,
        color: '#4a4a4a',
    },
    itemText: {
        color: '#000000',
        fontSize: 16,
        fontWeight: 600,
    },
    itemWarningText: {
        color: 'red',
        fontSize: 16,
        fontWeight: 600,
    }
}

const Text = ({ type, children, ...rest }) => {
    return (
        <Box
            {...rest}
            style={style[type]}
        >
            {children}
        </Box>
    );
};


export default Text;
