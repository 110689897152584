export const modalConstants={
    OPEN_DIALOG:{
        isLoading: false,
        isOpen: true
    },
    CLOSE_DIALOG:{
        isLoading: false,
        isOpen: false
    },
    LOADING_DIALOG:{
        isLoading: true,
        isOpen: true,
    },
    ERROR_DIALOG:{
        isLoading: false,
        isOpen: true,
        isErrorOccur:true,
    }
}