import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
// import { UiService } from '../../Service/UiService';

export const RosterKeywordSearch = ({ setScreenTableData, originalTableData, setPage, keyword, setKeyword, ...rest }) => {


    const onChange = e => {
        setKeyword(e.target.value)
        setPage && setPage(0)
    }

    return <TextField
        {...rest}
        value={keyword}
        onChange={onChange}
        variant={"outlined"}
        margin={"dense"}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon htmlColor="#AAA" />
                </InputAdornment>
            ),
        }}
        placeholder="輸入搜尋" />
}

