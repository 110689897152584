import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { dayOfWeek } from '../../constants';

export function DayOffCheckBox({ form, editable }) {

  const handleChange = e => {
    let editedDayOff = [...form.values.dayoff];
    let value = Number(e.target.name);
    if (e.target.checked) {
      editedDayOff.push(value);
    } else {
      let index = editedDayOff.indexOf(value);
      editedDayOff.splice(index, 1);
    }
    editedDayOff.sort();
    form.handleChange({ target: { name: 'dayoff', value: editedDayOff } })
  };

  return (
    <>
      <FormControl
        disabled={editable ? false : true}>
        <FormLabel component="legend">休假日</FormLabel>
        <FormGroup aria-label="position" row>

          {
            dayOfWeek.map(item => (
              <FormControlLabel
                key={item.label}
                onChange={handleChange}
                control={<Checkbox color="primary" />}
                checked={form.values.dayoff.includes(Number(item.name))}
                label={item.label}
                labelPlacement="end"
                name={String(item.name)}
              />

            ))}
        </FormGroup>
      </FormControl>
    </>
  );
}
