import React from "react";
import { withStyles, Box } from "@material-ui/core";
import Text from "../Text";
import { DocumentService } from "../../Service/DocumentService";
import { UtilsService } from "../../Service/UtilsService";

export const StyledBox = withStyles({
  root: {
    position: "relative",
    padding: "10px 15px",
    borderTop: "1px solid #f4f4f4",
    borderBottom: "1px solid #f4f4f4",
    borderLeft: "1px solid #f4f4f4",
    borderRight: "1px solid #f4f4f4",
    display: "flex",
  },
})(Box);

export const StyledDocBox = ({
  index,
  name,
  path,
  uploadedAt,
  uploadedBy,
  isDeleted,
  deleteId,
  // deleteIndex,
  setDeleteDialog,
}) => (
  <StyledBox index={0}>
    <Box width="60px">{`文件${index}`}</Box>
    <Box flex="1" color="#7C7C7C">
      {name}
    </Box>
    <Box textAlign="right" color="#7C7C7C" minWidth="100px">
      用戶:{uploadedBy || "-"}{" "}
    </Box>
    <Box mx={2} textAlign="left" color="#7C7C7C" minWidth="184px">
      上載日期:{UtilsService.showReadableDayTime(uploadedAt)}
    </Box>
    <Box mx={2}>
      <Text
        onClick={() => DocumentService.handleDownload(path)}
        type="link"
        textAlign="end"
      >
        {path ? "詳細" : ""}
      </Text>
    </Box>
    <Text
      onClick={() => {
        // setDeleteDialog({ isOpen: true, index: deleteIndex, name });
        setDeleteDialog({ isOpen: true, deleteId: deleteId, name });
      }}
      type="link"
      textAlign="end"
    >
      刪除
    </Text>
    {isDeleted && (
      <Box
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "rgba(0,0,0,0.65)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#FFF",
        }}
      >
        更新後將會移除
      </Box>
    )}
  </StyledBox>
);
