import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import {
  roleMatching,
  shiftMatching,
  taxiStatusMatching,
  accidentStatusMatching,
} from "../../match";
import _ from "lodash/array";
import { connect } from "react-redux";
import MapOverlay from "../MapOverlay";
import { pageUIActions } from "../../redux/actions";
import PropTypes from "prop-types";
import { UtilsService } from "../../Service/UtilsService";
import StyledButton from "../Button";
import moment from "moment";
import UpdateGPS from "../UpdateGPS";
import { useHistory } from "react-router-dom";

const BtnBox = ({ btn1Txt, btn1OnClick, btn2Txt, btn2OnClick }) => (
  <Box display="flex">
    <Box mr={1}>
      <StyledButton minwidth="80px" background="purple" onClick={btn1OnClick}>
        {btn1Txt}
      </StyledButton>
    </Box>
    <StyledButton
      minwidth="80px"
      variant="outlined"
      background="white"
      onClick={btn2OnClick}
    >
      {btn2Txt}
    </StyledButton>
  </Box>
);

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    overflowX: "auto",
    backgroundColor: "#F8F8F8",
    marginBottom: "60px",
    // height: "calc(100% - 52px)",
  },
});

function StickyHeadTable({
  columns,
  rows,
  pathname,
  selectBy,
  byTaxiOwnerId,
  taxiByFleetGroup,
  location,
  page,
  rowsPerPage,
  updatePage,
  updateRowsPerPage,
  reConfirm,
  directConfirm,
  acceptLeave,
  rejectLeave,
  rejectWorkApplication,
  updateRosterShift
}) {
  const classes = useStyles();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isMapOpen, setIsMapOpen] = useState(false);
  const [gps, setGPS] = useState("");
  const history = useHistory();

  const GPSChecker = ({ carInfo, taxiColor, gpsInstalled }) => {
    const onGPSClick = () => {
      setIsMapOpen(true);
      setGPS({ ...carInfo, taxiColor });
    };

    return (
      <Box component="span" style={{ verticalAlign: "middle" }}>
        {carInfo && carInfo.AccOn !== null && (
          <Box mx={0.5} component="span">
            <img
              src={`/assets/ic-park${carInfo.AccOn === true ? "-off" : carInfo.AccOn === false && ""
                }.svg`}
              alt={"gps-icon"}
            />
          </Box>
        )}
        {gpsInstalled && (
          <Box
            mx={0.5}
            component="span"
            onClick={carInfo ? onGPSClick : undefined}
          >
            <img
              src={`/assets/ic-gps${carInfo && carInfo.Speed > 0
                ? ""
                : carInfo && carInfo.Speed === 0
                  ? "-off"
                  : "-abnormal"
                }.svg`}
              alt={"gps-icon"}
            />
          </Box>
        )}

        {
          <Box mx={0.5} component="span">
            {
              // eslint-disable-next-line
              carInfo && carInfo.ASensor4 == 0 ? (
                <img
                  src={`/assets/ic-cam${
                    // eslint-disable-next-line
                    carInfo.IsDVROnline == 0
                      ? "-offline"
                      : // eslint-disable-next-line
                      carInfo.IsDVROnline == 1
                        ? ""
                        : "-abnormal"
                    }.svg`}
                  alt={"video-cam-icon"}
                />
              ) : (
                  carInfo &&
                  // eslint-disable-next-line
                  carInfo.ASensor4 == 1 && (
                    <img
                      src={`/assets/ic-cam-abnormal.svg`}
                      alt={"video-cam-icon"}
                    />
                  )
                )
            }
          </Box>
        }
      </Box>
    );
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    updatePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    updateRowsPerPage(+event.target.value);
    updatePage(0);
  };

  // var style = {
  //   backgroundColor: "red",
  //   textAlign: "center",
  //   padding: "0px",
  //   position: "absolute",
  //   left: "10px",
  //   bottom: "10px",
  //   right: "10px",
  //   height: "60px",
  //   width: "100%",
  // };

  return (
    // <div style={{ position: "relative"}}>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ backgroundColor: "#FFF" }}
        >
          {/*Section 1: Table Heading */}
          <TableHead>
            <TableRow>
              {/* Sub-section 1-1: Column Names */}
              {columns.map((column) => (
                <TableCell
                  key={column.label}
                  align={column.align || "left"}
                  style={{
                    minWidth: column.minWidth,
                    left: "unset",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.label} {column.isShowGPS && <UpdateGPS />}
                </TableCell>
              ))}
              {/* Sub-section 1-2: Column for Link */}
              <TableCell style={{ minWidth: 40 }}></TableCell>
            </TableRow>
          </TableHead>

          {/* Section 2: Table Rows */}
          <TableBody>
            {/* Sub-section 2-1: Individual Records */}
            {rows
              ? rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                      {columns.map((field) => {
                        let text;
                        switch (field.type) {
                          case "gpsInstalled":
                            text = row[field.type] ? "已安裝" : "未安裝";
                            break;

                          case "daily_roster.nickname":
                          case "driver.nickname":
                            text =
                              row.driver &&
                              row.driver.driver &&
                              row.driver.driver.nickname
                                ? row.driver.driver.nickname
                                : "-";
                            break;
                          case "driver.phone":
                          case "daily_roster.name":
                          case "daily_roster.phone":
                            text =
                              row.driver && row.driver[field.nested]
                                ? row.driver[field.nested]
                                : "-";
                            break;
                          case "shift":
                            text =
                              row.shift && shiftMatching[row.shift]
                                ? shiftMatching[row.shift]
                                : "-";
                            break;
                          case "driver.shift":
                            text =
                              row.driver &&
                              row.driver.shift &&
                              shiftMatching[row.driver.shift]
                                ? shiftMatching[row.driver.shift]
                                : "-";
                            break;
                          case "driver.dayoff":
                            const duty = [
                              "日",
                              "一",
                              "二",
                              "三",
                              "四",
                              "五",
                              "六",
                            ];
                            row.driver &&
                              row.driver.dayoff &&
                              _.pullAt(duty, row.driver.dayoff);

                            if (duty[0] === "日") {
                              duty.shift();
                              duty.push("日");
                            }

                            duty.length > 0
                              ? (text = duty.join(","))
                              : (text = "暫無");
                            break;
                          case "driver.status":
                            text =
                              row.driver &&
                              row.driver.other &&
                              row.driver.other.status
                                ? row.driver.other.status
                                : "-";
                            break;
                          case "taxiStatus":
                            text = taxiStatusMatching[row.status] || "-";
                            break;
                          case "taxi_owner.company_name":
                            text =
                              row["taxi_owner"] &&
                              row["taxi_owner"].company &&
                              row["taxi_owner"].company.name
                                ? row["taxi_owner"].company.name
                                : "-";
                            break;
                          case "taxi_owner.taxi_number":
                            text = byTaxiOwnerId[row._id]
                              ? byTaxiOwnerId[row._id].length
                              : 0;
                            break;
                          case "redTaxiCount":
                          case "greenTaxiCount":
                          case "blueTaxiCount":
                          case "taxiTotalCount":
                            text = row[field.type] || 0;
                            break;
                          case "role":
                            text = roleMatching[row[field.type]] || "-";
                            break;
                          case "totalPayable":
                            text = <Box>${row["totalPayable"] || 0}</Box>;
                            break;
                          case "totalRentArrears":
                            text = (
                              <Box color="red">
                                ${row["totalRentArrears"] || 0}
                              </Box>
                            );
                            break;
                          case "accident.plateNumber":
                            text =
                              row.taxi && row.taxi.plateNumber
                                ? row.taxi.plateNumber
                                : "-";
                            break;
                          case "date":
                          case "occurrenceDateTime":
                          case "mileageLastUpdateDate":
                            text = (
                              <Box>
                                {row[field.type]
                                  ? UtilsService.showReadableDay(
                                      row[field.type]
                                    )
                                  : "-"}
                              </Box>
                            );
                            break;
                          case "accident.driver":
                            text =
                              row.driver && row.driver.name
                                ? row.driver.name
                                : "-";
                            break;
                          case "accident.status":
                            text = accidentStatusMatching[row.status] || "-";
                            break;
                          case "accident.owner":
                            text = (
                              <Box>
                                {row.taxi &&
                                row.taxi.owner &&
                                row.taxi.owner.name
                                  ? row.taxi.owner.name
                                  : "-"}
                              </Box>
                            );
                            break;
                          case "accident.insCompany":
                            text = (
                              <Box>
                                {row.taxi && row.taxi.insCompany
                                  ? row.taxi.insCompany
                                  : "-"}
                              </Box>
                            );
                            break;
                          case "maintenanceItems":
                            text =
                              Array.isArray(row[field.type]) &&
                              row[field.type].length > 0
                                ? row[field.type].join()
                                : [];
                            break;

                          case "daily_roster.fleet":
                            text =
                              row.fleet && row.fleet.name
                                ? row.fleet && row.fleet.name
                                : row.driver &&
                                  row.driver.fleet &&
                                  row.driver.fleet.name
                                ? row.driver.fleet.name
                                : "-";
                            break;
                          case "daily_roster.fleetGroup":
                            text =
                              row.fleetGroup && row.fleetGroup.name
                                ? row.fleetGroup && row.fleetGroup.name
                                : row.driver &&
                                  row.driver.fleetGroup &&
                                  row.driver.fleetGroup.name
                                ? row.driver.fleetGroup.name
                                : "-";
                            break;
                          case "daily_roster.shift":
                            text = row.shift
                              ? shiftMatching[row.shift]
                              : row.driver &&
                                row.driver.driver &&
                                row.driver.driver.shift
                              ? shiftMatching[row.driver.driver.shift]
                              : "-";
                            break;
                          case "daily_roster.status":
                            text =
                              row.type === "replacement"
                                ? "替更-未接受"
                                : row.type === "rejected"
                                ? "替更-已拒絕"
                                : row.type === "leave"
                                ? "申請休假"
                                : row.type === "work"
                                ? "申請更次"
                                : "";
                            break;
                          case "lastUpdateMileage":
                          case "mileage":
                            text = row[field.type]
                              ? row[field.type].toLocaleString()
                              : 0;
                            break;
                          case "createdAt":
                            text = row.createdAt
                              ? moment(row.createdAt).format("DD/MM/YYYY HH:mm")
                              : "-";
                            break;
                          default:
                            row[field.type] && row[field.type].name
                              ? (text = row[field.type].name)
                              : (text = row[field.type] || "-");
                        }
                        return (
                          <TableCell
                            key={field.label}
                            style={{
                              whiteSpace:
                                field.type === "plateNumber"
                                  ? "nowrap"
                                  : "unset",
                              maxWidth: "30%",
                            }}
                            align={"left"}
                          >
                            {field.type === "plateNumber" && field.isShowColor && (
                              <img
                                src={`/assets/ic-taxi-${
                                  row.type || "white"
                                }.svg`}
                                style={{
                                  height: 22,
                                  marginRight: 3,
                                  verticalAlign: "middle",
                                }}
                                alt={`taxi-color-is-${row.type || "unknown"}`}
                              />
                            )}
                            <span style={{ verticalAlign: "middle" }}>
                              {text}
                            </span>
                            {field.isShowGPS && (
                              <GPSChecker
                                carInfo={location[row["plateNumber"]]}
                                taxiColor={row.type}
                                gpsInstalled={row.gpsInstalled}
                              />
                            )}
                            {/* {field.type === "accident.plateNumber" &&
                              row.taxi &&
                              row.taxi.plateNumber && (
                                <GPSChecker
                                  carInfo={location[row.taxi.plateNumber]}
                                  taxiColor={row.taxi.type}
                                  gpsInstalled={row.taxi.gpsInstalled}
                                />
                              )} */}
                          </TableCell>
                        );
                      })}

                      {/* Sub-section 2-2: Link to details page*/}

                      <TableCell align={"right"} style={{ minWidth: 80 }}>
                        {row.type === "rejected" ? (
                          <></>
                        ) : row.type === "replacement" ? (
                          <BtnBox
                            btn1Txt="再發訊息"
                            btn1OnClick={() => reConfirm(row._id)}
                            btn2Txt="直接確認"
                            btn2OnClick={() => directConfirm(row._id)}
                          />
                        ) : row.type === "leave" ? (
                          <BtnBox
                            btn1Txt="確定"
                            btn1OnClick={() => acceptLeave(row._id)}
                            btn2Txt="拒絕"
                            btn2OnClick={() => rejectLeave(row._id)}
                          />
                        ) : row.type === "work" ? (
                          <BtnBox
                            btn1Txt="前往更表"
                            btn1OnClick={() => {
                              if (row.driver?.driver?.shift) {
                                row.driver.driver.shift.includes("morning") &&
                                  updateRosterShift("morning");
                                row.driver.driver.shift.includes("night") &&
                                  updateRosterShift("night");
                              }
                              setTimeout(
                                () =>history.push(`/roster/${row.driver?.fleetGroup?._id || ""}`)
                                ,500
                              );
                            }}
                            btn2Txt="拒絕"
                            btn2OnClick={() => rejectWorkApplication(row._id)}
                          />
                        ) : (
                          <Link
                            style={{ color: "blue" }}
                            to={`${pathname}${row[selectBy]}`}
                          >
                            詳細
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div style={style}> */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
        labelRowsPerPage={"每頁行數："}
      />
      {/* </div> */}
      <MapOverlay {...{ isMapOpen, setIsMapOpen, gps }} />
    </Paper>
    // </div>
  );
}
const mapStateToProps = (state, ownProps) => {
  var component = ownProps.component;
  var page = 0;
  var rowsPerPage = 10;
  var rows = [];
  if (state.ui[component] && state.ui[component].page >= 0) {
    page = state.ui[component].page;
  }
  if (state.ui[component] && state.ui[component].rowsPerPage >= 0) {
    rowsPerPage = state.ui[component].rowsPerPage;
  }
  if (ownProps.origin==="owner_taxi"){
    rows = state.ui.ownerTaxi?.rows||[];
  }else if (
    ownProps.origin == null ||
    // eslint-disable-next-line
    (ownProps.origin != null && ownProps.origin == state.ui.origin)
  ) {
    if (state.ui && state.ui.rows && Array.isArray(state.ui.rows)) {
      rows = state.ui.rows;
    }
  }

  return {
    taxiByFleetGroup: state.taxi.byFleetGroup,
    byTaxiOwnerId: state.taxi.byTaxiOwnerId,
    location: state.taxi.location,
    page,
    rowsPerPage,
    rows,
  };
};

StickyHeadTable.propTypes = {
  component: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePage: (page) =>
    dispatch({
      type: pageUIActions.CHANGE_PAGE,
      page,
      component: ownProps.component,
    }),
  updateRowsPerPage: (rowsPerPage) =>
    dispatch({
      type: pageUIActions.CHANGE_ROW_PER_PAGE,
      rowsPerPage,
      component: ownProps.component,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyHeadTable);
