import { put, takeLatest, call } from "redux-saga/effects";
import { getAllAccounts } from "../API";
import { driverActions } from "../actions";
import _ from "lodash";

function* watchGetAllDriver() {
  yield takeLatest(driverActions.GET_ALL_DRIVER, fetchAllDriver);
}
function* watchGetDeletedDriver() {
  yield takeLatest(driverActions.GET_DELETED_DRIVER, fetchDeletedDriver);
}

export function* fetchAllDriver() {
  try {
    const driver = yield call(() => getAllAccounts({ role: "driver" }));

    const driverByShift = _.groupBy(
      driver,
      (current) => current.driver && current.driver.shift
    );
    const partTime = {
      morning: driverByShift["re_morning"] || [],
      night: driverByShift["re_night"] || [],
    };

    const partTimeByFleet = {
      morning:
        _.groupBy(
          driverByShift["re_morning"],
          (driver) => driver.fleet && driver.fleet._id
        ) || [],
      night:
        _.groupBy(
          driverByShift["re_night"],
          (driver) => driver.fleet && driver.fleet._id
        ) || [],
    };

    const fullTimeByFleetGroup = {
      morning:
        _.groupBy(
          driverByShift["morning"],
          (driver) => driver.fleetGroup && driver.fleetGroup._id
        ) || [],
      night:
        _.groupBy(
          driverByShift["night"],
          (driver) => driver.fleetGroup && driver.fleetGroup._id
        ) || [],
    };

    yield put({
      type: driverActions.UPDATE_ALL_DRIVER,
      list: driver,
      partTime: partTime,
      fullTimeByFleetGroup,
      partTimeByFleet,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: driverActions.FAIL_GET_ALL_DRIVER });
  }
}

export function* fetchDeletedDriver() {
  try {
    const deletedDriverList = yield call(() =>
      getAllAccounts({ role: "driver", isDisabled: true })
    );
    // const deletedDriverList = yield new Promise((resolve, reject) => {
    //   setTimeout(() => resolve([]), 2000);
    // });
    yield put({
      type: driverActions.UPDATE_DELETED_DRIVER,
      deletedDriverList,
    });
  } catch (e) {
    console.log(e);
  }
}

export default [
  watchGetAllDriver(),
  watchGetDeletedDriver()]