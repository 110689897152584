import React from "react";
import { connect } from "react-redux";
import AppBar from "../../components/AppBar";
import PageContainer from "../../components/PageContainer";
import { Redirect } from "react-router-dom";
import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";

import FleetTab from "../Fleet/FleetTab";
import Table from "../../components/Table";
import { pageUIActions } from "../../redux/actions";
import { getIncome } from "../../redux/API";
import { IncomeCard } from "../../components/Card/IncomeCard";
import { useState } from "react";

const columns = [
  { type: "name", label: "車組名", minWidth: 100 },
  {
    type: "groupLeader",
    label: "車組管理員",
    minWidth: 60,
  },
  {
    type: "operator",
    label: "事務員",
    minWidth: 60,
  },
  {
    type: "address",
    label: "交更地址",
    minWidth: 60,
  },
  {
    type: "redTaxiCount",
    label: "紅的",
    minWidth: 80,
  },
  {
    type: "greenTaxiCount",
    label: "綠的",
    minWidth: 80,
  },
  {
    type: "blueTaxiCount",
    label: "藍的",
    minWidth: 80,
  },
  {
    type: "taxiTotalCount",
    label: "的士總數",
    minWidth: 80,
  },
  {
    type: "totalPayable",
    label: "總應交租金",
    minWidth: 90,
  },
  {
    type: "totalRentArrears",
    label: "欠收租金",
    minWidth: 100,
  },
];

const AllRental = ({
  role,
  userGroup,
  fleet,
  fleetGroup,
  updateOriginalTable,
  fleetIndex,
  origin,
  rentalSelectedDate,
}) => {
  const [incomeBox, setIncomeBox] = useState("");
  React.useEffect(() => {
    let isMounted = true;
    if (fleet && fleet[fleetIndex] && fleet[fleetIndex]._id) {
      if (rentalSelectedDate.startDate && rentalSelectedDate.endDate) {
        getIncome({
          fleet: fleet[fleetIndex]._id,
          from: rentalSelectedDate.startDate.format("YYYY-MM-DD"),
          to: rentalSelectedDate.endDate.format("YYYY-MM-DD"),
        })
          .then((res) => {
            if(!isMounted){
              return null
            }
            setIncomeBox(res);
            updateOriginalTable(
              res.list || [],
              `rental_list-${fleet[fleetIndex]._id}`
            );
          })
          .catch((e) => console.log(e));
      }
    }
    return ()=>isMounted=false
  }, [
    fleetIndex,
    fleetGroup,
    fleet,
    origin,
    updateOriginalTable,
    rentalSelectedDate.startDate,
    rentalSelectedDate.endDate,
  ]);

  if (role === "group_leader" || role === "operator") {
    return <Redirect to={`/rental/${userGroup}`} />;
  }

  return (
    <>
      <GetData fleet fleetGroup taxi />
      <AppBar>
        <KeywordSearch component="rental" style={{ maxWidth: "600px" }} />
      </AppBar>

      <PageContainer>
        <FleetTab fleet={fleet || []} />
        <IncomeCard {...{ incomeBox }} />
        <Table
          {...{ columns }}
          pathname="/rental/"
          selectBy="_id"
          component="rental"
          origin={
            fleet[fleetIndex] ? `rental_list-${fleet[fleetIndex]._id}` : ""
          }
        />
      </PageContainer>
    </>
  );
};
const mapStateToProps = (state) => ({
  role: state.user.role,
  userGroup: state.user.fleetGroup,

  fleet: state.fleet.list,
  fleetGroup: state.fleetGroup.byFleetId,
  origin: state.ui.origin,
  fleetIndex: state.ui.fleetIndex || 0,
  rentalSelectedDate: state.ui.rentalSelectedDate,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllRental);
