import { put, takeLatest, call } from "redux-saga/effects";
import { getAllAccounts } from "../API";
import { taxiOwnerActions } from "../actions";

function* watchGetAllTaxiOwner() {
  yield takeLatest(taxiOwnerActions.GET_ALL_TAXI_OWNER, fetchAllTaxiOwner);
}
function* watchGetDeletedTaxiOwner() {
  yield takeLatest(
    taxiOwnerActions.GET_DELETED_TAXI_OWNER,
    fetchDeletedTaxiOwner
  );
}

function* fetchAllTaxiOwner() {
  try {
    console.log("Saga - fetchAllTaxiOwner");
    const response = yield call(() => getAllAccounts({ role: "taxi_owner" }));
    if (Array.isArray(response)) {
      yield put({
        type: taxiOwnerActions.UPDATE_ALL_TAXI_OWNER,
        list: response,
      });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({ type: taxiOwnerActions.FAIL_GET_ALL_TAXI_OWNER });
  }
}

function* fetchDeletedTaxiOwner() {
  try {
    const deletedTaxiOwnerList = yield call(() =>
      getAllAccounts({ role: "taxi_owner", isDisabled: true })
    );
    // const deletedTaxiOwnerList = yield new Promise((resolve, reject) => {
    //   setTimeout(() => resolve([]), 2000);
    // });
    yield put({
      type: taxiOwnerActions.UPDATE_DELETED_TAXI_OWNER,
      deletedTaxiOwnerList,
    });
  } catch (e) {
    console.log(e);
  }
}

export default [
  watchGetAllTaxiOwner(),
  watchGetDeletedTaxiOwner()]