
export const deleteDialogActions = {
    OPEN_DELETE_DIALOG: "OPEN_DELETE_DIALOG",
    FETCH_DELETE_DIALOG: "FETCH_DELETE_DIALOG",
    DELETE_DIALOG: "DELETE_DIALOG",
    SUCCESS_DELETE_DIALOG: "SUCCESS_DELETE_DIALOG",
    CLOSE_DELETE_DIALOG: "CLOSE_DELETE_DIALOG",
    RESET_DELETE_DIALOG: "RESET_DELETE_DIALOG",
    FAIL_TO_DELETE_DIALOG: "FAIL_TO_DELETE_DIALOG"
}

