import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal/index';

import FormControl from '@material-ui/core/FormControl';
import { rosterActions } from '../../../redux/actions';
import RosterIcon from '../../../components/Icon/Roster';
import GetData from '../../../components/GetData';
import StyledButton from '../../../components/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';


const PartTimeDriverOverlay = ({ 
    replacement, partTimeDriver, shift, from, to, week,//from redux store (State)
    setReplacement, resendMsg, cancelReplacement, cancelChangeTaxi, confirmReplacement, removeLeave,//from redux store (dispatch)
    closePartTimeDialog, resetDialog, 
    partTimeDialog:{ isOpen, isLoading, isErrorOccur, isSuccess, category, //Dialog setting in redux store
      driverObj, dayOfWeek, positionOfWeek, targetDate, taxiId, pendingUnits, leave, replacementDriver },
    currentGroup, //from roster cell component (State)
    ...rest 
}) => {

    const [driverId, setDriverId] = useState("")
    const handleChangeDriver = (e,newValue) => {
        setDriverId(newValue?._id||"")
    }
    const driverList=week[positionOfWeek]?.driverList||[];
    useEffect(() => resetDialog, [resetDialog])

    const closeDialog = () => {
        closePartTimeDialog()
        setTimeout(() => {
            resetDialog();
            setDriverId("")
        }, 500)
    }

    const handleCancelReplacement = () => {
        cancelReplacement({
            driverShift: replacementDriver?.driver?.driver?.shift,
            driverId: replacementDriver?.driver?._id,
            driverName: replacementDriver?.driver?.name,
            status: replacementDriver && replacementDriver.status,
            id: replacementDriver && replacementDriver._id,
            date: targetDate,
            dayOfWeek
        })
    }

    const handleResendMsg = () => {
        resendMsg({ id: replacementDriver?._id })
    }

    const handleRemoveLeave = () => {
        removeLeave({
            leaveId: leave,
            fleet: currentGroup.fleet._id,
            fleetGroup: currentGroup._id
        })
    }

    const handleCancelPendingUnits = () => {
        cancelChangeTaxi({
            pendingUnits,
            targetDate, 
            positionOfWeek, 
            taxiId,
            driverId:driverObj._id
        })
    }

    const handleManualConfirm = () => {
        confirmReplacement({
            driverId: replacementDriver && replacementDriver.driver && replacementDriver.driver._id,
            id: replacementDriver && replacementDriver._id,
            date: targetDate,
            dayOfWeek
        })
    }

    const handleSubmit = ({ cancelRegularDayOff }) => {
        setReplacement({
            driver: cancelRegularDayOff ? driverObj._id : driverId,
            date: targetDate,
            shift,
            taxi: taxiId,
            fleet: currentGroup.fleet._id,
            fleetGroup: currentGroup._id,
            from,
            to,
            dayOfWeek
        })
    }

    const isRegularDayOff = React.useMemo(() => driverObj && driverObj.driver && driverObj.driver.dayoff.includes(dayOfWeek), [driverObj, dayOfWeek])
    const isPendingDriverGotShift = driverObj?._id && replacement[targetDate] &&replacement[targetDate].data[driverObj._id]

    return (
      <>
        <GetData driver />
        <Modal
          title={replacementDriver ? "替更司機" : "選擇司機"}
          {...{ closeDialog, ...rest,isOpen, isSuccess, isLoading, isErrorOccur }}
        >
          {isSuccess ? (
            <>
              {category === "selected" && <Box>成功上傳到公司後台</Box>}
              {category === "resend" && <Box>系統已再次發送訊息</Box>}
              {category === "confirm" && <Box>系統已直接確認替更司機</Box>}
              {category === "remove" && <Box>系統已銷假</Box>}
              {category === "cancel" && <Box>系統已取消替更司機</Box>}
              {category === "cancelPending" && <Box>系統已恢復司機原有更份</Box>}
            </>
          ) : replacementDriver && replacementDriver.driver ? (
            <>
              {replacementDriver.status === "PENDING" && (
                <Box display="flex" alignItems="center">
                  {" "}
                  <Box color="red">消息已發送給替更司機 </Box>{" "}
                  <Box style={{ textDecoration: "underline" }}>
                    {replacementDriver.driver.name}
                  </Box>
                  <RosterIcon type="pending" />
                </Box>
              )}
              {replacementDriver.status === "ACCEPTED" && (
                <Box display="flex" alignItems="center">
                  {" "}
                  <Box color="red">已確認替更司機 </Box>{" "}
                  <Box style={{ textDecoration: "underline" }}>
                    {replacementDriver.driver.name}
                  </Box>{" "}
                  <RosterIcon type="accepted" />
                </Box>
              )}
            </>
          ) : (
            <FormControl fullWidth error={false}>
              {driverObj && (pendingUnits || leave || isRegularDayOff) && (
                <>
                  <Box>正更司機</Box>
                  <Box
                    my={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box fontSize="16px" fontWeight="600">
                      {driverObj.name}
                      {
                        isPendingDriverGotShift
                          ? `(${
                              isPendingDriverGotShift.status === "PENDING"
                                ? "待確認"
                                : "已轉駕"
                            }的士${isPendingDriverGotShift?.taxi?.plateNumber})`
                          : pendingUnits
                          ? "(已取消更份)"
                          : isRegularDayOff
                          ? "(例休)"
                          : "(休假)"
                        // isRegularDayOff ? "(例休)" : "(休假)"
                      }
                    </Box>
                    <StyledButton
                      disabled={isPendingDriverGotShift}
                      onClick={() => {
                        pendingUnits
                          ? handleCancelPendingUnits()
                          : isRegularDayOff
                          ? handleSubmit({ cancelRegularDayOff: true })
                          : handleRemoveLeave();
                        // isRegularDayOff
                        //     ?
                        //     handleSubmit({ cancelRegularDayOff: true })
                        //     :
                        //     handleRemoveLeave()
                      }}
                      background="lightBlue"
                      variant="outlined"
                      height="30px"
                      width="90px"
                    >
                      {pendingUnits
                        ? "恢復更份"
                        : isRegularDayOff
                        ? "取消例休"
                        : "取消休假"}
                    </StyledButton>
                  </Box>
                </>
              )}

              <Box>司機</Box>
              <Autocomplete
                value={driverId?driverList.find(driver=>driver._id===driverId):null}
                onChange={handleChangeDriver}
                options={driverList.sort((a,b)=>a.group.localeCompare(b.group))}
                noOptionsText="沒有相關司機"
                groupBy={(driver) => driver?.group||""}
                getOptionSelected={(option,value)=>{
                    if (value === null) {
                        return true;
                      } else if (value === option) {
                        return true;
                      }
                }}
                getOptionLabel={(driver) => driver?.name||""}
                renderInput={(params) => (
                  <TextField {...params} placeholder="請選擇" margin="dense" variant="outlined" />
                )}
              />

            </FormControl>
          )}

          <Box mt={2.5} mb={1} display="flex" justifyContent="flex-end">
            {isSuccess ? (
              <Button background="purple" onClick={closeDialog}>
                關閉
              </Button>
            ) : replacementDriver && replacementDriver.driver ? (
              <>
                {replacementDriver.status === "PENDING" && (
                  <>
                    <Box ml={3}>
                      <Button
                        variant="outlined"
                        background="white"
                        onClick={handleCancelReplacement}
                      >
                        取消替更司機{" "}
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button background="purple" onClick={handleManualConfirm}>
                        直接確認
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button background="purple" onClick={handleResendMsg}>
                        再次發送信息
                      </Button>
                    </Box>
                  </>
                )}
                {replacementDriver.status === "ACCEPTED" && (
                  <Button background="purple" onClick={handleCancelReplacement}>
                    取消替更司機
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  background="white"
                  onClick={closeDialog}
                >
                  取消
                </Button>
                <Box ml={2}>
                  <Button
                    background="purple"
                    disabled={!driverId}
                    onClick={handleSubmit}
                  >
                    發送確定信息
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </>
    );
}

const mapStateToProps = (state) => ({
    shift: state.roster.selector.shift,
    from: state.roster.selector.from,
    to: state.roster.selector.to,
    week:state.roster.week,
    partTimeDriver: state.driver.partTime,
    replacement: state.roster.replacement,
    errMsg: state.roster.errMsg,

    partTimeDialog:state.roster.partTimeDialog
})
const mapDispatchToProps = (dispatch) => ({
    setReplacement: (payload) => dispatch({ type: rosterActions.FETCH_SET_REPLACEMENT, category:"selected", ...payload }),
    resetDialog: () => dispatch({ type: rosterActions.RESET_DIALOG }),
    cancelChangeTaxi:(payload)=>dispatch({type: rosterActions.FETCH_CANCEL_CHANGE_TAXI, ...payload }),
    cancelReplacement: (payload) => dispatch({ type: rosterActions.FETCH_CANCEL_REPLACEMENT, ...payload }),
    resendMsg: (payload) => dispatch({ type: rosterActions.FETCH_RESEND_MSG, ...payload }),
    confirmReplacement: (payload) => dispatch({ type: rosterActions.FETCH_CONFIRM_REPLACEMENT, ...payload }),
    removeLeave: (payload) => dispatch({ type: rosterActions.FETCH_CANCEL_LEAVE, category:"remove", ...payload }),
    closePartTimeDialog:()=>dispatch({type:rosterActions.CLOSE_PART_TIME_DIALOG})
  })
export default connect(mapStateToProps, mapDispatchToProps)(PartTimeDriverOverlay);

