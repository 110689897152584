import React, { useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Box } from "@material-ui/core";
import {
  systemRoleMatching,
  taxiStatusMatching,
  shiftMatching,
  driverStatusMatching,
  accidentStatusMatching,
} from "../../match";
import {
  pageUIActions,
  driverActions,
  taxiActions,
  taxiOwnerActions,
  accountActions,
} from "../../redux/actions";
import GetData from "../GetData";
import StyledButton from "../Button";
import { checker } from "../../permission";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  actionBox: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px",
    minWidth: "180px",
  },
}));

const FilterBtn = withStyles((props) => ({
  root: {
    width: 80,
    color: (props) => (props.on ? "#FFFFFF" : "#9B9B9B"),
    fontWeight: "700",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: (props) => (props.on ? "#5650ef" : "#e0e0e0"),
    transition: "all 0.25s",
    "&:hover": {
      backgroundColor: (props) => (props.on ? "#5650ef" : "#e0e0e0"),
      opacity: 0.6,
    },
  },
  outlined: {
    border: (props) => (props.on ? "1px solid #5650ef" : "1px solid #bbbbbb"),
    padding: "5px 10px",
  },
}))(Button);

const menuOptions = {
  fleetGroup: [{ title: "狀態", name: "status", iterable: taxiStatusMatching }],
  taxi_owner: [
    // {
    //   title: "GPS安裝",
    //   name: "gpsInstalled",
    //   iterable: { true: "已安裝", false: "未安裝" },
    // },
  ],
  taxi: [
    { title: "車隊", name: "fleet", iterable: "" },
    { title: "車組", name: "fleetGroup", iterable: "" },
    { title: "狀態", name: "status", iterable: taxiStatusMatching },
    {
      title: "GPS安裝",
      name: "gpsInstalled",
      iterable: { true: "已安裝", false: "未安裝" },
    },
  ],
  driver: [
    { title: "車隊", name: "fleet", iterable: "" },
    { title: "車組", name: "fleetGroup", iterable: "" },
    { title: "更次", name: "shift", iterable: shiftMatching },
    {
      title: "休假日",
      name: "dayoff",
      iterable: {
        0: "星期日",
        1: "星期一",
        2: "星期二",
        3: "星期三",
        4: "星期四",
        5: "星期五",
        6: "星期六",
      },
    },
    { title: "狀態", name: "driver_status", iterable: driverStatusMatching },
  ],
  daily_roster: [
    {
      title: "狀態",
      name: "daily_roster_status",
      iterable: { replacement: "替更-未接受", rejected: "替更-已拒絕", leave: "申請休假", work:"申請更次" },
    },
  ],
  daily_accident: [
    {
      title: "狀態",
      name: "maintenance_status",
      iterable: accidentStatusMatching,
    },
  ],
  accident: [
    {
      title: "狀態",
      name: "maintenance_status",
      iterable: accidentStatusMatching,
    },
  ],
  account: [
    { title: "車隊", name: "fleet", iterable: "" },
    { title: "車組", name: "fleetGroup", iterable: "" },
    { title: "角色", name: "role", iterable: systemRoleMatching },
  ],
};

function SimpleMenu({
  role,
  storeFilter,
  updateStoreFilter,
  updateOwnerTaxiFilter,
  type,
  component,
  fleet,
  fleetGroup,
  origin,
  showDeletedPage,

  deletedPage,
  setDeletedPage,
  resetPageToZero,
}) {
  const classes = useStyles();
  const [filterList, setFilterList] = React.useState(storeFilter || {});
  const [isDeleteFilterOn, setDeleteFilter] = React.useState(deletedPage);
  const isFilterOn = Object.keys(storeFilter).length || deletedPage;
  const handleChange = (event) => {
    const newList = { ...filterList };
    if (event.target.name === "fleet") {
      delete newList.fleetGroup;
    }
    if (event.target.value) {
      setFilterList({
        ...newList,
        [event.target.name]: event.target.value,
      });
    } else {
      delete newList[event.target.name];
      setFilterList({
        ...newList,
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubmit = () => {
    if (isDeleteFilterOn) {
      showDeletedPage(origin);
      setDeletedPage(true);
      resetPageToZero(origin);
    } else {
      const newList = { ...filterList };
      Object.keys(filterList).forEach((key) => {
        if (!newList[key]) {
          delete newList[key];
        }
      });
      if(origin==="owner_taxi"){
        updateOwnerTaxiFilter(newList);
      }else{
        updateStoreFilter(
          {
            ...newList,
          },
          component
        );
      }
      setDeletedPage(false);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    setDeleteFilter(deletedPage);
  }, [deletedPage]);

  useEffect(() => {
    if(type!==origin){
    setFilterList({});
    }
  }, [type,origin]);

  const handleClose = () => {
    setTimeout(() => setFilterList(storeFilter), 150);
    setDeleteFilter(deletedPage);
    setAnchorEl(null);
  };

  const handleDisplayDeletedItems = () => {
    setDeleteFilter(!isDeleteFilterOn);
    setFilterList(storeFilter);
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <GetData fleet fleetGroup />
      <FilterBtn
        on={isFilterOn}
        variant="outlined"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FilterListIcon htmlColor={isFilterOn ? "#FFF" : "#9B9B9B"} />{" "}
        <Box>篩選</Box>
      </FilterBtn>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ list: classes.list }}
      >
        {menuOptions[type].map(({ title, name, iterable }, i) => (
          <FormControl
            key={i}
            variant="outlined"
            className={classes.formControl}
            disabled={deletedPage || isDeleteFilterOn}
          >
            <Box>{title}</Box>
            <Select
              margin="dense"
              name={name}
              displayEmpty
              value={filterList[name] || ""}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>所有</em>
              </MenuItem>

              {name === "fleet"
                ? fleet.map(({ _id, name }) => (
                  <MenuItem key={_id} value={_id}>
                    {name}
                  </MenuItem>
                ))
                : name === "fleetGroup"
                  ? filterList.fleet &&
                  fleetGroup[filterList.fleet] &&
                  fleetGroup[filterList.fleet].map(({ _id, name }) => (
                    <MenuItem key={_id} value={_id}>
                      {name}
                    </MenuItem>
                  ))
                  : Object.entries(iterable).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        ))}
        {checker(role, "disable:view") &&
        origin!=="owner_taxi"&&
          ["taxi_owner", "taxi", "driver", "account"].includes(type) && (
            <FormControl variant="outlined" className={classes.formControl}>
              <Box>檢視刪除帳戶</Box>
              <Select
                margin="dense"
                value={isDeleteFilterOn}
                onChange={handleDisplayDeletedItems}
              >
                <MenuItem value={true}>是</MenuItem>
                <MenuItem value={false}>否</MenuItem>
              </Select>
            </FormControl>
          )}

        <Box className={classes.actionBox}>
          <StyledButton
            height="30px"
            minheight="30px"
            minwidth="70px"
            variant="outlined"
            background="white"
            onClick={handleClose}
          >
            取消
          </StyledButton>
          <Box ml={1}>
            <StyledButton
              height="30px"
              minheight="30px"
              minwidth="70px"
              background="purple"
              onClick={handleSubmit}
            >
              確定
            </StyledButton>
          </Box>
        </Box>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state,ownProps) => {
  let storeFilter,origin;
  origin=state.ui.origin;
  if (origin==="owner_taxi"){
    storeFilter=state.ui.ownerTaxi?.filter||{};
  }else{
    storeFilter=state.ui.filter;
  }
  return({
  role: state.user.role,
  origin,
  storeFilter,
  deletedPage: state.ui.deletedPage,
  fleet: state.fleet.list || [],
  fleetGroup: state.fleetGroup.byFleetId || {},
})};

const mapDispatchToProps = (dispatch) => ({
  updateStoreFilter: (filter, component) =>
    dispatch({ type: pageUIActions.UPDATE_FILTER_BTN, filter, component }),
  updateOwnerTaxiFilter: (filter) =>
    dispatch({ type: pageUIActions.UPDATE_OWNER_TAXI_FILTER_BTN, filter }),
  resetPageToZero: (origin) =>
    dispatch({ type: pageUIActions.CHANGE_PAGE, page: 0, component: origin }),
  showDeletedPage: (origin) =>
    dispatch({
      type:
        origin === "driver"
          ? driverActions.GET_DELETED_DRIVER
          : origin === "taxi"
            ? taxiActions.GET_DELETED_TAXI
            : origin === "taxi_owner"
              ? taxiOwnerActions.GET_DELETED_TAXI_OWNER
              : accountActions.GET_DELETED_SYSTEM_ACCOUNT,
    }),
  setDeletedPage: (status) =>
    dispatch({
      type: pageUIActions.TOGGLE_DELETE_PAGE,
      status,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SimpleMenu);
