import { driverActions } from "../actions";
const init_state = {
  status: "init",
  list: [],
  partTime: { morning: [], night: [] },
  partTimeByFleet: { morning: {}, night: {} },
  fullTimeByFleetGroup: { morning: {}, night: {} },
  isReady: false,

  deletedDriverList: [],
  isDeletedDriverReady: false,
};

export default function driverReducer(state = init_state, action) {
  switch (action.type) {
    case driverActions.GET_ALL_DRIVER:
      return {
        ...state,
        status: "loading",
        isReady: false,
      };
    case driverActions.UPDATE_ALL_DRIVER:
      return {
        ...state,
        status: "success",
        list: action.list,
        partTime: action.partTime,
        fullTimeByFleetGroup: action.fullTimeByFleetGroup,
        partTimeByFleet: action.partTimeByFleet,
        isReady: true,
      };
    case driverActions.FAIL_GET_ALL_DRIVER:
      return {
        ...state,
        status: "error",
        isReady: false,
      };
    case driverActions.GET_DELETED_DRIVER:
      return {
        ...state,
        isDeletedDriverReady: false,
      };
    case driverActions.UPDATE_DELETED_DRIVER:
      return {
        ...state,
        deletedDriverList: action.deletedDriverList,
        isDeletedDriverReady: true,
      };
    case driverActions.REMOVE_ENABLE_DRIVER:
      return {
        ...state,
        deletedDriverList: state.deletedDriverList.filter(
          (item) => item._id !== action.id
        ),
        isDeletedDriverReady: true,
      };
    default:
      return state;
  }
}
