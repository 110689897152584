
export const normalRentalActions = {
    GET_RENTAL_INFO: "GET_RENTAL_INFO",
    FAIL_GET_RENTAL_INFO: "FAIL_GET_RENTAL_INFO",

    UPDATE_NORMAL_RENTAL: "UPDATE_NORMAL_RENTAL",
    UPDATE_EDITABLE: "UPDATE_EDITABLE",
    NO_RENTAL_FOUND: "NO_RENTAL_FOUND"
}

