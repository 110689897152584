import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import StyledButton from "./index";
import { Box } from "@material-ui/core";

const style = {
  borderRadius: 20,
  height: 20,
  width: 20,
  backgroundColor: "red",
  cursor: "pointer",
};

export function RedConfirmClearButton({ onClick }) {
  const [isConfirmState, setIsConfirmState] = React.useState(false);
  return isConfirmState ? (
    <ClickAwayListener onClickAway={() => setIsConfirmState(false)}>
      <Box display="flex">
        <StyledButton
          height="30px"
          width="50px"
          onClick={()=>{
            onClick();
            setIsConfirmState(false);}}
          background="purple"
        >
          確定
        </StyledButton>

        <Box ml={1}>
          <StyledButton
            height="30px"
            width="50px"
            onClick={() => setIsConfirmState(false)}
            variant="outlined"
            background="white"
          >
            取消
          </StyledButton>
        </Box>
      </Box>
    </ClickAwayListener>
  ) : (
    <div style={style} onClick={() => setIsConfirmState(true)}>
      <ClearIcon fontSize="small" htmlColor="#FFF" />
    </div>
  );
}
