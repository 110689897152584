import { normalRentalActions } from '../actions';

const init_state = {
    status: 'init',
    fleetId: "",
    isEditable: false,
    taxiTransaction: {},
    summary: {
        expectedRent: "",
        morningRent: "",
        morningPaid: "",
        nightRent: "",
        nightPaid: "",
        totalRent: "",
        totalPaid: ""
    },
    totalDeposit:0,
    totalDeduction: 0,
    morningDeductionList: [],
    nightDeductionList: [],
    isReady: false
}

export default function normalRentalReducer(state = init_state, action) {
    switch (action.type) {
        case normalRentalActions.GET_RENTAL_INFO:
            return {
                ...state,
                isEditable: false,
                status: 'loading',
                isReady: false
            }

        case normalRentalActions.UPDATE_NORMAL_RENTAL:
            return {
                ...state,
                status: 'ready',
                isEditable: false,
                taxiTransaction: action.taxiTransaction,
                summary: action.summary,
                totalDeposit: action.totalDeposit,
                totalRentPaidReceived:action.totalRentPaidReceived,
                totalDeduction: action.totalDeduction,
                morningDeductionList: action.morningDeductionList,
                nightDeductionList: action.nightDeductionList,
                currentStatus: action.currentStatus,
                isReady: true
            }

        case normalRentalActions.UPDATE_EDITABLE:
            return {
                ...state,
                currentStatus: action.currentStatus,
                isEditable: true,
                status: 'ready',
                isReady: true
            }

        case normalRentalActions.NO_RENTAL_FOUND:
            return {
                ...state,
                isEditable: false,
                status: 'empty',
                isReady: true
            }

        default:
            return state
    }
}
