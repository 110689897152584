import React from 'react';
import {Box,withStyles }from '@material-ui/core';

const StyledBox=withStyles({
    root:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        padding:'5px 10px',
        border:'1px solid red',
        borderRadius:5,
        backgroundColor:'pink',
        color:'red',
    }
})(Box)

export default ({text,icon,...rest})=><StyledBox {...{rest}}><Box mr={1}>{icon}</Box><Box pb={0.5}>{text}</Box></StyledBox>