import { dailyActions } from '../actions';

const init = {
  tabIndex: 0
}

export default function dailyReducer(state = init, action) {
  switch (action.type) {

    case dailyActions.CHANGE_INDEX:
      return {
        ...state,
        tabIndex: action.index
      }
    default:
      return state
  }
}
