import React from 'react';
import Box from '@material-ui/core/Box';

import Button from '../../components/Button';
import Modal from '../../components/Modal/index';
import StyledButton from '../../components/Button';


const ConflictAlert = ({ updateFleetGroupHandler, isSuccess, isLoading, isErrorOccur, setDialog,
    type, operatorConflict, operatorName, operatorOriginalGroup, leaderConflict, leaderName, leaderOriginalGroup
    , ...rest }) => {

    const closeDialog = () => setDialog(prev => ({ ...prev, isOpen: false }))
    return <Modal title="更新車組資料" {...{ closeDialog, ...rest, isLoading, isErrorOccur }} >
        {isLoading &&
            <Box my={2}>正在上載資料到後台</Box>}

        {isSuccess && <Box>
            <Box> 已更新資料</Box>
            <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
                <Button background="purple" onClick={closeDialog}> 關閉</Button>
            </Box>
        </Box>}

        {type === "conflict"
            ?
            <>
                <Box color="red"> {operatorConflict && <Box>{operatorName}本身屬於{operatorOriginalGroup}，系統會自動移除</Box>}
                    {leaderConflict && <Box>{leaderName}本身屬於{leaderOriginalGroup}，系統會自動移除</Box>}
                    <Box> 確定繼續？</Box>
                </Box>

                <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
                    <StyledButton background="white" variant="outlined" type="button" onClick={closeDialog}>取消選擇</StyledButton>
                    <Box ml={1}><StyledButton background="purple" onClick={updateFleetGroupHandler}>確定繼續</StyledButton></Box>
                </Box>
            </>
            :

            type === "noChange"
                ?
                <>
                    <Box> 閣下未有更改任何資料。</Box>

                    <Box mt={2} mb={1} display="flex" justifyContent="flex-end">
                        <Button background="purple" onClick={closeDialog}> 關閉</Button>
                    </Box>
                </>
                :
                <></>
        }
    </Modal >
}


export default ConflictAlert;

