import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

export const Divider = withStyles({
    root: {
        width: "100%",
        borderBottom: "1px solid #eaedf3",
        margin: '10px 0px'
    }
})(Box)

