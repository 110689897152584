import { put, takeLatest, call } from 'redux-saga/effects'
import { getAllFleetGroup, createFleetGroup } from '../API';
import { fleetGroupActions, accountActions } from '../actions';

export function* watchGetAllFleetGroup() {
    yield takeLatest(fleetGroupActions.GET_ALL_FLEET_GROUP, fetchAllFleetGroup)
}
export function* watchCreateFleetGroup() {
    yield takeLatest(fleetGroupActions.CREATE_FLEET_GROUP, fetchCreateFleetGroup)
}

export function* fetchAllFleetGroup() {
    try {

        const list = yield call(getAllFleetGroup)

        let groupByFleetId = {}
        for (let item of list) {
            item.fleet._id && groupByFleetId[item.fleet._id]
                ?
                groupByFleetId[item.fleet._id].push(item)
                :
                groupByFleetId[item.fleet._id] = [item]
        }

        yield put({ type: fleetGroupActions.UPDATE_FLEET_GROUP, list, groupByFleetId })
    } catch (e) {
        console.log(e)
        yield put({ type: fleetGroupActions.ERROR_FETCHING_FLEET_GROUP })
    }
}

export function* fetchCreateFleetGroup(action) {
    try {
        const fleetGroup = yield call(() => createFleetGroup(action.payload))

        if (!!fleetGroup.name && !!fleetGroup._id) {
            yield put({ type: fleetGroupActions.GET_ALL_FLEET_GROUP })
            yield put({ type: accountActions.GET_ALL_ACCOUNT })
        } else {
            throw new Error(fleetGroup)
        }
    } catch (e) {
        console.log('error in create new fleet')
        console.log(e)
        yield put({
            type: fleetGroupActions.FAIL_CREATE_FLEET_GROUP, errMsg: e &&
                e.response &&
                e.response.data &&
                e.response.data.result &&
                e.response.data.result.err
        })
    }
}

export default [
    watchGetAllFleetGroup(),
    watchCreateFleetGroup()]