import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';


const StyledCard = withStyles({
    root: {
        margin: '15px 0',
        padding: '20px 15px',
        borderRadius: 8,
        overflow: 'unset'
    }
})(Card)

export const HeadingCard = (props) => {
    return (<StyledCard>
        <Box fontSize="1.5em" fontWeight="bold" mb={1}>{props.title}</Box>
        <Box my={1.5} width="100%" border="0.2px solid #ddd" ></Box>
        <Box mt={1.5} mb={1}>
            {props.children}
        </Box>
    </StyledCard>
    )
}