import { put, takeLatest, call, select, delay } from "redux-saga/effects";
import {
  getRoster,
  setReplacement,
  cancelReplacement,
  confirmReplacement,
  cancelChangeTaxiStatus,
  freezeRoster,
  resendReplacement,
  getPublicHoliday,
  rejectDriverLeave,
  applyLeave,
  setChangeTaxiStatus,
} from "../API";
import { rosterActions } from "../actions";
import { UtilsService } from "../../Service/UtilsService";
import { store } from "../../App";
// import moment from "moment";

// const isRosterShowPastRecord = false;

function* watchGetRoster() {
  yield takeLatest(rosterActions.GET_ROSTER, fetchRoster);
}
function* watchUpdateReplacement() {
  yield takeLatest(rosterActions.FETCH_SET_REPLACEMENT, fetchSetReplacement);
}
function* watchApplyLeave(){
  yield takeLatest(rosterActions.FETCH_APPLY_FOR_LEAVE,fetchApplyLeave)
}

function* watchPendingUnitRequest(){
  yield takeLatest(rosterActions.FETCH_PENDING_UNIT_REQUEST,fetchPendingUnitRequest)
}

function* watchCancelLeave() {
  yield takeLatest(rosterActions.FETCH_CANCEL_LEAVE, fetchCancelLeave);
}
function* watchResendMsg() {
  yield takeLatest(rosterActions.FETCH_RESEND_MSG, fetchResendMsg);
}

function* watchCancelReplacement() {
  yield takeLatest(
    rosterActions.FETCH_CANCEL_REPLACEMENT,
    fetchCancelReplacement
  );
}

function* watchCancelChangeTaxi() {
  yield takeLatest(
    rosterActions.FETCH_CANCEL_CHANGE_TAXI,
    fetchCancelChangeTaxi
  );
}

function* watchConfirmReplacement() {
  yield takeLatest(
    rosterActions.FETCH_CONFIRM_REPLACEMENT,
    fetchConfirmReplacement
  );
}

function* watchCloseRosterDialog(){
  yield takeLatest(rosterActions.CLOSE_ROSTER_CELL_DIALOG, resetRosterDialog);
}

function* watchFreezeRoster() {
  yield takeLatest(rosterActions.FETCH_FREEZE_ROSTER, fetchFreezeRoster);
}

function* watchFetchPublicHoliday() {
  yield takeLatest(rosterActions.FETCH_PUBLIC_HOLIDAY, fetchPublicHoliday);
}

function checkPartTimeAvailableDrivers(today,dayOfWeek,partTimeDrivers=[]){
  let partTimeList = [];
  let availablePartTimeNo = 0;
  partTimeDrivers.forEach((driver) => {
    const isFakeDriver = driver.name.substring(0, 1) === "*";
    const todayReplacement = driver.replacements[today];
    if (!driver.driver.dayoff.includes(dayOfWeek) && !todayReplacement) {
      partTimeList.push({...driver,group:"替更"});
      !isFakeDriver && availablePartTimeNo++;
    }
  });
  return { availablePartTimeNo, partTimeList };
}

function countVacantTaxiAndFullTimeDrivers(today,dayOfWeek,shift, list, replacement){
  let vacantTaxiNo = 0;
  let availableFullTimeNo=0;
  const fullTimeList=[];
  const shiftDriver = shift === "night" ? "nightShiftDriver" : "morningShiftDriver";
  const todayReplacement = replacement && replacement[today]&& replacement[today].data; //To check whether replacement exist on that date. If not, replacements equals to undefined

  list.forEach((taxi) => {
    //list: the info of each taxi, including the full time drivers' dayoff,leaves,pendingUnits info.

    //Part I :DriverListFormation: check whether full time driver in this taxi or not. And whether the driver has already assigned another taxi.
    if (
      taxi[shiftDriver] &&
      (!todayReplacement || !todayReplacement[taxi[shiftDriver]._id])
    ) {
      if(taxi.pendingUnits[today]){
        fullTimeList.push({
          ...taxi[shiftDriver],
          name: `${taxi[shiftDriver].name}`,
          group: "已取消更份",
        });
        availableFullTimeNo++;
      }
    }

    //Part II: check whether any driver assigned for this taxi fot that day.
    const replacementDriverId = // check whether this taxi has replacement driver.
      todayReplacement &&
      Object.keys(todayReplacement).find(
        (driverId) =>
          todayReplacement[driverId].taxi._id === taxi._id &&
          todayReplacement[driverId].status === "ACCEPTED"
      );
    if (replacementDriverId) {
      return;
    }
    if (
      !taxi[shiftDriver] || //no full time driver for this taxi
      (taxi[shiftDriver] &&
        taxi[shiftDriver].driver.dayoff.includes(dayOfWeek)) || //full time driver is dayoff today
      (taxi.leaves && taxi.leaves[today]) || //full time driver applied leave today
      (taxi.pendingUnits && taxi.pendingUnits[today])
    ) {
      vacantTaxiNo++;
    }
  });
  return {vacantTaxiNo,fullTimeList,availableFullTimeNo};
}

function checkVacantTaxiAndFormDriverList(index, partTimeDrivers, date, shift, list, replacement){
  let today = date.format("YYYY-MM-DD");
  let dayOfWeek = index === 6 ? 0 : index + 1;
  let {partTimeList,availablePartTimeNo}=checkPartTimeAvailableDrivers(today,dayOfWeek,partTimeDrivers);
  let {vacantTaxiNo,fullTimeList,availableFullTimeNo}=countVacantTaxiAndFullTimeDrivers(today,dayOfWeek,shift, list, replacement);
  const driverList=fullTimeList.concat(partTimeList);
  const availableDriverNo=availablePartTimeNo+availableFullTimeNo;

  return {today,driverList,availableDriverNo,vacantTaxiNo}
}

export function* fetchRoster(action) {
  const { type, ...rest } = action;
  try {
    const res = yield call(() => getRoster(rest));
    const partTimeDrivers = res.driverList || [];

    if (res.list) {
      const week = UtilsService.weekFormation(action.from);
      const editedWeek = week.map((item, index) => {
        const {driverList,availableDriverNo,vacantTaxiNo}=
        checkVacantTaxiAndFormDriverList(
          index, 
          partTimeDrivers, 
          item.date,     
          action.shift,
          res.list,
          res.replacements || {})
        
        return {
          ...item,
          availableDriverNo,
          driverList,
          vacancy: vacantTaxiNo
        };
      });

      yield put({
        type: rosterActions.UPDATE_ROSTER,
        list: res.list,
        replacement: res.replacements || {},
        transaction: res.transactions || {},
        week: editedWeek,
        todayIsPosted: res.todayIsPosted,
        ...rest,
      });
    } else {
      throw new Error(res);
    }
  } catch (e) {
    console.log(e);
    yield put({ type: rosterActions.ERROR_FETCHING_ROSTER });
  }
}

export function* fetchSetReplacement(action) {
  const { driver, date, shift, taxi, fleet, fleetGroup, from, to, category } = action;
  try {
    const response = yield call(() =>
      setReplacement({ driver, date, shift, taxi, fleet, fleetGroup })
    );
    if (response._id) {
      yield put({ type: rosterActions.SET_REPLACEMENT,category });
      yield put({
        type: rosterActions.GET_ROSTER,
        shift,
        fleet,
        fleetGroup,
        from,
        to,
      });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_SET_REPLACEMENT,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export function* fetchResendMsg(action) {
  try {
    const response = yield call(() => resendReplacement(action.id));
    if (response.id) {
      yield put({ type: rosterActions.SUCCESS_RESEND_MSG });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_RESEND_MSG,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export function* fetchApplyLeave(){
  try{
    const dialog=yield select(state=>state.roster.dialog);
    const {dayOfWeek, taxiId,fullTimeDriverId,selectedDate}=dialog;
    yield put({type:rosterActions.SET_LOADING_ROSTER_CELL_DIALOG});
    const response = yield call(()=>applyLeave({
      driver: fullTimeDriverId,
      date:selectedDate,
      status: "APPROVED",
    }))
    if (response._id) {
      yield put({ type: rosterActions.INCREASE_VACANCY, dayOfWeek })
      yield put({ type: rosterActions.ADD_REPLACEMENT, ...response, taxiId, targetDate: selectedDate })
      yield put({ type: rosterActions.CLOSE_ROSTER_CELL_DIALOG})
  
    } else {
      throw new Error(response);
    }
  }catch(e){
    console.log('error in saga apply leave',e?.response?.data?.result?.err)
    yield put({type:rosterActions.FAIL_TO_APPLY_LEAVE,err:e?.response?.data?.result?.err||"發生錯誤，請假未能完成"});
  }
}

export function* fetchPendingUnitRequest() {
  try {
    const dialog = yield select((state) => state.roster.dialog);
    const {
      dayOfWeek,
      taxiId,
      fullTimeDriverName,
      fullTimeDriverId,
      selectedDate,
    } = dialog;
    yield put({ type: rosterActions.SET_LOADING_ROSTER_CELL_DIALOG });
    const response = yield call(() =>
      setChangeTaxiStatus({
        driver: fullTimeDriverId,
        date: selectedDate,
      })
    );
    if (response._id) {
      yield put({ type: rosterActions.INCREASE_VACANCY, dayOfWeek });
      yield put({
        type: rosterActions.INCREASE_AVAILABLE_DRIVERS,
        driverName: fullTimeDriverName,
        driverId: fullTimeDriverId,
        dayOfWeek,
      });
      yield put({
        type: rosterActions.PENDING_UNIT_REQUEST,
        ...response,
        taxiId,
        targetDate: selectedDate,
      });
      yield put({ type: rosterActions.CLOSE_ROSTER_CELL_DIALOG });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log("error in saga fetchPendingUnitRequest",e?.response?.data?.result?.err);
    yield put({
      type: rosterActions.FAIL_TO_FETCH_PENDING_UNIT,
      err: e?.response?.data?.result?.err || "發生錯誤，請假未能完成",
    });
  }
}

export function* fetchCancelLeave(action) {
  const selector = store.getState().roster.selector;
  try {
    const response = yield call(() => rejectDriverLeave(action.leaveId, true));
    if (response._id) {
      yield put({ type: rosterActions.SET_REPLACEMENT, category:action.category });
      yield put({
        type: rosterActions.GET_ROSTER,
        shift: selector.shift,
        fleet: action.fleet,
        fleetGroup: action.fleetGroup,
        from: selector.from,
        to: selector.to,
      });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_FETCH_CANCEL_LEAVE,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export function* fetchCancelReplacement(action) {
  const { type, status, ...rest } = action;
  try {
    const response = yield call(() => cancelReplacement(action.id));
    if (response._id) {
      if (status === "ACCEPTED") {
        yield put({ type: rosterActions.INCREASE_VACANCY, ...rest });
      }

      yield put({ type: rosterActions.INCREASE_AVAILABLE_DRIVERS, ...rest });
      yield put({ type: rosterActions.DELETE_REPLACEMENT, ...rest });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_CANCEL_REPLACEMENT,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export function* fetchCancelChangeTaxi(action){
  const { type, ...rest } = action;
  try {
    const response = yield call(() => cancelChangeTaxiStatus(action.pendingUnits));
    if (response._id) {
      yield put({ type: rosterActions.DELETE_PENDING_UNIT, ...rest });
      yield put({ type: rosterActions.ADD_BACK_FULL_TIME_DRIVER, ...rest });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_CANCEL_CHANGE_TAXI,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

export function* fetchConfirmReplacement(action) {
  const { type, ...rest } = action;
  try {
    console.log("fetch Confirm Replacement ");
    const response = yield call(() => confirmReplacement(action.id));
    console.log(response);
    if (response._id) {
      yield put({ type: rosterActions.UPDATE_CONFIRM_REPLACEMENT, ...rest });
    } else {
      throw new Error(response);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_FETCH_CONFIRM_REPLACEMENT,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

function* resetRosterDialog(){
  yield delay(500)
  yield put({type:rosterActions.RESET_ROSTER_CELL_DIALOG})
}

export function* fetchFreezeRoster(action) {
  const { payload } = action; // action.replacementId
  try {
    const response = yield call(() => freezeRoster(payload)); // action.replacementId for API
    if (Array.isArray(response)) {
      yield put({ type: rosterActions.FREEZE_ROSTER });
    } else {
      console.log("Error in Posting");
      console.log(response);
      yield put({
        type: rosterActions.FAIL_FETCH_FREEZE_ROSTER,
        errMsg: response && response.result && response.result.err,
      });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: rosterActions.FAIL_FETCH_FREEZE_ROSTER,
      errMsg:
        e &&
        e.response &&
        e.response.data &&
        e.response.data.result &&
        e.response.data.result.err,
    });
  }
}

function* fetchPublicHoliday() {
  try {
    const response = yield call(getPublicHoliday);

    const vevent = response.vcalendar[0].vevent;
    const publicHoliday = vevent.reduce((r, e) => {
      r[e.dtstart[0]] = e.summary;
      return r;
    }, {});
    yield put({
      type: rosterActions.FETCH_PUBLIC_HOLIDAY_SUCCESS,
      publicHoliday,
    });
  } catch (e) {
    console.log(e);
    yield put({ type: rosterActions.FETCH_PUBLIC_HOLIDAY_FAIL });
  }
}


export default [
  watchGetRoster(),
  watchUpdateReplacement(),
  watchCloseRosterDialog(),
  watchApplyLeave(),
  watchPendingUnitRequest(),
  watchCancelLeave(),
  watchResendMsg(),
  watchCancelReplacement(),
  watchCancelChangeTaxi(),
  watchConfirmReplacement(),
  watchFreezeRoster(),
  watchFetchPublicHoliday()
]