import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormHelperText } from '@material-ui/core';

export function TrueOrFalseCheckBox({ editable, form, field, title, trueWording, falseWording }) {

    const handleChange = e => {
        let value = Boolean(e.target.value)
        form.handleChange({ target: { name: field, value: form.values[field] === value ? !value : value } })
    };

    return (
        <>
            <FormControl
                error={form.touched[field] && form.errors[field] ? true : false}
                disabled={editable ? false : true}>
                <FormLabel component="legend">{title}</FormLabel>
                <FormGroup
                    aria-label="position"
                    row
                >
                    {[
                        { value: true, label: trueWording },
                        { value: false, label: falseWording },
                    ].map(item => (
                        <FormControlLabel
                            key={item.label}
                            onChange={handleChange}
                            control={<Checkbox color="primary" />}
                            checked={form.values[field] === item.value}
                            label={item.label}
                            labelPlacement="end"
                            value={item.value}
                        />

                    ))}
                    <FormHelperText>{form.touched[field] && form.errors[field] && form.errors[field]}</FormHelperText>
                </FormGroup>
            </FormControl>
        </>
    );
}
