export const taxiActions = {
  GET_ALL_TAXI: "GET_ALL_TAXI",
  UPDATE_TAXI: "UPDATE_TAXI",
  ERROR_FETCHING_TAXI: "ERROR_FETCHING_TAXI",

  GET_DELETED_TAXI: "GET_DELETED_TAXI",
  UPDATE_DELETED_TAXI: "UPDATE_DELETED_TAXI",

  CREATE_TAXI: "CREATE_TAXI",
  ADD_NEW_TAXI: "ADD_NEW_TAXI",
  FAIL_CREATE_TAXI: "FAIL_CREATE_TAXI",

  GET_TAXI_LOCATION: "GET_TAXI_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  ERROR_FETCHING_LOCATION: "ERROR_FETCHING_LOCATION",

  REMOVE_ENABLE_TAXI: "REMOVE_ENABLE_TAXI",
};
