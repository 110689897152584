import React, { useState } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";

// import Button from '../../components/Button';
// import Modal from '../../components/Modal/index';

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import GetData from "../../components/GetData";
import Table from "../../components/Table";
import { columns } from "../ClientListing/index";
import { dayOfWeek } from "../../constants";
import { withStyles } from "@material-ui/core";
import { pageUIActions } from "../../redux/actions";

const DaySelector = withStyles({
  root: {
    width: "100px",
    marginBottom: "10px",
  },
})(Box);
const ReplacementDriverList = ({
  partTimeDriver,
  shift,
  updateOriginalTable,
  //from redux store (State)
}) => {
  const [day, setDay] = useState(new Date().getDay());
  const driverList =
    partTimeDriver[shift] &&
    partTimeDriver[shift].filter(
      (driver) => !driver.driver.dayoff.includes(day)
    );

  updateOriginalTable(driverList, "replacement");

  return (
    <>
      <GetData driver />
      <DaySelector>
        <Box>選擇</Box>
        <FormControl variant="outlined" margin="dense">
          <Select
            id="day-selector"
            value={day}
            onChange={(event) => {
              setDay(event.target.value);
            }}
          >
            {dayOfWeek.map(({ name, label }) => (
              <MenuItem value={name}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DaySelector>

      <Table
        // rows={driverList}
        columns={columns.driver}
        pathname="/driver/"
        selectBy="_id"
        component="replacement"
        origin={"replacement"}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  shift: state.roster.selector.shift,
  partTimeDriver: state.driver.partTime,
  replacement: state.roster.replacement,
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) =>
    dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementDriverList);
