import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "../../../components/Divider";
import Button from "@material-ui/core/Button";
import NavigationIcon from "@material-ui/icons/Navigation";
import MapOverlay from "../../../components/MapOverlay";

const StyledGPSIcon = withStyles({
  root: {
    height: "60px",
    width: "60px",
    borderRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "grey",
  },
})(({ classes }) => (
  <Box className={classes.root}>
    <NavigationIcon htmlColor="#FFF" fontSize="large" />
  </Box>
));

export const GPSBtn = ({ gps }) => {
  const [isMapOpen, setIsMapOpen] = React.useState(false);

  const onGPSClick = () => {
    setIsMapOpen(true);
  };

  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <Box mx={2}>
          <StyledGPSIcon />
        </Box>

        <Box mx={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onGPSClick}
            disabled={gps && gps.Latitude && gps.Longitude ? false : true}
          >
            現在地點
          </Button>
        </Box>
      </Box>
      <Divider />
      <MapOverlay {...{ isMapOpen, setIsMapOpen, gps }} />
    </Grid>
  );
};
