import React from 'react';
import { Box } from '@material-ui/core';

export const StyledTextBox = ({ label, children }) => {
    return (
        <Box>
            <Box>{label}</Box>
            {children}
        </Box>)
}
