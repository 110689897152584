import { put, takeLatest, call } from 'redux-saga/effects'
import { getAllFleet, createFleet } from '../API';
import { fleetActions, accountActions } from '../actions';

export function* watchGetAllFleet() {
    yield takeLatest(fleetActions.GET_ALL_FLEET, fetchAllFleet)
}
export function* watchCreateFleet() {
    yield takeLatest(fleetActions.CREATE_FLEET, fetchCreateFleet)
}


export function* fetchAllFleet() {
    try {
        const fleet = yield call(getAllFleet)
        yield put({ type: fleetActions.UPDATE_FLEET, fleet })
    } catch (e) {
        yield put({ type: fleetActions.ERROR_FETCHING_FLEET })
    }
}

export function* fetchCreateFleet(action) {
    try {
        const fleet = yield call(() => createFleet(action.payload))
        if (!!fleet.name && !!fleet._id) {
            yield put({ type: fleetActions.ADD_NEW_FLEET, fleet })
            yield put({ type: accountActions.GET_ALL_ACCOUNT })
        } else {
            console.log(fleet)
            throw new Error(fleet)
        }
    } catch (e) {
        console.log('error in create new fleet')
        console.log(e.toString())
        console.log(e.foo)
        console.log(e.stack)
        console.log(e.message)
        console.log(e.response)
        console.log(e.response.data.result.err)
        yield put({
            type: fleetActions.FAIL_CREATE_FLEET, errMsg: e &&
                e.response &&
                e.response.data &&
                e.response.data.result &&
                e.response.data.result.err
        })
    }
}

export default [
    watchGetAllFleet(),
    watchCreateFleet()]