import createAuthRefreshInterceptor from "axios-auth-refresh";
import { userActions } from "../actions";
var store = null;
const API_ROOT = "https://api.taxipos.org";
const version = `/api/v1`
const getToken = () => localStorage.getItem("access_token");

const axios = require("axios");

const instance = axios.create({
  baseURL: API_ROOT + version,
  timeout: 8000,
});

const authedInstance = axios.create({
  baseURL: API_ROOT + version,
  timeout: 8000,
});

const refreshAuthLogic = async (failedRequest) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append("refresh_token", localStorage.getItem("refresh_token"));
  urlencoded.append("grant_type", "refresh_token");
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  urlencoded.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
  try {
    const res = await instance.post("/auth/token", urlencoded);
    authedInstance.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
    console.log("on Refresh Token success", res.data);
    for (let key in res.data) {
      localStorage.setItem(key, res.data[key]);
    }
    failedRequest.response.config.headers[
      "Authorization"
    ] = `Bearer ${res.data.access_token}`;
  } catch (err) {
    console.log("refresh token fail, logout");
    store.dispatch({ type: userActions.LOGOUT_USER });
  }
  return Promise.resolve();
};

createAuthRefreshInterceptor(authedInstance, refreshAuthLogic, {
  skipWhileRefreshing: false,
});

authedInstance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `Bearer ${getToken()}`;
  return request;
});

const queryBuilder = (obj) => {
  const reducer = (acc, key) => acc + key + "=" + obj[key] + "&";
  const result = Object.keys(obj).reduce(reducer, "");
  if (Object.keys(obj).length > 0) {
    return "?" + result.substring(0, result.length - 1);
  }
  return result;
};

const genericProtectedAPI = async (method, path, payload) => {
  var response = null;
  switch (method) {
    case "GET":
      response = await authedInstance.get(path);
      break;
    case "POST":
      response = await authedInstance.post(path, payload);
      break;
    case "PUT":
      response = await authedInstance.put(path, payload);
      break;
    case "DELETE":
      response = await authedInstance.delete(path);
      break;
    default:
      throw new Error("Method not defined");
  }
  return response.data;
};

export function apiStore(reduxStore) {
  store = reduxStore;
}

export async function login(data) {
  const urlencoded = new URLSearchParams();
  urlencoded.append("username", data.username);
  urlencoded.append("password", data.password);
  urlencoded.append("grant_type", "password");
  urlencoded.append("client_id", process.env.REACT_APP_CLIENT_ID);
  const res = await instance.post("/auth/token", urlencoded);
  authedInstance.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
  return res.data;
}

export async function getUserData(id = "me") {
  return await genericProtectedAPI("GET", `/users/${id}`);
}

//incomplete
export async function updatePassword(payload) {
  return await genericProtectedAPI("PUT", `/users/me`, payload);
}

//incomplete
export async function resetUserPassword(userId) {
  return await genericProtectedAPI("POST", `/users/reset-password/${userId}`);
}

export async function getAllAccounts(payload = {}) {
  return await genericProtectedAPI("GET", `/users${queryBuilder(payload)}`);
}

export async function createUser(payload) {
  return await genericProtectedAPI("POST", `/users`, payload);
}

export async function updateUser(userId, form) {
  return await genericProtectedAPI("PUT", `/users/${userId}`, form);
}

export async function toggleDisabledUser(userId) {
  return await genericProtectedAPI("POST", `/users/${userId}`);
}

export async function getAllFleet() {
  return await genericProtectedAPI("GET", "/fleets");
}

export async function createFleet(payload) {
  return await genericProtectedAPI("POST", "/fleets", payload);
}

export async function updateFleet(fleetId, payload) {
  return await genericProtectedAPI("PUT", `/fleets/${fleetId}`, payload);
}

export async function deleteFleet(fleetId) {
  return await genericProtectedAPI("DELETE", `/fleets/${fleetId}`);
}

export async function getAllFleetGroup() {
  return await genericProtectedAPI("GET", "/fleetGroups");
}

export async function createFleetGroup(payload) {
  return await genericProtectedAPI("POST", "/fleetGroups", payload);
}

export async function updateFleetGroup(fleetGroupId, payload) {
  return await genericProtectedAPI(
    "PUT",
    `/fleetGroups/${fleetGroupId}`,
    payload
  );
}

export async function deleteFleetGroup(fleetGroupId) {
  return await genericProtectedAPI("DELETE", `/fleetGroups/${fleetGroupId}`);
}

export async function getGeolocation() {
  return await genericProtectedAPI("GET", `/geolocation`);
}

export async function getTaxi(id) {
  return await genericProtectedAPI("GET", `/taxies/${id}`);
}

export async function getTaxies(payload = {}) {
  return await genericProtectedAPI("GET", `/taxies${queryBuilder(payload)}`);
}

export async function createTaxi(payload) {
  return await genericProtectedAPI("POST", `/taxies`, payload);
}

export async function updateTaxi(taxiId, payload) {
  return await genericProtectedAPI("PUT", `/taxies/${taxiId}`, payload);
}

export async function toggleDisabledTaxi(taxiId) {
  return await genericProtectedAPI("POST", `/taxies/${taxiId}`);
}

export async function getRoster(payload) {
  return await genericProtectedAPI("GET", `/roster${queryBuilder(payload)}`);
}

export async function getFleetRoster(id) {
  return await genericProtectedAPI("GET", `/roster/fleet/${id}`);
}

export async function freezeRoster(payload) {
  return await genericProtectedAPI("POST", `/roster/posting`, payload);
}

export async function setChangeTaxiStatus(payload) {
  return await genericProtectedAPI("POST", `/pendingUnits`,payload);
}

export async function cancelChangeTaxiStatus(id) {
  return await genericProtectedAPI("PUT", `/pendingUnits/${id}`,{
    status: "UNVALID"
  });
}

export async function getLeave(payload = "") {
  return await genericProtectedAPI("GET", `/leaves${queryBuilder(payload)}`);
}
export async function applyLeave(payload) {
  return await genericProtectedAPI("POST", `/leaves`, payload);
}
export async function acceptDriverLeave(id) {
  return await genericProtectedAPI("PUT", `/leaves/${id}`, {
    status: "APPROVED",
  });
}
export async function rejectDriverLeave(id, cancel = false) {
  return await genericProtectedAPI("PUT", `/leaves/${id}`, {
    status: cancel ? "CANCELED" : "REJECTED",
  });
}
export async function getDriverWorkApplication(payload = "") {
  return await genericProtectedAPI(
    "GET",
    `/workApplication${queryBuilder(payload)}`
  );
}
export async function rejectDriverWorkApplication(id) {
  return await genericProtectedAPI("PUT", `/workApplication/${id}`, {
    status: "REJECTED",
  });
}
export async function getReplacement(payload = "") {
  return await genericProtectedAPI(
    "GET",
    `/replacements${queryBuilder(payload)}`
  );
}
export async function setReplacement(payload) {
  return await genericProtectedAPI("POST", `/replacements`, payload);
}

export async function cancelReplacement(id) {
  return await genericProtectedAPI("PUT", `/replacements/${id}`, {
    status: "CANCELED",
  });
}

export async function resendReplacement(id) {
  return await genericProtectedAPI("PUT", `/replacements/resend/${id}`);
}

export async function confirmReplacement(id) {
  return await genericProtectedAPI("PUT", `/replacements/${id}`, {
    status: "ACCEPTED",
  });
}

export async function uploadPresignedURL(type, resourceId, filename) {
  return await genericProtectedAPI(
    "GET",
    `/presignedUrl/upload/${type}/${resourceId}/${filename}`
  );
}

export async function downloadPresignedURL(fileKey) {
  return await genericProtectedAPI("GET", `/presignedUrl/download/${fileKey}`);
}

export async function getTaxiTransaction(payload) {
  return await genericProtectedAPI(
    "GET",
    `/taxiTransaction${queryBuilder(payload)}`
  );
}

export async function getDriverTransaction(payload) {
  return await genericProtectedAPI(
    "GET",
    `/driverTransaction${queryBuilder(payload)}`
  );
}

export async function getRentArrears(payload) {
  return await genericProtectedAPI(
    "GET",
    `/rentArrears${queryBuilder(payload)}`
  );
}

export async function submitTaxiTransaction(payload) {
  return await genericProtectedAPI("POST", `/taxiTransaction/submit`, payload);
}

export async function submitDriverTransaction(payload) {
  return await genericProtectedAPI(
    "POST",
    `/driverTransaction/submit`,
    payload
  );
}

export async function getTransaction(id, page) {
  return await genericProtectedAPI(
    "GET",
    `/transaction?driver=${id}&page=${page}`
  );
}

export async function updateDeposit(payload) {
  return await genericProtectedAPI(
    "PUT",
    `/transaction/deposit/update`,
    payload
  );
}

export async function submitRentalTransaction(payload) {
  return await genericProtectedAPI("POST", `/transaction/submit`, payload);
}

export async function getIncome(payload) {
  return await genericProtectedAPI(
    "GET",
    `transaction/income${queryBuilder(payload)}`
  );
}

export async function getAllAccidents(payload) {
  return await genericProtectedAPI("GET", `/accidents${queryBuilder(payload)}`);
}

export async function getAccident(id) {
  return await genericProtectedAPI("GET", `/accidents/${id}`);
}

export async function createAccident(payload) {
  return await genericProtectedAPI("POST", `/accidents`, payload);
}

export async function updateAccident(accidentId, payload) {
  return await genericProtectedAPI("PUT", `/accidents/${accidentId}`, payload);
}

export async function getPublicHoliday() {
  return await genericProtectedAPI("GET", `/publicHoliday`);
}

export async function getAllMaintenance() {
  return await genericProtectedAPI("GET", `/maintenance`);
}

export async function getMaintenance(id) {
  return await genericProtectedAPI("GET", `/maintenance/${id}`);
}

export async function updateMaintenance(taxiId, payload) {
  return await genericProtectedAPI("PUT", `/maintenance/${taxiId}`, payload);
}
