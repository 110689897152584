import React, { useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { SpaceBetweenBox, RowAlignCenterBox } from "../../components/Box";
import Button from "../../components/Button";
import AppBar from "../../components/AppBar";
import { UserService } from "../../Service/UserService";
import { useParams } from "react-router";
import { useHistory, withRouter } from "react-router-dom";
import { checker } from "../../permission";
import { connect } from "react-redux";
import Back from "../../components/Back";
import FilterBox from "../../components/FilterBox";
import KeywordSearch from "../../components/KeywordSearch";
import DeleteModal from "../../components/DeleteModal";
import { DeletedItemOverlay } from "../../components/DeletedItemOverlay";
import StyledButton from "../../components/Button";

const PrevNextFileBtn = ({ onClickHandler, children }) => (
  <Box mr={1}>
    <StyledButton
      onClick={onClickHandler}
      variant="outlined"
      background="paleGrey"
      height="30px"
      width="80px"
    >
      {children}
    </StyledButton>
  </Box>
);

const ManagementAppBar = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    list,
    role,
    group,
    setDialogStatus,
    isTabSelectorShow,
    tabIndex,
    disabledOverlay,
    isLoadingDisableOverlay,
    enableHandler,
  } = props;
  const isCreatePage = props.type.toLowerCase() === "create";

  const heading = useMemo(() => {
    switch (group + "_" + isCreatePage) {
      case "taxi_true":
        return "新增的士";
      case "taxi_false":
        return "的士資料";
      case "driver_true":
        return "新增司機";
      case "driver_false":
        return "司機資料";
      case "taxi_owner_true":
        return "新增車主";
      case "taxi_owner_false":
        return "車主資料";
      default:
        return "";
    }
  }, [group, isCreatePage]);

  const isResetPwBtnShow =
    ((group === "taxi_owner" && checker(role, "taxi_owner:reset")) ||
      (group === "driver" && checker(role, "driver:reset"))) &&
    !isCreatePage;

  const isSubmitBtnShow = isCreatePage
    ? (group === "taxi_owner" && checker(role, "taxi_owner:create")) ||
      (group === "driver" && checker(role, "driver:create")) ||
      (group === "taxi" && checker(role, "taxi:create"))
    : (group === "taxi_owner" && checker(role, "taxi_owner:update")) ||
      (group === "driver" && checker(role, "driver:update")) ||
      (group === "taxi" && checker(role, "taxi:update"));

  const handleResetPassword = () => {
    UserService.handleResetPassword(id, setDialogStatus, group);
  };
  const [prevFile, setPrevFile] = useState(null);
  const [nextFile, setNextFile] = useState(null);

  const browseAnotherFile = ({ group, id }) => {
    history.replace(`/${group === "taxi_owner" ? "taxi-owner" : group}/${id}`);
  };

  React.useEffect(() => {

    const length = list.length;
    if (length > 1) {
      const index = list.findIndex((file) => file._id === id);
      if (index - 1 >= 0) {
        setPrevFile(list[index - 1]._id);
      }
      if (index + 1 < length) {
        setNextFile(list[index + 1]._id);
      }
    }
    return () => {
      setPrevFile(null);
      setNextFile(null);
    };
  }, [list, id]);

  return (
    <AppBar>
      <SpaceBetweenBox>
        <RowAlignCenterBox style={{ minWidth: "60%" }}>
          <Back />
          <Box mx={4}>
            <Typography variant="h5">{heading}</Typography>
          </Box>
          {group === "taxi_owner" && isTabSelectorShow && !!tabIndex && (
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <KeywordSearch component={"owner-taxi"} style={{ flex: 1 }} />
              <FilterBox type="taxi" component={"owner-taxi"} />
            </div>
          )}
          {prevFile &&!tabIndex&& (
            <PrevNextFileBtn
              onClickHandler={() => browseAnotherFile({ group, id: prevFile })}
            >
              上一檔案
            </PrevNextFileBtn>
          )}
          {nextFile &&!tabIndex&& (
            <PrevNextFileBtn
              onClickHandler={() => browseAnotherFile({ group, id: nextFile })}
            >
              下一檔案
            </PrevNextFileBtn>
          )}
        </RowAlignCenterBox>

        <RowAlignCenterBox>
          {checker(role, `${group}:delete`) &&
            !isCreatePage &&
            !disabledOverlay && <DeleteModal category={group} id={id} />}

          {isResetPwBtnShow && !disabledOverlay && (
            <Box ml={2}>
              <Button background="orange" onClick={handleResetPassword}>
                重設密碼
              </Button>
            </Box>
          )}

          {isSubmitBtnShow && (
            <Box ml={2}>
              <Button
                background="purple"
                type={disabledOverlay ? "button" : "submit"}
                onClick={disabledOverlay ? enableHandler : undefined}
              >
                {disabledOverlay ? "重啟帳戶" : isCreatePage ? "新增" : "更新"}
              </Button>
            </Box>
          )}
        </RowAlignCenterBox>
      </SpaceBetweenBox>
      <DeletedItemOverlay
        disabledOverlay={disabledOverlay}
        isLoadingDisableOverlay={isLoadingDisableOverlay}
      />
    </AppBar>
  );
};

const mapStateToProps = (state, ownProps) => {
  var list;
  let taxiDetailRedirectFromOwnerPage=(state.ui.origin&&state.ui.origin.includes("owner_taxi")&&ownProps.group==="taxi");
  if(taxiDetailRedirectFromOwnerPage){
    list=state.ui.ownerTaxi?.rows||[];
  } else if (state.ui.rows) {
    list = state.ui.rows;
  } else {
    switch (ownProps.group) {
      case "taxi_owner":
        list = state.taxiOwner.list||[]
        break;
      case "taxi":
        list = state.taxi.list||[]
        break;
      case "driver":
        list = state.driver.list||[]
        break;
      default:
        list = [];
    }
  }

  return {
    list: list,
    role: state.user.role,
  };
};

export default withRouter(connect(mapStateToProps)(ManagementAppBar));
