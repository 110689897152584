import React from "react";
import { connect } from "react-redux";
import { LinkButton } from "../../components/Button";
import Table from "../../components/Table";
import PageContainer from "../../components/PageContainer";
import AppBar from "../../components/AppBar";
import { RowAlignCenterBox, SpaceBetweenBox } from "../../components/Box";
import GetData from "../../components/GetData";
import KeywordSearch from "../../components/KeywordSearch";
import { pageUIActions } from "../../redux/actions";
import { getAllAccidents } from "../../redux/API";
import { checker } from "../../permission";
import FilterBox from "../../components/FilterBox";

export const columns = [
  { type: "accident.plateNumber", label: "車牌號碼", minWidth: 80 },
  { type: "title", label: "標題", minWidth: 80 },
  {
    type: "accident.driver",
    nested: "name",
    label: "司機",
    minWidth: 80,
  },
  {
    type: "accident.owner",
    label: "車主名稱",
    minWidth: 80,
  },
  {
    type: "accident.insCompany",
    label: "保險公司",
    minWidth: 80,
  },
  {
    type: "occurrenceDateTime",
    label: "日期",
    minWidth: 80,
  },
  {
    type: "accident.status",
    label: "狀態",
    minWidth: 80,
  },
  {
    type: 'createdAt',
    label: "創建日期",
    minWidth: 80,
  }
]


const Accident = ({
  role,
  origin,
  updateOriginalTable,
  dailyMode,
  isRefreshNeeded,
  toggleRefreshFlag
}) => {

  React.useEffect(() => {
    if (isRefreshNeeded || (dailyMode ? origin !== "daily_accident" : origin !== "accident")) {
      getAllAccidents(dailyMode ? { status: "ONGOING" } : "")
        .then(res => {
          updateOriginalTable(res, dailyMode ? "daily_accident" : "accident")
        })
        .catch(e => {
          console.log(e)
          console.log('Error in getting all accidents info')
        })
    }
    if (isRefreshNeeded) {
      toggleRefreshFlag()
    }
  }, [updateOriginalTable, dailyMode, origin, isRefreshNeeded, toggleRefreshFlag]);

  return dailyMode ?
    <>
      <GetData taxi />
      <Table
        columns={columns}
        pathname={'/accident/'}
        selectBy="_id"
        component={"accident"}
      />
    </>
    :
    <>
      <GetData taxi />
      <AppBar>
        <SpaceBetweenBox>

          <RowAlignCenterBox style={{ minWidth: '50%' }}>
            <KeywordSearch component={"accident"} style={{ flex: "1" }} />
            <FilterBox type={"accident"} component={"accident"} />
          </RowAlignCenterBox>



          {checker(role, "accident:create") && <RowAlignCenterBox>
            <LinkButton background="purple" to={'/accident/create'}>
              新增意外
                        </LinkButton>
          </RowAlignCenterBox>}
        </SpaceBetweenBox>
      </AppBar>

      <PageContainer>
        <Table
          columns={columns}
          pathname={"/accident/"}
          selectBy="_id"
          component={"accident"}
          origin={dailyMode ? "daily_accident" : "accident"}
        />
      </PageContainer>
    </>

}

const mapStateToProps = (state) => ({
  role: state.user.role,
  origin: state.ui.origin,
  isRefreshNeeded: state.ui.refresh
});
const mapDispatchToProps = (dispatch) => ({
  updateOriginalTable: (rows, origin) => dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE, rows, origin }),
  toggleRefreshFlag: () => dispatch({ type: pageUIActions.TOGGLE_REFRESH_FLAG, refresh: false }),
  // updateTable: (rows) => dispatch({ type: pageUIActions.UPDATE_ORIGINAL_TABLE_WITH_KEYWORD, rows }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accident)
