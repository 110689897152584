import { taxiOwnerActions } from "../actions";
const init_state = {
  status: "init",
  list: [],
  isReady: false,

  deletedTaxiOwnerList: [],
  isDeletedTaxiOwnerReady: false,
};

export default function taxiOwnerReducer(state = init_state, action) {
  switch (action.type) {
    case taxiOwnerActions.GET_ALL_TAXI_OWNER:
      return {
        ...state,
        status: "loading",
        isReady: false,
      };
    case taxiOwnerActions.UPDATE_ALL_TAXI_OWNER:
      return {
        ...state,
        status: "success",
        list: action.list,
        isReady: true,
      };
    case taxiOwnerActions.GET_DELETED_TAXI_OWNER:
      return {
        ...state,
        deletedTaxiOwnerList: [],
        isDeletedTaxiOwnerReady: false,
      };
    case taxiOwnerActions.UPDATE_DELETED_TAXI_OWNER:
      return {
        ...state,
        deletedTaxiOwnerList: action.deletedTaxiOwnerList,
        isDeletedTaxiOwnerReady: true,
      };
    case taxiOwnerActions.REMOVE_ENABLE_TAXI_OWNER:
      return {
        ...state,
        deletedTaxiOwnerList: state.deletedTaxiOwnerList.filter(
          (item) => item._id !== action.id
        ),
        isDeletedTaxiOwnerReady: true,
      };
    case taxiOwnerActions.FAIL_GET_ALL_TAXI_OWNER:
      return {
        ...state,
        status: "error",
        isReady: false,
      };
    default:
      return state;
  }
}
